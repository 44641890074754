import React from 'react'

import { Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import NextStepsCarousel from 'design-system/components/guide/next-steps-carousel'
import { Section } from 'design-system/components/guide/utils'

const nextSteps = [
  {
    leadText:
      'Once you and your designer <span class="bold">agree on changes</span>, they will begin <span class="bold">work on the next revision.</span>',
    image: `images/interactive-guides/design-review/next-step-design-revision-01.svg`,
  },
  {
    leadText:
      'Seemingly <span class="bold">small changes</span> to appliances or measurements may require extensive rework, so it is best to <span class="bold">confirm these as early as possible.</span>',
    image: `images/interactive-guides/design-review/next-step-design-revision-02.svg`,
  },
  {
    leadText:
      'Your designer will <span class="bold">reach out when a revision is available</span> on your dashboard. You can comment directly on the design if there is anything you would like to discuss on the next call.',
    image: `images/interactive-guides/design-review/next-step-design-revision-03.svg`,
  },
  {
    leadText:
      'When you have approved your designs, <span class="bold">the balance payment will send your order to the factory for confirmation.</span>',
    description: 'All deposits are deducted from the total due.',
    image: `images/interactive-guides/design-review/next-step-design-revision-05.svg`,
  },
  {
    leadText:
      'A <span class="bold">dedicated technical designer</span> will work with the processing team in Germany to <span class="bold">double-check your project order’s specifications</span>, from appliances down to safety.',
    description:
      'When the order is ready, it’ll be sent to production and you’ll be notified of the <span class="bold">Factory Quoted Delivery Date.</span>',
    image: `images/interactive-guides/design-review/next-step-design-revision-04.svg`,
  },
]

const nextTradeSteps = [
  {
    leadText:
      'Once you land on <span class="bold">design changes</span>, your designer will work on the <span class="bold">next revision</span>.',
    image: `images/interactive-guides/design-review/next-step-trade-design-revision-01.svg`,
  },
  {
    leadText:
      'There are <span class="bold">3 design revisions</span> included in our process.',
    description:
      '5% of our clients require more than three revisions. Additional revisions are available at cost ($199 per revision).',
    image: `images/interactive-guides/design-review/next-step-trade-design-revision-02.svg`,
  },
  {
    leadText:
      'At each revision you’ll jump on a call with your designer to review <span class="bold">shop level drawings</span> and <span class="bold">itemized costing</span>.',
    description:
      'Additional photorealistic visuals are available at cost ($299 for a set of 5)',
    image: `images/interactive-guides/design-review/next-step-trade-design-revision-03.svg`,
  },
  {
    leadText:
      'Your designer will reach out when the revision is complete and they’re ready to <span class="bold">schedule the next call.</span>',
    image: `images/interactive-guides/design-review/next-step-trade-design-revision-04.svg`,
  },
  {
    leadText:
      'Revisions will also be posted to your <span class="bold">Project Dashboard</span> so you can further review after your call. There you can <span class="bold">make additional comments</span> directly on the design.',
    image: `images/interactive-guides/design-review/next-step-trade-design-revision-05.svg`,
  },
  {
    leadText:
      'When you have approved your design, the <span class="bold">balance payment</span> will <span class="bold">send your order</span> to the factory <span class="bold">for confirmation</span>.',
    description: 'All deposits are deducted from the total due.',
    image: `images/interactive-guides/design-review/next-step-trade-design-revision-06.svg`,
  },
  {
    leadText:
      "A dedicated <span class='bold'>technical designer</span> will work with the processing team in Germany to <span class='bold'>double check your project</span> order's specifications, from appliances down to safety.",
    description:
      "When the order is ready, it'll be sent to production and you'll be notified of the Factory Quoted Delivery Date.",
    image: `images/interactive-guides/design-review/next-step-trade-design-revision-07.svg`,
  },
]

const NextSteps = () => {
  const { isTradeProject } = useProject()

  return (
    <Section id="next-steps">
      <Grid centered>
        <NextStepsCarousel
          isTradeProject={isTradeProject}
          slideData={isTradeProject ? nextTradeSteps : nextSteps}
        />
      </Grid>
    </Section>
  )
}

export default NextSteps
