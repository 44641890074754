import React, { createRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import axios from 'axios'
import { navigate } from 'gatsby'
import sanitize from 'sanitize-filename'
import { Divider, Form, Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import Loader from 'components/shared/loader'
import { apolloClient } from 'context/authorized-apollo/provider'
import { notifyError } from 'context/notifications/trigger'
import useUser from 'context/user/use'
import useUserMutateNoContext from 'context/user/use-mutate-no-context'
import Button from 'design-system/components/button'
import { PhoneInput, validatePhone } from 'design-system/components/form'
import SaveToast from 'design-system/components/save-toast'
import AppLayout from 'design-system/containers/app-layout'
import {
  apiResultToAWSFormData,
  GET_USER_PROFILE_FILE_UPLOAD_URL,
  GetUserProfileFileUploadPayload,
  GetUserProfileFileUploadVariables,
} from 'queries/aws'
import { StyledForm } from 'styles/app/components/form'
import { Breakpoint, Colors } from 'styles/app/system'

const Module = (
  props: RouteComponentProps<{
    location?: string
    project_id: string
  }>,
) => {
  const ref = createRef<HTMLInputElement>()
  const { user, refetch } = useUser()
  const { updateUser } = useUserMutateNoContext()
  const [loading, setLoading] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [profile, setProfile] = useState({
    companyName: user?.data?.companyName || '',
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    phone: user?.phone || '',
    picture: user?.picture || '',
  })
  console.log('user', user)
  const handleSave = async () => {
    setLoading(true)
    if (formChanged) {
      await updateUser({
        variables: {
          data: {
            firstName: profile.firstName,
            lastName: profile.lastName,
            phone: profile.phone,
            picture: profile.picture,
            data: {
              ...user.data,
              companyName: profile.companyName,
            },
          },
          where: {
            id: user.id,
          },
        },
      })
      refetch()
    }
    setLoading(false)
  }

  const onChangeInput = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setProfile({ ...profile, [name]: value })

  const formChanged =
    profile.firstName !== (user?.firstName || '') ||
    profile.lastName !== (user?.lastName || '') ||
    profile.phone !== (user?.phone || '') ||
    profile.picture !== (user?.picture || '') ||
    profile.companyName !== (user?.data?.companyName || '')

  const navigateTo = props.project_id
    ? `/app/projects/${props.project_id}`
    : '/app'

  return (
    <>
      <AppLayout>
        <Grid>
          {!user ? (
            <Grid.Row centered>
              <Grid.Column>
                <p style={{ textAlign: 'center' }}>
                  No matching owner profile found for this account.
                </p>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          {user && (
            <>
              <Grid.Row centered columns="equal">
                <Grid.Column computer={8} tablet={13} mobile={16}>
                  <div className="flex">
                    {profile.picture && !uploadLoading ? (
                      <StyledImage className="avatar" src={profile.picture} />
                    ) : (
                      <ImagePlaceholder>
                        {uploadLoading ? (
                          <Loader />
                        ) : (
                          <FontAwesomeIcon size="2x" icon={['fal', 'user']} />
                        )}
                      </ImagePlaceholder>
                    )}
                    <Button
                      kind="ghost"
                      text="Upload Profile Photo"
                      color="gray"
                      fontAwesomeIcon="upload"
                      iconAnimation="up"
                      onClick={() => {
                        document.getElementById('profileInput')?.click()
                      }}
                    />
                    <input
                      id="profileInput"
                      accept=".jpg,.jpeg,.png"
                      hidden
                      multiple={false}
                      onChange={async (e) => {
                        try {
                          setUploadLoading(true)
                          const picture = await uploadImage(
                            e.target.files?.length ? e.target.files[0] : null,
                            user.id,
                          )
                          setProfile({
                            ...profile,
                            picture: picture,
                          })
                        } catch (error) {
                          notifyError("Couldn't update profile picture")
                        } finally {
                          setUploadLoading(false)
                        }
                      }}
                      ref={ref}
                      type="file"
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns="equal">
                <Grid.Column computer={8} tablet={13} mobile={16}>
                  <StyledForm styled={{ whiteInputs: true }}>
                    <Form.Group widths="equal">
                      <Form.Input
                        {...(formChanged &&
                          !profile.firstName && {
                            error: {
                              content: (
                                <p className="red small">
                                  Please enter a first name
                                </p>
                              ),
                            },
                          })}
                        label="Name"
                        name="firstName"
                        onChange={onChangeInput}
                        placeholder="First Name"
                        required
                        value={profile.firstName}
                      />
                      <Form.Input
                        {...(formChanged &&
                          !profile.lastName && {
                            error: {
                              content: (
                                <p className="red small">
                                  Please enter a last name
                                </p>
                              ),
                            },
                          })}
                        label="Last Name"
                        name="lastName"
                        onChange={onChangeInput}
                        placeholder="Last Name"
                        required
                        value={profile.lastName}
                      />
                    </Form.Group>
                    <Form.Group>
                      <PhoneInput
                        {...(formChanged &&
                          !profile.phone && {
                            error: {
                              content: (
                                <p className="red small">
                                  Please enter a phone number
                                </p>
                              ),
                            },
                          })}
                        label="Phone number"
                        name="phone"
                        onChange={(value) =>
                          setProfile({ ...profile, phone: value })
                        }
                        placeholder="Primary number"
                        required
                        value={profile?.phone}
                        width={9}
                      />
                      <Form.Input
                        disabled
                        label="Email"
                        name="email"
                        onChange={onChangeInput}
                        placeholder="Email"
                        required
                        value={profile.email}
                        width={7}
                      />
                    </Form.Group>
                  </StyledForm>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      </AppLayout>
      <Divider hidden section />
      <SaveToast
        disabled={
          !profile.lastName ||
          !profile.firstName ||
          !validatePhone(profile.phone)
        }
        loading={loading}
        onClick={async () => {
          await handleSave()
          navigate(navigateTo)
        }}
        show={formChanged}
      />
    </>
  )
}

export default Module

const uploadImage = async (
  file: File | null,
  userId: string,
): Promise<string> => {
  if (file) {
    const fileRemovedSpecialCharacters = file.name.replace(
      /[^a-zA-Z0-9.\-_]/g,
      '',
    )
    const fileNameSanitized = sanitize(fileRemovedSpecialCharacters)
    const res = await apolloClient?.mutate<
      GetUserProfileFileUploadPayload,
      GetUserProfileFileUploadVariables
    >({
      mutation: GET_USER_PROFILE_FILE_UPLOAD_URL,
      variables: { data: { fname: fileNameSanitized, userId } },
    })

    const url = res?.data?.uploadUrlUserProfileFile.url
    const dataFields = apiResultToAWSFormData(
      res?.data?.uploadUrlUserProfileFile ?? {},
    )
    const data = new FormData()
    Object.keys(dataFields).forEach((k) => {
      data.append(k, dataFields[k])
    })
    data.append('acl', 'public-read')
    data.append('Content-Type', file.type)
    data.append('file', file)

    const uploadRes = url ? await axios.post(url, data) : null
    if (uploadRes?.status === 204) {
      return `${res?.data?.uploadUrlUserProfileFile?.url}/${res?.data?.uploadUrlUserProfileFile?.key}`
    }
    throw new Error(
      `[${uploadRes?.status}] Upload image failed: ${uploadRes?.statusText}`,
    )
  }

  return ''
}

const StyledImage = styled(Image)`
  &&&& {
    border-radius: 100% !important;
    height: 80px;
    margin: 0 24px 0 0;
    width: 80px;

    @media ${Breakpoint.downFromSmallMobile} {
      height: 70px;
      width: 70px;
    }
  }
`

const ImagePlaceholder = styled.div`
  align-items: center;
  background: ${Colors.gray200};
  border-radius: 50%;
  display: flex;
  height: 133px;
  justify-content: center;
  margin: 0 24px 0 0;
  width: 133px;

  @media ${Breakpoint.downFromSmallMobile} {
    height: 80px;
    width: 80px;
  }
`
