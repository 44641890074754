import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { parse } from 'query-string'

import HeaderBasic from 'components/app/project/header/header-basic'
import ProjectDeposit from 'components/shared/project/payment/versions/project-deposit'
import { getDefaultPayment } from 'context/payment/utils'
import PaymentSourceManyProvider from 'context/payment-source/provider-many'
import useProject from 'context/project/use'
import useProjectPaymentMutate from 'context/project/use-payment-mutate'
import fireEvent from 'context/tracking/events'
import AppLayout from 'design-system/containers/app-layout'
import { PaymentMethodType, PaymentSource, PaymentType } from 'types/payment'

// only works for project deposit currently, could be extended to other types
const PaymentIndexByType = (
  props: RouteComponentProps<{
    payment_type: string
    project_id: string
  }>,
) => {
  const { isTradeProject, paymentsAll, project, refetch } = useProject()
  const { loadingCreate, createPaymentAndRefetch } = useProjectPaymentMutate()
  const { deepLink } = (props.location &&
    parse(props.location?.search, {
      parseBooleans: true,
    })) ?? { deepLink: false }

  // redirect if invalid or to existing payment if one exists
  useEffect(() => {
    if (props.payment_type !== 'project-deposit') {
      navigate(`/app/projects/${props.project_id}`, {
        replace: true,
      })
      return
    }

    const existing = paymentsAll.find(
      (p) => p.metadata?.type === PaymentType.PROJECT_DEPOSIT,
    )
    if (existing) {
      navigate(`/app/projects/${props.project_id}/payments/${existing.id}`, {
        replace: true,
      })
    }
    return
  }, [paymentsAll, isTradeProject, props.payment_type])

  const payment = getDefaultPayment(PaymentType.PROJECT_DEPOSIT, isTradeProject)

  const onPay = async (
    paymentSource?: PaymentSource,
    paymentMethod?: PaymentMethodType,
  ) => {
    await createPaymentAndRefetch({
      variables: {
        data: {
          amount: payment.amount,
          description: payment.description,
          metadata: {
            ...payment.metadata,
            method: paymentMethod,
          },
          stripeSourceId: paymentSource?.id,
          project: {
            connect: { id: project.id },
          },
        },
      },
      onCompleted: async () => {
        refetch()
        fireEvent({
          type: 'project_deposit_completed',
        })
      },
    })
  }

  return (
    <PaymentSourceManyProvider>
      <AppLayout
        header={
          deepLink ? (
            <HeaderBasic
              onBack={() => navigate(`/app/projects/${props.project_id}`)}
            />
          ) : undefined
        }
      >
        <ProjectDeposit
          isTradeProject={isTradeProject}
          loading={loadingCreate}
          onPay={onPay}
          payment={payment}
        />
      </AppLayout>
    </PaymentSourceManyProvider>
  )
}

export default PaymentIndexByType
