import React from 'react'

import { RouteComponentProps } from '@reach/router'

import RoomProjectFileUploads from 'components/app/project/room/project-file-uploads'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import AppLayout from 'design-system/containers/app-layout'
import { ProjectFileType } from 'types/project-file'

const Module = (
  _: RouteComponentProps<{
    location?: string
    project_id: string
    room_id: string
  }>,
) => {
  const { isTradeProject } = useProject()
  const { inspirationImages } = useRoom()
  return (
    <AppLayout>
      <RoomProjectFileUploads
        chooseProspectFiles={!isTradeProject}
        projectFiles={inspirationImages}
        projectFileType={ProjectFileType.INSPIRATION_IMAGE}
        title={
          isTradeProject
            ? 'Are there any inspiration images you want us to look at?'
            : 'Are there any images that would help us see your vision?'
        }
      />
    </AppLayout>
  )
}

export default Module
