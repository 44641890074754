import { useContext, useState } from 'react'

import { useMutation } from '@apollo/client'

import useProject from 'context/project/use'
import useProjectFileMutateNoContext from 'context/project-file/use-mutate-no-context'
import {
  DELETE_PROJECT_FILE,
  DeleteProjectFilePayload,
  DeleteProjectFileVariables,
  UPDATE_PROJECT_FILE,
  UpdateProjectFilePayload,
  UpdateProjectFileVariables,
} from 'queries/project-file'
import { ProjectFile, ProjectFileType } from 'types/project-file'

import { RoomContext } from './provider'

const useRoomProjectFileMutate = () => {
  const { project } = useProject()
  const { room, refetch } = useContext(RoomContext)
  const { updateProjectFile } = useProjectFileMutateNoContext()
  const [loadingConnect, setLoadingConnect] = useState(false)
  const [loadingDisconnect, setLoadingDisconnect] = useState(false)

  const [updateProjectFileAndRefetch, { loading: loadingUpdate }] = useMutation<
    UpdateProjectFilePayload,
    UpdateProjectFileVariables
  >(UPDATE_PROJECT_FILE, {
    onCompleted: refetch,
  })

  const [deleteProjectFileAndRefetch, { loading: loadingDelete }] = useMutation<
    DeleteProjectFilePayload,
    DeleteProjectFileVariables
  >(DELETE_PROJECT_FILE, {
    onCompleted: refetch,
  })

  const onProjectFilesUploadedRefetch = (_: Partial<ProjectFile>[]) => {
    refetch()
  }

  // to disconnect a prospect file from room
  const disconnectProjectFileAndRefetch = async (f: Partial<ProjectFile>) => {
    setLoadingDisconnect(true)
    await updateProjectFile({
      variables: {
        data: {
          type: ProjectFileType.PROSPECT_PHOTO,
          project: { disconnect: true },
          room: { disconnect: true },
        },
        where: {
          id: f.id ?? '',
        },
      },
    })
    setLoadingConnect(false)
    refetch()
  }

  const connectProjectFilesAndRefetch = async (
    ids: string[],
    projectFileType: ProjectFileType,
  ) => {
    setLoadingConnect(true)
    await Promise.all(
      ids.map(async (id) => {
        return await updateProjectFile({
          variables: {
            data: {
              type: projectFileType,
              project: { connect: { id: project.id } },
              room: { connect: { id: room?.id ?? '' } },
            },
            where: {
              id,
            },
          },
        })
      }),
    )
    setLoadingConnect(false)
    refetch()
  }

  return {
    connectProjectFilesAndRefetch,
    deleteProjectFileAndRefetch,
    disconnectProjectFileAndRefetch,
    loadingConnect,
    loadingDelete,
    loadingDisconnect,
    loadingUpdate,
    onProjectFilesUploadedRefetch,
    updateProjectFileAndRefetch,
  }
}

export default useRoomProjectFileMutate
