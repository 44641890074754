import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AddCommentForm from 'components/shared/project/project-file/comment/form'
import CommentList from 'components/shared/project/project-file/comment/list'
import {
  BottomSection,
  InfoPopup,
  SidebarHeader,
} from 'components/shared/project/project-file/utils'
import useProject from 'context/project/use'
import useProjectFile from 'context/project-file/use'
import Button from 'design-system/components/button'
import { FormDivider } from 'styles/app/system'

import ReviewDocumentModal from './modal-review-document'
import SubmitModal from './modal-submit'

interface SidebarCommentsProps {
  sidebarToggle: () => void
}

const SidebarComments = ({ sidebarToggle }: SidebarCommentsProps) => {
  const { project } = useProject()
  const { commentsNumbered, creationMode, refetch } = useProjectFile()
  const [showReviewModal, toggleReviewModal] = useState<boolean>(
    !project.data?.fileReviewModalDone,
  )
  const [showSubmitModal, toggleSubmitModal] = useState<boolean>(false)

  return (
    <>
      <div>
        <p className="up-mobile">
          To add comments, click on the document and leave notes.
        </p>
        <p>
          When you’re done, submit your comments to complete your review.
          <InfoPopup
            content={
              <p style={{ padding: '5px' }}>
                Once submitted, you will review your comments with your designer
                on a design review call.
                <br />
                <br />
                Once the design review call is complete, a new revision will be
                uploaded typically within 5-10 business days.
              </p>
            }
            position="top left"
            style={{ minWidth: '340px' }}
            trigger={
              <FontAwesomeIcon
                className="gray up-mobile"
                icon={['fal', 'question-circle']}
                style={{
                  cursor: 'pointer',
                  fontSize: '14px',
                  marginLeft: '10px',
                }}
              />
            }
          />
        </p>
        <Button
          kind="solid"
          size="fluid"
          onClick={() => toggleSubmitModal(true)}
          text={
            commentsNumbered?.length
              ? 'Submit Comments'
              : 'Submit Without Comments'
          }
        />
        <FormDivider style={{ margin: '20px 0' }} />
      </div>
      <SidebarHeader className="down-computer">
        <p className="subtitle">Comments</p>
        <FontAwesomeIcon onClick={sidebarToggle} icon={['fal', 'times']} />
      </SidebarHeader>
      <CommentList onClose={sidebarToggle} />
      {creationMode && <div style={{ padding: '85px 0' }} />}
      <BottomSection>
        <div className="content">
          <AddCommentForm onClose={sidebarToggle} onCommentCreated={refetch} />
        </div>
      </BottomSection>
      <SubmitModal
        open={showSubmitModal}
        onClose={() => toggleSubmitModal(false)}
      />
      <ReviewDocumentModal
        showReviewModal={showReviewModal}
        toggleReviewModal={(toggle) => toggleReviewModal(toggle)}
        sidebarToggle={sidebarToggle}
      />
    </>
  )
}

export default SidebarComments
