import React from 'react'

import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import RoomCard from 'design-system/components/cards/room-card'
import { Section } from 'design-system/components/guide/utils'
import { RoomType, RoomTypeImage } from 'types/room'

const Scope = () => {
  const { isFORMStaff } = useAuth()
  const { roomsUnarchived, project } = useProject()

  return (
    <Section id="scope">
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Scope</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="row-gap">
          {roomsUnarchived?.map((r) => {
            const typeIndex = Object.values(RoomType).indexOf(r.type)

            return (
              <Grid.Column key={r.id} computer={5} tablet={8} mobile={16}>
                <RoomCard
                  color={r.data.tagColor}
                  image={`${
                    process.env.GATSBY_CDN_ROOT
                  }/images/app/illustrations/${
                    Object.values(RoomTypeImage)[typeIndex]
                  }`}
                  noActions
                  subtitle={r.data?.descriptionAdditional}
                  title={r.type}
                />
              </Grid.Column>
            )
          })}
        </Grid.Row>
        {isFORMStaff ? (
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Button
                color="blue"
                fontAwesomeIcon="arrow-right"
                kind="solid"
                onClick={() =>
                  navigate(`/app/projects/${project.id}/create-room`)
                }
                text="Edit Rooms"
                size="medium"
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}
      </Grid>
    </Section>
  )
}

export default Scope
