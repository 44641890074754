import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { isEqual } from 'lodash'
import { useMedia } from 'react-use'
import { Grid } from 'semantic-ui-react'

import * as SelectionUtils from 'components/app/project/room/selection/utils'
import DesignManyProvider from 'context/design/provider-many'
import useDesignMany from 'context/design/use-many'
import useRoom from 'context/room/use'
import useRoomMutate from 'context/room/use-mutate'
import Button from 'design-system/components/button'
import SelectionCardExpandSelect from 'design-system/components/selection-card/card-expand-select'
import SelectionLayout from 'design-system/containers/selection-layout'
import { Breakpoint } from 'styles/app/system'
import { Design } from 'types/design'
import { filterAndSortRendersForGallery } from 'views/app/catalog/utils'

type SelectionInspirationProps = RouteComponentProps<{
  project_id: string
  room_id: string
}>

const SelectionInspiration = (props: SelectionInspirationProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const { room } = useRoom()
  const { loadingUpdate, updateRoomAndRefetch } = useRoomMutate()
  const { designs } = useDesignMany()

  const formInspiration = room?.designsInspiration ?? []

  const [selection, setSelection] = useState<Design[]>(formInspiration)

  const unsavedChanges = !isEqual(
    formInspiration.map((f) => f.id).sort(),
    selection.map((f) => f.id).sort(),
  )

  const onSelect = (design: Design) => {
    if (selection.find((s) => s.id === design.id))
      setSelection(selection.filter((s) => s.id !== design.id))
    else setSelection([...selection, design])
  }

  const onDelete = (inspirationId: string) => {
    setSelection(selection.filter((s) => s.id !== inspirationId))
  }

  const onSave = async () => {
    await updateRoomAndRefetch({
      variables: {
        data: {
          designsInspiration: {
            set:
              selection.map((s) => ({
                id: s.id,
              })) || [],
          },
        },
        where: {
          id: room?.id ?? '',
        },
      },
    })
  }

  return (
    <SelectionLayout
      back={
        unsavedChanges ? (
          <Button
            kind="solid"
            loading={loadingUpdate}
            onClick={async () => {
              await onSave()
              navigate(
                `/app/projects/${props.project_id}/rooms/${props.room_id}/design-call`,
              )
            }}
            text="Save"
          />
        ) : (
          <Button
            color="gray"
            fontAwesomeIcon="arrow-left"
            iconAnimation="left"
            iconPosition="left"
            kind="text"
            onClick={() =>
              navigate(
                `/app/projects/${props.project_id}/rooms/${props.room_id}/design-call`,
              )
            }
            text={isMobile ? '' : 'Back'}
          />
        )
      }
      selection={
        selection.length ? (
          <Grid.Row centered>
            <Grid.Column
              computer={11}
              tablet={14}
              mobile={16}
              style={{ display: 'grid' }}
            >
              <SelectionUtils.Selection>
                {selection.map((s) => (
                  <SelectionUtils.OptionSelected key={s.id}>
                    <p className="no-margin small dark-gray">
                      {s.metadataGallery?.name}
                    </p>
                    <span onClick={() => onDelete(s.id)}>
                      <FontAwesomeIcon icon={['fal', 'times']} />
                    </span>
                  </SelectionUtils.OptionSelected>
                ))}
              </SelectionUtils.Selection>
            </Grid.Column>
          </Grid.Row>
        ) : null
      }
      title="Inspiration"
    >
      <Grid>
        <Grid.Row centered stretched style={{ rowGap: '20px' }}>
          {designs.map((d) => {
            const galleryRender = filterAndSortRendersForGallery(d.renders)[0]

            return (
              <Grid.Column key={d.id} computer={3} tablet={4} mobile={8}>
                <SelectionCardExpandSelect
                  expandAndSelect
                  imageUrl={`${
                    process.env.GATSBY_CDN_ROOT
                  }/${encodeURIComponent(
                    galleryRender?.metadata?.keyCompressed ||
                      galleryRender?.key,
                  )}`}
                  imageRatio="1/1"
                  onExpand={() =>
                    navigate(
                      `/app/catalog/${process.env.GATSBY_DEFAULT_CATALOG}/designs/${d.id}`,
                    )
                  }
                  onSelect={() => onSelect(d)}
                  selected={!!selection.find((s) => s.id === d.id)}
                  title={d.metadataGallery?.name}
                />
              </Grid.Column>
            )
          })}
        </Grid.Row>
      </Grid>
    </SelectionLayout>
  )
}

export default (
  props: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  return (
    <DesignManyProvider
      defaultFilters={{
        galleryStatus: 'showingInGallery',
        take: undefined,
      }}
    >
      <SelectionInspiration {...props} />
    </DesignManyProvider>
  )
}
