import React, { useEffect } from 'react'

import { MeetingBooker } from 'components/app/project/meeting'
import MeetingProvider from 'context/meeting/provider'
import useProject from 'context/project/use'
import { MeetingTypeProject } from 'types/meeting'

import { StepViewProps } from '../types'

const BookingView = ({
  setNextDisabled,
  setOnNext,
  navigate,
}: StepViewProps) => {
  const { meetingDesignScheduled, project } = useProject()

  useEffect(() => {
    setOnNext(undefined)
    setNextDisabled(true)
  }, [])

  useEffect(() => {
    if (meetingDesignScheduled)
      navigate?.(
        `/app/projects/${project.id}/meeting?meetingId=${meetingDesignScheduled.id}`,
      )
  }, [meetingDesignScheduled])

  return (
    <MeetingProvider meetingType={MeetingTypeProject.DESIGN}>
      <MeetingBooker />
    </MeetingProvider>
  )
}

export default BookingView
