import React, { useEffect, useState } from 'react'

import { useMedia } from 'react-use'
import { Accordion } from 'semantic-ui-react'
import styled from 'styled-components'

import Button from 'design-system/components/button'
import { Breakpoint, Colors } from 'styles/app/system'

export default ({
  children,
  onClick,
  open,
  titleExtras,
  titleText,
}: {
  children: React.ReactNode
  onClick?: () => void
  open: boolean
  titleExtras?: React.ReactNode
  titleText: string
}) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const [active, setActive] = useState<boolean>(false)

  useEffect(() => {
    setActive(open)
  }, [open])

  return (
    <AccordionComponent fluid>
      <Accordion.Title
        active={active}
        onClick={() => {
          setActive(!active)
          onClick?.()
        }}
        className="accordion-component-title"
      >
        <div className="title">
          <p className="caption no-margin">{titleText}</p>
          {titleExtras}
        </div>
        {!isMobile ? (
          <Button
            color="blue"
            kind="text"
            text="Show issues resolved"
            fontAwesomeIcon={active ? 'chevron-up' : 'chevron-down'}
          />
        ) : (
          <Button
            color="blue"
            kind="solid"
            fontAwesomeIcon={active ? 'chevron-up' : 'chevron-down'}
          />
        )}
      </Accordion.Title>
      <Accordion.Content
        active={active}
        className="accordion-component-content"
      >
        {children}
      </Accordion.Content>
    </AccordionComponent>
  )
}

export const AccordionComponent = styled(Accordion)`
  &&& {
    background: none;
    border-bottom: 1px solid ${Colors.gray200};
    margin: 0;

    .accordion-component-title {
      align-items: center;
      background: ${Colors.gray100};
      box-shadow: none;
      display: flex;
      justify-content: space-between;
      padding: 16px 20px !important;

      &.active {
        background: ${Colors.white};
        border-top: 1px solid ${Colors.gray500};
      }

      div.title {
        align-items: center;
        display: flex;
        gap: 16px;

        @media ${Breakpoint.onlyMobile} {
          align-items: baseline;
          flex-direction: column;
          gap: 8px;
        }
      }

      svg {
        font-size: 15px;
      }
    }

    .accordion-component-content {
      background: ${Colors.white};
      padding: 0 !important;
    }
  }
`
