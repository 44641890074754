import { useContext, useMemo } from 'react'

import { parseProjectsByStatus } from 'context/company/utils'

import { UserContext } from './provider'

const useUser = () => {
  const { user, projects, refetch, startPolling, stopPolling } =
    useContext(UserContext)

  const inTradeProgram = !!user?.data?.inTradeProgram
  const company = user?.company

  const parsedUserProjects = useMemo(
    () => parseProjectsByStatus(projects || []),
    [projects],
  )

  return {
    company,
    inTradeProgram,
    parsedUserProjects,
    projects,
    refetch,
    startPolling,
    stopPolling,
    user,
  }
}

export default useUser
