import React from 'react'

import { navigate } from 'gatsby'
import { Divider, Grid } from 'semantic-ui-react'

import { getLatestPlan } from 'context/design/utils'
import useProject from 'context/project/use'
import { parseFiles } from 'context/project-file/utils-many'
import { getRoomTypeWithDescription, parseDesigns } from 'context/room/utils'
import FilePlaceholderCard from 'design-system/components/cards/file-placeholder-card'
import PrimaryCard from 'design-system/components/cards/primary-card'
import {
  Section,
  UnderlineSubtitle,
} from 'design-system/components/guide/utils'
import { Design } from 'types/design'
import { ProjectFile } from 'types/project-file'
import { RoomType } from 'types/room'
import { getRoomImageByType } from 'views/utils'

const CommentsMessages = () => {
  const { project, isTradeProject, roomsUnarchived } = useProject()
  const floorPlans = isTradeProject
    ? roomsUnarchived.reduce<ProjectFile[]>((acc, r) => {
        const { floorPlans } = parseFiles(r.files)
        if (floorPlans.length) acc.push(...floorPlans)
        return acc
      }, [])
    : []

  const designs = project.rooms.reduce<
    (Design & {
      roomId: string
      roomType: RoomType
      roomTypeWithDescription: string
    })[]
  >((acc, r) => {
    const { latestDesignPublished } = parseDesigns(r)
    const roomTypeWithDescription = getRoomTypeWithDescription(r)
    if (latestDesignPublished && !latestDesignPublished.order?.id)
      acc.push({
        ...latestDesignPublished,
        roomId: r.id,
        roomType: r.type,
        roomTypeWithDescription,
      })
    return acc
  }, [])

  return (
    <Section id="comments-messages">
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">
              {isTradeProject
                ? 'Designs to review'
                : 'What are the main things to cover today?'}
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row centered stretched className="row-gap">
          {!isTradeProject ? (
            <Grid.Column largeScreen={4} computer={5} tablet={7} mobile={16}>
              <PrimaryCard
                image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/messages.svg`}
                onClick={() => navigate(`/app/projects/${project.id}/messages`)}
                title="Messages"
              />
            </Grid.Column>
          ) : null}
          {designs.map((d) => {
            const latestPlan = getLatestPlan(d)
            return (
              <Grid.Column
                key={d.id}
                largeScreen={4}
                computer={5}
                tablet={7}
                mobile={16}
              >
                <PrimaryCard
                  image={getRoomImageByType(d.roomType)}
                  subtitle={d.roomTypeWithDescription}
                  onClick={() =>
                    navigate(
                      `/app/projects/${project.id}/rooms/${d.roomId}/designs/${d.id}/plan/${latestPlan?.id}`,
                    )
                  }
                  title="Design Plan"
                />
              </Grid.Column>
            )
          })}
        </Grid.Row>
        {isTradeProject && floorPlans.length ? (
          <>
            <Divider hidden />
            <Grid.Row>
              <Grid.Column>
                <UnderlineSubtitle>Floor Plan</UnderlineSubtitle>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              {floorPlans.map((f) => (
                <Grid.Column key={f.id} computer={4} tablet={8} mobile={16}>
                  <FilePlaceholderCard file={f} />
                </Grid.Column>
              ))}
            </Grid.Row>
          </>
        ) : null}
      </Grid>
    </Section>
  )
}

export default CommentsMessages
