import React from 'react'

import { navigate, RouteComponentProps } from '@reach/router'

import Elements from 'components/shared/guide/elements'
import Fronts from 'components/shared/guide/fronts'
import Handles from 'components/shared/guide/handles'
import { getAddressStreetAndLocalityString } from 'context/address/utils'
import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import { parseOwners } from 'context/project/utils'
import ProspectProvider from 'context/prospect/provider'
import { NavigationProps } from 'design-system/components/guide/navigation'
import { Spacer, UnderlineSubtitle } from 'design-system/components/guide/utils'
import GuideLayout from 'design-system/containers/guide-layout'
import { GuideType } from 'types/utils'
import { getNameForUser } from 'views/utils'

import Appliances from './sections/appliances'
import BrandReviews from './sections/brand-reviews'
import Budget from './sections/budget'
import DesignBrief from './sections/design-brief'
import DesignDeposit from './sections/design-deposit'
import HowWeWork from './sections/how-we-work'
import Inspiration from './sections/inspiration'
import NextCall from './sections/next-call'
import NextSteps from './sections/next-steps'
import ProjectSpace from './sections/project-space'
import QuizResults from './sections/prospect/quiz-results'
import WhyForm from './sections/prospect/why-form'
import StartProject from './sections/start-project'
import Timeline from './sections/timeline'
import WhatWeOffer from './sections/what-we-offer'

type DesignCallProps = RouteComponentProps<{
  room_id: string
}>

const navItems: NavigationProps['items'] = [
  {
    title: 'Learn More',
    icon: ['fal', 'hexagon'],
    items: [
      { title: 'Floor plan', linkTo: 'floor-plan' },
      { title: 'Photos', linkTo: 'photos' },
      { title: 'Inspiration', linkTo: 'inspiration' },
      { title: 'About FORM', linkTo: 'about-form' },
      { title: 'Budget', linkTo: 'budget' },
      { title: 'Timeline', linkTo: 'timeline' },
    ],
  },
  {
    title: 'Set Design Direction',
    icon: ['fal', 'arrow-right'],
    items: [
      { title: 'Fronts', linkTo: 'fronts' },
      { title: 'Handles', linkTo: 'handles' },
      { title: 'Appliances', linkTo: 'appliances' },
      { title: 'Other elements', linkTo: 'elements' },
    ],
  },
  {
    title: 'Get Started',
    icon: ['fal', 'clipboard-list-check'],
    items: [
      { title: 'Next steps', linkTo: 'next-steps' },
      { title: 'Ready to approve', linkTo: 'design-brief' },
      { title: 'Schedule next call', linkTo: 'next-call' },
    ],
  },
]

const DesignCall = ({ room_id }: DesignCallProps) => {
  const { isFORMStaff } = useAuth()
  const { project, primaryProspect } = useProject()

  const { primaryOwner } = parseOwners(project)

  return (
    <ProspectProvider prospect_id={primaryProspect?.id || ''}>
      <GuideLayout
        guideId="design-call-page"
        coverProps={{
          address: getAddressStreetAndLocalityString(project.projectAddress),
          src: '/images/interactive-guides/design-call/kickoff-guide.webp',
          subTitle: getNameForUser(primaryOwner),
          title: 'Initial Call',
        }}
        handleLogoClick={() => navigate(`/app/projects/${project.id}`)}
        introProps={{
          agenda: [
            {
              text: 'Learn more about your project and FORM to see if we could be a good fit.',
            },
            {
              text: 'Agree on a design direction so we can get started on your project.',
            },
          ],
        }}
        navItems={
          isFORMStaff
            ? navItems
            : navItems.filter((i) => i.linkTo !== 'next-call')
        }
      >
        <ProjectSpace />
        <Inspiration projectId={project.id} />
        {!!primaryProspect && <QuizResults />}
        <WhyForm />
        <WhatWeOffer />
        <Budget />
        <Timeline />
        <HowWeWork />
        <BrandReviews />
        <StartProject />
        <Fronts
          projectId={project.id}
          guideType={GuideType.DesignCall}
          header={
            <>
              <UnderlineSubtitle>1/4</UnderlineSubtitle>
              <Spacer height={30} />
            </>
          }
        />
        <Handles
          projectId={project.id}
          guideType={GuideType.DesignCall}
          header={
            <>
              <UnderlineSubtitle>2/4</UnderlineSubtitle>
              <Spacer height={30} />
            </>
          }
        />
        <Appliances projectId={project.id} />
        <Elements
          projectId={project.id}
          roomId={room_id ?? ''}
          header={
            <>
              <UnderlineSubtitle>4/4</UnderlineSubtitle>
              <Spacer height={30} />
            </>
          }
        />
        <NextSteps />
        <DesignDeposit />
        <DesignBrief />
        {isFORMStaff ? <NextCall projectId={project.id} /> : null}
      </GuideLayout>
    </ProspectProvider>
  )
}

export default DesignCall
