import React from 'react'

import { RouteComponentProps } from '@reach/router'

import DeliveryProvider from 'context/delivery/provider'

interface DeliveryIndexProps {
  project_id: string
  delivery_id: string
}

interface DeliveryProps extends RouteComponentProps<DeliveryIndexProps> {
  children?: React.ReactNode
}

const DeliveryIndex = (props: DeliveryProps) => {
  return (
    <DeliveryProvider delivery_id={props.delivery_id}>
      {props.children}
    </DeliveryProvider>
  )
}

export default DeliveryIndex
