import React from 'react'

import { Divider, Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { Hexagon } from 'styles/app/components/icon'
import { Breakpoint } from 'styles/app/system'

type HexBulletRowProps = {
  button?: React.ReactNode
  color?: string
  description?: string
  hexText: string
  image: string
  title: string
}

const HexBulletRow = ({
  button,
  color,
  description,
  hexText,
  image,
  title,
}: HexBulletRowProps) => {
  return (
    <>
      <Grid.Row style={{ padding: '36px 0px' }}>
        <Grid.Column computer={6} tablet={8} mobile={16}>
          <StyledImage src={`${process.env.GATSBY_CDN_ROOT}/${image}`} />
        </Grid.Column>
        <Grid.Column
          computer={10}
          tablet={8}
          mobile={16}
          verticalAlign="middle"
        >
          <div>
            <Hexagon
              styled={{
                background: color as string,
                fontSize: '14px',
                size: '34px',
              }}
            >
              {hexText}
            </Hexagon>
            <h5>{title}</h5>
            {description ? (
              <p
                className="gray"
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            ) : null}
            {button}
          </div>
        </Grid.Column>
      </Grid.Row>
      <StyledDivider />
    </>
  )
}

export default HexBulletRow

const StyledImage = styled(Image)`
  border: 2px solid white;
  border-radius: 4px;
`

const StyledDivider = styled(Divider)`
  @media ${Breakpoint.onlyMobile} {
    margin: 0 !important;
  }
`
