import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { OverflowContainer } from 'design-system/components/guide/utils'
import { Colors } from 'styles/app/system'

const CDN = `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation`

const reviews = [
  {
    brand: ' Good-Housekeepin',
    logo: CDN + '/good-housekeeping.svg',
    review: 'Stunning, well-built, and definitely saved me the cost of custom.',
  },
  {
    brand: 'domino',
    logo: CDN + '/domino.svg',
    review:
      'These New Kitchen Cabinets Cost 40% Less Than Typical Made-to-Order Ones',
  },
  {
    brand: 'elle decor',
    logo: CDN + '/elle-decor.svg',
    review: 'FORM is the online innovator in kitchen design',
  },
  {
    brand: 'Better Homes and Gardens',
    logo: CDN + '/bhg.svg',
    review: 'The brand specializing in direct-to-consumer cabinetry.',
  },
  {
    brand: 'business of home',
    logo: CDN + '/business-of-home.svg',
    review:
      'High-performance, sleek cabinetry at a fraction of the showroom price.',
  },
]

const BrandReviews = () => {
  return (
    <OverflowContainer overflowX>
      {reviews.map((r) => (
        <Review key={r.brand} {...r} />
      ))}
    </OverflowContainer>
  )
}

export default BrandReviews

type ReviewProps = {
  brand: string
  logo: string
  review: string
}

const Review = ({ brand, logo, review }: ReviewProps) => {
  return (
    <ReviewContainer>
      <img src={logo} alt={brand} />
      <FlexRow>
        <FontAwesomeIcon icon={['fas', 'quote-left']} color={Colors.blue200} />
        <p className="small">{review}"</p>
      </FlexRow>
    </ReviewContainer>
  )
}

const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-grow: 1;
  text-align: left;
  min-width: min-content;

  img {
    display: block;
    height: 60px;
    margin: 0 auto;
    max-width: 160px;
  }
`

const FlexRow = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;
`
