import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Image } from 'semantic-ui-react'

import { getSupplierOrdersIssues } from 'context/order/utils'
import { getCourierSupplierOrderSteps } from 'context/supplier-order/utils'
import Button from 'design-system/components/button'
import LogisticsCard from 'design-system/components/cards/logistics-card'
import { SupplierOrder } from 'types/supplier-order'

import DetailsModal from './modal-details'

const getTrackingNumberAndLink = (trackId: string | undefined) => {
  let logoUrl = ''
  let courierLink = ''

  if (!trackId) return

  const idLength = trackId.replace(/\s/g, '').length

  if (idLength < 12) {
    logoUrl = `${process.env.GATSBY_CDN_ROOT}/images/app/dhl-logo.webp`
    courierLink = `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${trackId}`
  } else if (idLength < 18) {
    logoUrl = `${process.env.GATSBY_CDN_ROOT}/images/app/fedex-logo.webp`
    courierLink = `https://www.fedex.com/fedextrack/?trknbr=${trackId}`
  } else if (idLength >= 18) {
    logoUrl = `${process.env.GATSBY_CDN_ROOT}/images/app/ups-logo.webp`
    courierLink = `https://www.ups.com/track?trackingNumber=${trackId}`
  }

  return {
    courierLink,
    logoUrl,
  }
}

const CourierCards = ({
  supplierOrdersByCourier,
  onSeeIssues,
}: {
  supplierOrdersByCourier: SupplierOrder[]
  onSeeIssues?: (shipmentId: string) => void
}) => {
  return (
    <>
      {supplierOrdersByCourier.map((s) => {
        const renderBottomInfo = () => {
          const trackingData = getTrackingNumberAndLink(
            s.data?.nobiliaTrackingNumber,
          )

          if (!trackingData || !s.data?.nobiliaTrackingNumber) return null

          const { courierLink, logoUrl } = trackingData

          return (
            <>
              <p className="no-margin" style={{ display: 'inline-flex' }}>
                Delivery via:{' '}
                {logoUrl ? (
                  <Image
                    centered
                    src={logoUrl}
                    style={{ paddingLeft: '10px', height: '20px' }}
                  />
                ) : null}
              </p>
              <p>
                Tracking number:{' '}
                <Button
                  icon={
                    <FontAwesomeIcon
                      icon={['fal', 'arrow-up-right-from-square']}
                    />
                  }
                  kind="text"
                  onClick={() => window.open(courierLink, '_blank')}
                  text={s.data.nobiliaTrackingNumber}
                />
              </p>
            </>
          )
        }

        return (
          <Grid.Column
            widescreen={4}
            largeScreen={4}
            computer={5}
            tablet={8}
            mobile={16}
            key={s.id}
          >
            <LogisticsCard
              detailsModal={<DetailsModal supplierOrders={[s]} />}
              iconHeader={
                <FontAwesomeIcon
                  icon={['fal', 'truck']}
                  style={{ marginRight: '16px', fontSize: '16px' }}
                />
              }
              parts={1}
              steps={getCourierSupplierOrderSteps(s)}
              bottomInfo={renderBottomInfo()}
              textHeader="Courier"
            />
            {!!getSupplierOrdersIssues([s])?.length && (
              <Button
                color="blue"
                fontAwesomeIcon="file-magnifying-glass"
                kind="text"
                onClick={() => onSeeIssues?.(`${s.id}3`)}
                style={{ marginTop: '8px' }}
                text="See Issues Resolved"
              />
            )}
          </Grid.Column>
        )
      })}
    </>
  )
}

export default CourierCards
