import React from 'react'

import { navigate } from 'gatsby'
import { Divider, Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import { parseOrders } from 'context/project/utils'
import useRoom from 'context/room/use'
import { PrimaryCard } from 'design-system/components/cards'
import { Section } from 'design-system/components/guide/utils'

const DesignBrief = () => {
  const { room } = useRoom()
  const { project } = useProject()

  const { ordersSamples } = parseOrders(project)

  return (
    <Section id="design-brief">
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center" computer={12} tablet={14} mobile={16}>
            <h3 className="gray">
              Are you ready to approve the design brief, so we can start working
              on your design?
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row centered stretched className="row-gap">
          <Grid.Column computer={4} tablet={7} mobile={16}>
            <PrimaryCard
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/designbrief.svg`}
              subtitle={room?.data?.designBriefApprovedAt ? 'Approved' : ''}
              onClick={() =>
                navigate(
                  `/app/projects/${project.id}/rooms/${room?.id}/design-brief`,
                )
              }
              title="Design brief"
            />
          </Grid.Column>
          <Grid.Column computer={4} tablet={7} mobile={16}>
            <PrimaryCard
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/samples.svg`}
              title="Order samples"
              subtitle={ordersSamples.length ? 'Ordered' : ''}
              onClick={() =>
                navigate(`/app/projects/${project.id}/sample-orders`, {
                  state: {
                    fromDesignCall: true,
                  },
                })
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default DesignBrief
