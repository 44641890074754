import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import ThankYou from 'design-system/containers/thank-you'

type ProjectPaymentThankYouPageProps = RouteComponentProps<{
  project_id: string
}>

const ProjectPaymentThankYouPage = ({
  project_id: projectId,
}: ProjectPaymentThankYouPageProps) => {
  const { isTradeProject } = useProject()

  return (
    <ThankYou
      imageUrl={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/dashboard.svg`}
      mainButton={
        <Button
          kind="solid"
          text="Go to Dashboard"
          fontAwesomeIcon="arrow-right-long"
          iconAnimation="right"
          onClick={() => navigate(`/app/projects/${projectId}`)}
        />
      }
      subtitle={
        isTradeProject
          ? 'Your payment has been received. Your designer will reach out shortly to talk about next steps.'
          : 'Your payment has been received. Your project is ready for the revisions stage.'
      }
    />
  )
}

export default ProjectPaymentThankYouPage
