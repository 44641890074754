import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import GenericOptionProvider from 'context/generic-option/provider'
import useGenericOption from 'context/generic-option/use'
import { parseMedia } from 'context/media/utils-many'

import { Slider, StyledGrid } from './utils'

const Module = () => {
  const { genericOption } = useGenericOption()
  const { reference, imageClose, imageMedium } = parseMedia(genericOption.media)
  const gallery = [...reference, ...imageClose, ...imageMedium].slice(0, 4)

  return (
    <StyledGrid stackable>
      <Grid.Row>
        <Grid.Column computer={10} tablet={9} mobile={16}>
          <Slider items={gallery} />
        </Grid.Column>
        <Grid.Column computer={5} tablet={6} mobile={16}>
          <h2>{genericOption.description}</h2>
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  )
}

export default (
  props: RouteComponentProps<{
    option_id: string
    feature_id: string
  }>,
) => {
  return (
    <GenericOptionProvider generic_option_id={props.option_id}>
      <Module />
    </GenericOptionProvider>
  )
}
