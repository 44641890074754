import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  ApprovedSideBar,
  CloseIconMobile,
} from 'components/shared/project/project-file/utils'
import { Hexagon } from 'styles/app/components/icon'
import { Colors } from 'styles/app/system'

const SidebarApproved = ({
  extraButton,
  sidebarToggle,
}: {
  extraButton?: React.ReactNode
  sidebarToggle: () => void
}) => {
  return (
    <div style={{ flex: '1', overflow: 'auto' }}>
      <CloseIconMobile onClick={sidebarToggle}>
        <FontAwesomeIcon icon={['fal', 'times']} />
      </CloseIconMobile>
      <ApprovedSideBar>
        <Hexagon
          styled={{
            background: Colors.greenBright,
            size: '43px',
          }}
        >
          <FontAwesomeIcon icon={['fal', 'check']} />
        </Hexagon>
        <br />
        <h2>Congratulations, your design plan has been approved.</h2>
        <p>
          Your order will be sent to production after the project balance is
          paid.
        </p>
        {extraButton}
      </ApprovedSideBar>
    </div>
  )
}

export default SidebarApproved
