import React from 'react'

import { navigate } from 'gatsby'
import { Divider, Grid } from 'semantic-ui-react'

import useOrder from 'context/order/use'
import useProject from 'context/project/use'
import PrimaryCard from 'design-system/components/cards/primary-card'
import { Section } from 'design-system/components/guide/utils'

const Orders = () => {
  const { project } = useProject()
  const { order } = useOrder()

  return (
    <Section id="orders">
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Review the status of your order(s)</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row stretched>
          <Grid.Column largeScreen={4} computer={5} tablet={7} mobile={16}>
            <PrimaryCard
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/track-order.svg`}
              onClick={() =>
                navigate(`/app/projects/${project.id}/orders/${order.id}`, {
                  state: {
                    fromHandoverGuide: true,
                  },
                })
              }
              title="Track order"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default Orders
