import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import useUser from 'context/user/use'

const UserRedirect = (_: RouteComponentProps) => {
  const { projects, inTradeProgram, company } = useUser()

  // if one project go to that project, else go to user home
  useEffect(() => {
    if (inTradeProgram && company?.id) {
      navigate(`/app/company/${company.id}`)
    } else if (projects.length === 1) {
      navigate(`/app/projects/${projects[0].id}`)
    } else {
      navigate(`/app/home`)
    }
  }, [])

  return <></>
}

export default UserRedirect
