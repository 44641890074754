import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'

import { StepperProps } from 'design-system/components/stepper'

import Size from './01-size'
import Scope from './02-scope'
import Walls from './03-walls'
import Appliances from './04-appliances'
import Flooring from './05-flooring'
import Additions from './06-additions'
import CountertopBacksplash from './07-countertop-backsplash'
import Information from './08-information'
import Booking from './09-booking'

type RoomFlowRouteProps = RouteComponentProps<{
  '*': string
  project_id: string
}>

export type StepViewProps = RoomFlowRouteProps & {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setNextDisabled: React.Dispatch<React.SetStateAction<boolean>>
  setOnNext: React.Dispatch<
    React.SetStateAction<(() => Promise<void>) | undefined>
  >
}

type NavigationStep = StepperProps['steps'][number] & {
  subroute: string
  View: React.ComponentType<StepViewProps>
}

type StartProjectStepsType =
  | 'size'
  | 'scope'
  | 'walls'
  | 'appliances'
  | 'flooring'
  | 'additions'
  | 'countertop_backsplash'
  | 'information'
  | 'booking'

export const START_PROJECT_STEPS: Record<
  StartProjectStepsType,
  NavigationStep
> = {
  size: {
    label: 'size',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'size',
    View: Size,
  },
  scope: {
    label: 'scope',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'scope',
    View: Scope,
  },
  walls: {
    label: 'walls',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'walls',
    View: Walls,
  },
  appliances: {
    label: 'appliances',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'appliances',
    View: Appliances,
  },
  flooring: {
    label: 'flooring',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'flooring',
    View: Flooring,
  },
  additions: {
    label: 'additions',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'additions',
    View: Additions,
  },
  countertop_backsplash: {
    label: 'countertop backsplash',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'countertop_backsplash',
    View: CountertopBacksplash,
  },
  information: {
    label: 'information',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'information',
    View: Information,
  },
  booking: {
    label: 'booking',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'booking',
    View: Booking,
  },
}

export const START_PROJECT_STEPS_ARRAY = [
  START_PROJECT_STEPS.size,
  START_PROJECT_STEPS.scope,
  START_PROJECT_STEPS.walls,
  START_PROJECT_STEPS.appliances,
  START_PROJECT_STEPS.flooring,
  START_PROJECT_STEPS.additions,
  START_PROJECT_STEPS.countertop_backsplash,
  START_PROJECT_STEPS.information,
  START_PROJECT_STEPS.booking,
]
