import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from 'semantic-ui-react'
import styled from 'styled-components'

import { Breakpoint, Colors } from 'styles/app/system'

const HeaderMenu = ({
  children,
  direction = 'right',
  large,
  text,
  trigger,
}: {
  children: React.ReactNode
  direction?: 'left' | 'right'
  large?: boolean
  text?: string
  trigger?: React.ReactNode
}) => {
  return (
    <HeaderDropdown
      className="up-mobile"
      direction={direction}
      icon={<FontAwesomeIcon icon={['fal', 'chevron-down']} />}
      $large={large}
      text={text}
      trigger={trigger}
    >
      <DropdownMenu $large={large}>{children}</DropdownMenu>
    </HeaderDropdown>
  )
}

export default HeaderMenu

const HeaderDropdown = styled(Dropdown)<{ $large?: boolean }>`
  align-items: center;
  display: flex;
  gap: 8px;
  padding: ${(props) => (props.$large ? '17px 30px' : '12px 8px')};
  border-right: ${(props) =>
    props.$large ? `1px solid ${Colors.gray100}` : 'none'};
  min-height: 60px;

  color: ${Colors.blue900};
  font-family: 'GT America';
  font-size: 14px;
  font-weight: 300;
  line-height: 160%;

  svg {
    color: ${Colors.blue900};
    font-size: 12px;
    transition: transform 0.3s ease-out;
  }

  :hover svg {
    transform: translate(0, 3px);
  }
`

export const DropdownMenu = styled(Dropdown.Menu)<{ $large?: boolean }>`
  &&&& {
    border: none;
    border-radius: 0;
    box-shadow: none;
    filter: drop-shadow(0px 4px 15px rgba(120, 113, 108, 0.1));
    top: calc(100% + 1px);

    min-width: ${(props) => (props.$large ? '312px' : '176px')};

    div.item {
      align-items: center;
      display: flex;
      gap: 12px;
      padding: 18px 14px !important;

      @media ${Breakpoint.onlyMobile} {
        padding: 24px 24px 24px 70px !important;
      }

      color: ${Colors.gray600};
      font-size: 16px;
      font-weight: 100;

      svg {
        color: #aeb3bf;
        font-size: 16px;
      }

      &.active {
        background: ${Colors.blue100};
        color: ${Colors.blue900};
      }

      :hover:not(.active) {
        background: ${Colors.gray100};
      }
    }

    div.item.primary {
      border-bottom: 1px solid ${Colors.gray200};
      justify-content: space-between;

      @media ${Breakpoint.onlyMobile} {
        border: none;
      }

      svg {
        color: ${Colors.orange500};
      }
    }
  }
`
