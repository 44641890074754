import React, { useState, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  DotGroup,
  Slide,
  Slider,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import { Button, Image } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import { FilePlaceholder } from 'components/shared/file-placeholder'
import { isImageFile } from 'context/project-file/utils'
import { Breakpoint, Colors } from 'styles/app/system'

import CarouselModal from './carousel-modal'

interface CarouselProps {
  items?: string[]
  maxHeight?: number
}

const Carousel = ({ items, maxHeight = 750 }: CarouselProps) => {
  const slideRef = useRef<(HTMLDivElement | null)[]>([])
  const [imgHeight, setImgHeight] = useState<number>(0)
  const [slideHeight, setSlideHeight] = useState<number>(1)

  const getSliderHeight = () => {
    const minSlideHeight = Math.min(
      ...slideRef.current.map((ref) => ref?.offsetHeight ?? Infinity),
    )
    setImgHeight(minSlideHeight)
    return minSlideHeight && minSlideHeight / getSlideWidth()
  }

  const getSlideWidth = () => {
    return Math.max(
      ...slideRef.current.map((ref) => ref?.offsetWidth ?? -Infinity),
    )
  }

  return (
    <CarouselContainer>
      <CarouselProvider
        className="carousel"
        naturalSlideHeight={slideHeight}
        naturalSlideWidth={1}
        totalSlides={items?.length ?? 0}
        visibleSlides={1}
      >
        <Slider className="slider">
          {items?.map((src, idx) => (
            <Slide index={idx} key={idx}>
              <div
                ref={(el) => (slideRef.current[idx] = el)}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    width: 'fit-content',
                  }}
                >
                  {isImageFile(src) ? (
                    <>
                      <Image
                        onLoad={() => setSlideHeight(getSliderHeight())}
                        src={src}
                        style={{
                          margin: '0 auto',
                          ...(imgHeight > 0 && imgHeight < maxHeight
                            ? { maxHeight: `${imgHeight}px` }
                            : {
                                maxHeight: maxHeight,
                              }),
                        }}
                      />
                      <CarouselModal
                        currentIdx={idx}
                        images={items}
                        trigger={
                          <ExpandButton
                            icon={<FontAwesomeIcon icon={['fal', 'expand']} />}
                          />
                        }
                      />
                    </>
                  ) : (
                    <FilePlaceholder fileKey={src} />
                  )}
                </div>
              </div>
            </Slide>
          ))}
        </Slider>
        <DotGroup className="dots" />
        <StyledButtonBack>
          <FontAwesomeIcon icon={['fal', 'arrow-left']} />
        </StyledButtonBack>
        <StyledButtonNext>
          <FontAwesomeIcon icon={['fal', 'arrow-right']} />
        </StyledButtonNext>
      </CarouselProvider>
    </CarouselContainer>
  )
}

export default Carousel

const CarouselContainer = styled.section`
  &&& {
    .carousel {
      padding: 10px 0 20px 0;
      position: relative;
      width: auto;
    }

    .slider {
      text-align: left;
      :focus {
        outline: none !important;
      }
    }

    .dots {
      margin-top: 30px;
      text-align: center;

      button {
        background: ${Colors.gray200};
        border: none;
        box-sizing: border-box;
        height: 2px !important;
        margin: 0 8px;
        padding: 0;
        width: 25px !important;

        :disabled {
          background: ${Colors.orange500};
        }

        :focus {
          outline: none !important;
        }
      }
    }
  }
`

const carouselButton = css`
  align-items: center;
  background: ${Colors.white};
  border: none;
  border-radius: 100%;
  color: ${Colors.red600};
  display: flex;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: calc(50% - 30px);
  transform: translateY(-50%);
  transition: all 0.2s ease-in-out;
  width: 40px;
  box-shadow: 0px 0px 15px rgba(120, 113, 108, 0.15);

  :disabled {
    opacity: 0.1;
  }

  :focus {
    outline: none !important;
  }

  :hover:enabled {
    box-shadow: 0px 0px 15px rgba(120, 113, 108, 0.15);
  }

  @media ${Breakpoint.downFromComputer} {
    display: none !important;
  }
`

export const StyledButtonBack = styled(ButtonBack)`
  ${carouselButton}
  transform: translateX(-130%);
`
export const StyledButtonNext = styled(ButtonNext)`
  ${carouselButton}
  right: 0;
  transform: translateX(130%);
`

const ExpandButton = styled(Button)`
  background: ${Colors.gray300} !important;
  bottom: 10px;
  margin: 0 !important;
  opacity: 0.8;
  position: absolute;
  right: 10px;

  svg {
    color: ${Colors.gray700};
  }

  :hover {
    background: ${Colors.gray300} !important;
    opacity: 1;
  }
`
