import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import DashedCard from 'components/app/dashed-card'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useGenericProductClassMany from 'context/generic-product-class/use-many'
import { getReferenceImage } from 'context/media/utils-many'
import useRoom from 'context/room/use'
import useRoomGenericProductMutate from 'context/room/use-generic-product-mutate'
import Button from 'design-system/components/button'
import ApplianceCard from 'design-system/components/cards/appliance-card'
import { Colors } from 'styles/app/system'
import { GenericProductGraphQL } from 'types/generic-product'
import { GenericProductClass } from 'types/generic-product-class'
import { RoomType } from 'types/room'
import { capsSnakeCaseToTitleCase } from 'views/utils'

import ApplianceModal from './modal'

type State = {
  genericProduct: null | GenericProductGraphQL
  genericProductClasses: GenericProductClass[]
  open: boolean
}

const ApplianceEditView = ({
  deletionEnabled,
  editEnabled,
  showLabels,
}: {
  deletionEnabled?: boolean
  editEnabled?: boolean
  showLabels?: boolean
}) => {
  const {
    appliancesUnarchived,
    appliancesConfirmed,
    appliancesAreAcknowledged,
    cooktops,
    dishwashers,
    microwaves,
    ranges,
    refrigerators,
    refetch,
    room,
    sinks,
  } = useRoom()
  const {
    hardDeleteGenericProduct,
    loadingDelete,
    loadingUpdate,
    saveGenericProduct,
    toggleSoftDeleteGenericProduct,
  } = useRoomGenericProductMutate()
  const {
    cooktopProductClass,
    dishwasherProductClass,
    genericProductClasses,
    microwaveProductClass,
    rangeProductClass,
    refrigeratorProductClass,
    sinkProductClass,
  } = useGenericProductClassMany()

  const [applianceToDelete, setApplianceToDelete] =
    useState<GenericProductGraphQL | null>(null)
  const [applianceCreateUpdateState, setApplianceCreateUpdateState] =
    useState<State>({
      genericProduct: null,
      genericProductClasses: [],
      open: false,
    })

  const isKitchen = room?.type === RoomType.Kitchen

  const individualApplianceCards = isKitchen
    ? [
        {
          products: [...cooktops, ...ranges],
          productClasses: [cooktopProductClass, rangeProductClass],
          name: 'Cooktop/Range',
        },
        {
          products: dishwashers,
          productClasses: [dishwasherProductClass],
          name: 'Dishwasher',
        },
        {
          products: sinks,
          productClasses: [sinkProductClass],
          name: 'Sink',
        },
        {
          products: microwaves,
          productClasses: [microwaveProductClass],
          name: 'Microwave',
        },
        {
          products: refrigerators,
          productClasses: [refrigeratorProductClass],
          name: 'Refrigerator',
        },
      ]
    : []

  const StatusLabel = ({
    type,
  }: {
    type: 'confirmed' | 'deletion_requested' | 'updated'
  }) => (
    <Label>
      <div
        className={
          type === 'confirmed'
            ? 'circle green'
            : type === 'updated'
            ? 'circle orange'
            : 'circle red'
        }
      />
      <p>{capsSnakeCaseToTitleCase(type)}</p>
    </Label>
  )

  return (
    <>
      <Grid.Row centered className="row-gap">
        {appliancesUnarchived.map((o) => (
          <Grid.Column
            key={o.id}
            widescreen={5}
            largeScreen={5}
            computer={8}
            tablet={8}
            mobile={16}
          >
            <DeletionHover>
              <ApplianceCard
                genericProduct={o}
                noActions={!editEnabled}
                setApplianceToDelete={setApplianceToDelete}
                setApplianceCreateUpdateState={setApplianceCreateUpdateState}
              />
              {o.data?.deletionRequestedByCustomerAt && (
                <div className="deletion-requested">
                  <Button
                    disabled={!editEnabled}
                    color="gray"
                    icon={
                      <FontAwesomeIcon icon={['fal', 'arrow-rotate-left']} />
                    }
                    kind="ghost"
                    onClick={async () => {
                      await toggleSoftDeleteGenericProduct(o)
                    }}
                    text="Restore Appliance"
                  />
                </div>
              )}
            </DeletionHover>
            {showLabels &&
            (!appliancesAreAcknowledged || appliancesConfirmed.length) ? (
              <StatusContainer>
                {o.data?.lastUpdatedByCustomerAt &&
                  !o.data.lastAcknowledgedAt &&
                  !o.data?.deletionRequestedByCustomerAt && (
                    <StatusLabel type="updated" />
                  )}
                {o.data?.confirmedAt && <StatusLabel type="confirmed" />}
                {o.data?.deletionRequestedByCustomerAt && (
                  <StatusLabel type="deletion_requested" />
                )}
              </StatusContainer>
            ) : null}
          </Grid.Column>
        ))}
        {editEnabled &&
          individualApplianceCards
            .filter((card) => !card.products.length)
            .map((card, idx) => {
              return (
                <Grid.Column
                  key={`${card.name}#${idx}`}
                  widescreen={5}
                  largeScreen={5}
                  computer={8}
                  tablet={8}
                  mobile={16}
                >
                  <DashedCard
                    image={
                      <Image
                        src={getReferenceImage(card.productClasses[0].media)}
                      />
                    }
                    onClick={() =>
                      setApplianceCreateUpdateState({
                        genericProduct: null,
                        genericProductClasses: card.productClasses,
                        open: true,
                      })
                    }
                    title={card.name}
                  />
                </Grid.Column>
              )
            })}
        {editEnabled ? (
          <Grid.Column
            widescreen={5}
            largeScreen={5}
            computer={8}
            tablet={8}
            mobile={16}
          >
            <DashedCard
              image={
                <div className="icon">
                  <FontAwesomeIcon icon={['fal', 'oven']} />
                </div>
              }
              onClick={() =>
                setApplianceCreateUpdateState({
                  genericProduct: null,
                  genericProductClasses,
                  open: true,
                })
              }
              title="New Appliance"
              whiteCard
            />
          </Grid.Column>
        ) : null}
      </Grid.Row>
      <ConfirmationModal
        confirmText="Delete"
        firstTitle="Are you sure you want to delete this appliance?"
        loading={loadingDelete || loadingUpdate}
        onCancel={() => {
          setApplianceToDelete(null)
        }}
        onConfirm={async () => {
          if (deletionEnabled) await hardDeleteGenericProduct(applianceToDelete)
          else await toggleSoftDeleteGenericProduct(applianceToDelete)
          setApplianceToDelete(null)
        }}
        open={!!applianceToDelete}
      />
      <ApplianceModal
        genericProduct={applianceCreateUpdateState.genericProduct}
        genericProductClasses={
          applianceCreateUpdateState.genericProductClasses?.length
            ? applianceCreateUpdateState.genericProductClasses
            : genericProductClasses
        }
        onClose={() =>
          setApplianceCreateUpdateState({
            ...applianceCreateUpdateState,
            open: false,
          })
        }
        onSaveGenericProduct={async (g) =>
          await saveGenericProduct(g, () => {
            refetch()
            setApplianceCreateUpdateState({
              genericProduct: null,
              genericProductClasses: [],
              open: false,
            })
          })
        }
        loadingSave={loadingUpdate}
        open={applianceCreateUpdateState.open}
      />
    </>
  )
}

export default ApplianceEditView

const Label = styled.div`
  align-items: center;
  background: ${Colors.gray100};
  border-radius: 2px;
  display: flex;
  gap: 7px;
  padding: 0 6px;

  div.circle {
    background: #ea6565;
    border-radius: 50%;
    height: 8px;
    width: 8px;

    &.orange {
      background: #ebb371;
    }

    &.green {
      background: #a9c969;
    }
  }
`

const DeletionHover = styled.div`
  position: relative;

  div.deletion-requested {
    align-items: center;
    background: #ffffff;
    border: 1px solid ${Colors.gray200};
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    opacity: 0.94;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const StatusContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  min-height: 26px;
`
