import React from 'react'

import { useMedia } from 'react-use'
import { Grid } from 'semantic-ui-react'

import Carousel from 'design-system/components/carousel'
import StepCard from 'design-system/components/guide/step-card'
import { Section, Spacer } from 'design-system/components/guide/utils'
import { Breakpoint } from 'styles/app/system'

const stepsFORM = [
  {
    title: 'Initial Design',
    description:
      'This step is about giving you what you need to make an informed decision on whether to continue working with FORM. ',
    step: 1,
    subtitle: '$299 Design Deposit',
    listItems: [
      '1 hour presentation.',
      'Initial design and pricing.',
      'Typically 10 hours of design work.',
      '3 full sized samples delivered to your home.',
      'Set of photorealistic visuals.',
    ],
  },
  {
    title: 'Refine',
    description:
      'We will work with you to perfect your design and finalize details. We can also discuss adding cabinetry for other areas of the home.',
    step: 2,
    subtitle: '$1,999 Project Deposit',
    listItems: [
      '3 revisions included.',
      'Detailed kitchen plans with itemized pricing.',
      'Typically 20 - 60 hours of design work.',
      'Option to add additional rooms.',
    ],
  },
  {
    title: 'Deliver',
    description:
      'Your order is confirmed by technical teams for production. Our Customer Success team is on hand to support you every step of the way through completion.',
    step: 3,
    subtitle: 'Balance payment sends the order to Germany',
    listItems: [
      'Fully assembled cabinetry with award-winning hardware.',
      '25 years Manufacturing warranty.',
      'Order status check-in call.',
      'Best-in-class installation support.',
      'Free shipping to your US address',
      'Final set of photorealistic visuals.',
    ],
  },
]

const stepSlides = stepsFORM.map((s) => ({
  id: s.step,
  item: (
    <div style={{ paddingTop: 40, height: '100%', paddingBottom: 20 }}>
      <StepCard {...s} />
    </div>
  ),
}))

const HowWeWork = () => {
  const isDownFromComputer = useMedia(Breakpoint.downFromComputer)
  const isMobile = useMedia(Breakpoint.onlyMobile)

  return (
    <Section id="how-we-work">
      <Grid centered>
        <Spacer height={150} />
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">How does FORM work?</h3>
          </Grid.Column>
        </Grid.Row>
        <Spacer height={50} />
        {isDownFromComputer ? (
          <Carousel
            slides={stepSlides}
            hideNavButtons
            slideSize={isMobile ? 80 : 50}
          />
        ) : (
          <Grid.Row columns={16}>
            {stepsFORM.map((step) => (
              <Grid.Column key={step.title} width={5}>
                <StepCard {...step} />
              </Grid.Column>
            ))}
          </Grid.Row>
        )}
        {!isDownFromComputer && <Spacer height={150} />}
      </Grid>
    </Section>
  )
}

export default HowWeWork
