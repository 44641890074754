import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, List } from 'semantic-ui-react'
import styled from 'styled-components'

import { Hexagon } from 'styles/app/components/icon'
import { Colors } from 'styles/app/system'

const FinalChecks = () => {
  return (
    <Grid.Column computer={8} tablet={16} mobile={16}>
      <p className="light-gray subtitle">Final checks before payment</p>
      <List verticalAlign="middle" relaxed>
        <ListItem>
          <List.Icon>
            <Hexagon
              styled={{
                background: Colors.green100,
                color: Colors.green800,
                size: '50px',
              }}
            >
              <FontAwesomeIcon icon={['fal', 'check']} />
            </Hexagon>
          </List.Icon>
          <List.Content>
            <p>Plans and dimensions have been checked with the contractor.</p>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Icon>
            <Hexagon
              styled={{
                background: Colors.green100,
                color: Colors.green800,
                size: '50px',
              }}
            >
              <FontAwesomeIcon icon={['fal', 'check']} />
            </Hexagon>
          </List.Icon>
          <List.Content>
            <p>
              Colors may appear slightly different to your visuals. See samples
              for exact color.
            </p>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Icon>
            <Hexagon
              styled={{
                background: Colors.green100,
                color: Colors.green800,
                size: '50px',
              }}
            >
              <FontAwesomeIcon icon={['fal', 'check']} />
            </Hexagon>
          </List.Icon>
          <List.Content>
            <p>I am ready to check the delivery on arrival.</p>
          </List.Content>
        </ListItem>
        <ListItem>
          <List.Icon>
            <Hexagon
              styled={{
                background: Colors.green100,
                color: Colors.green800,
                size: '50px',
              }}
            >
              <FontAwesomeIcon icon={['fal', 'check']} />
            </Hexagon>
          </List.Icon>
          <List.Content>
            <p>My order is final once sent to production.</p>
          </List.Content>
        </ListItem>
      </List>
    </Grid.Column>
  )
}

export default FinalChecks

const ListItem = styled(List.Item)`
  position: relative;
  padding: 15px 0 !important;
`
