import React from 'react'

import MeetingConfirmation from 'components/shared/meeting/confirmation'
import useMeeting from 'context/meeting/use'
import { hasMeetingOccurred } from 'context/meeting/utils'
import useProject from 'context/project/use'
import { MeetingTypeProject } from 'types/meeting'

import { MeetingDetailContainer } from './utils'
import DesignerHeader from '../designer-header'

const S3_ROOT = process.env.GATSBY_CDN_ROOT

const MeetingDetail = () => {
  const { meeting, meetingName } = useMeeting()
  const { primaryDesigner, project } = useProject()
  const {
    duration,
    metadata: { type: meetingType },
  } = meeting

  const durationText =
    meetingType === MeetingTypeProject.CHECK_IN ? ` ${duration} min` : ''
  const headerPicture = meetingType.includes('BUILDZOOM')
    ? `${S3_ROOT}/images/app/illustrations/buildzoom.png`
    : primaryDesigner?.picture ?? ''
  const meetingHasOccured = hasMeetingOccurred(meeting)

  return (
    <MeetingDetailContainer>
      <DesignerHeader
        title={
          meetingHasOccured
            ? 'It was great meeting with you!'
            : `Next up: ${meetingName}${durationText}`
        }
        designerPicture={headerPicture}
        caption="Looking forward to our meeting!"
      />
      {!meetingHasOccured && (
        <MeetingConfirmation meeting={meeting} projectId={project.id} />
      )}
    </MeetingDetailContainer>
  )
}

export default MeetingDetail
