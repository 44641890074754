import React, { useState } from 'react'

import styled from 'styled-components'

import { Colors } from 'styles/app/system'

import Tabs, { TabsProps, Tab } from '../../components/tabs'

type ControlledProps<T extends Record<string, unknown> | undefined> = {
  controlled: true
} & Omit<TabsProps<T>, 'tabs'>

type UncontrolledProps = {
  controlled?: false | undefined
}

type UnionProps<T extends Record<string, unknown> | undefined> =
  | ControlledProps<T>
  | UncontrolledProps

type OwnProps<T extends Record<string, unknown> | undefined> = {
  children(
    currentTab: Tab<T>,
    tabIndex: number,
    changeTab: TabsProps<T>['handleClick'],
  ): React.ReactNode
}

type TabsContainerProps<
  T extends Record<string, unknown> | undefined = undefined,
> = Pick<TabsProps<T>, 'tabs'> & UnionProps<T> & OwnProps<T>

const TabsContainer = <
  T extends Record<string, unknown> | undefined = undefined,
>({
  children,
  ...props
}: TabsContainerProps<T>) => {
  const [tab, setTab] = useState(0)

  const currentTabIndex = props.controlled ? props.currentTab : tab

  const changeTab: TabsProps<T>['handleClick'] = (tabIdx, tab, evt) => {
    if (props.controlled) {
      props.handleClick?.(tabIdx, tab, evt)
    } else {
      setTab(tabIdx)
    }
  }

  return (
    <Container>
      <Tabs
        tabs={props.tabs}
        currentTab={currentTabIndex}
        handleClick={changeTab}
      />
      <TabsContent>
        {children(props.tabs[currentTabIndex], currentTabIndex, changeTab)}
      </TabsContent>
    </Container>
  )
}

export default TabsContainer

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const TabsContent = styled.div`
  background-color: ${Colors.gray100};
  padding: 32px 24px;
`
