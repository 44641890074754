import React, { useEffect } from 'react'

import { MeetingBooker } from 'components/app/project/meeting'
import MeetingProvider from 'context/meeting/provider'
import useProject from 'context/project/use'
import { MeetingTypeProject } from 'types/meeting'

import { StepViewProps } from './steps'

const Booking = ({ setNextDisabled, setOnNext, navigate }: StepViewProps) => {
  const { meetingBuildZoomScheduled, project } = useProject()

  useEffect(() => {
    setOnNext(undefined as any)
    setNextDisabled(true)
  }, [])

  useEffect(() => {
    if (meetingBuildZoomScheduled)
      navigate?.(`/app/projects/${project.id}/buildzoom/thank-you`)
  }, [meetingBuildZoomScheduled])

  return (
    <MeetingProvider meetingType={MeetingTypeProject.BUILDZOOM}>
      <MeetingBooker />
    </MeetingProvider>
  )
}

export default Booking
