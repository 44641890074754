import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { navigate } from 'gatsby'
import moment from 'moment'
import { Grid, Table } from 'semantic-ui-react'
import styled from 'styled-components'

import HeaderBasic from 'components/app/project/header/header-basic'
import { formatDollars } from 'context/dollar-utils'
import useOrder from 'context/order/use'
import * as checkoutUtils from 'context/order/utils-checkout'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import AppLayout from 'design-system/containers/app-layout'
import { Colors } from 'styles/app/system'
import { Design } from 'types/design'
import { NobiliaSampleGraphQL } from 'types/nobilia-sample'
import { Order } from 'types/order'
import { Payment, PaymentType } from 'types/payment'

import { ReceiptPDF } from './pdf-receipt'

const parseOrder = (order: Order) => {
  const designs = order.designs || []
  const discounts = order.discounts || []
  const nobiliaSamples = order.nobiliaSamples || []

  const { totalOrderPayment, paymentMethods } = (order.payments ?? [])
    .filter((p) => p.metadata?.type === PaymentType.ORDER_PAYMENT)
    .reduce<{
      totalOrderPayment: number
      paymentMethods: string[]
    }>(
      (acc, p) => {
        const isCardPayment = !!p.stripeCharge?.payment_method_details?.card
        const method = isCardPayment
          ? `${p?.stripeCharge?.payment_method_details?.card?.brand?.toUpperCase()}-${p
              ?.stripeCharge?.payment_method_details?.card.last4}`
          : 'Invoice'
        const paymentMethods = !acc.paymentMethods.some(
          (pm: string) => pm === method,
        )
          ? [...acc.paymentMethods, method]
          : acc.paymentMethods
        return {
          totalOrderPayment: acc.totalOrderPayment + p.amount,
          paymentMethods,
        }
      },
      {
        totalOrderPayment: 0,
        paymentMethods: [] as string[],
      },
    )

  const methodString = paymentMethods.join(', ')
  const payments = order.payments || []
  const totalBeforeTaxes = checkoutUtils.calculateTotalBeforeTaxes(order)
  const taxes = checkoutUtils.calculateTaxes(order)
  const amount = formatDollars(totalOrderPayment ? totalOrderPayment : 0)
  const amountDue = checkoutUtils.calculateAmountDue(order)
  const orderPlacedAt = moment(order.placedAt).format('ll')

  return {
    amount,
    amountDue,
    designs,
    discounts,
    methodString,
    nobiliaSamples,
    orderPlacedAt,
    payments,
    totalBeforeTaxes,
    taxes,
  }
}

const PaymentReceipt = (_: RouteComponentProps) => {
  const { order } = useOrder()
  const { project } = useProject()

  const { amount, methodString, orderPlacedAt } = parseOrder(order)

  return (
    <AppLayout
      header={
        <HeaderBasic
          onBack={() =>
            navigate(`/app/projects/${project.id}/orders/${order.id}`)
          }
        />
      }
    >
      <Grid padded style={{ background: 'white' }}>
        <Grid.Row>
          <Grid.Column>
            <h2>FORM Remodel, Inc.</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column computer={8} tablet={8} mobile={16}>
            <p className="no-margin">5900 Balcones Drive STE 4000</p>
            <p className="no-margin">Austin TX 78731</p>
            <p className="no-margin">United States</p>
            <p className="no-margin">+1 (415) 429-3515</p>
            <p>help@formkitchens.com</p>
          </Grid.Column>
          <Grid.Column floated="right" computer={4} tablet={4} mobile={16}>
            <h4>Receipt</h4>
            <p className="no-margin">Balance Paid: {orderPlacedAt}</p>
            <p>Payment Method(s): {methodString}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <h3>
              {amount} paid on {orderPlacedAt}
            </h3>
            <p>Thank You!</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button
              kind="outlined"
              size="large"
              customContent={
                <PDFDownloadLink
                  document={<ReceiptPDF order={order} />}
                  fileName="FORM-Receipt.pdf"
                >
                  Download PDF
                </PDFDownloadLink>
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <PaymentsTable order={order} />
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default PaymentReceipt

const PaymentsTable = ({ order }: { order: Order }) => {
  const {
    amountDue,
    designs,
    discounts,
    nobiliaSamples,
    payments,
    totalBeforeTaxes,
    taxes,
  } = parseOrder(order)

  return (
    <StyledTable basic="very" singleLine style={{ border: 'none' }}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell style={{ paddingLeft: '15px' }}>
            <p className="caption">Description</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="caption">Qty</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="caption">Unit Price</p>
          </Table.HeaderCell>
          <Table.HeaderCell>
            <p className="caption">Amount</p>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {designs.map(renderDesign)}
        {nobiliaSamples.map(renderNobiliaSample)}
        {discounts.map((d, i) =>
          renderPaymentItem({
            item: d,
            key: i,
          }),
        )}
        {totalBeforeTaxes
          ? renderPaymentItemFromPrice({
              key: 1,
              name: 'Total Before Tax',
              price: totalBeforeTaxes,
            })
          : null}
        {totalBeforeTaxes
          ? renderPaymentItemFromPrice({
              key: 2,
              name: `Estimated Tax ${
                order.taxRate ? `(${order.taxRate}%)` : ''
              }`,
              price: taxes as number,
            })
          : null}
        {totalBeforeTaxes ? payments.map(renderPaymentMade) : null}
        <Table.Row>
          <Table.Cell className="no-border"></Table.Cell>
          <Table.Cell className="no-border"></Table.Cell>
          <Table.Cell className="no-border gray-row">
            <p>Amount Due</p>
          </Table.Cell>
          <Table.Cell className="no-border gray-row">
            <p>{formatDollars(amountDue)}</p>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </StyledTable>
  )
}

const StyledTable = styled(Table)`
  .gray-row {
    background: ${Colors.gray50} !important;
  }

  .caption {
    font-weight: caption;
  }

  .no-border {
    background: ${Colors.white};
    border-bottom: none !important;
    border-top: none !important;
  }
`

const renderPaymentItem = ({
  item,
  key,
  paidAt,
}: {
  item: any
  key: number
  paidAt?: string
}) => {
  const total = item.price * item.quantity

  return (
    <Table.Row
      key={key}
      {...(item.name.includes('Total Before Tax') && {
        className: 'gray-row',
      })}
    >
      <Table.Cell style={{ paddingLeft: '15px' }}>
        <p>{`${item.name}${paidAt ? ` paid on ${paidAt}` : ''}`}</p>
      </Table.Cell>
      <Table.Cell>
        <p>{item.quantity}</p>
      </Table.Cell>
      <Table.Cell>
        <p>{formatDollars(item.price)}</p>
      </Table.Cell>
      <Table.Cell>
        <p>{formatDollars(total)}</p>
      </Table.Cell>
    </Table.Row>
  )
}

const renderDesign = (design: Design, key: number) => {
  return renderPaymentItem({
    item: checkoutUtils.designToPaymentItem(design),
    key: key,
  })
}

const renderNobiliaSample = (product: NobiliaSampleGraphQL, key: number) => {
  return renderPaymentItem({
    item: checkoutUtils.nobiliaSampleToPaymentItem(product),
    key: key,
  })
}

const renderPaymentMade = (payment: Payment, key: number) => {
  return renderPaymentItem({
    item: madePaymentToPaymentItem(payment),
    key: key,
    paidAt: moment(payment.metadata?.paidAt).format('ll'),
  })
}

const renderPaymentItemFromPrice = (params: {
  key: number
  name: string
  price: number
}) => {
  return renderPaymentItem({
    item: {
      name: params.name,
      price: params.price,
      quantity: 1,
    },
    key: params.key,
  })
}

const madePaymentToPaymentItem = (payment: Payment) => {
  return {
    name: `${payment.metadata.name || payment.description}`,
    price: -payment.amount,
    quantity: 1,
  }
}
