import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CommentList from 'components/shared/project/project-file/comment/list'
import { CloseIconMobile } from 'components/shared/project/project-file/utils'
import { Hexagon } from 'styles/app/components/icon'
import { Colors } from 'styles/app/system'

interface SidebarSubmittedProps {
  sidebarToggle: () => void
}

const SidebarSubmitted = ({ sidebarToggle }: SidebarSubmittedProps) => {
  return (
    <div
      style={{
        paddingTop: '20px',
      }}
    >
      <CloseIconMobile onClick={sidebarToggle}>
        <FontAwesomeIcon icon={['fal', 'times']} />
      </CloseIconMobile>
      <Hexagon
        styled={{
          background: Colors.greenBright,
          fontSize: '18px',
          size: '40px',
        }}
      >
        <FontAwesomeIcon icon={['fal', 'check']} />
      </Hexagon>
      <h3>We’ve Received Your Comments</h3>
      <p>
        Your designer will review these comments before meeting with you to
        discuss these changes.
      </p>

      <CommentList disabled onClose={sidebarToggle} />
    </div>
  )
}

export default SidebarSubmitted
