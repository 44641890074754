import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import PaymentSourceManyProvider from 'context/payment-source/provider-many'
import ProjectOrderSamplesManyProvider from 'context/project/order-samples/provider-many'
import useProjectOrderSamplesMany from 'context/project/order-samples/use-many'
import useProject from 'context/project/use'
import RoomProvider from 'context/room/provider'

const SamplesIndex = (
  props: RouteComponentProps & { children: React.ReactNode },
) => {
  const { project } = useProject()
  const { orders, ordersPending } = useProjectOrderSamplesMany()

  // redirect to new order if none exist
  if (
    props.location?.pathname === `/app/projects/${project.id}/sample-orders/` &&
    orders.length === 0
  ) {
    navigate(`/app/projects/${project.id}/sample-orders/new`, {
      state: props.location.state ?? undefined,
    })
    return null
  }

  // redirect to home on checkout if no pending orders exist
  if (
    props.location?.pathname ===
      `/app/projects/${project.id}/sample-orders/checkout/` &&
    ordersPending.length === 0
  ) {
    navigate(`/app/projects/${project.id}/sample-orders`, {
      state: props.location.state ?? undefined,
    })
    return null
  }

  return <PaymentSourceManyProvider>{props.children}</PaymentSourceManyProvider>
}

export default (props: RouteComponentProps & { children: React.ReactNode }) => {
  const { roomPrimary } = useProject()

  return (
    <ProjectOrderSamplesManyProvider>
      <RoomProvider room_id={roomPrimary?.id}>
        <SamplesIndex {...props}>{props.children}</SamplesIndex>
      </RoomProvider>
    </ProjectOrderSamplesManyProvider>
  )
}
