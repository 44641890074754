import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import { getReferenceImage } from 'context/media/utils-many'
import Button from 'design-system/components/button'
import { Breakpoint, Colors } from 'styles/app/system'
import { GenericProductGraphQL } from 'types/generic-product'
import { GenericProductClass } from 'types/generic-product-class'

const validateUrl = (link: string) =>
  link.startsWith('http') ? link : `http://${link}`

const ApplianceCard = ({
  genericProduct,
  noActions,
  setApplianceToDelete,
  setApplianceCreateUpdateState,
}: {
  genericProduct: GenericProductGraphQL
  noActions?: boolean
  setApplianceToDelete?: React.Dispatch<
    React.SetStateAction<GenericProductGraphQL | null>
  >
  setApplianceCreateUpdateState?: React.Dispatch<
    React.SetStateAction<{
      genericProduct: null | GenericProductGraphQL
      genericProductClasses: GenericProductClass[]
      open: boolean
    }>
  >
}) => {
  return (
    <StyledCard
      styled={{
        disabled: !!genericProduct.data?.deletionRequestedByCustomerAt,
      }}
    >
      <Image src={getReferenceImage(genericProduct.productClass.media)} />
      <div className="content">
        <div className="info">
          <div>
            <p className="caption">
              {genericProduct.productClass?.metadata?.name}
              {genericProduct.data?.size?.endsWith('"')
                ? ` ${genericProduct.data.size}`
                : ''}
            </p>
            <p>{genericProduct.data?.productDetails}</p>
          </div>
          <a
            href={validateUrl(genericProduct.data?.linkToProduct ?? '')}
            style={{ color: Colors.blue800 }}
            target="_blank"
          >
            {genericProduct.data?.linkToProduct && (
              <>
                <FontAwesomeIcon icon={['fal', 'link']} />
                <span style={{ marginLeft: '5px' }}>Link</span>
              </>
            )}
          </a>
        </div>
        {!noActions ? (
          <div className="actions">
            <Button
              fontAwesomeIcon="times"
              kind="ghost"
              onClick={() => setApplianceToDelete?.(genericProduct)}
            />
            <Button
              fontAwesomeIcon="pencil"
              kind="ghost"
              onClick={() =>
                setApplianceCreateUpdateState?.({
                  genericProduct,
                  genericProductClasses: [genericProduct.productClass],
                  open: true,
                })
              }
            />
          </div>
        ) : null}
      </div>
    </StyledCard>
  )
}

export default ApplianceCard

const StyledCard = styled.div<{ styled?: { disabled?: boolean } }>`
  align-items: center;
  background: ${Colors.white};
  border: 1px solid ${Colors.gray100};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 100%;
  padding: 18px 16px;

  ${({ styled }) =>
    styled?.disabled &&
    css`
      cursor: initial;
      pointer-events: none;
    `}

  div.content {
    display: flex;
    gap: 10px;
    height: 100%;
    justify-content: space-between;
    width: 100%;

    div.info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: space-between;

      p {
        margin-bottom: 4px;
      }

      p.caption {
        font-size: 16px;
      }
    }

    div.actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  img {
    align-items: center;
    background: ${Colors.white};
    display: flex;
    height: 80px;
    justify-content: center;
    min-height: 80px;
    min-width: 80px;
    width: 80px;

    img {
      margin: 0;
    }
  }

  @media ${Breakpoint.onlyMobile} {
    height: unset;
  }
`
