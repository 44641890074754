import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Divider, Grid } from 'semantic-ui-react'

import Header from 'components/app/project/header/header-basic'
import OrderCabinetryManyProvider from 'context/order/provider-many-cabinetry'
import useOrderCabinetryMany from 'context/order/use-many-cabinetry'
import useProject from 'context/project/use'
import { TertiaryCard } from 'design-system/components/cards'
import Carousel from 'design-system/components/carousel'
import { Drawer } from 'design-system/components/drawer'
import AppLayout from 'design-system/containers/app-layout'
import { Breakpoint } from 'styles/app/system'
import { OrderType } from 'types/order'

import IssuesResolved from './issues-resolved'
import CourierCards from '../order/placed/cards/courier'
import DeliveryCards from '../order/placed/cards/delivery'
import OceanFreightCards from '../order/placed/cards/ocean-freight'

const AddOnsAndRemedials = () => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const { project } = useProject()
  const { shipments, deliveriesByOrder, supplierOrdersByCourier } =
    useOrderCabinetryMany()

  const [activeIndex, setActiveIndex] = useState<string[]>([])

  const onClickIssuesButton = (index: string) => {
    setActiveIndex([index])
    setTimeout(() => {
      const element = document.getElementById(`order-accordion-${index}`)
      element?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  return (
    <AppLayout
      header={<Header onBack={() => navigate(`/app/projects/${project.id}`)} />}
    >
      <Grid stackable>
        <Grid.Row columns="equal" verticalAlign="middle">
          <Grid.Column>
            <h2>Add-Ons & Remedials</h2>
          </Grid.Column>
          <Grid.Column textAlign="right" computer={4} tablet={6} mobile={8}>
            <TertiaryCard
              icon="file-invoice-dollar"
              title="Order Receipt(s)"
              onClick={() =>
                navigate(`/app/projects/${project.id}/order-payments`)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        {!!shipments.length && (
          <Grid.Row>
            <Grid.Column>
              <Drawer
                content={() => (
                  <Grid stackable style={{ paddingTop: '16px' }}>
                    <Grid.Row className="row-gap">
                      {isMobile ? (
                        <Carousel
                          slides={shipments.map((s, id) => ({
                            id: id,
                            item: (
                              <div style={{ paddingTop: 20, height: '100%' }}>
                                <OceanFreightCards
                                  shipments={[s]}
                                  onSeeIssues={onClickIssuesButton}
                                />
                              </div>
                            ),
                          }))}
                          hideNavButtons
                          slideSize={80}
                        />
                      ) : (
                        <OceanFreightCards
                          shipments={shipments}
                          onSeeIssues={onClickIssuesButton}
                        />
                      )}
                    </Grid.Row>
                    <Divider hidden />
                    <Grid.Row>
                      <Grid.Column>
                        <p className="small black no-margin">
                          *Factory quoted date
                        </p>
                        <p className="gray small">
                          Once your order is confirmed with the factory,
                          production will be scheduled and we will receive a
                          ‘factory quoted delivery date’. Please note that this
                          is not a scheduled delivery appointment. This date is
                          calculated by the factory planning department based on
                          the information available today and could move forward
                          or backwards. If you are unsure or have any questions
                          on how to schedule or prepare for the demolition of
                          the existing kitchen, please message customer support.
                        </p>
                        <p className="small black no-margin">
                          *Ready for delivery
                        </p>
                        <p className="gray small">
                          Once your order has reached the last mile facility in
                          your area, a member of our team will reach out to
                          schedule the delivery of your order.
                        </p>
                      </Grid.Column>
                    </Grid.Row>
                    <Divider hidden />
                  </Grid>
                )}
                defaultOpen
                title="In transit by Ocean Freight"
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {!!deliveriesByOrder.length && (
          <Grid.Row>
            <Grid.Column>
              <Drawer
                content={() => (
                  <Grid stackable style={{ paddingTop: '16px' }}>
                    <Grid.Row className="row-gap">
                      {isMobile ? (
                        <Carousel
                          slides={deliveriesByOrder.reduce<
                            { id: number; item: JSX.Element }[]
                          >((acc, d) => {
                            const slides = d.deliveries.map((dd, id) => ({
                              id: id,
                              item: (
                                <div style={{ paddingTop: 20, height: '100%' }}>
                                  <DeliveryCards
                                    deliveries={[dd]}
                                    fromAddOnsAndRemedials
                                    onSeeIssues={onClickIssuesButton}
                                    order={d.order}
                                  />
                                </div>
                              ),
                            }))

                            acc.push(...slides)
                            return acc
                          }, [])}
                          hideNavButtons
                          slideSize={80}
                        />
                      ) : (
                        deliveriesByOrder.map((d) => (
                          <DeliveryCards
                            deliveries={d.deliveries}
                            fromAddOnsAndRemedials
                            onSeeIssues={onClickIssuesButton}
                            order={d.order}
                          />
                        ))
                      )}
                    </Grid.Row>
                    <Divider hidden />
                  </Grid>
                )}
                defaultOpen
                title="Ready for delivery"
              />
            </Grid.Column>
          </Grid.Row>
        )}
        {!!supplierOrdersByCourier.length && (
          <>
            <Grid.Row>
              <Grid.Column>
                <Drawer
                  content={() => (
                    <Grid stackable style={{ paddingTop: '16px' }}>
                      <Grid.Row className="row-gap">
                        {isMobile ? (
                          <Carousel
                            slides={supplierOrdersByCourier.map((s, id) => ({
                              id: id,
                              item: (
                                <div style={{ paddingTop: 20, height: '100%' }}>
                                  <CourierCards
                                    supplierOrdersByCourier={[s]}
                                    onSeeIssues={onClickIssuesButton}
                                  />
                                </div>
                              ),
                            }))}
                            hideNavButtons
                            slideSize={80}
                          />
                        ) : (
                          <CourierCards
                            supplierOrdersByCourier={supplierOrdersByCourier}
                            onSeeIssues={onClickIssuesButton}
                          />
                        )}
                      </Grid.Row>
                      <Divider hidden />
                    </Grid>
                  )}
                  defaultOpen
                  title="Courier Shipments"
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <h3>Issues Resolved</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <IssuesResolved
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default (_: RouteComponentProps<{ project_id: string }>) => {
  const { ordersIssuesPlaced, project } = useProject()

  return (
    <OrderCabinetryManyProvider
      defaultFilters={{
        ids: ordersIssuesPlaced.map((o) => o.id ?? '').filter((o) => !!o),
        type: OrderType.CABINETRY,
        projectId: project.id,
      }}
    >
      <AddOnsAndRemedials />
    </OrderCabinetryManyProvider>
  )
}
