import React, { useEffect } from 'react'

import AwaitingInvoice from 'components/app/project/payment/awaiting-invoice'
import ExtraRoomDeposit from 'components/app/project/room/extra-room-deposit'
import Paid from 'components/shared/project/payment/versions/paid'
import PaymentSourceManyProvider from 'context/payment-source/provider-many'
import useRoom from 'context/room/use'
import { PaymentMethodType } from 'types/payment'

import { StepViewProps } from '../types'

const ExtraRoomPaymentView = ({
  setNextDisabled,
  setOnNext,
  setSkip,
}: StepViewProps) => {
  const { extraRoomPaymentSucceeded, extraRoomPaymentFirstOrUndefined, room } =
    useRoom()

  useEffect(() => {
    setSkip(false) //* Cannot skip
    setOnNext(undefined)
  }, [])

  useEffect(() => {
    setNextDisabled(!extraRoomPaymentSucceeded)
  }, [room?.payments])

  const renderPayment = () => {
    if (extraRoomPaymentSucceeded)
      return <Paid payment={extraRoomPaymentSucceeded} noReceipt />
    else if (
      extraRoomPaymentFirstOrUndefined?.metadata?.method ===
        PaymentMethodType.INVOICE &&
      extraRoomPaymentFirstOrUndefined?.status !== 'succeeded'
    )
      return <AwaitingInvoice />
    return <ExtraRoomDeposit payment={extraRoomPaymentFirstOrUndefined} />
  }

  return (
    <PaymentSourceManyProvider>{renderPayment()}</PaymentSourceManyProvider>
  )
}

export default ExtraRoomPaymentView
