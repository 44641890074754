import React, { useEffect } from 'react'

import { Grid } from 'semantic-ui-react'

import StepTitle from 'components/app/step-title'
import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import useDelivery from 'context/delivery/use'
import useDeliveryMutate from 'context/delivery/use-mutate'
import useProject from 'context/project/use'
import { ProjectFileType } from 'types/project-file'

import { DeliveryStepViewProps } from '../types'

export default ({
  setNextDisabled,
  setOnNext,
  delivery_id: deliveryId,
}: DeliveryStepViewProps) => {
  const { deliveryPalletes, delivery, refetch } = useDelivery()
  const { updateDeliveryAndRefetch } = useDeliveryMutate()
  const { project } = useProject()

  useEffect(() => {
    if (!deliveryId) return
    const handleNext = async () => {
      await updateDeliveryAndRefetch({
        variables: {
          data: {
            data: {
              ...delivery.data,
              palletesCompletedAt: new Date(),
            },
          },
          where: { id: deliveryId },
        },
      })
    }
    setOnNext(() => handleNext)
  }, [])

  useEffect(() => {
    //* Disable next if no photos have been added
    setNextDisabled(!deliveryPalletes.length)
  }, [deliveryPalletes])

  return (
    <Grid centered>
      <StepTitle
        title="Please upload photos of all your boxes and packages"
        subTitle="As soon as you receive your delivery please upload photos that show in detail the condition of all palletes and packages. This is for you own protection and allows us to file a claim if anything is damaged."
      />

      <ProjectFileUploads
        deliveryId={deliveryId}
        projectFileType={ProjectFileType.DELIVERY_PALLETE}
        projectFiles={deliveryPalletes}
        projectId={project.id}
        refetch={refetch}
      />
    </Grid>
  )
}
