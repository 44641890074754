import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import RoomProvider from 'context/room/provider'
import useRoomGuideOptions from 'context/room/use-guide-options'
import RoomCard from 'design-system/components/cards/room-card'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import { Section } from 'design-system/components/guide/utils'
import { RoomType, RoomTypeImage } from 'types/room'

const AdditionalRooms = () => {
  const { roomsUnarchived } = useProject()

  const { tradeBudget, tradeTimeline } = useRoomGuideOptions()

  const budget = tradeBudget?.data?.budget as string
  const timeline = tradeTimeline?.data?.timeline as string

  return (
    <Section id="review-changes">
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Has anything changed since we last spoke?</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        {roomsUnarchived ? (
          <>
            <Grid.Row>
              <Grid.Column>
                <p className="gray">Scope</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="row-gap">
              {roomsUnarchived.map((r) => {
                const typeIndex = Object.values(RoomType).indexOf(r.type)

                return (
                  <Grid.Column key={r.id} computer={5} tablet={8} mobile={16}>
                    <RoomCard
                      color={r.data.tagColor}
                      image={`${
                        process.env.GATSBY_CDN_ROOT
                      }/images/app/illustrations/${
                        Object.values(RoomTypeImage)[typeIndex]
                      }`}
                      noActions
                      subtitle={r.data?.descriptionAdditional}
                      title={r.type}
                    />
                  </Grid.Column>
                )
              })}
            </Grid.Row>
          </>
        ) : null}
        <Divider hidden />
        <Grid.Row>
          {budget ? (
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <p className="gray">Budget</p>
              <SelectIconCard active icon="file-invoice-dollar" text={budget} />
            </Grid.Column>
          ) : null}
          {timeline ? (
            <Grid.Column computer={5} tablet={8} mobile={16}>
              <p className="gray">Timeline</p>
              <SelectIconCard active icon="calendar" text={timeline} />
            </Grid.Column>
          ) : null}
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default () => {
  const { roomPrimary } = useProject()

  return (
    <RoomProvider room_id={roomPrimary.id}>
      <AdditionalRooms />
    </RoomProvider>
  )
}
