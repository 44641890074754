import React from 'react'

import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import Button from 'design-system/components/button'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import { Section } from 'design-system/components/guide/utils'
import { MeetingTypeProject } from 'types/meeting'

const NextCall = ({ projectId }: { projectId: string }) => {
  return (
    <Section id="next-call">
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Let’s schedule the next call.</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={5} tablet={5} mobile={16}>
            <SelectIconCard
              icon="video"
              text="Project Proposal"
              onClick={() =>
                navigate(
                  `/app/projects/${projectId}/meeting?meetingType=${MeetingTypeProject.PROJECT_PROPOSAL}`,
                  { state: { comingFromHome: true } },
                )
              }
              style={{ margin: '50px 0 ' }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Button
              color="gray"
              fontAwesomeIcon="arrow-right"
              iconAnimation="scale"
              kind="text"
              onClick={() => navigate(`/app/projects/${projectId}/scheduling`)}
              text="Scheduling tool"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default NextCall
