import React from 'react'

import { navigate } from 'gatsby'
import moment from 'moment'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import HelpMessage from 'components/shared/help-message'
import useOrder from 'context/order/use'
import { getOrderFormattedAdditional } from 'context/order/utils'
import useProject from 'context/project/use'
import { parseFiles } from 'context/project-file/utils-many'
import SecondaryCard from 'design-system/components/cards/secondary-card'
import { Breakpoint, Colors } from 'styles/app/system'

import CourierCards from './cards/courier'
import DeliveryCards from './cards/delivery'
import OceanFreightCards from './cards/ocean-freight'
import OrderDesigns from '../designs'

const OrderPlaced = () => {
  const { project } = useProject()
  const { order, deliveries, shipments, supplierOrdersByCourier } = useOrder()

  return (
    <>
      <TitleDiv>
        <div>
          <h2 className="no-margin">Your Order</h2>
          <p className="light-gray subtitle">
            {getOrderFormattedAdditional(order)}
          </p>
        </div>
        <HelpMessage
          fit
          icon="file-invoice-dollar"
          message={`Payment received ${moment(order.placedAt).format(
            'DD MMM YYYY',
          )}`}
        />
      </TitleDiv>
      <p>Please review the following materials before delivery</p>
      <Grid stackable style={{ marginBottom: '60px' }}>
        <Grid.Row className="row-gap">
          <Grid.Column largeScreen={4} computer={5} tablet={6} mobile={16}>
            <SecondaryCard
              basic={true}
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/prepare-for-delivery.svg`}
              onClick={() =>
                navigate(
                  `/app/projects/${project.id}/orders/${order.id}/prepare-delivery`,
                )
              }
              title="Prepare for delivery"
            />
          </Grid.Column>
          <Grid.Column largeScreen={4} computer={5} tablet={6} mobile={16}>
            <SecondaryCard
              basic={true}
              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/installation.svg`}
              onClick={() =>
                navigate(
                  `/app/projects/${project.id}/orders/${order.id}/installation-resources`,
                )
              }
              title="Prepare for installation"
            />
          </Grid.Column>
          {order.designs?.map(
            (d) =>
              parseFiles(d.room?.files || []).installationPacks?.map(
                (f, idx) => (
                  <Grid.Column
                    key={f.id}
                    largeScreen={4}
                    computer={5}
                    tablet={6}
                    mobile={16}
                  >
                    <SecondaryCard
                      basic={true}
                      image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/file-placeholder.svg`}
                      onClick={() =>
                        window.open(
                          `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                            f.key,
                          )}`,
                        )
                      }
                      title={`${d.room?.type} ${
                        idx ? idx + 1 : ''
                      } - Installation Pack`}
                    />
                  </Grid.Column>
                ),
              ),
          )}
        </Grid.Row>
      </Grid>
      <p className="subtitle">Your shipment(s)</p>
      <Grid stackable>
        <GridRow>
          <DeliveryCards deliveries={deliveries} order={order} />
          <OceanFreightCards shipments={shipments} />
          <CourierCards supplierOrdersByCourier={supplierOrdersByCourier} />
        </GridRow>
      </Grid>
      <div style={{ margin: '30px 0 22px 0' }}>
        <p className="gray small">
          <span style={{ color: Colors.black }}>*Factory quoted date</span>
          <br />
          Once your order is confirmed with the factory, production will be
          scheduled and we will receive a ‘factory quoted delivery date’. Please
          note that this is not a scheduled delivery appointment. This date is
          calculated by the factory planning department based on the information
          available today and could move forward or backwards. If you are unsure
          or have any questions on how to schedule or prepare for the demolition
          of the existing kitchen, please message customer support.
        </p>
      </div>
      <div style={{ marginBottom: '22px' }}>
        <p className="gray small">
          <span style={{ color: Colors.black }}>*Ready for delivery</span>
          <br />
          Once your order has reached the last mile facility in your area, a
          member of our team will reach out to schedule the delivery of your
          order.
        </p>
      </div>
      <OrderDesigns />
    </>
  )
}

export default OrderPlaced

const GridRow = styled(Grid.Row)`
  @media ${Breakpoint.upFromMobile} {
    row-gap: 40px;
  }
`

const TitleDiv = styled.div`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  width: 100%;

  @media ${Breakpoint.upFromMobile} {
    display: inline-flex;
  }
`
