import React, { useState } from 'react'

import { navigate } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import { ReportsTable } from 'components/app/project/deliveries'
import ConfirmationModal from 'components/shared/confirmation-modal'
import useDeliveryIssueMutate from 'context/delivery/use-issue-mutate'
import Button from 'design-system/components/button'
import { Issue } from 'types/issue'

type DeliveryIssueReportsProps = {
  issues: Issue[]
  projectId: string
  onEditIssue: (issue: Issue) => void
}

export const DeliveryIssueReports = ({
  issues,
  projectId,
  onEditIssue,
}: DeliveryIssueReportsProps) => {
  const { deleteIssueAndRefetch, loadingDelete } = useDeliveryIssueMutate()

  const [issueToDeleteId, setIssueToDeleteId] = useState<string>()

  const handleDeleteIssue = async () => {
    if (issueToDeleteId) {
      await deleteIssueAndRefetch({
        variables: { where: { id: issueToDeleteId } },
      })
    }
    setIssueToDeleteId(undefined)
  }

  return (
    <>
      <Grid padded>
        <Grid.Row centered>
          <h3>We’re sorry there are issues</h3>
        </Grid.Row>
        <Grid.Row centered>
          <p>
            We have received your report and we will reach out as soon as
            possible.
            <br />
            Please don’t hesitate to message us if you have any questions.
          </p>
        </Grid.Row>
        <Grid.Row centered>
          <Button
            kind="solid"
            color="blue"
            fontAwesomeIcon="envelope"
            iconPosition="left"
            text="Messages"
            onClick={() => navigate(`/app/projects/${projectId}/messages`)}
          />
        </Grid.Row>
        <Grid.Row>
          <ReportsTable
            mode="edit"
            onEditIssue={onEditIssue}
            onDeleteIssue={(issue) => setIssueToDeleteId(issue.id)}
            issues={issues}
          />
        </Grid.Row>
      </Grid>
      <ConfirmationModal
        open={!!issueToDeleteId}
        confirmText="Yes, Delete"
        firstTitle="Are you sure you want to delete this issue?"
        onConfirm={handleDeleteIssue}
        onCancel={() => {
          setIssueToDeleteId(undefined)
        }}
        loading={loadingDelete}
      />
    </>
  )
}
