import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container, Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import { Breakpoint, Colors } from 'styles/app/system'

import SectionTitle from './section-title'

const WhatWeHeard = ({ items }: { items: string[] }) => {
  return (
    <MainContainer>
      <Container>
        <Grid columns={4} stretched>
          <SectionTitle title="Things to consider in your project" />
          {items &&
            items.map((goal: string, id: number) => (
              <Grid.Column key={id} computer={4} tablet={8} mobile={16}>
                <StyledCard>
                  <FontAwesomeIcon icon={['fal', 'circle-check']} />
                  <h4>{goal}</h4>
                </StyledCard>
              </Grid.Column>
            ))}
        </Grid>
      </Container>
      <Divider />
    </MainContainer>
  )
}

export default WhatWeHeard

const MainContainer = styled.section`
  background: ${Colors.gray50};
  .container {
    padding: 90px 0;
  }

  div.divider {
    margin: 0 80px;
    @media ${Breakpoint.onlyMobile} {
      margin: 0;
    }
  }
`

const StyledCard = styled.div`
  align-items: baseline;
  background: ${Colors.white};
  border-radius: 8px;
  box-shadow: 0px 10px 15px rgba(120, 113, 108, 0.09);
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  margin: 0 10px;

  h2 {
    margin: 0;
  }

  svg {
    font-size: 24px;
    color: ${Colors.gray400};
  }

  @media ${Breakpoint.onlyMobile} {
    margin: 0;
  }
`
