import { RoomElementOption } from 'types/room-element-option'

const WoodLaminate = [
  'floorMaterialgeneric_inspiration_flooring001',
  'floorMaterialgeneric_inspiration_flooring002',
  'floorMaterialgeneric_inspiration_flooring004',
  'floorMaterialgeneric_inspiration_flooring005',
  'floorMaterialgeneric_inspiration_flooring011',
  'floorMaterialgeneric_inspiration_flooring012',
  'floorMaterialgeneric_inspiration_flooring014',
  'floorMaterialgeneric_inspiration_flooring022',
  'floorMaterialgeneric_inspiration_flooring013',
  'floorMaterialgeneric_inspiration_flooring015',
  'floorMaterialgeneric_inspiration_flooring016',
]

const Tile = [
  'floorMaterialgeneric_inspiration_flooring007',
  'floorMaterialgeneric_inspiration_flooring008',
  'floorMaterialgeneric_inspiration_flooring009',
]

const Other = [
  'floorMaterialgeneric_inspiration_flooring006',
  'floorMaterialgeneric_inspiration_flooring003',
  'floorMaterialgeneric_inspiration_flooring018',
  'floorMaterialgeneric_inspiration_flooring019',
  'floorMaterialgeneric_inspiration_flooring010',
  'floorMaterialgeneric_inspiration_flooring017',
  'floorMaterialgeneric_inspiration_flooring020',
  'floorMaterialgeneric_inspiration_flooring019',
]

export const parseFloorMaterials = (floorMaterials: RoomElementOption[]) => {
  return floorMaterials.reduce<{
    floorsWoodLaminate: RoomElementOption[]
    floorsWoodLaminateCount: number
    floorsTile: RoomElementOption[]
    floorsTileCount: number
    floorsOther: RoomElementOption[]
    floorsOtherCount: number
    floorsAll: RoomElementOption[]
    floorsNoCustom: RoomElementOption[]
  }>(
    (acc, reo) => {
      if (reo.optionIdentifier !== 'custom') acc.floorsAll.push(reo)
      if (reo.identifier && WoodLaminate.includes(reo.identifier))
        return {
          ...acc,
          floorsWoodLaminate: [...acc.floorsWoodLaminate, reo],
          floorsWoodLaminateCount: acc.floorsWoodLaminateCount + 1,
        }
      if (reo.identifier && Tile.includes(reo.identifier))
        return {
          ...acc,
          floorsTile: [...acc.floorsTile, reo],
          floorsTileCount: acc.floorsTileCount + 1,
        }
      if (reo.identifier && Other.includes(reo.identifier))
        return {
          ...acc,
          floorsOther: [...acc.floorsOther, reo],
          floorsOtherCount: acc.floorsOtherCount + 1,
        }
      return acc
    },
    {
      floorsWoodLaminate: [],
      floorsWoodLaminateCount: 0,
      floorsTile: [],
      floorsTileCount: 0,
      floorsOther: [],
      floorsOtherCount: 0,
      floorsAll: [],
      floorsNoCustom: [],
    },
  )
}
