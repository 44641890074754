import React from 'react'

import _ from 'lodash'
import { Card, Grid, Placeholder } from 'semantic-ui-react'
import styled from 'styled-components'

import { Breakpoint, Colors } from 'styles/app/system'

export const UserPlaceholder = () => {
  return (
    <Grid.Row columns="equal">
      <Grid.Column
        style={{
          flexGrow: 0,
          width: 'fit-content',
        }}
      >
        <StyledPlaceholder
          style={{
            borderRadius: '50%',
            height: '80px',
            width: '80px',
          }}
        >
          <Placeholder.Image />
        </StyledPlaceholder>
      </Grid.Column>
      <Grid.Column computer={8} tablet={12} mobile={11}>
        <StyledPlaceholder fluid>
          <Placeholder.Header>
            <Placeholder.Line className="big" />
          </Placeholder.Header>
          <Placeholder.Paragraph>
            <Placeholder.Line className="small" length="short" />
          </Placeholder.Paragraph>
        </StyledPlaceholder>
      </Grid.Column>
    </Grid.Row>
  )
}

export const RowCardsPlaceholder = ({
  items = 4,
  size = 'big',
  noTitle,
}: {
  items?: number
  size?: string
  noTitle?: boolean
}) => {
  return (
    <>
      {!noTitle && (
        <Grid.Row>
          <Grid.Column>
            <StyledPlaceholder>
              <Placeholder.Line length="short" />
            </StyledPlaceholder>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Row style={{ margin: '0 5px', gap: '20px' }}>
        {_.range(items).map((id) => (
          <CardPlaceholder key={id} size={size} />
        ))}
      </Grid.Row>
    </>
  )
}

export const CardPlaceholder = ({ size }: { size?: string }) => {
  return (
    <StyledCardPlaceholder>
      <Placeholder>
        {size === 'big' ? (
          <>
            <Placeholder.Paragraph>
              <Placeholder.Line className="big" length="very long" />
              <Placeholder.Line length="short" />
            </Placeholder.Paragraph>
            <Placeholder.Paragraph>
              <Placeholder.Line className="big" />
            </Placeholder.Paragraph>
          </>
        ) : null}
        {size === 'medium' ? (
          <Placeholder.Paragraph>
            <Placeholder.Line className="big" length="very long" />
            <Placeholder.Line className="medium" length="long" />
            <Placeholder.Line length="short" />
          </Placeholder.Paragraph>
        ) : null}
        {size === 'small' ? (
          <Placeholder.Paragraph>
            <Placeholder.Line className="medium" length="very long" />
          </Placeholder.Paragraph>
        ) : null}
      </Placeholder>
    </StyledCardPlaceholder>
  )
}

const StyledCardPlaceholder = styled(Card)`
  &&&& {
    border: 0;
    margin: 0;
    padding: 16px;
    width: 260px;
    box-shadow: 0px 0px 15px rgba(120, 113, 108, 0.09);
    border-radius: 8px;

    .placeholder {
      border-radius: 0 !important;
    }

    .line {
      margin-bottom: 21px;
      :after {
        height: 21px;
      }
    }

    .line.big {
      margin-bottom: 38px;
      :after {
        height: 38px;
      }
    }

    .line.medium {
      margin-bottom: 30px;
      :after {
        height: 30px;
      }
    }

    @media ${Breakpoint.downFromComputer} {
      margin: 0 10px;
      width: 40%;
    }

    @media ${Breakpoint.downFromSmallMobile} {
      margin: 0 15px;
      width: 100%;
    }
  }
`

const StyledPlaceholder = styled(Placeholder)`
  &&&& {
    .line {
      background-color: ${Colors.gray50} !important;
      height: 1em;
      margin-bottom: 27px;

      :after {
        height: 27px;
      }
    }

    .line.big {
      margin-bottom: 60px;
      :after {
        height: 60px;
      }
    }

    .line.small {
      margin-bottom: 18px;
      :after {
        height: 18px;
      }
    }

    .paragraph:before {
      background-color: ${Colors.gray50} !important;
    }
  }
`
