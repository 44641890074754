import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import StepTitle from 'components/app/step-title'
import FileCard from 'components/shared/project/project-file/card'
import { UploadProjectFileCard } from 'components/shared/project/project-file/upload-card'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomProjectFileMutate from 'context/room/use-project-file-mutate'
import Button from 'design-system/components/button'
import { Breakpoint, Colors } from 'styles/app/system'
import { ProjectFileType, ProjectFile } from 'types/project-file'

import HowToVideo, { HowToVideoProps } from './how-to-video'
import ProspectFilesModal from './prospect-files-modal'

type RoomProjectFileUploadsProps = RouteComponentProps & {
  chooseProspectFiles: boolean
  howToVideo?: HowToVideoProps['type']
  projectFiles?: ProjectFile[]
  projectFileType: ProjectFileType
  subTitle?: string
  title: string
}

const RoomUploads = (props: RoomProjectFileUploadsProps) => {
  const [openModal, setOpenModal] = useState(false)
  const { project } = useProject()
  const { prospectPhotosUnused, room } = useRoom()
  const {
    disconnectProjectFileAndRefetch,
    deleteProjectFileAndRefetch,
    onProjectFilesUploadedRefetch,
  } = useRoomProjectFileMutate()

  return (
    <>
      <Grid centered>
        <StepTitle title={props.title} subTitle={props.subTitle} />
        <Grid.Row centered>
          <Grid.Column textAlign="center">
            {props.howToVideo && <HowToVideo type={props.howToVideo} />}
            {props.chooseProspectFiles && !!prospectPhotosUnused?.length && (
              <UploadProspectFiles>
                <FontAwesomeIcon icon={['fal', 'folder']} />
                <p className="no-margin">
                  <span className="bold">Have you already uploaded it?</span>{' '}
                  Select a file from
                </p>
                <Button
                  kind="ghost"
                  onClick={() => setOpenModal(true)}
                  text="Your Uploads"
                />
              </UploadProspectFiles>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched style={{ rowGap: '28px' }}>
          <Grid.Column
            widescreen={3}
            largeScreen={3}
            computer={5}
            tablet={8}
            mobile={16}
          >
            <UploadProjectFileCard
              fileType={props.projectFileType}
              onFilesUploaded={onProjectFilesUploadedRefetch}
              projectId={project.id}
              roomId={room?.id}
            />
          </Grid.Column>
          {props.projectFiles?.map((file) => (
            <Grid.Column
              key={file.id}
              widescreen={3}
              largeScreen={3}
              computer={5}
              tablet={8}
              mobile={16}
            >
              <FileCard
                file={file}
                onFileRemoved={async (f) => {
                  if (f.prospect) {
                    await disconnectProjectFileAndRefetch(f)
                  } else {
                    await deleteProjectFileAndRefetch({
                      variables: {
                        where: {
                          id: f.id ?? '',
                        },
                      },
                    })
                  }
                }}
                showDeleteBtn={true}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      <ProspectFilesModal
        openModal={openModal}
        onCloseModal={() => setOpenModal(false)}
        projectFileType={props.projectFileType}
      />
    </>
  )
}

const UploadProspectFiles = styled.div`
  align-items: center;
  background: #fcf9f2;
  border: 1px solid ${Colors.yellow100};
  border-radius: 8px;
  display: flex;
  gap: 16px;
  margin: 16px auto;
  padding: 16px;
  width: fit-content;

  @media ${Breakpoint.onlyMobile} {
    flex-direction: column;
    gap: 8px;
  }
`

export default RoomUploads
