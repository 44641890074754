import React, { useEffect, useMemo, useState } from 'react'

import { Router } from '@reach/router'

import { NavFooter } from 'components/app/nav-footer'
import HeaderBasic from 'components/app/project/header/header-basic'
import StartFlowView from 'components/app/project/start-flow-view'
import Loader from 'components/shared/loader'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomMutate from 'context/room/use-mutate'
import { parsePayments } from 'context/room/utils'
import FlowLayout, { StepName } from 'design-system/containers/flow-layout'
import { RoomGraphQL, RoomData } from 'types/room'
import { parseStateWithPrevious } from 'utils/navigation'

import { EXTRA_ROOM_STEPS } from './steps'
import { NavigationStep, RoomFlowRouteProps } from '../types'

export const ExtraRoomFlowIndex = ({
  path: _,
  ...routeProps
}: RoomFlowRouteProps) => {
  const {
    '*': subroute,
    project_id: projectId,
    room_id: roomId,
    navigate,
    location,
  } = routeProps
  const { primaryDesigner, roomPrimary } = useProject()
  const {
    room,
    paymentIncluded,
    floorPlansIncluded,
    roomPhotosIncluded,
    inspirationImagesIncluded,
    appliancesIncluded,
    formattedType,
  } = useRoom()
  const { updateRoomAndRefetch } = useRoomMutate()
  const [nextDisabled, setNextDisabled] = useState(true)
  const [onNext, setOnNext] = useState<() => Promise<void>>()
  const [loadingButton, setLoadingButton] = useState(false)
  const [loading, setLoading] = useState(true)
  const [skip, setSkip] = useState(false)

  const STEPS = useMemo<NavigationStep[]>(() => {
    const steps: NavigationStep[] = []

    if (paymentIncluded) {
      steps.push(EXTRA_ROOM_STEPS.payment)
    }
    if (floorPlansIncluded) {
      steps.push(EXTRA_ROOM_STEPS.floorPlan)
    }
    if (roomPhotosIncluded) {
      steps.push(EXTRA_ROOM_STEPS.photos)
    }
    if (inspirationImagesIncluded) {
      steps.push(EXTRA_ROOM_STEPS.inspiration)
    }
    if (appliancesIncluded) {
      steps.push(EXTRA_ROOM_STEPS.appliances)
    }

    return steps
  }, [
    paymentIncluded,
    floorPlansIncluded,
    roomPhotosIncluded,
    inspirationImagesIncluded,
    appliancesIncluded,
  ])

  const state = parseStateWithPrevious<{
    isEffectRedirect: boolean
  }>(location?.state, { isEffectRedirect: false })
  const { isEffectRedirect } = state

  const currentStepIndex = useMemo(
    () => STEPS.findIndex((step) => step.subroute === subroute),
    [subroute],
  )

  useEffect(() => {
    if (roomPrimary?.id === roomId || !STEPS.length) {
      navigate?.(`/app/projects/${projectId}/rooms/${roomId}/home`, {
        replace: true,
      })
      return
    }

    if (STEPS.every((s) => isNavigationStepDone(s, room))) {
      navigate?.(`/app/projects/${projectId}/rooms/${roomId}/home`)
      return
    }

    let redirectSubroute = ''
    STEPS.forEach((s, i) => {
      const nextStep = STEPS[i + 1]
      if (
        isNavigationStepDone(s, room) &&
        nextStep &&
        !isNavigationStepDone(nextStep, room)
      ) {
        redirectSubroute = nextStep.subroute
      }
    })

    navigate?.(
      `/app/projects/${projectId}/rooms/${roomId}/extra-room/${redirectSubroute}`,
      { replace: true, state: { isEffectRedirect: true } },
    )
  }, [STEPS])

  useEffect(() => {
    isEffectRedirect && setLoading(false)
  }, [isEffectRedirect])

  const setStepCompleted = async () => {
    const roomData: RoomData = {}

    switch (subroute) {
      case EXTRA_ROOM_STEPS.floorPlan.subroute:
        if (room?.data.floorPlansCompletedAt) return
        roomData.floorPlansCompletedAt = new Date()
        break
      case EXTRA_ROOM_STEPS.photos.subroute:
        if (room?.data.roomPhotosCompletedAt) return
        roomData.roomPhotosCompletedAt = new Date()
        break
      case EXTRA_ROOM_STEPS.inspiration.subroute:
        if (room?.data.inspirationImagesCompletedAt) return
        roomData.inspirationImagesCompletedAt = new Date()
        break
      case EXTRA_ROOM_STEPS.appliances.subroute:
        if (room?.data.appliancesCompletedAt) return
        roomData.appliancesCompletedAt = new Date()
        break
      default:
        //* address or booking
        return
    }

    await updateRoomAndRefetch({
      variables: {
        data: { data: roomData },
        where: { id: roomId as string },
      },
    })
  }

  const isFirstStep = currentStepIndex <= 0
  const isLastStep = currentStepIndex >= STEPS.length - 1

  const handleNext = async () => {
    await onNext?.()
    await setStepCompleted()
    if (!isLastStep) {
      navigate?.(
        `/app/projects/${projectId}/rooms/${roomId}/extra-room/${
          STEPS[currentStepIndex + 1].subroute
        }`,
      )
    } else {
      navigate?.(`/app/projects/${projectId}/rooms/${roomId}/home`)
    }
  }

  if (loading) return <Loader />

  if (currentStepIndex === -1) {
    return (
      <StartFlowView
        description="Let's set up your room in order to start designing"
        designerInformation={{
          firstName: primaryDesigner?.firstName,
          picture: primaryDesigner?.picture ?? '',
        }}
        handleStart={() =>
          navigate?.(
            `/app/projects/${projectId}/rooms/${roomId}/extra-room/${STEPS[0].subroute}`,
          )
        }
        header={<HeaderBasic />}
        lateralText="Additional room"
        steps={STEPS}
        title={
          <h2>
            Let's get started with your <i>{formattedType}</i>
          </h2>
        }
      />
    )
  }

  return (
    <FlowLayout
      header={
        <HeaderBasic
          onBack={() => navigate?.(`/app/projects/${projectId}/home`)}
        />
      }
      main={
        <>
          <StepName>{STEPS[currentStepIndex].label}</StepName>
          <Router>
            {STEPS.map(({ View, subroute }) => (
              <View
                key={subroute}
                path={subroute}
                setOnNext={setOnNext}
                setNextDisabled={setNextDisabled}
                setLoading={setLoadingButton}
                setSkip={setSkip}
                chooseProspectFiles={false}
                isExtraRoom
                {...routeProps}
              />
            ))}
          </Router>
        </>
      }
      actions={
        <NavFooter
          hideBack={isFirstStep}
          backDisabled={isFirstStep}
          nextDisabled={nextDisabled}
          loading={loadingButton}
          nextText={skip ? 'Skip for now' : 'Continue'}
          onBack={() =>
            navigate?.(
              `/app/projects/${projectId}/rooms/${roomId}/extra-room/${
                STEPS[currentStepIndex - 1].subroute
              }`,
            )
          }
          onNext={handleNext}
        />
      }
      stepperProps={{
        currentStep: currentStepIndex,
        steps: STEPS,
      }}
    />
  )
}

const isNavigationStepDone = (n: NavigationStep, room?: RoomGraphQL) => {
  switch (n.subroute) {
    case EXTRA_ROOM_STEPS.payment.subroute: {
      const { extraRoomPaymentSucceeded } = parsePayments(room)
      return extraRoomPaymentSucceeded
    }
    case EXTRA_ROOM_STEPS.floorPlan.subroute:
      return !!room?.data.floorPlansCompletedAt
    case EXTRA_ROOM_STEPS.photos.subroute:
      return !!room?.data.roomPhotosCompletedAt
    case EXTRA_ROOM_STEPS.inspiration.subroute:
      return !!room?.data.inspirationImagesCompletedAt
    case EXTRA_ROOM_STEPS.appliances.subroute:
      return !!room?.data.appliancesCompletedAt
    default:
      return false
  }
}
