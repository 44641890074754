import React, { useEffect, useState } from 'react'

import { Form, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { getReferenceImage } from 'context/media/utils-many'
import Button from 'design-system/components/button'
import SelectTextCard from 'design-system/components/cards/selection/select-text-card'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/app/components/form'
import { Colors } from 'styles/app/system'
import {
  GenericProductGraphQL,
  GenericProductData,
} from 'types/generic-product'
import { GenericProductClass } from 'types/generic-product-class'

const initialGenericProductData = {
  confirmedAt: null,
  lastAcknowledgedAt: null,
  lastUpdatedByCustomerAt: null,
  linkToProduct: '',
  productDetails: '',
  size: '',
} as NullableRecord<GenericProductData>

type ApplianceModalProps = {
  genericProduct: Partial<GenericProductGraphQL> | null
  genericProductClasses: GenericProductClass[]
  onClose?: () => void
  onSaveGenericProduct: (
    value:
      | Partial<GenericProductGraphQL>
      | NullableRecord<Partial<GenericProductGraphQL>>,
  ) => Promise<void>
  open: boolean
  loadingSave?: boolean
}

const ApplianceModal = ({
  genericProduct,
  genericProductClasses,
  onClose,
  onSaveGenericProduct,
  open,
  loadingSave,
}: ApplianceModalProps) => {
  const defaultGenericProductClass =
    genericProductClasses.length === 1 ? genericProductClasses[0] : null
  const [state, setState] = useState<
    Partial<NullableRecord<GenericProductGraphQL>>
  >(
    genericProduct || {
      data: {
        ...initialGenericProductData,
        size: '',
      },
      productClass: defaultGenericProductClass,
    },
  )

  const genericProductClassOptions = genericProductClasses.map(
    (ce: GenericProductClass) => {
      return {
        key: ce.identifier,
        text: (
          <div
            style={{
              alignItems: 'center',
              display: 'inline-flex',
              flexDirection: 'row',
              width: '100%',
            }}
          >
            <Image
              style={{
                margin: '0 12px 0 0',
                width: '55px',
                height: '55px',
              }}
              src={getReferenceImage(ce.media)}
            />
            {ce.metadata?.name}
          </div>
        ),
        value: ce.identifier,
      }
    },
  )

  useEffect(() => {
    setState(
      genericProduct || {
        data: {
          ...initialGenericProductData,
          size: '',
        },
        productClass: defaultGenericProductClass,
      },
    )
  }, [genericProduct, genericProductClasses])

  const sizes = state.productClass?.metadata?.defaultSizes
  useEffect(() => {
    if (sizes?.length === 1) {
      setState({
        ...state,
        data: {
          ...state.data,
          size: sizes[0],
        },
      })
    }
  }, [sizes])

  const resetState = () => {
    setState(
      genericProduct || {
        data: {
          ...initialGenericProductData,
          size: '',
        },
        productClass: null,
      },
    )
  }

  const handleSave = async () => {
    await onSaveGenericProduct({
      ...(genericProduct && {
        id: genericProduct.id,
      }),
      productClass: state.productClass,
      data: state.data,
    })
    onClose?.()
  }

  return (
    <Modal
      title={`${genericProduct ? 'Edit' : 'Add'} appliance`}
      onClose={onClose}
      onExited={resetState}
      open={open}
      saveButton={{
        disabled:
          !state.productClass || !state.data?.size || !state.data.linkToProduct,
        loading: loadingSave,
        onClick: handleSave,
        text: genericProduct ? 'Update' : 'Save',
      }}
    >
      <StyledForm
        style={{ minHeight: '450px' }}
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
      >
        {genericProductClasses.length > 1 ? (
          <StyledDropdown
            icon={
              <Button
                className="button-angle"
                kind="ghost"
                fontAwesomeIcon="angle-down"
              />
            }
            label="Appliance"
            onChange={(
              _: React.ChangeEvent<HTMLInputElement>,
              { value }: { value: string },
            ) => {
              setState({
                ...state,
                productClass: genericProductClasses?.find(
                  (gpc) => gpc.identifier === value,
                ),
                data: {
                  ...state.data,
                  size: '',
                },
              })
            }}
            options={genericProductClassOptions}
            placeholder="Select an appliance"
            required
            selection
            style={{ height: 'auto', marginBottom: '30px' }}
            value={state.productClass?.identifier}
          />
        ) : (
          <>
            <Form.Field label="Appliance" required />
            <div
              style={{ display: 'flex', alignItems: 'center', padding: '0' }}
            >
              {defaultGenericProductClass?.media && (
                <Image
                  size="tiny"
                  src={getReferenceImage(defaultGenericProductClass.media)}
                />
              )}
              <p>{defaultGenericProductClass?.metadata?.name}</p>
            </div>
          </>
        )}
        {sizes?.length ? (
          <Form.Field required style={{ margin: '0' }}>
            <label>Size</label>
            <Form.Group
              className="up-mobile"
              style={{ flexWrap: 'wrap', rowGap: '10px', width: '100%' }}
              widths={5}
            >
              {sizes?.map((s: string, idx) => (
                <Form.Field key={idx}>
                  <SelectTextCard
                    onClick={() => {
                      setState({
                        ...state,
                        data: {
                          ...state.data,
                          size: s,
                        },
                      })
                    }}
                    selected={state.data?.size === s}
                    text={s}
                  />
                </Form.Field>
              ))}
            </Form.Group>
            <Form.Group
              className="only-mobile"
              style={{
                flexFlow: 'row wrap',
                gap: '14px 0',
                width: '100%',
              }}
              unstackable
              widths={3}
            >
              {sizes?.map((s: string, idx) => (
                <Form.Field key={idx}>
                  <SelectTextCard
                    onClick={() => {
                      setState({
                        ...state,
                        data: {
                          ...state.data,
                          size: s,
                        },
                      })
                    }}
                    selected={state.data?.size === s}
                    text={s}
                  />
                </Form.Field>
              ))}
            </Form.Group>
          </Form.Field>
        ) : null}
        <Form.Field
          control="input"
          label={
            <label
              style={{
                marginTop: !sizes?.length ? '22px' : '32px',
              }}
            >
              Model
            </label>
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState({
              ...state,
              data: {
                ...state.data,
                [`${e.target.name}`]: e.target.value,
              },
            })
          }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
            e.key === 'Enter' && e.preventDefault()
          }
          name="productDetails"
          placeholder="Content"
          value={state.data?.productDetails}
        />
        <Form.Input
          label="Link"
          name="linkToProduct"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setState({
              ...state,
              data: {
                ...state.data,
                [`${e.target.name}`]: e.target.value,
              },
            })
          }}
          placeholder="Paste your link here"
          required
          value={state.data?.linkToProduct}
        />
      </StyledForm>
    </Modal>
  )
}

export default ApplianceModal

const StyledDropdown = styled(Form.Dropdown)`
  div.dropdown {
    background-color: white !important;
    border: 1px solid ${Colors.gray200} !important;
    padding: 5px;
    max-height: 80px !important;

    .item {
      align-items: center;
      display: flex;
      height: 80px !important;
      img {
        max-height: 60px;
        margin-right: 15px;
      }
    }

    .button-angle {
      position: absolute;
      right: 16px;

      svg {
        position: unset !important;
      }
    }

    div.menu.transition {
      max-height: 350px;
    }

    .divider.text {
      align-items: center;
      display: inline-flex;
      height: 60px !important;
      overflow: unset !important;

      img {
        margin: auto;
        max-height: 90%;
      }
    }

    div.default.text {
      margin-left: 16px;
    }
  }
`
