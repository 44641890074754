import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import Loader from 'components/shared/loader'
import CalendlyInlineScheduler from 'components/shared/meeting/calendly-inline-scheduler'
import useCompany from 'context/company/use'
import fireEvent from 'context/tracking/events'
import useUser from 'context/user/use'
import useUserUniqueWithFiles from 'context/user/use-unique-files'
import AppLayout from 'design-system/containers/app-layout'
import { MeetingTypeProject } from 'types/meeting'

const ScheduleCall = (_: RouteComponentProps) => {
  const { company } = useCompany()
  const { refetch, projects, startPolling, stopPolling, user } = useUser()

  const { user: referredDesigner, loading: loadingDesigner } =
    useUserUniqueWithFiles(user?.data?.referredDesignerId ?? '')

  const handleEventScheduled = () => {
    startPolling(2000)
  }

  useEffect(() => {
    if (projects.length > 0) {
      stopPolling()
      refetch()
      navigate(`/app/company/${company?.id}`)
    }
    return () => {
      stopPolling()
    }
  }, [company, projects])

  if (loadingDesigner) return <Loader />

  return (
    <AppLayout>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3>Meet your trade designer</h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column computer={11} tablet={16} textAlign="center">
            <p className="no-margin">
              Scheduling a call helps us gain insight into your business and
              project, ensuring a fast and accurate initial estimate.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column>
            <CalendlyInlineScheduler
              calendlyUrl={
                referredDesigner
                  ? `https://calendly.com/${referredDesigner.data.calendlyId}/${
                      referredDesigner.data
                        .calendlyUrlSlugProjectConsultation ||
                      'project-consultation'
                    }`
                  : process.env.GATSBY_CALENDLY_PROJECT_CONSULTATION_URL ?? ''
              }
              user={user}
              onEventScheduled={() => {
                handleEventScheduled()
                fireEvent({
                  type: 'identify',
                  email: user?.email,
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                })
                fireEvent({
                  type: 'schedule_completed',
                  email: user?.email,
                  firstName: user?.firstName,
                  lastName: user?.lastName,
                  meetingType: MeetingTypeProject.PROJECT_CONSULTATION,
                  phone: user?.phone,
                })
              }}
              prospectEmail={user?.email || ''}
              type={MeetingTypeProject.PROJECT_CONSULTATION}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default ScheduleCall
