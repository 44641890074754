import { formatDollars } from 'context/dollar-utils'
import { AppliancePack } from 'types/appliance-pack'

export const getAppliancePackPrice = (pack: Partial<AppliancePack>) => {
  let sum = 0
  if (pack) {
    sum =
      pack.appliances?.reduce(
        (acc, pack) => acc + Number(pack?.data?.price),
        0,
      ) ?? 0
  }
  return sum
}

export const getAppliancePackPriceFormatted = (
  pack: Partial<AppliancePack>,
) => {
  return formatDollars(getAppliancePackPrice(pack))
}
