import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { parse } from 'query-string'

import HeaderBasic from 'components/app/project/header/header-basic'
import DesignDeposit from 'components/shared/project/payment/versions/design-deposit'
import OtherPayment from 'components/shared/project/payment/versions/other-payment'
import Paid from 'components/shared/project/payment/versions/paid'
import ProjectDeposit from 'components/shared/project/payment/versions/project-deposit'
import PaymentSourceManyProvider from 'context/payment-source/provider-many'
import useProject from 'context/project/use'
import useProjectPaymentMutate from 'context/project/use-payment-mutate'
import fireEvent from 'context/tracking/events'
import AppLayout from 'design-system/containers/app-layout'
import {
  Payment,
  PaymentMethodType,
  PaymentSource,
  PaymentType,
} from 'types/payment'

const PaymentIndex = ({
  location,
  navigate,
  payment_id: paymentId,
  project_id: projectId,
}: RouteComponentProps<{ payment_id: string; project_id: string }>) => {
  const { paymentsAll, isTradeProject } = useProject()
  const { loadingUpdate, updatePaymentAndRefetch } = useProjectPaymentMutate()
  const { deepLink } = parse(location?.search ?? '', {
    parseBooleans: true,
  }) as {
    deepLink?: boolean
  }
  const payment = paymentsAll.find((o) => o.id === paymentId)

  const onPay = async (
    paymentSource?: PaymentSource,
    paymentMethod?: PaymentMethodType,
  ) => {
    await updatePaymentAndRefetch({
      variables: {
        data: {
          metadata: {
            ...payment?.metadata,
            method: paymentMethod,
          },
          stripeSourceId: paymentSource?.id,
        },
        where: { id: payment?.id ?? '' },
      },
      onCompleted: () => {
        if (payment?.metadata.type === PaymentType.DESIGN_DEPOSIT) {
          fireEvent({
            type: 'deposit_completed',
          })
        } else if (payment?.metadata.type === PaymentType.PROJECT_DEPOSIT) {
          fireEvent({
            type: 'project_deposit_completed',
          })
        }
      },
    })
  }

  const renderChild = () => {
    if (payment?.status === 'succeeded') {
      return <Paid payment={payment} />
    }
    if (payment?.metadata.type === PaymentType.DESIGN_DEPOSIT) {
      return (
        <DesignDeposit
          isTradeProject={isTradeProject}
          loading={loadingUpdate}
          onPay={onPay}
          payment={payment}
        />
      )
    }
    if (payment?.metadata.type === PaymentType.PROJECT_DEPOSIT) {
      return (
        <ProjectDeposit
          isTradeProject={isTradeProject}
          loading={loadingUpdate}
          onPay={onPay}
          payment={payment}
        />
      )
    }
    return (
      <OtherPayment
        loading={loadingUpdate}
        onPay={onPay}
        payment={payment as Payment}
      />
    )
  }

  return (
    <PaymentSourceManyProvider>
      <AppLayout
        header={
          deepLink ? (
            <HeaderBasic
              onBack={() => navigate?.(`/app/projects/${projectId}`)}
            />
          ) : undefined
        }
      >
        {renderChild()}
      </AppLayout>
    </PaymentSourceManyProvider>
  )
}

export default PaymentIndex
