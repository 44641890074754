import useProject from 'context/project/use'
import useGuideUrls from 'context/project/use-guide-urls'
import { MeetingTypeProject } from 'types/meeting'
import { PaymentType } from 'types/payment'
import { resourcesUrls } from 'views/utils'

import { PreProjectDepositCardTypes } from './provider'

const useCardsUrls = (roomId?: string) => {
  const { project, roomsAll, initialCostings, projectProposals, paymentsAll } =
    useProject()
  const guideUrls = useGuideUrls()

  return (
    cardType: PreProjectDepositCardTypes,
  ): { url?: string; newTab?: boolean } => {
    switch (cardType) {
      case PreProjectDepositCardTypes.APPLIANCES:
        return { url: `/app/projects/${project.id}/rooms/${roomId}/appliances` }
      case PreProjectDepositCardTypes.APPROVE_DESIGN_BRIEF: {
        const room = roomsAll.find((r) => !r.data.designBriefApprovedAt)
        return room && !!room.nobiliaOptions?.length
          ? { url: `/app/projects/${project.id}/rooms/${roomId}/design-brief` }
          : {}
      }
      case PreProjectDepositCardTypes.CALL_RECAPS:
        return { url: `/app/projects/${project.id}/call-recaps` }
      case PreProjectDepositCardTypes.CATALOGS:
        return { url: `/app/projects/${project.id}/catalogs` }
      case PreProjectDepositCardTypes.COLLABORATORS:
        return { url: `/app/projects/${project.id}/collaborators` }
      case PreProjectDepositCardTypes.COMPLETE_PROJECT_SETUP:
        return {
          url: `/app/projects/${project.id}/rooms/${roomId}/complete-information`,
        }
      case PreProjectDepositCardTypes.DESIGN_CALL_RECAP:
        return { url: guideUrls.DesignCall }
      case PreProjectDepositCardTypes.DESIGN_PRESENTATION_RECAP:
        return { url: guideUrls.DesignPresentation }
      case PreProjectDepositCardTypes.FLOOR_PLAN:
        return { url: `/app/projects/${project.id}/rooms/${roomId}/plans` }
      case PreProjectDepositCardTypes.INITIAL_COSTING:
        return initialCostings[0]
          ? {
              url: `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                initialCostings[0].key,
              )}`,
              newTab: true,
            }
          : {}
      case PreProjectDepositCardTypes.INSPIRATION:
        return {
          url: `/app/projects/${project.id}/rooms/${roomId}/inspiration-images`,
        }
      case PreProjectDepositCardTypes.INSPIRATION_GUIDE:
        return {
          url: resourcesUrls.inspirationGuide,
          newTab: true,
        }
      case PreProjectDepositCardTypes.ORDER_SAMPLES:
        return { url: `/app/projects/${project.id}/sample-orders` }
      case PreProjectDepositCardTypes.SAMPLES:
        return { url: `/app/projects/${project.id}/sample-orders` }
      case PreProjectDepositCardTypes.PROJECT_CONSULTATION_RECAP:
        return { url: guideUrls.ProjectConsultation }
      case PreProjectDepositCardTypes.PAY_DESIGN_DEPOSIT: {
        const payment = paymentsAll.find(
          (payment) => payment.metadata.type === PaymentType.DESIGN_DEPOSIT,
        )
        return payment
          ? { url: `/app/projects/${project.id}/payments/${payment.id}` }
          : {}
      }
      case PreProjectDepositCardTypes.PAY_PROJECT_DEPOSIT: {
        return {
          url: `/app/projects/${project.id}/payments-by-type/project-deposit`,
        }
      }
      case PreProjectDepositCardTypes.PROJECT_PROPOSAL:
        return projectProposals[0]
          ? {
              url: `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                projectProposals[0].key,
              )}`,
              newTab: true,
            }
          : {}
      case PreProjectDepositCardTypes.PROJECT_HANDBOOK:
        return {
          url: resourcesUrls.projectHandbook,
          newTab: true,
        }
      case PreProjectDepositCardTypes.ROOM_PHOTOS:
        return { url: `/app/projects/${project.id}/rooms/${roomId}/photos` }
      case PreProjectDepositCardTypes.SCHEDULE_DESIGN_CALL:
        return {
          url: `/app/projects/${project.id}/meeting?meetingType=${MeetingTypeProject.DESIGN}`,
        }
      case PreProjectDepositCardTypes.SCHEDULE_PROJECT_CONSULTATION:
        return {
          url: project?.id
            ? `/app/projects/${project?.id}/meeting?meetingType=${MeetingTypeProject.PROJECT_CONSULTATION}`
            : 'schedule-call',
        }
      default:
        return {}
    }
  }
}

export default useCardsUrls
