import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

import Button from 'design-system/components/button'
import { FormCard } from 'styles/app/components/card'
import { FormCheckbox } from 'styles/app/components/checkbox'
import { StyledForm } from 'styles/app/components/form'
import { Colors } from 'styles/app/system'
import { Dimension } from 'types/dimension'

export const DimensionEditCard = ({
  dimension,
  disableUpdate,
  loading,
  initialDimension,
  onSave,
  onUpdate,
}: {
  dimension: Dimension
  disableUpdate: boolean
  loading: boolean
  initialDimension?: Dimension
  onSave: () => void
  onUpdate: (value: number) => void
}) => {
  const { data } = dimension
  const isPending =
    initialDimension?.data.lengthInInches === data.lengthInInches &&
    data.lastUpdatedByCustomerAt &&
    !data.lastAcknowledgedAt
  return (
    <FormCard style={{ background: Colors.gray100, marginBottom: '18px' }}>
      <StyledForm styled={{ noMargin: true, whiteInputs: true }}>
        <Form.Group
          widths="equal"
          style={{ flexWrap: 'nowrap', flexDirection: 'row' }}
        >
          <Form.Input
            fluid
            label={data.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onUpdate(parseFloat(e.target.value))
            }}
            pattern="[0-9.]*"
            placeholder={`${data.name} Length In Inches`}
            type="number"
            value={data.lengthInInches || ''}
          >
            {disableUpdate ? (
              <p style={{ display: 'contents' }}>{data.lengthInInches || ''}</p>
            ) : (
              <input style={{ minWidth: '80px' }} />
            )}
            <p style={{ fontSize: '14px' }}>Inches</p>
          </Form.Input>
          {isPending ? (
            <Form.Field style={{ display: 'flex', width: 'fit-content' }}>
              <div
                className="flex"
                style={{
                  paddingBottom: '7px',
                  marginTop: 'auto',
                  width: '150px',
                  gap: '10px',
                }}
              >
                <FontAwesomeIcon icon={['fal', 'check']} />
                <p>Requested</p>
              </div>
            </Form.Field>
          ) : (
            <Form.Field style={{ display: 'flex', width: 'fit-content' }}>
              <Button
                kind="solid"
                color="blue"
                text="Request Change"
                disabled={
                  initialDimension?.data.lengthInInches ===
                    data.lengthInInches || !data.lengthInInches
                }
                loading={loading}
                onClick={() => onSave()}
                style={{ marginTop: 'auto' }}
              />
            </Form.Field>
          )}
        </Form.Group>
        {!data.lengthInInches && (
          <Form.Field>
            <p
              style={{
                color: Colors.orange100,
                marginTop: '10px',
                paddingLeft: '7px',
              }}
            >
              Please complete this dimension
            </p>
          </Form.Field>
        )}
        {isPending && (
          <Form.Field>
            <p
              style={{
                color: Colors.orange100,
                marginTop: '10px',
                paddingLeft: '7px',
              }}
            >
              Your designer will work on this update.
            </p>
          </Form.Field>
        )}
      </StyledForm>
    </FormCard>
  )
}

export const DimensionConfirmationCard = ({
  dimension,
  disabled,
  onToggleConfirm,
}: {
  dimension: Dimension
  disabled?: boolean
  onToggleConfirm: () => void
}) => {
  const { data } = dimension

  return (
    <FormCard style={{ background: Colors.gray100, marginBottom: '18px' }}>
      <StyledForm styled={{ noMargin: true, whiteInputs: true }}>
        <Form.Group
          widths="equal"
          style={{ flexWrap: 'nowrap', flexDirection: 'row' }}
        >
          <Form.Input
            fluid
            label={data.name}
            pattern="[0-9.]*"
            placeholder={`${data.name} Length In Inches`}
            type="number"
            value={data.lengthInInches || ''}
          >
            <p style={{ display: 'contents' }}>{data.lengthInInches || ''}</p>
            <p style={{ fontSize: '14px' }}>Inches</p>
          </Form.Input>
          <Form.Field
            style={{
              display: 'flex',
              alignItems: 'self-end',
              justifyContent: 'start',
            }}
          >
            <FormCheckbox
              disabled={disabled}
              checked={!!data.confirmedAt}
              label={
                <StyledLabel> Confirm{!!data.confirmedAt && 'ed'}</StyledLabel>
              }
              onChange={onToggleConfirm}
              style={{ marginTop: '16px' }}
            />
          </Form.Field>
        </Form.Group>
      </StyledForm>
    </FormCard>
  )
}

const StyledLabel = styled.label`
  &&&&&& {
    margin-right: 0 !important;
  }
`
