import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { Grid } from 'semantic-ui-react'

import { getSupplierOrdersIssues } from 'context/order/utils'
import { getShipmentSteps } from 'context/shipment/utils'
import { getLatestGuaranteedOnOrBefore } from 'context/supplier-order/utils'
import Button from 'design-system/components/button'
import LogisticsCard from 'design-system/components/cards/logistics-card'
import { Shipment } from 'types/shipment'

import DetailsModal from './modal-details'

const OceanFreightCard = ({
  shipments,
  onSeeIssues,
}: {
  shipments: Shipment[]
  onSeeIssues?: (shipmentId: string) => void
}) => {
  return (
    <>
      {shipments.map((shipment) => {
        const latestGuaranteedOnOrBefore = getLatestGuaranteedOnOrBefore(
          shipment.supplierOrders,
        )
        return (
          <Grid.Column
            widescreen={4}
            largeScreen={4}
            computer={5}
            tablet={8}
            mobile={16}
            key={shipment.id}
          >
            <LogisticsCard
              detailsModal={
                <DetailsModal supplierOrders={shipment.supplierOrders} />
              }
              iconHeader={
                <FontAwesomeIcon
                  icon={['fal', 'ship']}
                  style={{ marginRight: '16px', fontSize: '16px' }}
                />
              }
              finalTitle="Factory quoted date"
              finalInfo={
                latestGuaranteedOnOrBefore
                  ? moment(latestGuaranteedOnOrBefore).format('DD MMM YYYY')
                  : null
              }
              parts={shipment.supplierOrders.length}
              steps={getShipmentSteps(shipment)}
              textHeader="Ocean freight"
            />
            {!!getSupplierOrdersIssues(shipment.supplierOrders)?.length && (
              <Button
                color="blue"
                fontAwesomeIcon="file-magnifying-glass"
                kind="text"
                onClick={() => onSeeIssues?.(`${shipment.id}1`)}
                style={{ marginTop: '8px' }}
                text="See Issues Resolved"
              />
            )}
          </Grid.Column>
        )
      })}
    </>
  )
}

export default OceanFreightCard
