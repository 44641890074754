import { useContext } from 'react'

import { useMutation } from '@apollo/client'

import { notifyError } from 'context/notifications/trigger'
import {
  CREATE_PROJECT,
  CreateProjectPayload,
  CreateProjectVariables,
} from 'queries/project'

import { UserContext } from './provider'

const useUserProjectMutate = () => {
  const { refetch } = useContext(UserContext)

  const [createProjectAndRefetch, { loading: loadingCreate }] = useMutation<
    CreateProjectPayload,
    CreateProjectVariables
  >(CREATE_PROJECT, {
    onCompleted: () => {
      refetch()
    },
    onError: ({ message }) => {
      notifyError(message)
    },
  })

  return {
    createProjectAndRefetch,
    loadingCreate,
  }
}

export default useUserProjectMutate
