import React from 'react'

import { Divider, Grid, Image } from 'semantic-ui-react'

import useProject from 'context/project/use'
import { isImageFile } from 'context/project-file/utils'
import { PrimaryCard } from 'design-system/components/cards'
import { Section } from 'design-system/components/guide/utils'

const ProjectProposal = () => {
  const { projectProposals } = useProject()
  if (!projectProposals.length) return <></>

  const projectProposalKey = projectProposals?.[0]?.key

  if (!projectProposalKey) return <></>

  return (
    <Section id="project-scope">
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Your project scope</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        {isImageFile(projectProposalKey) ? (
          <Grid.Row>
            <Grid.Column>
              <Image
                centered
                src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                  projectProposalKey,
                )}`}
                style={{ maxHeight: '70vh' }}
              />
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row centered stretched className="row-gap">
            <Grid.Column computer={4} tablet={7} mobile={16}>
              <PrimaryCard
                image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/visual-quote.svg`}
                title="Project Proposal"
                onClick={() =>
                  window.open(
                    `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                      projectProposalKey,
                    )}`,
                  )
                }
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Section>
  )
}

export default ProjectProposal
