import React from 'react'

import { RouteComponentProps, useLocation } from '@reach/router'
import { navigate } from 'gatsby'

import HandlesBrowser from 'components/shared/catalog/nobilia-feature/handles-browser'
import useRoom from 'context/room/use'
import useRoomDesignOptionsMutate from 'context/room/use-design-options-mutate'
import { NobiliaOption } from 'types/nobilia-option'
import { GuideType } from 'types/utils'

type SelectionHandlesProps = RouteComponentProps<{
  project_id: string
  room_id: string
}>

const SelectionHandles = (props: SelectionHandlesProps) => {
  const { state } = useLocation()
  const stateGuide = state as {
    fromGuide?: GuideType
  }

  const { handles: handlesSelected, room } = useRoom()
  const { onSaveNobiliaOptions } = useRoomDesignOptionsMutate()

  const onBack = () => {
    const backGuide =
      stateGuide?.fromGuide === GuideType.ProjectConsultation
        ? 'project-consultation'
        : 'design-call'

    navigate(
      `/app/projects/${props.project_id}/rooms/${props.room_id}/${backGuide}`,
    )
  }

  const onSave = (item: Partial<NobiliaOption>) => {
    let selection
    if (handlesSelected?.find((s) => s.id === item.id))
      selection = handlesSelected.filter((s) => s.id !== item.id)
    else selection = handlesSelected ? [...handlesSelected, item] : [item]

    onSaveNobiliaOptions(selection, handlesSelected ?? [])
  }

  return (
    <HandlesBrowser
      catalog={room?.data?.catalog}
      handlesSelected={handlesSelected}
      onBack={onBack}
      onSave={onSave}
    />
  )
}

export default SelectionHandles
