import React, { useEffect, useRef, useState } from 'react'

import moment from 'moment'
import { Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { Chip } from 'components/shared/chip'
import { parseOwners } from 'context/project/utils'
import { getRoomFormattedType } from 'context/room/utils'
import * as StyleSystem from 'styles/app/system'
import { Project } from 'types/project'
import { RoomGraphQL } from 'types/room'
import { getReadableList, getUnarchivedRooms } from 'views/utils'

import Moodboard from './moodboard'

interface MoodboardSectionProps {
  project: Project
  room: RoomGraphQL
}

const MoodboardSection = ({ project, room }: MoodboardSectionProps) => {
  const designBriefApproved = room.data?.designBriefApprovedAt
  const owners = getReadableList(
    parseOwners(project).primaryOwners.map((o) =>
      o.user?.firstName ? o.user.firstName.split(' ')[0] : o.user?.email ?? '',
    ),
  )

  const moodboardRef = useRef<HTMLDivElement>(null)
  const [gridHeight, setGridHeight] = useState(600)

  useEffect(() => {
    if (moodboardRef.current) {
      setGridHeight(moodboardRef.current.offsetHeight)
    }
  }, [])

  return (
    <MainContainer>
      <ItemGrid
        stackable
        styled={{ height: gridHeight }}
        verticalAlign="middle"
      >
        <Grid.Row>
          <Grid.Column computer={8} tablet={16}>
            <div className="text">
              {getUnarchivedRooms(project.rooms).length > 1 && (
                <Chip
                  color={room.data?.tagColor}
                  name={getRoomFormattedType(room)}
                />
              )}
              {!designBriefApproved ? (
                <>
                  <h2>{owners}’s Brief</h2>
                  <p>
                    Once your design brief is approved, your designer will be
                    able to start working on your initial design based on the
                    information below, your appliances, and floor plan. <br />
                    <br />
                    Please note if you have not scheduled your next call, your
                    designer will reach out. Design deposits are not refundable
                    once design work has started.
                  </p>
                </>
              ) : (
                <>
                  <Image
                    size="tiny"
                    src={`${process.env.GATSBY_CDN_ROOT}/images/icons/icons_hexagon-completed-green.svg`}
                  />
                  <p
                    style={{
                      color: StyleSystem.Colors.gray600,
                      fontSize: '18px',
                      paddingTop: '30px',
                    }}
                  >
                    {moment(room.data?.designBriefApprovedAt).format(
                      'MMMM DD YYYY',
                    )}
                  </p>
                  <h2 className="big">
                    Thanks for approving your Design Brief.
                  </h2>
                </>
              )}
            </div>
          </Grid.Column>
          <Grid.Column className="image-column" computer={8} tablet={16}>
            <div className="moodboard-ref" ref={moodboardRef}>
              <Moodboard room={room} />
            </div>
          </Grid.Column>
        </Grid.Row>
      </ItemGrid>
    </MainContainer>
  )
}

export default MoodboardSection

const MainContainer = styled.section`
  padding-top: 72px;
`

const ItemGrid = styled(Grid)<{ styled: { height?: number } }>`
  display: flex;
  height: ${(props) => `${props.height}px`};

  .row {
    padding: 0 !important;
  }

  .image-column {
    padding: 0 !important;
    height: auto;
  }

  img {
    margin: 0;
  }

  .text {
    float: right !important;
    max-width: 40vw;
    padding: 40px 64px 40px 12px !important;
  }

  @media ${StyleSystem.Breakpoint.onlyComputer} {
    .moodboard-ref {
      height: 100%;
    }
  }

  @media ${StyleSystem.Breakpoint.downFromSmallDesktop} {
    .text {
      max-width: 50vw;
      padding: 40px !important;
    }
  }

  @media ${StyleSystem.Breakpoint.downFromComputer} {
    padding: 23px 0;
    text-align: center;

    .text {
      max-width: 100vw;
      padding: 30px 20px !important;
    }
  }

  @media ${StyleSystem.Breakpoint.onlyMobile} {
    .text {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 30px 0 10px !important;
    }
  }
`
