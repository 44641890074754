import React, { createContext, useContext, useMemo, useState } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { orderBy } from 'lodash'

import useAuth from 'context/auth/use'
import useUser from 'context/user/use'
import {
  FIND_MANY_MESSAGE,
  FindManyMessagePayload,
  FindManyMessageVariables,
} from 'queries/message'
import { Message } from 'types/message'

import useMessageCount from './use-count'
import { QueryFilters, getQueryVariables } from './utils-many'

interface IUserMessageManyContext {
  loading: boolean
  messages: Message[]
  newForMeCount: number
  refetch: () => Promise<unknown>
  refetching: boolean
}

type UserMessageManyProviderProps = React.PropsWithChildren<RouteComponentProps>

export const UserMessageManyContext = createContext<IUserMessageManyContext>(
  {} as IUserMessageManyContext,
)

const UserMessageManyProvider = (props: UserMessageManyProviderProps) => {
  const { projects } = useUser()
  const { formUserId } = useAuth()
  const [refetching, setRefetching] = useState(false)
  const queryFilters: QueryFilters = {
    projectIds: projects.map((p) => p.id),
    sortBy: 'createdAt',
    sortDirection: 'descending',
    distinct: 'projectId',
  }

  const {
    data,
    loading,
    refetch: _refetch,
  } = useQuery<FindManyMessagePayload, FindManyMessageVariables>(
    FIND_MANY_MESSAGE,
    {
      variables: getQueryVariables(queryFilters),
    },
  )

  const { count: newForMeCount, refetch: refetchNewForMeCount } =
    useMessageCount({
      ...queryFilters,
      newFor: {
        id: formUserId,
      },
      pinned: false,
    })

  const messages = useMemo(() => {
    return orderBy(data?.messages || [], 'createdAt', 'desc')
  }, [data?.messages])

  const refetch = async () => {
    setRefetching(true)
    await Promise.all([_refetch(), refetchNewForMeCount()])
    setRefetching(false)
  }

  return (
    <UserMessageManyContext.Provider
      value={{
        loading,
        messages,
        newForMeCount,
        refetch,
        refetching,
      }}
    >
      {props.children}
    </UserMessageManyContext.Provider>
  )
}

export default UserMessageManyProvider

export const useUserMessageMany = () => useContext(UserMessageManyContext)
