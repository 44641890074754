import React from 'react'

import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'

const RoomCard = ({
  color,
  image,
  noActions,
  onDelete,
  onEdit,
  subtitle,
  title,
}: {
  color?: string
  image: string
  noActions?: boolean
  onDelete?: () => void
  onEdit?: () => void
  subtitle?: string
  title: string
}) => {
  const circleStyle = color ? { background: color } : undefined
  return (
    <StyledCard>
      <Image src={image} />
      <div className="content">
        <div className="info">
          <p className="title caption no-margin">
            <div className="circle" style={circleStyle} />
            {title}
          </p>
          {subtitle ? <p>{subtitle}</p> : null}
        </div>
        {!noActions ? (
          <div className="actions">
            <Button fontAwesomeIcon="times" kind="ghost" onClick={onDelete} />
            <Button fontAwesomeIcon="pencil" kind="ghost" onClick={onEdit} />
          </div>
        ) : null}
      </div>
    </StyledCard>
  )
}

export default RoomCard

const StyledCard = styled.div`
  align-items: center;
  background: ${Colors.white};
  border: 1px solid ${Colors.gray100};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 100%;
  padding: 18px 16px;

  div.content {
    display: flex;
    gap: 15px;
    height: 100%;
    justify-content: space-between;
    min-height: 80px;
    width: 100%;

    div.info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p.title {
        align-items: center;
        display: flex;
        flex-direction: row;
        font-size: 16px;
        gap: 8px;
      }

      div.circle {
        height: 12px;
        width: 12px;
        border-radius: 50%;
      }
    }

    div.actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  img {
    align-items: center;
    background: ${Colors.white};
    display: flex;
    height: 80px;
    justify-content: center;
    min-height: 80px;
    min-width: 80px;
    width: 80px;

    img {
      margin: 0;
    }
  }
`
