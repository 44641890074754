import React from 'react'

import { navigate } from 'gatsby'
import { sortBy } from 'lodash'
import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel'
import { useMedia } from 'react-use'
import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import DesignerHeader from 'components/app/project/designer-header'
import Header from 'components/app/user/header'
import useUser from 'context/user/use'
import Button from 'design-system/components/button'
import AppLayout from 'design-system/containers/app-layout'
import { CardCarousel } from 'styles/app/components/carousel'
import { Breakpoint } from 'styles/app/system'

import ProjectCard from './project-card'

const UserHome = () => {
  const isMobile = useMedia(Breakpoint.downFromComputer)
  const { user, projects } = useUser()

  const preventDefault = (e: Event) => {
    if (e.cancelable) e.preventDefault()
  }

  const isProfileIncomplete = !user.firstName || !user.lastName || !user.phone

  return (
    <AppLayout header={<Header />}>
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column width={12}>
            <DesignerHeader
              actions={
                isProfileIncomplete ? (
                  <Button
                    fontAwesomeIcon="bell-exclamation"
                    iconAnimation="scale"
                    kind="text"
                    onClick={() => navigate('/app/profile')}
                    text="Complete profile"
                  />
                ) : null
              }
              title={`Welcome to FORM, ${user.firstName}`}
              designerPicture={user.picture}
            />
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <p className="overline">Your projects</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="only-computer" stretched>
          {sortBy(projects, 'createdAt')
            .reverse()
            .map((p, id) => (
              <GridColumn key={id} computer={4} mobile={16} tablet={8}>
                <ProjectCard project={p} />
              </GridColumn>
            ))}
        </Grid.Row>
        <Grid.Row className="down-computer" style={{ padding: '0' }} stretched>
          <Grid.Column>
            <CardCarousel oneCard={projects.length === 1}>
              <div className="background" />
              <CarouselProvider
                className="carousel"
                naturalSlideHeight={0.57}
                naturalSlideWidth={1}
                dragStep={isMobile ? 1 : 2}
                step={isMobile ? 1 : 2}
                totalSlides={projects.length}
                visibleSlides={isMobile ? 1 : 2}
                isIntrinsicHeight={true}
              >
                <Slider
                  classNameAnimation="carousel-animation"
                  trayProps={{
                    onPointerDown: () => {
                      window.addEventListener('touchmove', preventDefault, {
                        passive: false,
                      })
                    },
                    onPointerLeave: () => {
                      window.removeEventListener(
                        'touchmove',
                        preventDefault,
                        false,
                      )
                    },
                  }}
                >
                  {sortBy(projects, 'createdAt')
                    .reverse()
                    .map((p, id) => (
                      <Slide key={id} index={id}>
                        <GridColumn
                          computer={4}
                          tablet={8}
                          mobile={16}
                          styled={{ index: id }}
                        >
                          <ProjectCard project={p} />
                        </GridColumn>
                      </Slide>
                    ))}
                </Slider>
                {projects.length > 1 ? <DotGroup className="dots" /> : null}
              </CarouselProvider>
            </CardCarousel>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

const GridColumn = styled(Grid.Column)`
  &&& {
    padding: 0 10px !important;
  }
`

export default UserHome
