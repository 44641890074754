import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Grid, Image, Table, Loader as LoaderGif } from 'semantic-ui-react'
import styled from 'styled-components'

import StepTitle from 'components/app/step-title'
import HelpMessage from 'components/shared/help-message'
import { getReferenceImage } from 'context/media/utils-many'
import useRoom from 'context/room/use'
import useRoomGenericProductMutate from 'context/room/use-generic-product-mutate'
import { FormCheckbox } from 'styles/app/components/checkbox'
import { Colors } from 'styles/app/system'

const AppliancesConfirmationView = ({
  enableConfirm,
}: {
  enableConfirm?: boolean
}) => {
  const { appliancesUnarchived, formattedType } = useRoom()
  const { loadingConfirmId, toggleConfirmGenericProduct } =
    useRoomGenericProductMutate()

  return (
    <>
      <StepTitle
        title={
          enableConfirm
            ? 'Appliances & fixtures, final confirmation'
            : 'Appliances & fixtures confirmed'
        }
        subTitle={
          enableConfirm
            ? `Please confirm this list includes all of the appliances you’ll add to your ${formattedType}`
            : undefined
        }
      />
      {!enableConfirm ? (
        <Grid.Row centered>
          <Grid.Column computer={14} tablet={16} mobile={16}>
            <HelpMessage
              message="Thank you for making your final appliance selection. Please message your designer if you need urgent changes. Changes might affect your project timeline."
              title="You've confirmed your appliances"
            />
          </Grid.Column>{' '}
        </Grid.Row>
      ) : null}
      <Grid.Row centered>
        <Grid.Column
          className="only-computer"
          computer={14}
          tablet={16}
          mobile={16}
        >
          <ApplianceTable basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>
                  <p className="overline">Appliance</p>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <p className="overline">Model</p>
                </Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  <p className="overline">Confirm</p>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {appliancesUnarchived?.map((o) => (
                <Table.Row key={o.id}>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>
                    <div className="flex">
                      <Image
                        className="image"
                        size="tiny"
                        src={getReferenceImage(o.productClass.media)}
                      />
                      <p>
                        {o.productClass?.metadata?.name}
                        {o.data?.size?.endsWith('"') ? ` ${o.data.size}` : ''}
                      </p>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <p style={{ color: Colors.gray600 }}>
                      {o.data?.productDetails}
                    </p>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {o.data?.linkToProduct && (
                      <a
                        href={o.data.linkToProduct}
                        style={{ color: Colors.blue700 }}
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={['fal', 'link']} />
                        <span style={{ marginLeft: '5px' }}>Link</span>
                      </a>
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {loadingConfirmId === o.id ? (
                      <LoaderGif active inline />
                    ) : (
                      <FormCheckbox
                        checked={!!o.data?.confirmedAt}
                        disabled={
                          !enableConfirm ||
                          !!loadingConfirmId ||
                          (o.data?.lastUpdatedByCustomerAt &&
                            !o.data.lastAcknowledgedAt)
                        }
                        onChange={async () => {
                          await toggleConfirmGenericProduct(o)
                        }}
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </ApplianceTable>
        </Grid.Column>
        <Grid.Column className="down-computer" width={16}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={12}>
                <p className="small gray">Appliance</p>
              </Grid.Column>
              <Grid.Column textAlign="center" width={4}>
                <p className="small gray">Confirm</p>
              </Grid.Column>
            </Grid.Row>
            {appliancesUnarchived?.map((o, idx) => (
              <GridRow key={idx} verticalAlign="middle">
                <Grid.Column width={12}>
                  <div className="appliance">
                    <Image src={getReferenceImage(o.productClass.media)} />
                    <div>
                      <p>
                        {o.productClass?.metadata?.name}
                        {o.data?.size?.endsWith('"') ? ` ${o.data.size}` : ''}
                      </p>
                      <p>{o.data?.productDetails}</p>
                      <a
                        href={o.data?.linkToProduct ?? ''}
                        style={{ color: Colors.blue700 }}
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={['fal', 'link']} />
                        <span style={{ marginLeft: '5px' }}>Link</span>
                      </a>
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column textAlign="center" width={4}>
                  {loadingConfirmId === o.id ? (
                    <LoaderGif active inline style={{ zIndex: '0' }} />
                  ) : (
                    <FormCheckbox
                      disabled={
                        !!loadingConfirmId ||
                        (o.data?.lastUpdatedByCustomerAt &&
                          !o.data.lastAcknowledgedAt)
                      }
                      checked={!!o.data?.confirmedAt}
                      onChange={async () => {
                        await toggleConfirmGenericProduct(o)
                      }}
                    />
                  )}
                </Grid.Column>
              </GridRow>
            ))}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default AppliancesConfirmationView

const GridRow = styled(Grid.Row)`
  align-items: center;
  background: ${Colors.white};
  border-top: 1px solid ${Colors.gray100};
  display: flex;

  div.appliance {
    display: flex;

    img {
      margin-right: 8px;
      width: 70px;
    }
  }

  p {
    margin-bottom: 5px;
  }
`

const ApplianceTable = styled(Table)`
  img.image {
    max-width: 50px;
    margin-right: 15px;
  }

  tbody {
    background: ${Colors.white};

    tr td {
      border-top: none !important;
      border-bottom: 1px solid ${Colors.gray100} !important;
    }
  }

  thead tr th {
    border: none !important;
  }
`
