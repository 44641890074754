import React from 'react'

import { Grid } from 'semantic-ui-react'

import StepTitle from 'components/app/step-title'
import Checkout from 'components/app/user/checkout'
import PaymentCard from 'components/shared/project/payment/card'
import usePaymentMutateNoContext from 'context/payment/use-mutate-no-context'
import { getDefaultPayment } from 'context/payment/utils'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import { Payment, PaymentMethodType, PaymentType } from 'types/payment'

const ExtraRoomDeposit = ({ payment }: { payment?: Payment }) => {
  const { loadingCreate, createPayment, updatePayment, loadingUpdate } =
    usePaymentMutateNoContext()
  const { isTradeProject, project } = useProject()
  const { room, refetch } = useRoom()
  const extraRoomPayment =
    payment || getDefaultPayment(PaymentType.EXTRA_ROOM_DEPOSIT)

  return (
    <Grid>
      <StepTitle title="Make Extra Room Deposit" />
      <Grid.Row centered columns="equal">
        <Grid.Column
          widescreen={6}
          largeScreen={6}
          computer={9}
          tablet={12}
          mobile={16}
        >
          <Checkout
            buttonText="Pay Extra Room Deposit"
            includeInvoice={isTradeProject}
            loading={loadingCreate || loadingUpdate}
            onPay={async (paymentSource, paymentMethod) => {
              const isPaymentMethodCard =
                paymentMethod === PaymentMethodType.CREDIT_CARD
              const isPaymentMethodInvoice =
                paymentMethod === PaymentMethodType.INVOICE
              const stripeSourceId = isPaymentMethodCard
                ? paymentSource?.id
                : null

              if (payment)
                await updatePayment({
                  variables: {
                    data: {
                      stripeSourceId,
                      metadata: {
                        ...payment.metadata,
                        method: paymentMethod,
                        ...(isPaymentMethodInvoice && {
                          generateInvoiceAt: new Date(),
                        }),
                      },
                      ...(isPaymentMethodInvoice && {
                        status: 'pending',
                      }),
                    },
                    where: { id: payment.id ?? '' },
                  },
                })
              else
                await createPayment({
                  variables: {
                    data: {
                      amount: extraRoomPayment.amount,
                      description: extraRoomPayment.description,
                      metadata: {
                        ...extraRoomPayment.metadata,
                        method: paymentMethod,
                      },
                      status: extraRoomPayment.status,
                      stripeSourceId: stripeSourceId as string,
                      project: {
                        connect: {
                          id: project.id,
                        },
                      },
                      room: {
                        connect: {
                          id: room?.id ?? '',
                        },
                      },
                    },
                  },
                })
              await refetch()
            }}
            summary={<PaymentCard payment={extraRoomPayment} />}
            summaryTitle="Payment Request"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default ExtraRoomDeposit
