import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import { Image } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import AddressFormat from 'components/shared/address-format'
import * as pUtils from 'context/project/utils'
import Button from 'design-system/components/button'
import { Breakpoint, Colors } from 'styles/app/system'
import { Project } from 'types/project'
import * as viewUtils from 'views/utils'

const ProjectCard = ({ project: p }: { project: Project }) => {
  return (
    <StyledCard onClick={() => navigate(`/app/projects/${p.id}`)}>
      <div className="content">
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <h4 className="no-margin">
            {viewUtils.responsiveText(pUtils.getProjectName(p), 25)}
          </h4>
          {!pUtils.isTradeProject(p) ? (
            <Image
              avatar
              circular
              src={pUtils.parseStaff(p).primaryDesigner?.picture}
            />
          ) : null}
        </div>
        <p
          className={`small flex ${
            viewUtils.getPaymentString(p).includes('paid') ? 'green' : 'gray'
          }`}
          style={{ marginTop: '8px' }}
        >
          <FontAwesomeIcon
            fixedWidth
            icon={[
              'fal',
              viewUtils.getPaymentString(p).includes('paid')
                ? 'check-circle'
                : 'exclamation-circle',
            ]}
          />
          {viewUtils.getPaymentString(p)}
        </p>
        <p>
          {p.projectAddress ? (
            <AddressFormat address={p.projectAddress} />
          ) : null}
        </p>
        {p.rooms.length > 1 ? (
          <p className="small flex">
            <FontAwesomeIcon fixedWidth icon={['fal', 'house-chimney-heart']} />
            <span>{p.rooms.length} rooms</span>
          </p>
        ) : null}
      </div>
      <Button kind="outlined" text="Open" size="fluid" />
    </StyledCard>
  )
}

export default ProjectCard

const StyledCard = styled.div`
  &&& {
    background: white;
    border-radius: 8px;
    box-shadow: 0px 0px 15px 2px rgba(120, 113, 108, 0.09);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    min-height: 295px;
    padding: 16px;
    position: relative;
    transition: all 0.3s ease-in-out;

    .content {
      height: 100%;
      margin: 8px 0 5px;
      min-height: 130px;

      h2 {
        margin: 0 5px 12px 0;
        width: 80%;
      }

      .image {
        margin: 0;
        height: 38px;
        width: 38px;
      }

      p {
        margin-bottom: 11px;

        svg {
          font-size: 16px;
          margin-right: 8px;
        }
      }

      p.flex {
        align-items: center;
        display: flex;
      }

      p.green {
        color: ${Colors.green700};
      }
      p.gray {
        color: ${Colors.gray600};
      }
    }

    @media ${Breakpoint.onlyComputer} {
      :hover {
        ${css`
          box-shadow: 0px 10px 25px 5px rgba(120, 113, 108, 0.15);
          transform: translate(0px, -5px);

          button {
            background-color: ${Colors.gray600};
            color: ${Colors.white} !important;
            transition-duration: 0.3s;
          }
        `}
      }
    }
  }
`
