import React from 'react'

import { navigate } from 'gatsby'
import moment from 'moment'
import { useMedia } from 'react-use'
import { Divider, Image, Table } from 'semantic-ui-react'

import { getLatestPlan } from 'context/design/utils'
import { getFileImages } from 'context/project-file/utils'
import Button from 'design-system/components/button'
import StatusLabel from 'design-system/components/status-label'
import Tooltip from 'design-system/components/tooltip/tooltip'
import { Breakpoint } from 'styles/app/system'
import { Issue } from 'types/issue'
import { responsiveText } from 'views/utils'

import { StyledTable, TableHeaderCell, TableBodyRow } from './styles'

type ReportsTableProps = {
  issues: Issue[]
} & (
  | {
      mode: 'edit'
      onEditIssue?: (issue: Issue) => void
      onDeleteIssue?: (issue: Issue) => void
    }
  | {
      mode: 'readOnly'
    }
)

export const ReportsTable = ({ issues, ...props }: ReportsTableProps) => {
  const isMobile = useMedia(Breakpoint.downFromTablet)

  return (
    <StyledTable singleLine>
      {!isMobile && (
        <Table.Header>
          <Table.Row>
            <TableHeaderCell width={1} textAlign="center">
              #
            </TableHeaderCell>
            <TableHeaderCell width={1}>Pos No.</TableHeaderCell>
            {props.mode === 'readOnly' && (
              <TableHeaderCell width={2}>Type</TableHeaderCell>
            )}
            <TableHeaderCell width={5}>Description</TableHeaderCell>
            <TableHeaderCell width={4}>Photos</TableHeaderCell>
            {props.mode === 'readOnly' && (
              <TableHeaderCell width={3}>Design</TableHeaderCell>
            )}
            {props.mode === 'edit' && (
              <>
                <TableHeaderCell width={2}>Status</TableHeaderCell>
                <TableHeaderCell width={2} textAlign="center">
                  Actions
                </TableHeaderCell>
              </>
            )}
          </Table.Row>
        </Table.Header>
      )}
      <Table.Body>
        {issues.map((issue, idx) => {
          const { id, data, files, resolutionType, designResolving, project } =
            issue
          const latestPlan = getLatestPlan(designResolving)

          return (
            <TableBodyRow key={id}>
              <Table.Cell textAlign="center">
                <p className="light-gray">{idx + 1}</p>
              </Table.Cell>
              <Table.Cell>
                <p className="overline">
                  {!!isMobile && 'POS '}
                  {data?.positionNumber || '--'}
                </p>
              </Table.Cell>
              {props.mode === 'readOnly' && (
                <Table.Cell>
                  <p className="caption">{resolutionType}</p>
                </Table.Cell>
              )}
              <Table.Cell>
                {!!data?.customerDescription && (
                  <p className="small" style={{ whiteSpace: 'break-spaces' }}>
                    {data.customerDescription}
                  </p>
                )}
              </Table.Cell>
              <Table.Cell>
                {!!files?.length && (
                  <div style={{ display: 'grid', gap: '16px' }}>
                    {files?.map((file) => (
                      <div
                        key={file.id}
                        className="flex"
                        style={{ gap: '8px', cursor: 'pointer' }}
                        onClick={() =>
                          window.open(
                            `${
                              process.env.GATSBY_CDN_ROOT
                            }/${encodeURIComponent(
                              getFileImages(file.key, file.numPages)[0],
                            )}`,
                          )
                        }
                      >
                        <Image
                          src={`${
                            process.env.GATSBY_CDN_ROOT
                          }/${encodeURIComponent(
                            getFileImages(file.key, file.numPages)[0],
                          )}`}
                          style={{
                            width: '50px',
                            height: '50px',
                            objectFit: 'cover',
                          }}
                        />
                        <p className="small">{responsiveText(file.name, 15)}</p>
                      </div>
                    ))}
                  </div>
                )}
              </Table.Cell>
              {props.mode === 'readOnly' && designResolving && (
                <Table.Cell className="design-cell">
                  {!!isMobile && (
                    <>
                      <Divider />
                      <p className="overline no-margin">Design</p>
                    </>
                  )}
                  <Button
                    color="blue"
                    kind="text"
                    text={designResolving.name}
                    fontAwesomeIcon="arrow-up-right-from-square"
                    onClick={() =>
                      navigate(
                        `/app/projects/${project?.id}/rooms/${designResolving.room.id}/designs/${designResolving.id}/plan/${latestPlan?.id}`,
                      )
                    }
                  />
                  <p className="x-small gray">
                    Approved at{' '}
                    {moment(latestPlan?.approvedAt).format('MM/DD/YYYY')}
                  </p>
                </Table.Cell>
              )}
              {props.mode === 'edit' && (
                <>
                  <Table.Cell>
                    {!!isMobile && (
                      <>
                        <Divider />
                        <p className="overline" style={{ marginBottom: '8px' }}>
                          Status
                        </p>
                      </>
                    )}
                    <StatusLabel text="Received" />
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Tooltip label="Edit">
                      <Button
                        kind="ghost"
                        color="gray"
                        fontAwesomeIcon="pencil"
                        onClick={() => props.onEditIssue?.(issue)}
                      />
                    </Tooltip>
                    <Tooltip label="Delete">
                      <Button
                        kind="ghost"
                        color="gray"
                        fontAwesomeIcon="trash"
                        onClick={() => props.onDeleteIssue?.(issue)}
                      />
                    </Tooltip>
                  </Table.Cell>
                </>
              )}
            </TableBodyRow>
          )
        })}
      </Table.Body>
    </StyledTable>
  )
}
