import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEqual } from 'lodash'
import { useMedia } from 'react-use'
import styled from 'styled-components'

import ConfirmationModal from 'components/shared/confirmation-modal'
import {
  BottomSection,
  SidebarHeader,
} from 'components/shared/project/project-file/utils'
import useRoom from 'context/room/use'
import useRoomDimensionMutate from 'context/room/use-dimension-mutate'
import useRoomMutate from 'context/room/use-mutate'
import Button from 'design-system/components/button'
import { Breakpoint, FormDivider } from 'styles/app/system'
import { StatusMode } from 'types/utils'

import { DimensionConfirmationCard, DimensionEditCard } from './card'

const SidebarMeasure = ({ sidebarToggle }: { sidebarToggle: () => void }) => {
  const isMobile = useMedia(Breakpoint.downFromComputer)
  const { measurements, measurementsMode, room } = useRoom()
  const { loadingUpdate, updateRoomAndRefetch } = useRoomMutate()
  const { loadingDimensionSave, saveDimension } = useRoomDimensionMutate()
  const [initialDimensions, setInitialDimensions] = useState(measurements)
  const [state, setState] = useState(measurements)
  const [showConfirmModal, toggleConfirmModal] = useState<boolean>(false)
  const [showChangesModal, toggleChangesModal] = useState<boolean>(false)

  useEffect(() => {
    if (!isEqual(initialDimensions, measurements))
      setInitialDimensions(measurements)
  }, [measurements])

  const mode = measurementsMode

  const subTitle =
    mode === StatusMode.OpenForRevision || mode === StatusMode.Submitted
      ? 'Before working on your design, we want to check that the measurements of your space are correct. <br/> If everything looks good, complete this task.'
      : 'Click the checkboxes beside each measurement to confirm that they are correct. Once you’ve finished, press the black button to finish confirmation. <br/>If you’d like to make changes to any of these measurements, click the “Request Change” button instead.'

  return (
    <div style={{ padding: '10px 0 40px' }}>
      {isMobile ? (
        <SidebarHeader>
          <p className="subtitle">Measurements</p>
          <FontAwesomeIcon icon={['fal', 'times']} onClick={sidebarToggle} />
        </SidebarHeader>
      ) : (
        <h4>
          {mode === StatusMode.OpenForRevision || mode === StatusMode.Submitted
            ? 'Review measurements'
            : 'Confirm measurements'}
        </h4>
      )}
      <p
        dangerouslySetInnerHTML={{
          __html: subTitle,
        }}
      />
      <FormDivider />
      {state.map((dimension) => {
        if (
          mode === StatusMode.OpenForRevision ||
          mode === StatusMode.Submitted
        ) {
          const initialDimension = initialDimensions.find(
            (d) => d.id === dimension.id,
          )
          return (
            <DimensionEditCard
              key={dimension.id}
              disableUpdate={mode === StatusMode.Submitted}
              dimension={dimension}
              loading={loadingDimensionSave}
              initialDimension={initialDimension}
              onUpdate={(value: number) => {
                setState(
                  state.map((d) => {
                    if (d.id === dimension.id) {
                      if (initialDimension?.data.lengthInInches === value)
                        return initialDimension
                      else
                        return {
                          ...dimension,
                          data: {
                            ...dimension.data,
                            confirmedAt: null,
                            lastAcknowledgedAt: null,
                            lastUpdatedByCustomerAt: new Date(),
                            lengthInInches: value,
                          },
                        }
                    }
                    return d
                  }) as typeof measurements,
                )
              }}
              onSave={() => {
                saveDimension(dimension)
              }}
            />
          )
        }
        return (
          <DimensionConfirmationCard
            key={dimension.id}
            dimension={dimension}
            disabled={mode === StatusMode.Confirmed}
            onToggleConfirm={() => {
              const newDimension = {
                ...dimension,
                data: {
                  ...dimension.data,
                  confirmedAt: dimension.data.confirmedAt ? null : new Date(),
                },
              }
              setState(
                state.map((d) => {
                  if (d.id === dimension.id) {
                    return newDimension
                  }
                  return d
                }),
              )
              saveDimension(newDimension)
            }}
          />
        )
      })}
      {mode === StatusMode.OpenForRevision && (
        <>
          <div style={{ padding: '65px 0' }} />
          <BottomSection>
            <div className="content white">
              <p>Press the black button when you're finished</p>
              <Button
                disabled={state.some(
                  (s) =>
                    !s.data.lengthInInches ||
                    measurements.find((m) => m.id === s.id)?.data
                      .lengthInInches !== s.data.lengthInInches,
                )}
                loading={loadingUpdate}
                onClick={async () => {
                  await updateRoomAndRefetch({
                    variables: {
                      data: {
                        data: {
                          measurementChangesSubmittedAt: new Date(),
                        },
                      },
                      where: {
                        id: room?.id ?? '',
                      },
                    },
                  })
                }}
                kind="solid"
                text="Complete Task"
                size="large"
              />
            </div>
          </BottomSection>
        </>
      )}
      {mode === StatusMode.Confirmation && (
        <>
          <div style={{ padding: '65px 0' }} />
          <StyledBottomSection>
            <div className="content white">
              <div
                className="flex"
                style={{
                  flexDirection: 'row',
                  flexWrap: isMobile ? 'nowrap' : 'wrap',
                  gap: isMobile ? '11px' : '4px',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Button
                  disabled={!state.every((m) => !!m.data.confirmedAt)}
                  loading={loadingUpdate}
                  onClick={async () => toggleConfirmModal(true)}
                  kind="solid"
                  text="Confirm Measurements"
                />
                <p className="no-margin">or</p>
                <Button
                  color="dark"
                  kind="outlined"
                  loading={loadingUpdate}
                  onClick={() => toggleChangesModal(true)}
                  text="Make Changes"
                />
              </div>
            </div>
          </StyledBottomSection>
        </>
      )}
      {mode === StatusMode.Confirmed && (
        <>
          <div style={{ padding: '65px 0' }} />
          <StyledBottomSection>
            <div className="content white">
              <p style={{ textAlign: 'center' }}>
                Please contact your designer to change confirmed measurements.
                Changes will affect your timeline.
              </p>
            </div>
          </StyledBottomSection>
        </>
      )}
      <ConfirmationModal
        confirmText="Yes, Confirm"
        firstTitle="Are you ready to confirm your final list of measurements?"
        loading={loadingUpdate}
        onCancel={() => toggleConfirmModal(false)}
        onConfirm={async () => {
          await updateRoomAndRefetch({
            variables: {
              data: {
                data: {
                  measurementsConfirmedAt: new Date(),
                },
              },
              where: {
                id: room?.id ?? '',
              },
            },
          })
          toggleConfirmModal(false)
        }}
        open={showConfirmModal}
      />
      <ConfirmationModal
        confirmText="Yes, Make Changes"
        description="Changes can require additional design work and affect your project timeline. Finalizing these details as soon as possible help avoid delays."
        firstTitle="Are you sure you want to make changes?"
        loading={loadingUpdate}
        onCancel={() => toggleChangesModal(false)}
        onConfirm={async () => {
          await updateRoomAndRefetch({
            variables: {
              data: {
                data: {
                  measurementChangesSubmittedAt: null,
                },
              },
              where: {
                id: room?.id ?? '',
              },
            },
          })
          toggleChangesModal(false)
        }}
        open={showChangesModal}
      />
    </div>
  )
}

export default SidebarMeasure

const StyledBottomSection = styled(BottomSection)`
  && {
    div.content {
      padding: 16px 48px !important;

      button {
        width: 100%;
        div {
          min-width: unset;
        }
      }

      @media ${Breakpoint.onlyMobile} {
        padding: 16px 24px !important;
      }
    }
  }
`
