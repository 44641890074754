import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { useMedia } from 'react-use'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { getAddressStreetAndLocalityString } from 'context/address/utils'
import useAuth from 'context/auth/use'
import { newForMe } from 'context/new-for'
import { useUserMessageMany } from 'context/project/message/provider-user-many'
import Button from 'design-system/components/button'
import RichText from 'design-system/components/rich-text'
import AppLayout from 'design-system/containers/app-layout'
import { Breakpoint, Colors } from 'styles/app/system'
import { MessageSource } from 'types/message'
import { getNameForUser } from 'views/utils'

const Module = (_: RouteComponentProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const { user, formUserId } = useAuth()
  const { messages } = useUserMessageMany()

  return (
    <AppLayout>
      <h3>Inbox</h3>
      {messages.map((message) => {
        const fromMe = message.from?.email === user.email
        const _newForMe = newForMe(message, formUserId) && !fromMe

        const messageNotFromApp =
          message.data?.source && message.data.source !== MessageSource.APP

        const formattedSource = messageNotFromApp ? (
          <>
            <span
              style={{
                color: Colors.gray400,
              }}
            >{` | `}</span>
            {message.data?.sourceFrom}
          </>
        ) : (
          ''
        )

        return (
          <StyledCard
            key={message.id}
            onClick={() =>
              window.open(`/app/projects/${message.project?.id}/messages`)
            }
          >
            <div className="content">
              {message.from ? (
                <Image
                  className="up-mobile avatar-from"
                  src={message.from.picture}
                />
              ) : (
                <AnonymousDiv className="up-mobile">
                  <FontAwesomeIcon icon={['fal', 'user-slash']} />
                </AnonymousDiv>
              )}
              <div className="body">
                {isMobile ? (
                  <>
                    <div className="title">
                      {message.from ? (
                        <Image
                          className="avatar-from"
                          src={message.from.picture}
                        />
                      ) : (
                        <AnonymousDiv>
                          <FontAwesomeIcon icon={['fal', 'user-slash']} />
                        </AnonymousDiv>
                      )}
                      <h6 className="no-margin">
                        {message.from
                          ? `${getNameForUser(message.from)}${formattedSource}`
                          : `Anonymous${formattedSource}`}
                      </h6>
                      {_newForMe ? (
                        <NewBadge>
                          <p className="no-margin small">New</p>
                        </NewBadge>
                      ) : null}
                    </div>
                    <h6 className="no-margin">
                      {getAddressStreetAndLocalityString(
                        message.project?.projectAddress,
                      )}
                    </h6>
                  </>
                ) : (
                  <div className="title">
                    <h6 className="no-margin">
                      {message.from
                        ? `${getNameForUser(message.from)}${formattedSource}`
                        : `Anonymous${formattedSource}`}
                    </h6>
                    <div className="divider" />
                    <h6 className="no-margin">
                      {getAddressStreetAndLocalityString(
                        message.project?.projectAddress,
                      )}
                    </h6>
                    {_newForMe ? (
                      <NewBadge>
                        <p className="no-margin small">New</p>
                      </NewBadge>
                    ) : null}
                  </div>
                )}
                <RichText
                  bgColor="transparent"
                  content={message.body ?? message.bodyHtml}
                  deepLinks
                  mentions
                  maxHeight={
                    message.data?.source === MessageSource.CALL_TRANSCRIPT
                      ? '300px'
                      : undefined
                  }
                />
                <p className="x-small gray no-margin">
                  Sent {moment(message.createdAt).format('MMMM DD YYYY')}
                </p>
              </div>
            </div>
            {isMobile ? (
              <Button
                fontAwesomeIcon="chevron-right"
                kind="text"
                text="Read More"
              />
            ) : (
              <Button
                fontAwesomeIcon="chevron-right"
                kind="ghost"
                color="dark"
              />
            )}
          </StyledCard>
        )
      })}
    </AppLayout>
  )
}

export default Module

export const StyledCard = styled.div`
  align-items: center;
  border-radius: 4px;
  border-bottom: 1px solid ${Colors.gray200};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px 20px;
  transition: all 0.3s ease-in-out;

  @media ${Breakpoint.upFromTablet} {
    :hover {
      background: white;
      box-shadow: 0px 4px 15px 0px rgba(120, 113, 108, 0.2);

      button {
        background-color: ${Colors.red100};
        border-color: ${Colors.red700};
        color: ${Colors.red700};
      }
    }
  }

  div.content {
    display: flex;
    gap: 18px;
    align-items: center;

    div.body {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    img.avatar-from {
      width: 36px;
      height: 36px;
      border-radius: 100%;
    }

    div.title {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 16px;

      div.divider {
        background: ${Colors.gray500};
        height: 13px;
        width: 2px;
      }
    }
  }

  @media ${Breakpoint.onlyMobile} {
    align-items: flex-start;
    background: white;
    border: none;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;

    div.title {
      margin-bottom: 10px;
    }

    div.content div.body {
      gap: 6px;
    }
  }
`

const NewBadge = styled.div`
  align-items: center;
  background-color: ${Colors.gray500};
  border-radius: 2px;
  display: flex;
  justify-content: center;
  padding: 0 10px;

  p {
    color: white;
  }
`

const AnonymousDiv = styled.div`
  align-items: center;
  background-color: ${Colors.black};
  border-radius: 50%;
  color: ${Colors.white};
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 4px;
  width: 32px;
`
