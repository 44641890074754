import React from 'react'

import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import * as SelectionUtils from 'components/app/project/room/selection/utils'
import useAuth from 'context/auth/use'
import useRoom from 'context/room/use'
import useRoomGuideOptions from 'context/room/use-guide-options'
import Button from 'design-system/components/button'
import ApplianceCard from 'design-system/components/cards/appliance-card'
import SelectionCover from 'design-system/components/guide/selection-cover'
import {
  Section,
  Spacer,
  UnderlineSubtitle,
} from 'design-system/components/guide/utils'
import { Breakpoint } from 'styles/app/system'

interface AppliancesProps {
  projectId: string
}

const Appliances = (props: AppliancesProps) => {
  const isDownFromComputer = useMedia(Breakpoint.downFromComputer)
  const { isFORMStaff } = useAuth()
  const { room, appliancesUnarchived: appliances } = useRoom()
  const { appliancePacks } = useRoomGuideOptions()

  return (
    <Section id="appliances">
      <Grid centered>
        <UnderlineSubtitle>3/4</UnderlineSubtitle>
        <Spacer height={30} />
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Your appliances</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        {!appliances.length ? (
          <Grid.Row>
            <Grid.Column>
              <SelectionCover
                image="design-call/kickoff-appliances.png"
                onClick={() =>
                  navigate(
                    `/app/projects/${props.projectId}/rooms/${room?.id}/selection/appliance-selection`,
                  )
                }
                title="We have complied the most popular appliance packages by look and price point."
              />
            </Grid.Column>
          </Grid.Row>
        ) : (
          <>
            {appliancePacks.length ? (
              <Grid.Row>
                <Grid.Column
                  computer={11}
                  tablet={14}
                  mobile={16}
                  style={{ display: 'grid' }}
                >
                  <SelectionUtils.Selection>
                    {appliancePacks.map((p) => (
                      <SelectionUtils.OptionSelected key={p.id}>
                        <p className="no-margin small dark-gray">
                          {p?.data?.name as string}
                        </p>
                      </SelectionUtils.OptionSelected>
                    ))}
                  </SelectionUtils.Selection>
                </Grid.Column>
              </Grid.Row>
            ) : null}
            <GridRow centered stretched>
              {appliances.map((a) => (
                <Grid.Column key={a.id} computer={5} tablet={8} mobile={16}>
                  <ApplianceCard genericProduct={a} noActions />
                </Grid.Column>
              ))}
            </GridRow>
            <Divider hidden />
            {isFORMStaff ? (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Button
                    color="blue"
                    fontAwesomeIcon="arrow-right"
                    kind="solid"
                    onClick={() =>
                      navigate(
                        `/app/projects/${props.projectId}/rooms/${room?.id}/selection/appliance-selection`,
                      )
                    }
                    text="See Appliance Packages"
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  fontAwesomeIcon="arrow-up-right-from-square"
                  kind="ghost"
                  onClick={() =>
                    navigate(
                      `/app/projects/${props.projectId}/rooms/${room?.id}/appliances`,
                    )
                  }
                  text="Your Appliance Card"
                />
              </Grid.Column>
            </Grid.Row>
          </>
        )}
        {!isDownFromComputer && <Spacer height={300} />}
      </Grid>
    </Section>
  )
}

export default Appliances

const GridRow = styled(Grid.Row)`
  @media ${Breakpoint.onlyMobile} {
    row-gap: 16px;
  }
  @media ${Breakpoint.upFromMobile} {
    row-gap: 24px;
  }
`
