import { DrawerCardTypes } from 'components/app/project/drawer-panel'
import useProject from 'context/project/use'
import useGuideUrls from 'context/project/use-guide-urls'
import { parseFiles } from 'context/project-file/utils-many'

const useDrawerUrls = () => {
  const { project, roomsAll, initialCostings, projectProposals } = useProject()
  const guideUrls = useGuideUrls()

  return (cardType: DrawerCardTypes, roomId?: string): string | null => {
    switch (cardType) {
      case DrawerCardTypes.ADDRESS:
        return `/app/projects/${project.id}/project-address`
      case DrawerCardTypes.APPLIANCES:
        return `/app/projects/${project.id}/rooms/${roomId}/appliances`
      case DrawerCardTypes.CATALOGS:
        return `/app/projects/${project.id}/catalogs`
      case DrawerCardTypes.COLLABORATORS:
        return `/app/projects/${project.id}/collaborators`
      case DrawerCardTypes.DESIGN:
        return guideUrls.DesignCall
      case DrawerCardTypes.FLOOR_PLAN:
        return `/app/projects/${project.id}/rooms/${roomId}/plans`
      case DrawerCardTypes.INITIAL_COSTING:
        return initialCostings[0]
          ? `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
              initialCostings[0].key,
            )}`
          : null
      case DrawerCardTypes.INSPIRATION:
        return `/app/projects/${project.id}/rooms/${roomId}/inspiration-images`
      case DrawerCardTypes.MEASUREMENTS: {
        const room = roomsAll.find((r) => r.id === roomId)
        if (!room) return ''
        const { measurementAids } = parseFiles(room.files)
        return measurementAids[0]
          ? `/app/projects/${project.id}/rooms/${roomId}/measurements/${measurementAids[0].id}`
          : null
      }
      case DrawerCardTypes.PRESENTATION:
        return guideUrls.DesignPresentation as string
      case DrawerCardTypes.PROJECT_CONSULTATION:
        return guideUrls.ProjectConsultation
      case DrawerCardTypes.PROJECT_PROPOSAL:
        return projectProposals[0]
          ? `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
              projectProposals[0].key,
            )}`
          : null
      case DrawerCardTypes.PROJECT_PROPOSAL_GUIDE:
        return guideUrls.ProjectProposal
      case DrawerCardTypes.ROOM_PHOTOS:
        return `/app/projects/${project.id}/rooms/${roomId}/photos`
      case DrawerCardTypes.SAMPLE_ORDER:
        return `/app/projects/${project.id}/sample-orders`
      default:
        return ''
    }
  }
}

export default useDrawerUrls
