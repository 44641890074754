import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'

import ProjectFileNotFound from 'components/shared/project/project-file/file-not-found'
import ProjectFileLayout from 'components/shared/project/project-file/layout'
import { ScrollContainer } from 'components/shared/project/project-file/utils'
import ProjectFileProvider from 'context/project-file/provider'
import useProjectFile from 'context/project-file/use'
import Button from 'design-system/components/button'

import Sidebar from './sidebar'

type ProjectFileProps = RouteComponentProps<{
  project_id: string
  room_id: string
  file_id: string
}>

const Measurements = (_: ProjectFileProps) => {
  const { projectFile } = useProjectFile()
  const [sidebarShow, setSidebarShow] = useState(false)

  if (!projectFile) return <ProjectFileNotFound />

  const sidebarToggle = () => setSidebarShow(!sidebarShow)

  return (
    <ProjectFileLayout
      sidebar={
        <ScrollContainer>
          <Sidebar sidebarToggle={sidebarToggle} />
        </ScrollContainer>
      }
      sidebarShow={sidebarShow}
      sidebarToggle={sidebarToggle}
      sidebarTrigger={
        <Button
          kind="solid"
          color="blue"
          size="large"
          text="Review measurements"
        />
      }
    />
  )
}

export default (props: ProjectFileProps) => (
  <ProjectFileProvider file_id={props.file_id}>
    <Measurements {...props} />
  </ProjectFileProvider>
)
