import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import StepTitle from 'components/app/step-title'
import useOrder from 'context/order/use'
import Button from 'design-system/components/button'
import AppLayout from 'design-system/containers/app-layout'
import HexBulletRow from 'design-system/containers/hex-bullet-row/hex-bullet-row'
import { Colors } from 'styles/app/system'

const Module = (_: RouteComponentProps<{ project_id: string }>) => {
  const { order } = useOrder()
  return (
    <AppLayout>
      <Grid stackable padded>
        <Grid.Row centered>
          <Grid.Column computer={12} tablet={12} mobile={16} textAlign="center">
            <StepTitle title="Prepare for Delivery" />
          </Grid.Column>
        </Grid.Row>
        {/* One day we will have videos  */}
        {/* <Grid.Row centered>
          <Grid.Column computer={10} tablet={10} mobile={16} textAlign="center">
            <FormVideo src="https://www.youtube.com/embed/uV16kEqNSe0" />
          </Grid.Column>
        </Grid.Row> */}
        <HexBulletRow
          color={Colors.green600}
          description="Once your order has arrived at the warehouse, please be prepared to receive your order at short notice:
          <br/><br/>
          The local delivery company will reach out to you directly to schedule delivery, sometimes at short notice. In most areas of the country logistics providers require the orders to be palletized to ensure safe delivery.
          <br/><br/>
          It can be helpful to think about the delivery / receiving process in 2 steps:
          <br/>
          1. Receive the order
          <br/>
          2. Check the order and move the cabinets to the staging location
          <br/><br/>
          Local delivery companies are contractually required to bring the order to the first dry place, typically a garage or loading dock. Please prepare a drop-off area which is accessible by pallet jack.
          If you see any external visible damages/issues on the day of delivery, please take a photograph and report on the ‘Confirm Delivery’ section inside the 'Project Order' card on your dashboard.
          <br/><br/>
          Our Customer Success team will be on hand to support you if anything comes up. Please unwrap and check the cabinets within 7 days of delivery. This is the time period where we can place claims if necessary."
          hexText="1"
          image="images/app/prepare-delivery-01.webp"
          title="Prepare for the delivery"
        />
        <HexBulletRow
          button={
            <Button
              color="blue"
              fontAwesomeIcon="upload"
              kind="solid"
              onClick={() => {
                window.open(
                  'https://docs.google.com/forms/d/e/1FAIpQLSf7Kf7vMf5OGVmQLmjk1XjPoSb6o4_5Yfadfi1Q1Rni4oWnwg/viewform',
                )
              }}
              text="Upload"
            />
          }
          color={Colors.orange500}
          description="Please complete the form linked below. We will pass this information along to the 3rd party warehouse handling your order to ensure a smooth delivery experience."
          hexText="2"
          image="images/app/prepare-delivery-02.webp"
          title="Upload information for the delivery company"
        />
        <HexBulletRow
          button={
            <div
              style={{
                display: 'flex',
                gap: '20px',
                flexWrap: 'wrap',
              }}
            >
              {order.designs?.map((d) =>
                d.supplierOrders.map((so, idx) =>
                  so.deliveryNote ? (
                    <Button
                      key={idx}
                      color="blue"
                      icon={
                        <FontAwesomeIcon icon={['fal', 'arrow-down-to-line']} />
                      }
                      kind="solid"
                      onClick={() =>
                        window.open(
                          `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                            so.deliveryNote.key,
                          )}`,
                        )
                      }
                      text={`Download ${d.room?.type} ${idx ? idx + 1 : ''}`}
                    />
                  ) : null,
                ),
              )}
            </div>
          }
          color={Colors.red500}
          description="It is important to do a thorough inventory once your cabinets have been delivered.
          <br /><br />
          To make sure that everything has arrived, simply look through each unit and make sure that you have received all of the cabinets and panels that are marked with position numbers on your plans. Please refer to the labels on each box or item which will indicate its position number (POS NUMBER). This is referenced in both the Delivery Note and the drawing set in your Installation Pack.
          <br /><br />
          *<b>Please note, the POS numbers on your approved design will be incorrect and should not be used during installation or inventory.</b>
          <br /><br />
          Certain items such as plinths, accessories, and recessed handles may arrive without position numbers. If you are unsure if something is on site, please contact our Customer Success team."
          hexText="3"
          image="images/app/prepare-delivery-03.webp"
          title="Review / inventory delivery"
        />
        <HexBulletRow
          color={Colors.gray500}
          description="Once you receive your delivery, we ask that you share photos of the shipment. This will also serve as documentation in case any issues or damages are found during the inventory or installation process.
          <br /><br />
          These photos can be uploaded in the Confirm Delivery section of your project order.
          <br />
          *Please note that this card will only be available on your dashboard once your delivery arrives at the local warehouse."
          hexText="4"
          image="images/app/prepare-delivery-04.webp"
          title="Upload photos of the delivery"
        />
      </Grid>
      <Footer>
        <Image
          src={`${process.env.GATSBY_CDN_ROOT}/images/app/charlie.png`}
          circular
        />
        <p className="x-small">Charlie Ward, Customer Success</p>
        <h4>Questions or Issues?</h4>
        <p>
          Please text us <span>650-449-4884</span> and we will get back to you
          as soon as we can
        </p>
      </Footer>
    </AppLayout>
  )
}

export default Module

const Footer = styled.div`
  align-items: center;
  background: ${Colors.gray100};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 90px;
  padding: 50px 16px 40px;
  position: relative;
  width: 100%;
  text-align: center;

  img {
    height: 80px;
    position: absolute !important;
    top: 0;
    transform: translateY(-50%);
    width: 80px;
  }

  span {
    font-weight: 300;
  }
`
