import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { Hexagon } from 'styles/app/components/icon'
import { Colors } from 'styles/app/system'

interface HexagonStepperProps {
  steps: {
    done: boolean
    title: string
    extraInfo?: string | null
  }[]
}

const HexagonStepper = ({ steps }: HexagonStepperProps) => {
  return (
    <ContainerDiv>
      {steps.map((s, idx) => (
        <div className="step-content" key={idx}>
          <div className="step">
            <div className="hexagon">
              <Hexagon
                styled={{
                  background: s.done ? Colors.greenBright : Colors.gray200,
                  fontSize: '11px',
                  size: '16px',
                }}
              >
                {!!s.done && <FontAwesomeIcon icon={['fal', 'check']} />}
              </Hexagon>
            </div>
          </div>
          <div className="status">
            <p className="gray no-margin">{s.title}</p>
            {s.extraInfo ? (
              <p>{s.extraInfo}</p>
            ) : (
              <p className="light-gray" style={{ fontStyle: 'italic' }}>
                Pending
              </p>
            )}
          </div>
        </div>
      ))}
    </ContainerDiv>
  )
}

const ContainerDiv = styled.div`
  div.step-content {
    display: flex;
  }

  .step {
    justify-content: center;
    display: grid;
    background: linear-gradient(${Colors.gray200}, ${Colors.gray200}) no-repeat
      center/1px;
  }

  div.step-content:last-of-type {
    .step {
      background: none;
    }
  }

  .status {
    display: initial;
    padding: 0 0 30px 15px;
  }

  .hexagon {
    background-color: white;
    height: 30px;
    padding-top: 5px;
  }
`

export default HexagonStepper
