import React from 'react'

import * as checkoutRenderUtils from 'components/shared/checkout/render-utils'
import * as checkoutUtils from 'context/order/utils-checkout'
import { FormList } from 'styles/app/components/list'
import { Colors, FormDivider } from 'styles/app/system'
import { NobiliaSampleGraphQL } from 'types/nobilia-sample'
import { Order } from 'types/order'
import { Payment } from 'types/payment'
import { capsSnakeCaseToTitleCase } from 'views/utils'

interface OrdersCardProps {
  orders: Partial<Order>[]
  showPayments?: boolean
  title?: string
}

/*
 * Only works for Samples Orders
 */
const OrdersCard = ({ orders, showPayments, title }: OrdersCardProps) => {
  const orderTitle =
    title || `${capsSnakeCaseToTitleCase(orders[0].type)} Order`
  const discountsAll = orders.reduce<
    { imageUrl?: string; name: string; price: number; quantity: number }[]
  >((acc, p) => acc.concat(p.discounts ?? []), [])
  const nobiliaSamplesAll = orders.reduce<NobiliaSampleGraphQL[]>(
    (acc, p) => acc.concat(p.nobiliaSamples ?? []),
    [],
  )
  const paymentsAll = orders.reduce<Payment[]>(
    (acc, p) => acc.concat(p.payments ?? []),
    [],
  )
  const totalBeforeTaxesAll = orders.reduce(
    (acc, p) => acc + checkoutUtils.calculateTotalBeforeTaxes(p),
    0,
  )
  const taxesAll = orders.reduce(
    (acc, p) => acc + (checkoutUtils.calculateTaxes(p) ?? 0),
    0,
  )
  const amountDueAll = orders.reduce(
    (acc, p) => acc + checkoutUtils.calculateAmountDue(p),
    0,
  )
  const doRenderBreak = [...nobiliaSamplesAll, ...discountsAll].length > 0

  return (
    <FormList>
      {checkoutRenderUtils.renderHeader(orderTitle)}
      {orders.map((order, i) => {
        const nobiliaSamples = order.nobiliaSamples || []
        const amountDue =
          checkoutUtils.calculateTotalBeforeTaxesAndDiscounts(order)

        return (
          <React.Fragment key={i}>
            {checkoutRenderUtils.renderPaymentItem({
              item: {
                name: `Order ${i + 1}`,
                price: showPayments ? amountDue : null,
                quantity: 1,
              },
              itemColor: Colors.gray1000,
              key: i,
              priceColor: Colors.gray1000,
            })}
            {nobiliaSamples.map((s, i) =>
              checkoutRenderUtils.renderNobiliaSample({
                key: i,
                product: s,
                priceHidden: true,
              }),
            )}
          </React.Fragment>
        )
      })}
      {showPayments && (
        <>
          {discountsAll.map((d, i) =>
            checkoutRenderUtils.renderPaymentItem({
              item: d,
              key: i,
              priceColor: Colors.greenBright,
            }),
          )}
          {doRenderBreak ? <FormDivider noMargin /> : null}
          {totalBeforeTaxesAll
            ? checkoutRenderUtils.renderPaymentItemFromPrice({
                name: 'Total Before Tax',
                price: totalBeforeTaxesAll,
              })
            : null}
          {totalBeforeTaxesAll
            ? checkoutRenderUtils.renderPaymentItemFromPrice({
                name: 'Estimated Tax',
                price: taxesAll,
                priceColor: Colors.gray500,
                itemColor: Colors.gray500,
              })
            : null}
          {totalBeforeTaxesAll
            ? paymentsAll.map(checkoutRenderUtils.renderPaymentMade)
            : null}
          {checkoutRenderUtils.renderAmountDue(amountDueAll)}
        </>
      )}
    </FormList>
  )
}

export default OrdersCard
