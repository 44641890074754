import styled from 'styled-components'

import { Breakpoint, Colors } from 'styles/app/system'

export const MeetingDetailContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  row-gap: 32px;
  max-width: 100%;
`

export const RecapContainer = styled.div`
  @media ${Breakpoint.upFromTablet} {
    max-width: 300px;
  }
`

export const PaymentContainer = styled.div`
  border-top: 1px solid ${Colors.gray200};
  max-width: 700px;
  padding-top: 40px;

  & > h4 {
    color: ${Colors.gray600};
    font-weight: 300;
  }

  & > div.project-deposit {
    display: grid;
    gap: 16px;
    grid-template-columns: calc(50% - 8px) auto;
    grid-template-rows: auto auto;
    margin-bottom: 32px;
    margin-top: 16px;

    @media ${Breakpoint.downFromSmallMobile} {
      grid-template-columns: 100%;
      grid-template-rows: auto auto auto auto;
    }
  }
`
