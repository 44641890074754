import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Container, Divider, Grid } from 'semantic-ui-react'

import * as SelectionUtils from 'components/app/project/room/selection/utils'
import * as DesignCallData from 'context/guide/design-call'
import useRoom from 'context/room/use'
import useRoomGuideOptions from 'context/room/use-guide-options'
import useRoomMutate from 'context/room/use-mutate'
import Button from 'design-system/components/button'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import SelectImageCard from 'design-system/components/cards/selection/select-image-card'
import { Breakpoint } from 'styles/app/system'
import { GuideOptionType } from 'types/guide-option'

type SelectionElementsProps = RouteComponentProps<{
  project_id: string
  room_id: string
}>

export default (props: SelectionElementsProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const { room } = useRoom()
  const { updateRoomAndRefetch } = useRoomMutate()

  const { reasonsForRenovating, projectActivities } = useRoomGuideOptions()

  const onSave = async (
    key: string,
    identifier: 'reasonsForRenovating' | 'projectActivities',
  ) => {
    const optionSelected =
      projectActivities.find((o) => o.data?.titleOverride === key) ||
      reasonsForRenovating.find((o) => o.data?.titleOverride === key)

    await updateRoomAndRefetch({
      variables: {
        data: {
          guideOptions: {
            ...(optionSelected
              ? {
                  delete: [{ id: optionSelected.id }],
                }
              : {
                  create: [
                    {
                      guide: GuideOptionType.DESIGN_CALL,
                      identifier: identifier,
                      data: {
                        titleOverride: key,
                      },
                    },
                  ],
                }),
          } as any,
        },
        where: {
          id: room?.id ?? '',
        },
      },
    })
  }

  return (
    <SelectionUtils.MainContainer id="scroll-container">
      <Container style={{ padding: '35px 0' }}>
        <Grid>
          <Grid.Row columns="equal" verticalAlign="middle">
            <Grid.Column computer={4} tablet={3} mobile={3} textAlign="left">
              <Button
                color="gray"
                fontAwesomeIcon="arrow-left"
                iconAnimation="left"
                iconPosition="left"
                kind="text"
                onClick={() =>
                  navigate(
                    `/app/projects/${props.project_id}/rooms/${props.room_id}/design-call`,
                  )
                }
                text={isMobile ? '' : 'Back'}
              />
            </Grid.Column>
            <Grid.Column textAlign="center">
              <h3 className="gray">What good looks like for you</h3>
            </Grid.Column>
            <Grid.Column computer={4} tablet={3} only="tablet computer" />
          </Grid.Row>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              <h4 className="gray">
                What do you hope the impact of this project will be?
              </h4>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p className="overline gray no-margin">Recommended to select 2</p>
              <div
                style={{
                  borderBottom: '1px dashed #D6D3D1',
                  height: '8px',
                  width: '100%',
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched style={{ rowGap: '30px' }}>
            {DesignCallData.projectActivities.map((opt, key) => (
              <Grid.Column key={key} computer={4} tablet={5} mobile={8}>
                <SelectImageCard
                  image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-call/${opt.image}`}
                  onClick={() => onSave(opt.title, 'projectActivities')}
                  selected={
                    !!projectActivities.find(
                      (o) => o.data?.titleOverride === opt.title,
                    )
                  }
                  text={opt.title}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              <h4 className="gray">What would you most like us to focus on?</h4>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p className="overline gray no-margin">Recommended to select 2</p>
              <div
                style={{
                  borderBottom: '1px dashed #D6D3D1',
                  height: '8px',
                  width: '100%',
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row stretched style={{ rowGap: '30px' }}>
            {DesignCallData.reasonsForRenovating.map((opt, key) => (
              <Grid.Column key={key} computer={4} tablet={5} mobile={8}>
                <SelectIconCard
                  icon={opt.icon}
                  onClick={() => onSave(opt.title, 'reasonsForRenovating')}
                  selected={
                    !!reasonsForRenovating.find(
                      (o) => o.data?.titleOverride === opt.title,
                    )
                  }
                  text={opt.title}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Container>
    </SelectionUtils.MainContainer>
  )
}
