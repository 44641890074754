import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Container, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import DashedCard from 'components/app/dashed-card'
import ConfirmationModal from 'components/shared/confirmation-modal'
import CreateUpdateRoomModal from 'components/shared/project/room/create-update-modal'
import useProject from 'context/project/use'
import useProjectRoomMutate from 'context/project/use-room-mutate'
import { getRoomFormattedType } from 'context/room/utils'
import Button from 'design-system/components/button'
import RoomCard from 'design-system/components/cards/room-card'
import { RoomGraphQL } from 'types/room'
import { getRoomImageByType } from 'views/utils'

const Module = (
  props: RouteComponentProps<{
    project_id: string
  }>,
) => {
  const { isTradeProject, roomsAll, roomsAdditional } = useProject()
  const { deleteRoomAndRefetchProject, loadingDelete } = useProjectRoomMutate()

  const [showModal, toggleModal] = useState<boolean>(false)
  const [roomToDelete, setRoomToDelete] = useState<string>('')
  const [editableRoom, setEditableRoom] = useState<RoomGraphQL | null>(null)

  return (
    <MainContainer>
      <Grid stackable>
        <Grid.Row columns="equal" verticalAlign="middle">
          <Grid.Column computer={4} tablet={5} mobile={3} textAlign="left">
            <Button
              color="gray"
              fontAwesomeIcon="arrow-left"
              iconAnimation="left"
              iconPosition="left"
              kind="ghost"
              onClick={() => navigate(-1)}
              text="Back"
            />
          </Grid.Column>
          <Grid.Column textAlign="center">
            <h3 className="gray">Additional rooms</h3>
          </Grid.Column>
          <Grid.Column computer={4} tablet={5} mobile={3} textAlign="left" />
        </Grid.Row>
        <Grid.Row style={{ rowGap: '24px' }} verticalAlign="middle">
          <Grid.Column computer={5} tablet={8} mobile={16}>
            <DashedCard
              image={
                <div className="icon">
                  <FontAwesomeIcon icon={['fal', 'door-open']} />
                </div>
              }
              onClick={() => toggleModal(true)}
              title="Create Room"
              whiteCard
            />
          </Grid.Column>
          {roomsAdditional.map((r) => (
            <Grid.Column key={r.id} computer={5} tablet={8} mobile={16}>
              <RoomCard
                color={r.data.tagColor}
                image={getRoomImageByType(r.type)}
                title={getRoomFormattedType(r)}
                subtitle={r.data?.descriptionAdditional}
                onDelete={() => setRoomToDelete(r.id)}
                onEdit={() => {
                  setEditableRoom(r)
                  toggleModal(true)
                }}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      <CreateUpdateRoomModal
        isTradeProject={isTradeProject}
        onClose={() => {
          setEditableRoom(null)
          toggleModal(false)
        }}
        open={showModal}
        projectId={props.project_id ?? ''}
        room={editableRoom ?? undefined}
        rooms={roomsAll}
      />
      <ConfirmationModal
        confirmText="Delete"
        firstTitle="Are you sure you want to delete this room?"
        loading={loadingDelete}
        onCancel={() => setRoomToDelete('')}
        onConfirm={async () => {
          await deleteRoomAndRefetchProject({
            variables: {
              where: {
                id: roomToDelete,
              },
            },
          })
          setRoomToDelete('')
        }}
        open={!!roomToDelete}
      />
    </MainContainer>
  )
}

export default Module

const MainContainer = styled(Container)`
  padding: 35px 0;
`
