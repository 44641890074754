import React from 'react'

import { navigate, RouteComponentProps } from '@reach/router'

import { getAddressStreetAndLocalityString } from 'context/address/utils'
import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import { parseOwners } from 'context/project/utils'
import { NavigationProps } from 'design-system/components/guide/navigation'
import GuideLayout from 'design-system/containers/guide-layout'
import { getNameForUser } from 'views/utils'

import AdditionalRooms from './sections/additional-rooms'
import DiscussOptions from './sections/discuss-options'
import NextCall from './sections/next-call'
import NextSteps from './sections/next-steps'

const navItems: NavigationProps['items'] = [
  {
    linkTo: 'discuss-options',
    title: 'Discuss your extra rooms',
    icon: ['fal', 'bath'],
  },
  {
    linkTo: 'next-steps',
    title: 'Next steps',
    icon: ['fal', 'clipboard-list-check'],
  },
  {
    linkTo: 'add-rooms',
    title: 'Create additional rooms',
    icon: ['fal', 'bath'],
  },
  {
    linkTo: 'next-call',
    title: 'Schedule the next call',
    icon: ['fal', 'video-plus'],
  },
]

const ExtraRoom = (_: RouteComponentProps) => {
  const { isFORMStaff } = useAuth()
  const { project } = useProject()

  const { primaryOwner } = parseOwners(project)

  return (
    <>
      <GuideLayout
        guideId="extra-room-page"
        coverProps={{
          address: getAddressStreetAndLocalityString(project.projectAddress),
          src: '/images/interactive-guides/extra-room/extra-room.webp',
          subTitle: getNameForUser(primaryOwner),
          title: 'Extra Room',
        }}
        handleLogoClick={() => navigate(`/app/projects/${project.id}`)}
        introProps={{
          agenda: [
            {
              text: 'Understand what other spaces you would like to design with us and agree on a path forward.',
            },
          ],
        }}
        navItems={
          isFORMStaff
            ? navItems
            : navItems.filter((i) => i.linkTo !== 'next-call')
        }
      >
        <DiscussOptions />
        <NextSteps />
        <AdditionalRooms />
        {isFORMStaff ? <NextCall projectId={project.id} /> : null}
      </GuideLayout>
    </>
  )
}

export default ExtraRoom
