import React from 'react'

import { RouteComponentProps } from '@reach/router'

import OrderProvider from 'context/order/provider'

interface OrderRouteProps {
  project_id: string
  order_id: string
}

interface OrderProps extends RouteComponentProps<OrderRouteProps> {
  children?: React.ReactNode
}

const OrderIndex = (props: OrderProps) => {
  return (
    <OrderProvider order_id={props.order_id}>{props.children}</OrderProvider>
  )
}

export default OrderIndex
