import React from 'react'

import { RouteComponentProps } from '@reach/router'

interface SelectionProps
  extends RouteComponentProps<{
    project_id: string
    room_id: string
  }> {
  children: React.ReactNode
}

const Selection = (props: SelectionProps) => {
  return <section style={{ background: 'white' }}>{props.children}</section>
}

export default Selection
