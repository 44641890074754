import React, { useEffect, useMemo } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import HeaderDashboard from 'components/app/project/header/header-dashboard'
import { MeetingBooker, MeetingDetail } from 'components/app/project/meeting'
import NotificationBar from 'components/shared/notification-bar'
import MeetingProvider from 'context/meeting/provider'
import useMeeting from 'context/meeting/use'
import { parseMeetingQueries } from 'context/meeting/utils'
import PaymentSourceManyProvider from 'context/payment-source/provider-many'
import useProject from 'context/project/use'
import AppLayout from 'design-system/containers/app-layout'
import { Colors } from 'styles/app/system'

type MeetingIndexProps = RouteComponentProps<{ project_id: string }>

const MeetingIndex = ({ location }: MeetingIndexProps) => {
  const {
    meetingType,
    duration: meetingDuration,
    meetingId,
    deepLink,
    calendar,
  } = useMemo(
    () => parseMeetingQueries(location?.search ?? ''),
    [location?.search],
  )
  const { primaryDesigner: designer } = useProject()

  const { comingFromHome } = (location?.state as {
    comingFromHome?: boolean
  }) ?? { comingFromHome: false }

  useEffect(() => {
    const appPageSection = document.getElementById('app-page')
    if (appPageSection) {
      appPageSection.style.backgroundColor = Colors.white
    }

    return () => {
      if (appPageSection) {
        appPageSection.style.backgroundColor = Colors.gray50
      }
    }
  }, [])

  return (
    <PaymentSourceManyProvider>
      <MeetingProvider
        calendar={calendar}
        meetingType={meetingType}
        meetingDuration={meetingDuration}
        meetingId={meetingId}
      >
        <AppLayout
          header={comingFromHome || deepLink ? undefined : <HeaderDashboard />}
        >
          {designer?.data?.awayMessage ? (
            <div style={{ marginBottom: '40px' }}>
              <NotificationBar text={designer.data.awayMessage} />
            </div>
          ) : null}
          <MeetingBody />
        </AppLayout>
      </MeetingProvider>
    </PaymentSourceManyProvider>
  )
}

export default MeetingIndex

const MeetingBody = () => {
  const { meeting } = useMeeting()
  const { project } = useProject()

  if (!meeting.startTime)
    return (
      <MeetingBooker
        handleMeetingScheduled={() =>
          navigate(`/app/projects/${project.id}/home`)
        }
      />
    )

  return <MeetingDetail />
}
