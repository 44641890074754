import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Divider, Form, Grid } from 'semantic-ui-react'

import StepTitle from 'components/app/step-title'
import useProject from 'context/project/use'
import SaveToast from 'design-system/components/save-toast'
import AppLayout from 'design-system/containers/app-layout'
import {
  UPDATE_PROJECT,
  UpdateProjectPayload,
  UpdateProjectVariables,
} from 'queries/project'
import { StyledForm } from 'styles/app/components/form'

const Module = (
  props: RouteComponentProps<{
    location?: string
    project_id: string
  }>,
) => {
  const { project, refetch } = useProject()
  const [loading, setLoading] = useState(false)
  const [projectLabel, setProjectName] = useState(
    project.data?.projectLabel || '',
  )

  const [updateProject] = useMutation<
    UpdateProjectPayload,
    UpdateProjectVariables
  >(UPDATE_PROJECT)

  const handleOnSave = async () => {
    setLoading(true)
    await updateProject({
      variables: {
        data: {
          data: {
            projectLabel: projectLabel,
          },
        },
        where: {
          id: project.id,
        },
      },
    })
    refetch()
    setLoading(false)
  }

  const formChanged = project.data?.projectLabel !== projectLabel
  const formIncomplete = !projectLabel

  return (
    <AppLayout>
      <Grid>
        <StepTitle
          title="Name this project"
          subTitle="Choose a name for this project so you can identify it."
        />
        <Grid.Row centered columns="equal">
          <Grid.Column computer={8} tablet={13} mobile={16}>
            <StyledForm styled={{ whiteInputs: true }}>
              <Form.Group widths="equal">
                <Form.Input
                  label="Name of project"
                  maxLength={30}
                  name="projectName"
                  onChange={(v) => setProjectName(v.target.value)}
                  placeholder="Name of project"
                  required
                  value={projectLabel}
                />
              </Form.Group>
            </StyledForm>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden section />
      <SaveToast
        disabled={formIncomplete}
        loading={loading}
        onClick={async () => {
          await handleOnSave()
          navigate(`/app/projects/${props.project_id}`)
        }}
        show={!formIncomplete && formChanged}
      />
    </AppLayout>
  )
}

export default Module
