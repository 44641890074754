import React from 'react'

import ConfirmationModal from 'components/shared/confirmation-modal'
import useProjectFile from 'context/project-file/use'
import useProjectFileMutate from 'context/project-file/use-mutate'

const SubmitModal = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) => {
  const { commentsNumbered, projectFile } = useProjectFile()
  const { loadingUpdate, updateProjectFileAndRefetch } = useProjectFileMutate()

  return (
    <ConfirmationModal
      open={open}
      firstTitle={`Ready to submit ${
        commentsNumbered?.length ? 'your' : 'without'
      } comments?`}
      onCancel={onClose}
      description={`Please make sure you've included all your requests for the next revision
    as you won't be able to ${commentsNumbered?.length ? 'edit your' : 'add'}
    comments after submission.`}
      confirmText="Yes, submit"
      onConfirm={async () => {
        await updateProjectFileAndRefetch({
          variables: {
            data: { submittedAt: new Date() },
            where: { id: projectFile.id },
          },
        })
      }}
      loading={loadingUpdate}
    />
  )
}

export default SubmitModal
