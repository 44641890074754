import React from 'react'

import { navigate } from 'gatsby'
import { Divider, Grid } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import useRoomGuideOptions from 'context/room/use-guide-options'
import Button from 'design-system/components/button'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import SelectionCover from 'design-system/components/guide/selection-cover'
import { Section } from 'design-system/components/guide/utils'

interface ProjectDetailsProps {
  projectId: string
  roomId: string
}

const ProjectDetails = (props: ProjectDetailsProps) => {
  const { isFORMStaff } = useAuth()
  const { goalsSelected, prioritiesSelected } = useRoomGuideOptions()

  return (
    <Section id="your-project">
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">
              Understanding your priorities helps us design and communicate more
              clearly.
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        {!goalsSelected.length && !prioritiesSelected.length ? (
          <Grid.Row>
            <Grid.Column>
              <SelectionCover
                image="design-call/kickoff-elements.png"
                onClick={() =>
                  navigate(
                    `/app/projects/${props.projectId}/rooms/${props.roomId}/selection/selection-options-project-consultation`,
                  )
                }
                title="Your priorities"
              />
            </Grid.Column>
          </Grid.Row>
        ) : (
          <>
            {prioritiesSelected.length ? (
              <>
                <Grid.Row>
                  <Grid.Column textAlign="center">
                    <p>What brought you to FORM?</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered stretched style={{ rowGap: '24px' }}>
                  {prioritiesSelected.map((opt, key) => (
                    <Grid.Column key={key} computer={5} tablet={5} mobile={8}>
                      <SelectIconCard active icon={opt.icon} text={opt.title} />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </>
            ) : null}
            {goalsSelected.length ? (
              <>
                <Grid.Row>
                  <Grid.Column textAlign="center">
                    <p>What's driving your project goals?</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered style={{ rowGap: '24px' }}>
                  {goalsSelected.map((opt, key) => (
                    <Grid.Column key={key} computer={5} tablet={5} mobile={8}>
                      <SelectIconCard active icon={opt.icon} text={opt.title} />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </>
            ) : null}
            <Divider hidden />
            {isFORMStaff ? (
              <Grid.Row centered>
                <Grid.Column textAlign="center">
                  <Button
                    color="blue"
                    fontAwesomeIcon="arrow-right"
                    kind="solid"
                    onClick={() =>
                      navigate(
                        `/app/projects/${props.projectId}/rooms/${props.roomId}/selection/selection-options-project-consultation`,
                      )
                    }
                    text="Change selection"
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}
          </>
        )}
      </Grid>
    </Section>
  )
}

export default ProjectDetails
