import React, { useState } from 'react'

import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

import Button from 'design-system/components/button'
import { StyledForm } from 'styles/app/components/form'
import { Address } from 'types/utils'

import PlacesAutocomplete from './autocomplete'
import { addressToString, isAddressComplete } from './parser'

const requiredError = {
  content: <p className="red small">This field is required</p>,
}

interface AddressFormProps {
  address: Address | null
  labels?: boolean
  onChange: (complete: boolean, address: Address) => void
  whiteInputs?: boolean
  includeRecipientName?: boolean
}

const AddressForm = ({
  address,
  labels,
  onChange,
  whiteInputs = true,
  includeRecipientName,
}: AddressFormProps) => {
  const [disableAutocomplete, setDisableAutocomplete] = useState<boolean>(
    isAddressComplete(address),
  )
  const [addressState, setAddressState] = useState<Address>(
    address || {
      addressCountry: '',
      addressLocality: '',
      addressRegion: '',
      apartmentNumber: '',
      recipient: '',
      postalCode: '',
      postOfficeBoxNumber: '',
      streetAddress: '',
    },
  )
  const [touchedFields, setTouchedFields] = useState({
    streetAddress: false,
    addressLocality: false,
    addressRegion: false,
    postalCode: false,
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTouchedFields((currTouchedFields) => ({
      ...currTouchedFields,
      [e.target.name]: true,
    }))
    const newState = { ...addressState, [e.target.name]: e.target.value }
    onChange(isAddressComplete(newState), newState)
    setAddressState(newState)
  }

  const renderBody = () => (
    <>
      {includeRecipientName && (
        <Form.Input
          label={labels ? 'Name of Recipient' : null}
          onChange={handleChange}
          placeholder="Name of Recipient"
          value={addressState.recipient ?? ''}
          name="recipient"
        />
      )}
      <Form.Input
        label={labels ? 'Street Address' : null}
        onChange={handleChange}
        placeholder="Street Address"
        required
        error={
          touchedFields.streetAddress && !addressState.streetAddress.length
            ? requiredError
            : null
        }
        value={addressState.streetAddress}
        name="streetAddress"
      />
      <StyledFormGroup widths="equal">
        <Form.Input
          label={labels ? 'Apt / Floor Number' : null}
          onChange={handleChange}
          placeholder="Apt / Floor Number"
          value={addressState.apartmentNumber}
          name="apartmentNumber"
        />
        <Form.Input
          label={labels ? 'City' : null}
          onChange={handleChange}
          placeholder="City"
          required
          error={
            touchedFields.addressLocality &&
            !addressState.addressLocality.length
              ? requiredError
              : null
          }
          value={addressState.addressLocality}
          name="addressLocality"
        />
      </StyledFormGroup>
      <StyledFormGroup widths="equal">
        <Form.Input
          label={labels ? 'State' : null}
          onChange={handleChange}
          placeholder="State"
          required
          error={
            touchedFields.addressRegion && !addressState.addressRegion.length
              ? requiredError
              : null
          }
          value={addressState.addressRegion}
          name="addressRegion"
        />
        <Form.Input
          label={labels ? 'Zip Code' : null}
          onChange={handleChange}
          placeholder="Zip Code"
          required
          error={
            touchedFields.postalCode && !addressState.postalCode.length
              ? requiredError
              : null
          }
          value={addressState.postalCode}
          name="postalCode"
        />
      </StyledFormGroup>
    </>
  )

  const renderSearch = () => (
    <Form.Field>
      {labels && (
        <p
          style={{
            fontSize: '14px',
            marginBottom: '6px',
          }}
        >
          Find Your Address
        </p>
      )}
      <PlacesAutocomplete
        defaultAddress={addressToString(address)}
        onAddressSelected={(a: Address) => {
          setAddressState(a)
          onChange(isAddressComplete(a), a)
          setDisableAutocomplete(true)
        }}
      />
      <br />
      <ButtonContainer>
        <Button
          kind="text"
          fontAwesomeIcon="long-arrow-right"
          text="I can't find my address"
          onClick={() => setDisableAutocomplete(true)}
          color="blue"
          iconAnimation="right"
        />
      </ButtonContainer>
    </Form.Field>
  )

  return (
    <StyledForm
      styled={{ whiteInputs }}
      onSubmit={(e: React.SyntheticEvent<HTMLFormElement>) =>
        e.preventDefault()
      }
      style={{ margin: '0' }}
    >
      {disableAutocomplete ? renderBody() : renderSearch()}
    </StyledForm>
  )
}

export default AddressForm

const StyledFormGroup = styled(Form.Group)`
  &&&&& {
    gap: 14px;

    .field {
      padding: 0;
    }

    .field .input input {
      width: 100%;
    }
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
