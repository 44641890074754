import React from 'react'

import * as checkoutRenderUtils from 'components/shared/checkout/render-utils'
import * as checkoutUtils from 'context/order/utils-checkout'
import { FormList } from 'styles/app/components/list'
import { Colors, FormDivider } from 'styles/app/system'
import { Order } from 'types/order'
import { capsSnakeCaseToTitleCase } from 'views/utils'

interface OrderCardProps {
  order: Partial<Order>
  showPayments?: boolean
}

const OrderCard = ({ order, showPayments }: OrderCardProps) => {
  const designs = checkoutUtils.sortOrderDesignsByPrice(order)
  const discounts = order.discounts || []
  const nobiliaSamples = order.nobiliaSamples || []
  const succeededPayments =
    order.payments?.filter((p) => p.status === 'succeeded') || []
  const totalBeforeTaxes = checkoutUtils.calculateTotalBeforeTaxes(order)
  const taxes = checkoutUtils.calculateTaxes(order)
  const amountDue = checkoutUtils.calculateAmountDueWithSucceededPayments(order)
  const orderTypeReadable = capsSnakeCaseToTitleCase(order.type)
  const doRenderBreak = [...designs, ...nobiliaSamples, ...discounts].length > 0

  return (
    <FormList>
      {checkoutRenderUtils.renderHeader(`${orderTypeReadable} Order`)}
      {designs.map(checkoutRenderUtils.renderDesign)}
      {nobiliaSamples.map((s, i) =>
        checkoutRenderUtils.renderNobiliaSample({
          key: i,
          product: s,
        }),
      )}
      {showPayments && (
        <>
          {discounts.map((d, i) =>
            checkoutRenderUtils.renderPaymentItem({
              item: d,
              key: i,
              priceColor: Colors.greenBright,
            }),
          )}
          {doRenderBreak ? <FormDivider style={{ margin: '10px 0' }} /> : null}
          {totalBeforeTaxes
            ? checkoutRenderUtils.renderPaymentItemFromPrice({
                name: 'Total Before Tax',
                price: totalBeforeTaxes,
              })
            : null}
          {totalBeforeTaxes
            ? checkoutRenderUtils.renderPaymentItemFromPrice({
                name: `Estimated Tax ${
                  order.taxRate ? `(${order.taxRate}%)` : ''
                }`,
                price: taxes as number,
                priceColor: Colors.gray500,
                itemColor: Colors.gray500,
              })
            : null}
          {totalBeforeTaxes
            ? succeededPayments.map(checkoutRenderUtils.renderPaymentMade)
            : null}
          {checkoutRenderUtils.renderAmountDue(amountDue)}
        </>
      )}
    </FormList>
  )
}

export default OrderCard
