import React from 'react'

import styled from 'styled-components'

import { Drawer } from 'design-system/components/drawer'
import { Colors } from 'styles/app/system'

import { cardFactory, DrawerCardTypes } from './cards'

export type DrawerPanelSection = {
  title: string
  items: DrawerCardTypes[]
  isOpen?: boolean
}

export type DrawerPanelProps = {
  sections: DrawerPanelSection[]
  getUrlForDrawerType: (
    cardType: DrawerCardTypes,
    roomId: string,
  ) => string | null
  roomId: string
}

export const DrawerPanel = ({
  sections,
  roomId,
  getUrlForDrawerType,
}: DrawerPanelProps) => {
  const getCard = cardFactory({ getUrlForDrawerType, roomId })

  return sections.length ? (
    <DrawerPanelContainer>
      {sections.map(
        (drawer) =>
          !!drawer.items.length && (
            <Drawer
              key={drawer.title}
              title={drawer.title}
              content={() => (
                <CardsContainer>{drawer.items.map(getCard)}</CardsContainer>
              )}
              defaultOpen={drawer.isOpen}
            />
          ),
      )}
    </DrawerPanelContainer>
  ) : null
}

const DrawerPanelContainer = styled.div`
  background-color: ${Colors.gray50};
  border-radius: 4px;
  height: max-content;
  padding: 0 8px 8px;
  width: 100%;
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
`
