import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import useAuth from 'context/auth/use'
import { FIND_UNIQUE_USER_CONTEXT, FindUniqueUserPayload } from 'queries/user'
import { Project } from 'types/project'
import { User } from 'types/user'

interface IUserContext {
  projects: Project[]
  user: User
  refetch: () => void
  startPolling: (pollInterval: number) => void
  stopPolling: () => void
}

type UserProps = RouteComponentProps & {
  children: React.ReactNode
}

export const UserContext = createContext<IUserContext>({} as IUserContext)

const UserProvider = (props: UserProps) => {
  const { isFORMOrExternalStaff } = useAuth()
  const { data, loading, refetch, startPolling, stopPolling } =
    useQuery<FindUniqueUserPayload>(FIND_UNIQUE_USER_CONTEXT, {
      skip: typeof window === 'undefined' || isFORMOrExternalStaff,
    })
  if (loading) return <Loader />
  const user = data?.user

  return (
    <UserContext.Provider
      value={{
        projects: isFORMOrExternalStaff
          ? []
          : user?.projects.map((p) => p.project as Project) ?? [],
        user: data?.user as User,
        refetch,
        startPolling,
        stopPolling,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export default UserProvider
