import React, { useState } from 'react'

import styled from 'styled-components'

import { Colors } from 'styles/app/system'

export const Hamburger = ({ children }: { children: React.ReactNode }) => {
  const [showMenu, toggleMenu] = useState<boolean>(false)

  return (
    <>
      <HamburgerLabel className="only-mobile">
        <input type="checkbox" onClick={() => toggleMenu(!showMenu)} />
        <span></span>
        <span></span>
        <span></span>
      </HamburgerLabel>
      <MobileMenu visible={showMenu}>{children}</MobileMenu>
    </>
  )
}

const MobileMenu = styled.div<{ visible?: boolean }>`
  background: ${Colors.white};
  height: 100%;
  position: absolute;
  position: fixed;
  right: 0;
  top: 60px;
  transition: all 0.3s ease-in-out;
  width: ${(props) => (props.visible ? '100%' : '0')};
  z-index: 1;

  div.item,
  div.dropdown {
    padding: 24px;

    color: ${Colors.gray600};
    font-family: 'GT America';
    font-size: 16px;
    font-weight: 300;

    svg {
      color: ${Colors.orange500};
    }
  }

  div.dropdown {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;

    div.text,
    svg.dropdown {
      padding: 24px;
    }

    div.menu {
      filter: none !important;
      position: unset;
      width: 100%;
    }
  }
`

const HamburgerLabel = styled.label`
  display: flex;
  flex-direction: column;
  width: 18px;
  margin: 16px 14px;
  cursor: pointer;

  span {
    background: #000000;
    height: 1px;
    margin: 3px 0;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  span:nth-of-type(1) {
    width: 50%;
  }

  span:nth-of-type(2) {
    width: 100%;
  }

  span:nth-of-type(3) {
    width: 75%;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(3px, 2px);
  }

  input[type='checkbox']:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  input[type='checkbox']:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(7px, -4px) rotatez(45deg);
  }
`
