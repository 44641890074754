import React from 'react'

import { Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import NextStepsCarousel from 'design-system/components/guide/next-steps-carousel'
import { Section } from 'design-system/components/guide/utils'

const nextSteps = [
  {
    leadText:
      'Our Customer Success Team will <span class="bold">reach out once your order arrives in the US.</span>',
    description:
      'They will schedule a call with you and your installer to help you prep the space for installation, as well as answer any questions that you may have.',
    image: `images/interactive-guides/handover/next-step-handover-1.svg`,
  },
  {
    leadText:
      'Our Technical Team will <span class="bold">prepare a detailed installation pack</span> for your project and upload it to your dashboard in the coming weeks.',
    image: `images/interactive-guides/handover/next-step-handover-2.svg`,
  },
  {
    leadText: 'Please be aware:',
    description: `<ul>
    <li>Blocking is an important step for your contractor when installing cabinetry to provide a secure and stable anchor for the cabinets.</li>
    <li>Deliveries are typically made on pallets, so the team will ask you to prepare an area accessible by pallet jack.</li>
    <li><span class="bold">The Customer Success Team will walk you through all of the steps that are specific to your project to make sure everything is clear.</span></li></ul>`,
    image: `images/interactive-guides/handover/next-step-handover-3.svg`,
  },
  {
    leadText:
      '<span class="bold">Any further questions regarding delivery or installation</span>, don’t hesitate to reach out to our Customer Success Team for any assistance required. They will ensure you have everything needed for a <span class="bold">successful installation.</span>',
    image: `images/interactive-guides/handover/next-step-handover-4.svg`,
  },
]

const NextSteps = () => {
  const { isTradeProject } = useProject()

  return (
    <Section id="next-steps">
      <Grid centered>
        <NextStepsCarousel
          isTradeProject={isTradeProject}
          slideData={nextSteps}
        />
      </Grid>
    </Section>
  )
}

export default NextSteps
