import React from 'react'

import { navigate, RouteComponentProps } from '@reach/router'

import { getAddressStreetAndLocalityString } from 'context/address/utils'
import useProject from 'context/project/use'
import { parseOwners } from 'context/project/utils'
import { NavigationProps } from 'design-system/components/guide/navigation'
import GuideLayout from 'design-system/containers/guide-layout'
import { getNameForUser } from 'views/utils'

import CustomerSuccess from './sections/customer-success'
import NextSteps from './sections/next-steps'
import Orders from './sections/orders'
import ThankYou from './sections/thank-you'

const navItems: NavigationProps['items'] = [
  {
    linkTo: 'orders',
    title: 'Order status',
    icon: ['fal', 'badge-check'],
  },
  {
    linkTo: 'customer-success',
    title: 'Introduce customer success team',
    icon: ['fal', 'video'],
  },
  {
    linkTo: 'next-steps',
    title: 'Next steps',
    icon: ['fal', 'clipboard-list-check'],
  },
]

const Handover = (_: RouteComponentProps) => {
  const { project } = useProject()

  const { primaryOwner } = parseOwners(project)

  return (
    <>
      <GuideLayout
        handleLogoClick={() => navigate(`/app/projects/${project.id}`)}
        guideId="handover-page"
        coverProps={{
          address: getAddressStreetAndLocalityString(project.projectAddress),
          src: '/images/interactive-guides/handover/handover.webp',
          subTitle: getNameForUser(primaryOwner),
          title: 'Handover Guide',
        }}
        introProps={{
          agenda: [
            {
              text: 'Review your order status and go over what to expect next.',
              timer: '15 mins',
            },
          ],
        }}
        navItems={navItems}
      >
        <Orders />
        <CustomerSuccess />
        <NextSteps />
        <ThankYou />
      </GuideLayout>
    </>
  )
}

export default Handover
