import { useMutation } from '@apollo/client'

import {
  CreateGenericProductPayload,
  CreateGenericProductVariables,
  CREATE_GENERIC_PRODUCT,
} from 'queries/generic-product'

const useGenericProductMutateNoContext = () => {
  const [createGenericProduct, { loading: loadingCreate }] = useMutation<
    CreateGenericProductPayload,
    CreateGenericProductVariables
  >(CREATE_GENERIC_PRODUCT)

  return {
    loadingCreate,
    createGenericProduct,
  }
}

export default useGenericProductMutateNoContext
