import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components'

import * as StyleSystem from 'styles/app/system'

const StyledTextArea = styled(TextareaAutosize)`
  background-color: ${StyleSystem.Colors.white};
  border: ${StyleSystem.LineBorder};
  border-radius: 1px;
  color: ${StyleSystem.Colors.black} !important;
  font-family: 'GT America';
  font-size: 16px !important;
  height: 48px;
  max-height: 80vh;
  min-height: unset !important;
  padding: 10px;
  width: 100%;

  ::placeholder {
    color: ${StyleSystem.Colors.gray500} !important;
  }

  input {
    padding: 0em !important;
    border: none !important;
  }

  :active {
    border: none;
  }

  :focus {
    outline: 0;
  }
`

export default StyledTextArea
