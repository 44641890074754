import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Timeline from 'components/shared/timeline'
import { TIMELINE_STEPS_ARRAY } from 'components/shared/timeline/steps'
import LeadTimeProvider from 'context/lead-times/provider'

const Module = (_: RouteComponentProps) => {
  return <Timeline isTradeProject steps={TIMELINE_STEPS_ARRAY} useWeeks />
}

export default (_: RouteComponentProps) => {
  return (
    <LeadTimeProvider>
      <Module />
    </LeadTimeProvider>
  )
}
