import { GenericOption } from 'types/generic-option'

export const parseBacksplash = (backsplash: Partial<GenericOption>[]) => {
  return backsplash.reduce<{
    backsplashOnDesignCall: Partial<GenericOption>[]
    backsplashAll: Partial<GenericOption>[]
  }>(
    (acc, go) => {
      if (go.optionIdentifier === 'custom') return acc
      if (go.data?.showOnDesignCall) acc.backsplashOnDesignCall.push(go)

      acc.backsplashAll.push(go)
      return acc
    },
    {
      backsplashOnDesignCall: [],
      backsplashAll: [],
    },
  )
}
