import React, { useEffect, useState } from 'react'

import { isEqual } from 'lodash'

import ProjectAddress from 'components/app/project/address'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import { Address } from 'types/utils'

import { StepViewProps } from '../types'

const AddressView = ({
  setLoading,
  setNextDisabled,
  setOnNext,
  setSkip,
}: StepViewProps) => {
  const { project, isTradeProject } = useProject()
  const { loadingUpdate, updateProjectAndRefetch } = useProjectMutate()
  const [address, setAddress] = useState<Address>()

  useEffect(() => {
    setLoading(loadingUpdate)
    if (isTradeProject) {
      setNextDisabled(false)
      setSkip(!(address || project.projectAddress))
    } else {
      setNextDisabled(!(address || project.projectAddress))
      setSkip(false)
    }

    if (!isEqual(address, project.projectAddress)) {
      const onNext = async () => {
        await updateProjectAndRefetch({
          variables: {
            data: {
              projectAddress: address,
            },
            where: {
              id: project.id,
            },
          },
        })
      }
      setOnNext(() => onNext)
    } else {
      setOnNext(undefined)
    }
  }, [address, project.projectAddress, loadingUpdate])

  return (
    <ProjectAddress
      whiteInputs={false}
      setNextDisabled={setNextDisabled}
      setAddress={setAddress}
    />
  )
}

export default AddressView
