import React from 'react'

import { navigate, RouteComponentProps } from '@reach/router'

import Fronts from 'components/shared/guide/fronts'
import Handles from 'components/shared/guide/handles'
import { getAddressStreetAndLocalityString } from 'context/address/utils'
import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import { parseOwners } from 'context/project/utils'
import { NavigationProps } from 'design-system/components/guide/navigation'
import GuideLayout from 'design-system/containers/guide-layout'
import { GuideType } from 'types/utils'
import { getNameForUser } from 'views/utils'

import Budget from './sections/budget'
import DesignDeposit from './sections/design-deposit'
import HowWeWork from './sections/how-we-work'
import InitialCosting from './sections/initial-costing'
import NextCall from './sections/next-call'
import NextSteps from './sections/next-steps'
import ProjectDetails from './sections/project-details'
import ProjectSpace from './sections/project-space'
import Scope from './sections/scope'
import Timeline from './sections/timeline'
import WhatPeopleSay from './sections/what-people-say'
import WhatWeOffer from './sections/what-we-offer'

const navItems: NavigationProps['items'] = [
  {
    linkTo: 'your-project',
    title: 'Learn more about you and your project',
    icon: ['fal', 'house-chimney'],
  },
  {
    title: 'Your project',
    icon: ['fal', 'oven'],
    items: [
      { title: 'Your uploads', linkTo: 'project-space' },
      { title: 'Scope', linkTo: 'scope' },
      { title: 'What we offer', linkTo: 'what-we-offer' },
      {
        linkTo: 'fronts',
        title: 'Cabinet fronts',
      },
      {
        linkTo: 'handles',
        title: 'Cabinet handles',
      },
      { title: 'Budget', linkTo: 'budget' },
      { title: 'Preliminary Estimate', linkTo: 'preliminary-estimate' },
      { title: 'Timeline', linkTo: 'timeline' },
    ],
  },
  {
    linkTo: 'how-form-works',
    title: 'How FORM works',
    icon: ['fal', 'hexagon'],
  },
  {
    linkTo: 'next-steps',
    title: 'Next steps',
    icon: ['fal', 'clipboard-list-check'],
  },
  {
    linkTo: 'people-say',
    title: 'What people say',
    icon: ['fal', 'hand-holding-heart'],
  },
  {
    linkTo: 'design-deposit',
    title: 'Design with FORM for $99',
    icon: ['fal', 'file-invoice-dollar'],
  },
  {
    linkTo: 'next-call',
    title: 'Schedule the next call',
    icon: ['fal', 'video-plus'],
  },
]

const ProjectConsultation = ({
  room_id: roomId,
}: RouteComponentProps<{ room_id: string }>) => {
  const { isFORMStaff } = useAuth()
  const { project } = useProject()

  const { primaryOwner } = parseOwners(project)

  return (
    <>
      <GuideLayout
        guideId="project-consultation-page"
        coverProps={{
          address: getAddressStreetAndLocalityString(project.projectAddress),
          projectLabel: project.data?.projectLabel,
          src: '/images/interactive-guides/project-consultation/trade-project-consultation.webp',
          subTitle: getNameForUser(primaryOwner),
          title: 'Project Consultation',
        }}
        handleLogoClick={() => navigate(`/app/projects/${project.id}`)}
        introProps={{
          agenda: [
            {
              timer: '30 mins',
              text: 'Learn more about you and your business. Discuss your project. Check that budget and timeline make sense.',
            },
          ],
        }}
        navItems={
          isFORMStaff
            ? navItems
            : navItems.filter((i) => i.linkTo !== 'next-call')
        }
      >
        <ProjectDetails projectId={project.id} roomId={roomId ?? ''} />
        <ProjectSpace />
        <Scope />
        <WhatWeOffer />
        <Fronts
          projectId={project.id}
          guideType={GuideType.ProjectConsultation}
        />
        <Handles
          projectId={project.id}
          guideType={GuideType.ProjectConsultation}
        />
        <Budget />
        <InitialCosting />
        <Timeline />
        <HowWeWork />
        <NextSteps />
        <WhatPeopleSay />
        <DesignDeposit />
        {isFORMStaff ? <NextCall projectId={project.id} /> : null}
      </GuideLayout>
    </>
  )
}

export default ProjectConsultation
