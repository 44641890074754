import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

import CalendlyInlineScheduler from 'components/shared/meeting/calendly-inline-scheduler'
import useAuth from 'context/auth/use'
import useMeeting from 'context/meeting/use'
import useProject from 'context/project/use'
import { Meeting } from 'types/meeting'

type MeetingBookerProps = {
  handleMeetingScheduled?: (meeting: Meeting) => void
}

const MeetingBooker = ({ handleMeetingScheduled }: MeetingBookerProps) => {
  const { user } = useAuth()
  const { startPolling, stopPolling, primaryOwner, allOwners } = useProject()
  const { meeting } = useMeeting()
  const [isPolling, setIsPolling] = useState(false)

  const currentOwner =
    allOwners.find((owner) => owner.email === user.email) ?? primaryOwner

  const handleEventScheduled = () => {
    setIsPolling(true)
    startPolling(2000)
  }

  useEffect(() => {
    if (meeting.startTime) {
      handleMeetingScheduled?.(meeting)
      stopPolling()
    }

    return () => {
      handleMeetingScheduled?.(meeting)
      stopPolling()
    }
  }, [meeting])

  return (
    <BookerContainer>
      <h2>Find a time</h2>
      {isPolling && (
        <p className="no-margin">
          Scheduled successfully. You'll be redirected shortly
        </p>
      )}
      <CalendlyInlineScheduler
        calendlyUrl={meeting.calendlyUrl}
        user={currentOwner}
        meetingId={meeting.id}
        onEventScheduled={handleEventScheduled}
        type={meeting.metadata.type}
      />
    </BookerContainer>
  )
}

const BookerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

export default MeetingBooker
