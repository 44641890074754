import React, { useEffect, useState } from 'react'

import TextareaAutosize from 'react-textarea-autosize'
import { Form, FormInputProps, Grid } from 'semantic-ui-react'

import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import useDelivery from 'context/delivery/use'
import useDeliveryIssueMutate from 'context/delivery/use-issue-mutate'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/app/components/form'
import { Issue, IssueData, ResolutionType } from 'types/issue'
import { ProjectFile, ProjectFileType } from 'types/project-file'

type DeliveryReportModalProps = {
  issue?: Issue
  open: boolean
  onClose: () => void
  onExited: () => void
  onOpen: () => void
}

export const DeliveryReportModal = ({
  issue,
  open,
  onClose,
  onExited,
  onOpen,
}: DeliveryReportModalProps) => {
  const { project } = useProject()
  const { delivery, refetch } = useDelivery()
  const {
    createIssueAndRefetch,
    loadingCreate,
    loadingUpdate,
    updateIssueAndRefetch,
  } = useDeliveryIssueMutate()

  const [state, setState] = useState<Partial<Issue>>({
    data: issue?.data || {
      customerDescription: '',
      positionNumber: '',
    },
    files: issue?.files || [],
  })

  useEffect(() => {
    setState({
      data: issue?.data || {
        customerDescription: '',
        positionNumber: '',
      },
      files: issue?.files || [],
    })
  }, [issue])

  const handleProjectFilesUploaded = (f: Partial<ProjectFile>[]) => {
    setState((currentState) => ({
      ...currentState,
      files: [...(f as ProjectFile[]), ...(currentState.files ?? [])],
    }))
  }

  const handleChangeInput = (
    _:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
    { name, value }: { name: string; value: string },
  ) =>
    setState({
      ...state,
      data: {
        ...state.data,
        [name]: value,
      },
    })

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLInputElement>) =>
    setState({
      ...state,
      data: {
        ...state.data,
        [e.target.name]: e.target.value,
      },
    })

  const handleCloseWithReset = () => {
    onClose()
    setState({
      data: {
        customerDescription: '',
        positionNumber: '',
      },
      files: [],
    })
  }

  const handleCreateUpdate = async () => {
    if (issue) {
      await updateIssueAndRefetch({
        variables: {
          data: {
            data: {
              ...state.data,
            },
            files: {
              set: state.files?.map((d) => ({ id: d.id })) ?? [],
            },
          },
          where: {
            id: issue.id ?? '',
          },
        },
      })
    } else {
      await createIssueAndRefetch({
        variables: {
          data: {
            data: state.data as IssueData,
            resolutionType: ResolutionType.PENDING,
            delivery: {
              connect: {
                id: delivery.id ?? '',
              },
            },
            files: {
              connect: state.files?.map((d) => ({ id: d.id })) ?? [],
            },
            project: {
              connect: {
                id: project.id,
              },
            },
          },
        },
      })
    }
    handleCloseWithReset()
  }

  return (
    <Modal
      open={open}
      title="Report Issue"
      onClose={onClose}
      saveButton={{
        onClick: handleCreateUpdate,
        text: issue ? 'Update' : 'Create',
        loading: loadingCreate || loadingUpdate,
        disabled: !state.data?.customerDescription,
      }}
      cancelButton={{
        onClick: handleCloseWithReset,
      }}
      onExited={onExited}
      onOpen={onOpen}
      size="medium"
      trigger={
        <Button
          kind="solid"
          text="Create report"
          fontAwesomeIcon="plus"
          iconAnimation="scale"
          size="large"
        />
      }
    >
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <StyledForm styled={{ whiteInputs: false }}>
              <Form.Input
                label="Position Number (POS)"
                name="positionNumber"
                onChange={handleChangeInput as FormInputProps['onChange']}
                value={state.data?.positionNumber}
              />
              <Form.Input
                label="Description"
                name="customerDescription"
                onChange={handleChangeTextArea}
                required
                value={state.data?.customerDescription}
                control={TextareaAutosize}
                placeholder="e.g. The rear cross member is cracked"
              />
            </StyledForm>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <p className="gray">
              Upload photos of the issue and corresponding ID sticker if
              applicable
            </p>
          </Grid.Column>
        </Grid.Row>
        <ProjectFileUploads
          columnsProps={{
            computer: 5,
            tablet: 8,
            mobile: 16,
          }}
          deliveryId={delivery.id}
          projectFiles={state.files ?? []}
          projectFileType={ProjectFileType.DAMAGE_PHOTO}
          onProjectFileRemoved={(f: Partial<ProjectFile>) => {
            setState((currentState) => ({
              ...currentState,
              files: currentState.files?.filter((f_) => f.id !== f_.id),
            }))
          }}
          onProjectFilesUploaded={handleProjectFilesUploaded}
          projectId={project.id}
          refetch={refetch}
        />
      </Grid>
    </Modal>
  )
}
