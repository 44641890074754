import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Divider, Grid } from 'semantic-ui-react'

import StepTitle from 'components/app/step-title'
import useCompany from 'context/company/use'
import { SecondaryCard } from 'design-system/components/cards'
import AppLayout from 'design-system/containers/app-layout'

const S3_ROOT = process.env.GATSBY_CDN_ROOT

const CompanyMain = (_: RouteComponentProps) => {
  const { company } = useCompany()

  return (
    <AppLayout>
      <Grid stackable>
        <StepTitle title="Company Information" />
        <Divider hidden />
        <Grid.Row className="row-gap">
          <Grid.Column computer={5} tablet={8} mobile={16}>
            <SecondaryCard
              basic
              onClick={() =>
                navigate(`/app/company/${company?.id}/information`)
              }
              title="Main Information"
              image={`${S3_ROOT}/images/app/illustrations/contact.svg`}
            />
          </Grid.Column>
          <Grid.Column computer={5} tablet={8} mobile={16}>
            <SecondaryCard
              basic
              onClick={() => navigate(`/app/company/${company?.id}/documents`)}
              title="Company Documents"
              image={`${S3_ROOT}/images/app/illustrations/company.svg`}
            />
          </Grid.Column>
          <Grid.Column computer={5} tablet={8} mobile={16}>
            <SecondaryCard
              basic
              onClick={() => navigate(`/app/company/${company?.id}/address`)}
              title="Address"
              image={`${S3_ROOT}/images/app/illustrations/location.svg`}
            />
          </Grid.Column>
          <Grid.Column computer={5} tablet={8} mobile={16}>
            <SecondaryCard
              basic
              onClick={() => navigate(`/app/company/${company?.id}/team`)}
              title="Team"
              image={`${S3_ROOT}/images/app/illustrations/team.svg`}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default CompanyMain
