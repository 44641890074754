import React from 'react'

import { formatDollars } from 'context/dollar-utils'
import FormLogo from 'icons/short-logo.svg'
import { Payment, PaymentMethodType } from 'types/payment'

import AwaitingInvoice from './awaiting-invoice'

interface PaymentConfirmationProps {
  payment: Partial<Payment>
}

const PaymentConfirmation = ({ payment }: PaymentConfirmationProps) => {
  return (
    <>
      {payment.status === 'succeeded' && (
        <>
          <FormLogo />
          <h2 style={{ fontSize: '30px', margin: '40px 0 6px' }}>
            {payment.description}
          </h2>
          <p className="light-gray small">
            We succesfully received your payment.
          </p>
          <p className="subtitle light-gray" style={{ margin: '40px 0 0' }}>
            Amount
          </p>
          <h3 className="no-margin" style={{ fontVariant: 'oldstyle-nums' }}>
            {formatDollars(payment.amount)}
          </h3>
          <h4 style={{ margin: '40px 0' }}>Thank you</h4>
        </>
      )}
      {payment.metadata?.method === PaymentMethodType.BANK_ACCOUNT &&
      payment.status === 'pending' ? (
        <h2>
          Your payment has been submitted. Please note ACH payments can take up
          to 48 hours to process.
        </h2>
      ) : null}
      {payment.metadata?.method === PaymentMethodType.INVOICE &&
      payment.status === 'pending' ? (
        <AwaitingInvoice />
      ) : null}
    </>
  )
}

export default PaymentConfirmation
