export const parseState = <T extends Record<string, unknown>>(
  state: unknown,
  defaultState?: T,
): T => {
  if (!state) {
    return defaultState ?? ({} as T)
  }

  return state as T
}

export const parseStateWithPrevious = <T extends Record<string, unknown>>(
  state: unknown,
  defaultState: T,
): T & { previousLocation: string } => {
  return parseState<T & { previousLocation: string }>(state, {
    previousLocation: '',
    ...defaultState,
  })
}
