import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Dimmer, Divider, Grid } from 'semantic-ui-react'
import styled, { css } from 'styled-components'

import DashedCard from 'components/app/dashed-card'
import CollaboratorModal from 'components/app/project/project-user/create-update-modal'
import StepTitle from 'components/app/step-title'
import ConfirmationModal from 'components/shared/confirmation-modal'
import Loader from 'components/shared/loader'
import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import useUserMutateNoContext from 'context/user/use-mutate-no-context'
import { collaboratorTypes } from 'context/user/utils'
import Button from 'design-system/components/button'
import AppLayout from 'design-system/containers/app-layout'
import { Breakpoint, Colors } from 'styles/app/system'
import { User, UserTypeHomeowner } from 'types/user'
import { getNameForUser } from 'views/utils'

const Collaborators = (
  props: RouteComponentProps<{
    location?: string
    project_id: string
  }>,
) => {
  const { user } = useAuth()
  const { allOwners, project, refetch } = useProject()
  const owners = allOwners.filter((o) => o.email !== user.email)

  const [showModal, toggleModal] = useState<boolean>(false)
  const [userToDelete, setUserToDelete] = useState<User>()
  const [editableUser, setEditableUser] = useState<User>()

  const { updateProjectAndRefetch, loadingUpdate: loadingUpdateProject } =
    useProjectMutate()
  const { updateUser, loadingUpdate: loadingUpdateUser } =
    useUserMutateNoContext()

  const handleOnDelete = async (userId: string) => {
    await updateProjectAndRefetch({
      variables: {
        data: {
          owners: {
            delete: [
              {
                projectId_userId: {
                  projectId: project.id,
                  userId: userId,
                },
              },
            ],
          },
        },
        where: {
          id: props.project_id ?? '',
        },
      },
    })
  }

  const handleOnSave = async (collaborator: User) => {
    if (collaborator.id) {
      await updateUser({
        variables: {
          data: {
            firstName: collaborator.firstName,
            lastName: collaborator.lastName,
            phone: collaborator.phone,
            type: collaborator.type,
          },
          where: {
            id: collaborator.id,
          },
        },
      })
      refetch()
      setEditableUser(undefined)
    } else
      await updateProjectAndRefetch({
        variables: {
          data: {
            owners: {
              create: [
                {
                  isSubscribed:
                    collaborator.type === UserTypeHomeowner.INSTALLER
                      ? false
                      : true,
                  user: {
                    create: {
                      email: collaborator.email,
                      firstName: collaborator.firstName,
                      lastName: collaborator.lastName,
                      phone: collaborator.phone,
                      type: collaborator.type,
                    },
                  },
                  userRole: collaborator.type,
                },
              ],
            },
          },
          where: {
            id: props.project_id ?? '',
          },
        },
      })
    toggleModal(false)
  }

  return (
    <AppLayout>
      <Grid centered stackable>
        <StepTitle
          moreInfo={`You can add anyone involved in your project. For example:
            <ul>
              <li>Your partner or family members</li>
              <li>Your installer or contractor</li>
              <li>Your architect or interior designer</li>
            </ul>
            `}
          subTitle="Is there anyone else involved in this project? Add them here to give them access. We strongly suggest adding your installer / contractor if possible."
          title="Adding someone else to your project?"
        />
        <Dimmer
          active={loadingUpdateUser || loadingUpdateProject}
          inverted
          style={{ background: 'rgba(250,250,250,0.85)' }}
        >
          <Loader />
        </Dimmer>
        <Divider hidden />
        <Grid.Row className="row-gap">
          <Grid.Column largeScreen={5} computer={8} tablet={8} mobile={16}>
            <DashedCard
              image={
                <div className="icon">
                  <FontAwesomeIcon icon={['fal', 'user']} />
                </div>
              }
              onClick={() => toggleModal(true)}
              title="New Collaborator"
              whiteCard
            />
          </Grid.Column>
          {owners.map((c) => (
            <Grid.Column
              largeScreen={5}
              computer={8}
              tablet={8}
              mobile={16}
              key={c.id}
            >
              <StyledCard>
                <div className="content">
                  <p className="bold no-margin">{getNameForUser(c)}</p>
                  <div className="row">
                    <FontAwesomeIcon icon={['fal', 'envelope']} />
                    <p style={{ wordBreak: 'break-word' }}>{c.email}</p>
                  </div>
                  <div className="row">
                    <FontAwesomeIcon
                      icon={[
                        'fal',
                        c.type === UserTypeHomeowner.INSTALLER
                          ? 'vest'
                          : 'user',
                      ]}
                    />
                    <p>{collaboratorTypes[c.type]}</p>
                  </div>
                  {c.phone ? (
                    <div className="row">
                      <FontAwesomeIcon icon={['fal', 'phone']} />
                      <p>{c.phone}</p>
                    </div>
                  ) : null}
                </div>
                <div className="actions">
                  <Button
                    fontAwesomeIcon="times"
                    kind="ghost"
                    onClick={() => setUserToDelete(c)}
                  />
                  <Button
                    fontAwesomeIcon="pencil"
                    kind="ghost"
                    onClick={() => {
                      setEditableUser(c)
                      toggleModal(true)
                    }}
                  />
                </div>
              </StyledCard>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
      <ConfirmationModal
        confirmText="Yes, delete"
        firstTitle={`Are you sure you want to delete ${getNameForUser(
          userToDelete,
        )}?`}
        loading={loadingUpdateProject}
        onCancel={() => setUserToDelete(undefined)}
        onConfirm={async () => {
          setUserToDelete(undefined)
          userToDelete?.id ? await handleOnDelete(userToDelete.id) : undefined
        }}
        open={!!userToDelete}
      />
      <CollaboratorModal
        collaborator={editableUser}
        loading={loadingUpdateUser || loadingUpdateProject}
        onClose={() => {
          toggleModal(false)
        }}
        onSave={(newCollaborator: User) => handleOnSave(newCollaborator)}
        open={showModal}
        onExited={() => {
          setEditableUser(undefined)
        }}
      />
    </AppLayout>
  )
}

export default Collaborators

const StyledCard = styled.div<{ styled?: { disabled?: boolean } }>`
  background: ${Colors.white};
  border: 1px solid ${Colors.gray100};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 100%;
  justify-content: space-between;
  padding: 18px 16px;

  ${({ styled }) =>
    styled?.disabled &&
    css`
      cursor: initial;
      pointer-events: none;
    `}

  div.content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    div.row {
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }

  div.actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media ${Breakpoint.onlyMobile} {
    height: unset;
  }
`
