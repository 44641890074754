import React from 'react'

import { Container, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import GenericOptionSelectionCard from 'components/shared/catalog/generic-option/card'
import RoomElementOptionSelectionCard from 'components/shared/catalog/room-element-option/card'
import useRoom from 'context/room/use'
import {
  SelectionCardExpandSelectProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import { GenericOption } from 'types/generic-option'
import { RoomElementOption } from 'types/room-element-option'

import SectionTitle from './section-title'

const Materials = () => {
  const { backsplashMaterials, countertopMaterials, floorMaterials } = useRoom()

  return (
    <MainContainer>
      <Container>
        <Grid centered>
          <SectionTitle
            title="Other finishes"
            subtitle="These are the flooring, countertop, and backsplash options selected for the initial design. These are not sold by FORM and are for design purposes only."
          />
          <Grid.Row style={{ rowGap: '30px' }}>
            {countertopMaterials?.map((g: Partial<GenericOption>) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Countertop</p>
                <GenericOptionSelectionCard
                  genericOption={g}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {floorMaterials?.map((r: Partial<RoomElementOption>) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Flooring</p>
                <RoomElementOptionSelectionCard
                  roomElementOption={r}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {backsplashMaterials?.map((g: Partial<GenericOption>) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Backsplash</p>
                <GenericOptionSelectionCard
                  genericOption={g}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Container>
    </MainContainer>
  )
}

export default Materials

const MainContainer = styled.section`
  padding: 70px 0;
`
