import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Divider, Grid } from 'semantic-ui-react'

import PrimaryCard from 'design-system/components/cards/primary-card'
import AppLayout from 'design-system/containers/app-layout'

interface InstallationMaterialProps {
  image: string
  title: string
  onClick: () => void
}

const Module = (_: RouteComponentProps) => {
  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  })

  const tradeResources: InstallationMaterialProps[] = [
    {
      image: 'prepare-installation-05.webp',
      title: 'Installation Guide',
      onClick: () => navigate('/app/installation-resources'),
    },
    {
      image: 'prepare-installation-01.webp',
      title: 'Installation Materials',
      onClick: () =>
        window.open(
          'https://formkitchens.notion.site/General-Installation-Manual-86ae3c71b079479293df8a388e98ae46',
        ),
    },
    {
      image: 'featured-installers-network.webp',
      title: 'Featured Installers',
      onClick: () => window.open('/featured-installers'),
    },
  ]

  return (
    <AppLayout>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3>Installation Materials</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row centered stretched className="row-gap">
          {tradeResources.map((c, key) => (
            <Grid.Column key={key} computer={4} tablet={8} mobile={16}>
              <PrimaryCard
                image={`${process.env.GATSBY_CDN_ROOT}/images/app/${c.image}`}
                onClick={c.onClick}
                title={c.title}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default Module
