import React from 'react'

import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

interface SectionTitle {
  subtitle?: string
  title: string
  children?: React.ReactNode
}

const SectionTitle = ({ children, subtitle, title }: SectionTitle) => {
  return (
    <GridRow>
      <Grid.Column textAlign="center" width={16}>
        <h2>{title}</h2>
        {children ? children : null}
        {subtitle && <p>{subtitle}</p>}
      </Grid.Column>
    </GridRow>
  )
}

export default SectionTitle

const GridRow = styled(Grid.Row)`
  margin-bottom: 30px;

  h2 {
    margin: 0 0 16px;
  }
`
