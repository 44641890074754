import React from 'react'

import { Container, Grid } from 'semantic-ui-react'

import OrderCard from 'components/app/project/order/card'
import PaymentConfirmation from 'components/app/project/payment/confirmation'
import { isDesignApproved } from 'context/design/utils'
import useOrder from 'context/order/use'
import useOrderMutate from 'context/order/use-mutate'
import Button from 'design-system/components/button'

const OrderCheckoutPaid = () => {
  const { order, paymentOrder, isAddOnOrder, designs } = useOrder()
  const { updateOrderAndRefetch, loadingUpdate } = useOrderMutate()

  return (
    <Grid.Column computer={8} tablet={16} mobile={16}>
      {!!paymentOrder && (
        <Container
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            padding: '50px 0',
          }}
          textAlign="center"
        >
          <PaymentConfirmation payment={paymentOrder} />
        </Container>
      )}
      <OrderCard order={order} showPayments />
      {isAddOnOrder && !order.placedAt && (
        <Button
          kind="solid"
          disabled={!designs?.every((d) => isDesignApproved(d))}
          loading={loadingUpdate}
          onClick={async () =>
            await updateOrderAndRefetch({
              variables: {
                data: {
                  placedAt: new Date(),
                },
                where: {
                  id: order.id ?? '',
                },
              },
            })
          }
          size="fluid"
          style={{ marginTop: '16px' }}
          text="Send to Production"
        />
      )}
    </Grid.Column>
  )
}

export default OrderCheckoutPaid
