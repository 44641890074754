import { useMemo } from 'react'

import { TimelineStep } from 'components/shared/timeline/steps'
import useProject from 'context/project/use'

const useTimeline = () => {
  const {
    roomPrimary,
    paymentProjectDepositPaid,
    paymentDesignDepositPaid,
    project,
    orderCabinetryPlaced,
    orderSamplesPlaced,
  } = useProject()

  const timelineStepsCompleted: Record<TimelineStep, boolean> = useMemo(() => {
    const steps: Record<TimelineStep, boolean> = {
      Delivery: false,
      OrderConfirmation: project.supplierOrders.some((so) => !!so.delivery),
      RefineDesign: !!orderCabinetryPlaced,
      InitialDesign: !!paymentProjectDepositPaid,
      Kickoff:
        !!paymentDesignDepositPaid &&
        !!roomPrimary?.data?.designBriefApprovedAt &&
        !!orderSamplesPlaced,
    }

    // if one step is completed, all previous steps are completed
    const stepsCompleted = Object.entries(steps).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: Object.values(acc).pop() || value,
      }),
      {} as Record<TimelineStep, boolean>,
    )

    return stepsCompleted
  }, [
    project.supplierOrders,
    orderCabinetryPlaced,
    paymentProjectDepositPaid,
    paymentDesignDepositPaid,
    roomPrimary?.data?.designBriefApprovedAt,
    orderSamplesPlaced,
  ])

  return { timelineStepsCompleted }
}

export default useTimeline
