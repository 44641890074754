import React from 'react'

import { navigate, RouteComponentProps } from '@reach/router'

import { getAddressStreetAndLocalityString } from 'context/address/utils'
import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import { parseOwners } from 'context/project/utils'
import { NavigationProps } from 'design-system/components/guide/navigation'
import GuideLayout from 'design-system/containers/guide-layout'
import { getNameForUser } from 'views/utils'

import CommentsMessages from './sections/comments-messages'
import ImportantItems from './sections/important-items'
import NextCall from './sections/next-call'
import NextSteps from './sections/next-steps'
import Timeline from './sections/timeline'
import UsefulLinks from './sections/useful-links'

const navItems: NavigationProps['items'] = [
  {
    linkTo: 'important-items',
    title: 'Has anything changed?',
    icon: ['fal', 'circle-exclamation'],
  },
  {
    linkTo: 'comments-messages',
    title: 'Review comments / messages',
    icon: ['fal', 'comment-exclamation'],
  },
  {
    linkTo: 'useful-links',
    title: 'Useful links',
    icon: ['fal', 'book-sparkles'],
  },
  {
    linkTo: 'timeline',
    title: 'Review timeline',
    icon: ['fal', 'calendar'],
  },
  {
    linkTo: 'next-steps',
    title: 'Next steps',
    icon: ['fal', 'clipboard-list-check'],
  },
  {
    linkTo: 'next-call',
    title: 'Schedule the next call',
    icon: ['fal', 'video-plus'],
  },
]

const tradeNavItems: NavigationProps['items'] = [
  {
    linkTo: 'comments-messages',
    title: 'Review comments / messages',
    icon: ['fal', 'comment-exclamation'],
  },
  {
    linkTo: 'useful-links',
    title: 'Useful links',
    icon: ['fal', 'book-sparkles'],
  },
  {
    linkTo: 'timeline',
    title: 'Review timeline',
    icon: ['fal', 'calendar'],
  },
  {
    linkTo: 'important-items',
    title: 'Has anything changed?',
    icon: ['fal', 'circle-exclamation'],
  },
  {
    linkTo: 'next-steps',
    title: 'Next steps',
    icon: ['fal', 'clipboard-list-check'],
  },
  {
    linkTo: 'next-call',
    title: 'Schedule the next call',
    icon: ['fal', 'video-plus'],
  },
]

const DesignReview = (_: RouteComponentProps) => {
  const { isFORMStaff } = useAuth()
  const { project, isTradeProject } = useProject()

  const { primaryOwner } = parseOwners(project)

  const items = isTradeProject ? tradeNavItems : navItems
  return (
    <>
      <GuideLayout
        guideId="design-review-page"
        coverProps={{
          address: getAddressStreetAndLocalityString(project.projectAddress),
          src: '/images/interactive-guides/design-review/design-review.webp',
          subTitle: getNameForUser(primaryOwner),
          title: 'Design Review',
        }}
        handleLogoClick={() => navigate(`/app/projects/${project.id}`)}
        introProps={{
          agenda: [
            {
              text: isTradeProject
                ? 'Review the latest design plan. Discuss your feedback and next steps.'
                : 'Review comments and messages, then agree on next steps to move towards finalizing your project.',
            },
          ],
        }}
        navItems={
          isFORMStaff ? items : items.filter((i) => i.linkTo !== 'next-call')
        }
      >
        {!isTradeProject && <ImportantItems />}
        <CommentsMessages />
        <UsefulLinks />
        <Timeline />
        {isTradeProject && <ImportantItems />}
        <NextSteps />
        {isFORMStaff ? <NextCall projectId={project.id} /> : null}
      </GuideLayout>
    </>
  )
}

export default DesignReview
