import React from 'react'

import { Divider, Grid, Image } from 'semantic-ui-react'

const Feedback = ({ noTitle }: { noTitle?: boolean }) => {
  return (
    <Grid centered stackable>
      {!noTitle ? (
        <>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h3 className="gray">
                We want you to love your new kitchen, please don’t be afraid to
                give honest feedback.
              </h3>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
        </>
      ) : null}
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Image
            className="up-mobile"
            src={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-presentation/rating-desktop.webp`}
          />
          <Image
            className="only-mobile"
            size="medium"
            src={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-presentation/rating-mobile.webp`}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Feedback
