import React from 'react'

import { RouteComponentProps } from '@reach/router'
import styled from 'styled-components'

import FrontsBrowser from 'components/shared/catalog/nobilia-feature/fronts-browser'
import HandlesBrowser from 'components/shared/catalog/nobilia-feature/handles-browser'
import * as NO from 'planner/objects/products/nobilia/options/definitions'
import { Breakpoint } from 'styles/app/system'

const CatalogNobiliaFeature = (
  props: RouteComponentProps<{
    catalog_id: string
    feature_no: string
  }>,
) => {
  const browser = () => {
    if (props.feature_no === NO.FRONT_COMBO)
      return <FrontsBrowser catalog={props.catalog_id} hideScroll={true} />
    else if (props.feature_no === NO.HANDLE_COMBINATION)
      return <HandlesBrowser catalog={props.catalog_id} hideScroll={true} />
  }

  return <ContainerDiv>{browser()}</ContainerDiv>
}

export default CatalogNobiliaFeature

const ContainerDiv = styled.div`
  @media ${Breakpoint.upFromMobile} {
    margin-top: 40px;
  }
`
