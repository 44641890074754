import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'

import Header from 'components/app/project/header/header-basic'
import useDelivery from 'context/delivery/use'
import AppLayout from 'design-system/containers/app-layout'
import { Issue } from 'types/issue'

import { DeliveryCreateReport } from './create-report'
import { DeliveryReportModal } from './report-modal'
import { DeliveryIssueReports } from './reports'

type DeliveryIssuesProps = RouteComponentProps<{
  project_id: string
  order_id: string
  delivery_id: string
  location: {
    state: {
      toResolutionOrders?: boolean
    }
  }
}>

const DeliveryIssues = ({
  navigate,
  project_id: projectId,
  order_id: orderId,
  location,
}: DeliveryIssuesProps) => {
  const { issues, refetch } = useDelivery()

  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [selectedIssue, setSelectedIssue] = useState<Issue>()

  const handleOpen = () => {
    setOpenCreateModal(true)
  }

  const handleEditIssue = (issue: Issue) => {
    setSelectedIssue(issue)
    handleOpen()
  }

  return (
    <AppLayout
      header={
        <Header
          onBack={() =>
            navigate?.(
              location?.state?.toResolutionOrders
                ? `/app/projects/${projectId}/add-ons-and-remedials`
                : `/app/projects/${projectId}/orders/${orderId}`,
            )
          }
        />
      }
    >
      {issues?.length ? (
        <DeliveryIssueReports
          issues={issues}
          projectId={projectId ?? ''}
          onEditIssue={handleEditIssue}
        />
      ) : (
        <DeliveryCreateReport />
      )}
      <div className="flex-center" style={{ marginTop: '24px' }}>
        <DeliveryReportModal
          open={openCreateModal}
          onClose={() => {
            setOpenCreateModal(false)
            refetch()
          }}
          onExited={() => setSelectedIssue(undefined)}
          issue={selectedIssue}
          onOpen={handleOpen}
        />
      </div>
    </AppLayout>
  )
}

export default DeliveryIssues
