import React from 'react'

import { useMedia } from 'react-use'
import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import FormVideo from 'components/app/form-video'
import Button from 'design-system/components/button'
import { Section } from 'design-system/components/guide/utils'
import { Breakpoint, Colors } from 'styles/app/system'

import BrandReviews from './components/brand-reviews'

const WhatPeopleSay = () => {
  const isDownFromComputer = useMedia(Breakpoint.downFromComputer)

  const openReviewsPage = () => {
    window.open('/reviews')
  }

  return (
    <Section id="people-say">
      <Grid centered>
        <Grid.Row>
          <Grid.Column>
            <Grid centered>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <h3 className="gray">What people are saying</h3>
                </Grid.Column>
              </Grid.Row>
              <Divider hidden />
              <Grid.Row>
                {isDownFromComputer ? (
                  <Grid.Column tablet={16}>
                    <YtVideo />
                  </Grid.Column>
                ) : (
                  <Grid.Column>
                    <VideoContainer>
                      <YtVideo />
                    </VideoContainer>
                  </Grid.Column>
                )}
              </Grid.Row>
              <Divider hidden />
              <Grid.Row>
                <Grid.Column>
                  <BrandReviews />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Button
                    color="blue"
                    fontAwesomeIcon="external-link"
                    kind="solid"
                    onClick={openReviewsPage}
                    text="Reviews Page"
                  />
                </Grid.Column>
              </Grid.Row>
              <Divider hidden />
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default WhatPeopleSay

const VideoContainer = styled.div`
  background-color: ${Colors.green900};
  border-radius: 4px;
  margin: 0 auto;
  max-width: 100%;
  position: relative;

  iframe {
    border-radius: 4px;
  }

  #igReviews {
    position: absolute;
    right: -20px;
    bottom: -20px;
    z-index: 1;
  }

  @media ${Breakpoint.downFromComputer} {
    height: 350px;
    margin-bottom: 40px;
  }
`

const YtVideo = () => {
  return (
    <FormVideo
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      aspectRatio="16:9"
      src="https://www.youtube.com/embed/Z6QkIkWKcM0"
      title="FORM Free Consultaion Guide"
    />
  )
}
