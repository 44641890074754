import React from 'react'

import { navigate } from 'gatsby'

import {
  SelectionCardExpandSelect,
  SelectionCardExpandSelectProps,
  SelectionCardProps,
} from 'design-system/components/selection-card'
import { FRONT_COMBO } from 'planner/objects/products/nobilia/options/definitions'
import { NobiliaSampleClass } from 'types/nobilia-sample-class'

export interface NobiliaSampleClassSelectionCardProps {
  catalog?: string
  nobiliaSampleClass: NobiliaSampleClass
  onExpand?: () => void
  selectionCardProps: SelectionCardProps
}

const NobiliaSampleClassSelectionCard = ({
  catalog,
  nobiliaSampleClass,
  onExpand,
  selectionCardProps,
}: NobiliaSampleClassSelectionCardProps) => {
  return (
    <SelectionCardExpandSelect
      description={nobiliaSampleClass.description}
      imageUrl={`${process.env.GATSBY_CDN_ROOT}/${nobiliaSampleClass?.imageUrl}`}
      title={nobiliaSampleClass.identifier}
      onExpand={() => {
        onExpand?.()
        navigate(
          `/app/catalog/${
            catalog || process.env.GATSBY_DEFAULT_CATALOG
          }/nobilia-features/${FRONT_COMBO}/nobilia-options-by-key/${
            nobiliaSampleClass.identifier
          }`,
        )
      }}
      {...(selectionCardProps as Omit<
        SelectionCardExpandSelectProps,
        'imageUrl'
      >)}
    />
  )
}
export default NobiliaSampleClassSelectionCard
