import React from 'react'

import { useMedia } from 'react-use'
import { Divider, Grid } from 'semantic-ui-react'

import NobiliaSampleClassSelectionCard from 'components/shared/catalog/nobilia-sample-class/card'
import useProject from 'context/project/use'
import { Section, Spacer } from 'design-system/components/guide/utils'
import { SelectionCardExpandSelectProps } from 'design-system/components/selection-card'
import { Breakpoint } from 'styles/app/system'

const Samples = () => {
  const isMobile = useMedia(Breakpoint.downFromTablet)
  const { ordersSamplesPlaced } = useProject()

  return (
    <Section id="samples">
      <Grid centered>
        <Spacer height={isMobile ? 50 : 200} />
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Your samples</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        {ordersSamplesPlaced?.map((o) => (
          <Grid.Row centered key={o.id}>
            {o.nobiliaSamples?.map((s) => {
              return (
                <Grid.Column key={s.id} computer={4} tablet={5} mobile={8}>
                  <NobiliaSampleClassSelectionCard
                    nobiliaSampleClass={s.productClass}
                    selectionCardProps={
                      {
                        imageFitContain: true,
                        imageRatio: '2/3',
                      } as Partial<SelectionCardExpandSelectProps>
                    }
                  />
                </Grid.Column>
              )
            })}
          </Grid.Row>
        ))}
        <Spacer height={isMobile ? 50 : 200} />
      </Grid>
    </Section>
  )
}

export default Samples
