import React, { useLayoutEffect } from 'react'

import { withAuthenticationRequired } from '@auth0/auth0-react'
import 'moment-timezone'
import { Router } from '@reach/router'

import ErrorBoundary from 'components/shared/error-boundary'
import SemanticUIFull from 'components/shared/semantic-ui-full'
import SEO from 'components/shared/seo'
import Tracking from 'components/shared/tracking'
import useAuth from 'context/auth/use'
import fireEvent from 'context/tracking/events'
import { StyledContent } from 'styles/app/system'
import CatalogIndex from 'views/app/catalog'
import CatalogFeature from 'views/app/catalog/catalog-feature'
import CatalogDesign from 'views/app/catalog/design'
import CatalogDisconnectedOption from 'views/app/catalog/disconnected-option'
import CatalogGenericOption from 'views/app/catalog/generic-option'
import CatalogNobiliaFeature from 'views/app/catalog/nobilia-feature'
import CatalogNobiliaOption from 'views/app/catalog/nobilia-option'
import CatalogRoomElementOption from 'views/app/catalog/room-element-option'
import ProjectIndex from 'views/app/project'
import AddOnsAndRemedials from 'views/app/project/add-ons-and-remedials'
import ProjectAddress from 'views/app/project/address'
import BuildZoomIndex from 'views/app/project/buildzoom'
import ProjectCallRecaps from 'views/app/project/call-recaps'
import ProjectCatalogs from 'views/app/project/catalogs'
import ProjectCollaborators from 'views/app/project/collaborators'
import DesignReview from 'views/app/project/design-review-guide'
import ExtraRoom from 'views/app/project/extra-room-guide'
import ProjectHome from 'views/app/project/home'
import MeetingIndex from 'views/app/project/meeting'
import Messages from 'views/app/project/messages/home'
import ProjectName from 'views/app/project/name'
import OrderIndex from 'views/app/project/order'
import DeliveryIndex from 'views/app/project/order/delivery'
import DeliveryFlowIndex from 'views/app/project/order/delivery/flow'
import DeliveryIssues from 'views/app/project/order/delivery/issues'
import Handover from 'views/app/project/order/handover-guide'
import OrderHome from 'views/app/project/order/home'
import OrderInstallation from 'views/app/project/order/installation'
import OrderPrepareDelivery from 'views/app/project/order/prepare-for-delivery'
import OrderReceipt from 'views/app/project/order/receipt'
import Payment from 'views/app/project/payment'
import PaymentByType from 'views/app/project/payment/index-by-type'
import Payments from 'views/app/project/payment/index-many'
import ProjectFileIndex from 'views/app/project/project-file'
import ProjectPaymentThankYouPage from 'views/app/project/project-payment-thank-you'
import ProjectProposal from 'views/app/project/project-proposal-guide'
import RoomIndex from 'views/app/project/room'
import RoomAppliances from 'views/app/project/room/appliances'
import CreateRoom from 'views/app/project/room/create-room'
import DesignIndex from 'views/app/project/room/design'
import DesignPresentation from 'views/app/project/room/design/design-presentation-guide'
import DesignPlan from 'views/app/project/room/design/plan'
import DesignVisualQuote from 'views/app/project/room/design/visual-quote'
import RoomDesignBrief from 'views/app/project/room/design-brief'
import DesignCall from 'views/app/project/room/design-call-guide'
import RoomFloorPlans from 'views/app/project/room/floor-plans'
import { CompleteInformationFlowIndex } from 'views/app/project/room/flows/complete-info-flow'
import { ExtraRoomFlowIndex } from 'views/app/project/room/flows/extra-room-flow'
import { StartProjectFlowIndex } from 'views/app/project/room/flows/start-project-flow'
import { StartTradeProjectFlowIndex } from 'views/app/project/room/flows/start-trade-project-flow'
import RoomHome from 'views/app/project/room/home'
import RoomInspirationImages from 'views/app/project/room/inspiration-images'
import RoomMeasurements from 'views/app/project/room/measurements'
import RoomPhotos from 'views/app/project/room/photos'
import ProjectConsultation from 'views/app/project/room/project-consultation-guide'
import RoomRedirect from 'views/app/project/room/redirect'
import SelectionIndex from 'views/app/project/room/selection'
import SelectionAppliance from 'views/app/project/room/selection/selection-appliances'
import SelectionBacksplash from 'views/app/project/room/selection/selection-backsplash'
import SelectionCountertops from 'views/app/project/room/selection/selection-countertops'
import SelectionOptionsDesignCall from 'views/app/project/room/selection/selection-design-call'
import SelectionFlooring from 'views/app/project/room/selection/selection-flooring'
import SelectionFronts from 'views/app/project/room/selection/selection-fronts'
import SelectionHandles from 'views/app/project/room/selection/selection-handles'
import SelectionInspiration from 'views/app/project/room/selection/selection-inspiration'
import SelectionOptionsProjectConsultation from 'views/app/project/room/selection/selection-project-consultation'
import SamplesIndex from 'views/app/project/samples'
import SamplesCheckout from 'views/app/project/samples/checkout'
import SamplesHome from 'views/app/project/samples/home'
import Picker from 'views/app/project/samples/picker'
import Scheduling from 'views/app/project/scheduling'
import ProjectTimeline from 'views/app/project/timeline'
import UserIndex from 'views/app/user'
import Catalogs from 'views/app/user/catalogs'
import CompanyIndex from 'views/app/user/company'
import CompanyAddress from 'views/app/user/company/address'
import CompanyDocuments from 'views/app/user/company/documents'
import CompanyHome from 'views/app/user/company/home'
import CompanyInformation from 'views/app/user/company/information'
import CompanyMain from 'views/app/user/company/main'
import CompanyMessages from 'views/app/user/company/messages'
import CompanyScheduleCall from 'views/app/user/company/schedule-call'
import CompanyTeam from 'views/app/user/company/team'
import UserCreateProject from 'views/app/user/create-project'
import UserHome from 'views/app/user/home'
import InstallationMaterials from 'views/app/user/installation-materials'
import InstallationResources from 'views/app/user/installation-resources'
import UserProfile from 'views/app/user/profile'
import UserRedirect from 'views/app/user/redirect'
import Timeline from 'views/app/user/timeline'

const App = () => {
  const { isAuthenticated, isFORMOrExternalStaff, hubspotId, user } = useAuth()
  const isEnvTracked =
    process.env.GATSBY_ACTIVE_ENV === 'production' ||
    process.env.GATSBY_ACTIVE_ENV === 'stage'

  useLayoutEffect(() => {
    if (isAuthenticated)
      fireEvent({
        type: 'identify',
        id: hubspotId,
        email: user.email,
      })
  }, [isAuthenticated])

  return (
    <StyledContent>
      <SemanticUIFull />
      <Tracking
        segment={!!isEnvTracked}
        fullStory={
          process.env.GATSBY_ACTIVE_ENV === 'production' &&
          isAuthenticated &&
          !isFORMOrExternalStaff
        }
      />
      <ErrorBoundary>
        <Router>
          <UserIndex path="/app">
            <UserRedirect default />
            <UserHome path="home" />
            <UserCreateProject path="create-project" />
            <UserProfile path="profile" />
            <CatalogIndex path="catalog/:catalog_id">
              <CatalogDisconnectedOption path="disconnected-features/:feature_identifier/disconnected-options/:option_id" />
              <CatalogFeature path="catalog-features/:feature_id" />
              <CatalogDesign path="designs/:design_id" />
              <CatalogGenericOption path="generic-features/:feature_identifier/generic-options/:option_id" />
              <CatalogNobiliaFeature path="nobilia-features/:feature_no" />
              <CatalogNobiliaOption path="nobilia-features/:feature_no/nobilia-options/:option_id" />
              <CatalogNobiliaOption path="nobilia-features/:feature_no/nobilia-options-by-key/:option_key" />
              <CatalogRoomElementOption path="room-element-features/:feature_identifier/room-element-options/:option_id" />
            </CatalogIndex>
            <CompanyIndex path="company/:company_id">
              <CompanyHome default />
              <CompanyMessages path="messages" />
              <CompanyMain path="main" />
              <CompanyAddress path="address" />
              <CompanyDocuments path="documents" />
              <CompanyInformation path="information" />
              <CompanyTeam path="team" />
              <CompanyScheduleCall path="schedule-call" />
            </CompanyIndex>
            <Catalogs path="catalogs" />
            <InstallationMaterials path="installation-materials" />
            <InstallationResources path="installation-resources" />
            <Timeline path="timeline" />
            <ProjectIndex path="projects/:project_id">
              <AddOnsAndRemedials path="add-ons-and-remedials" />
              <BuildZoomIndex path="buildzoom/*" />
              <CreateRoom path="create-room" />
              <DesignReview path="design-review" />
              <ExtraRoom path="extra-room" />
              <MeetingIndex path="meeting" />
              <Messages path="messages" />
              <OrderIndex path="orders/:order_id">
                <OrderHome path="/" />
                <DeliveryIndex path="deliveries/:delivery_id">
                  <DeliveryIssues path="issues" />
                  <DeliveryFlowIndex path="confirm-delivery/*" />
                </DeliveryIndex>
                <Handover path="handover" />
                <OrderInstallation path="installation-resources" />
                <OrderPrepareDelivery path="prepare-delivery" />
                <OrderReceipt path="receipt" />
              </OrderIndex>
              <Payment path="payments/:payment_id" />
              <PaymentByType path="payments-by-type/:payment_type" />
              <Payments path="deposits" type="deposits" />
              <Payments path="payments" type="payments" />
              <Payments path="order-payments" type="order-payments" />
              <ProjectAddress path="project-address" />
              <ProjectCallRecaps path="call-recaps" />
              <ProjectCatalogs path="catalogs" />
              <ProjectCollaborators path="collaborators" />
              <ProjectFileIndex path="files/:file_id" />
              <ProjectHome default path="home" />
              <ProjectName path="project-name" />
              <ProjectPaymentThankYouPage path="payment-thank-you" />
              <ProjectProposal path="project-proposal" />
              <ProjectTimeline path="timeline" />
              <RoomIndex path="rooms/:room_id">
                <RoomRedirect default />
                <RoomHome path="home" />
                <RoomAppliances path="appliances" />
                <RoomDesignBrief path="design-brief" />
                <RoomFloorPlans path="plans" />
                <RoomInspirationImages path="inspiration-images" />
                <RoomMeasurements path="measurements/:file_id" />
                <RoomPhotos path="photos" />
                <DesignIndex path="designs/:design_id">
                  <DesignVisualQuote path="quote" />
                  <DesignPresentation path="design-presentation" />
                  <DesignPlan path="plan/:file_id" />
                </DesignIndex>
                <DesignCall path="design-call" />
                <ProjectConsultation path="project-consultation" />
                <SelectionIndex path="selection">
                  <SelectionAppliance path="appliance-selection" />
                  <SelectionBacksplash path="backsplash-selection" />
                  <SelectionCountertops path="countertop-selection" />
                  <SelectionFlooring path="flooring-selection" />
                  <SelectionFronts path="front-selection" />
                  <SelectionHandles path="handle-selection" />
                  <SelectionInspiration path="inspiration-selection" />
                  <SelectionOptionsDesignCall path="selection-options-design-call" />
                  <SelectionOptionsProjectConsultation path="selection-options-project-consultation" />
                </SelectionIndex>
                <StartProjectFlowIndex path="start-project/*" />
                <StartTradeProjectFlowIndex path="start-trade-project/*" />
                <ExtraRoomFlowIndex path="extra-room/*" />
                <CompleteInformationFlowIndex path="complete-information/*" />
              </RoomIndex>
              <SamplesIndex path="sample-orders">
                <SamplesHome default />
                <SamplesCheckout path="checkout" />
                <Picker path=":order_id" />
              </SamplesIndex>
              <Scheduling path="scheduling" />
              <UserProfile path="profile" />
            </ProjectIndex>
          </UserIndex>
        </Router>
      </ErrorBoundary>
    </StyledContent>
  )
}

export default withAuthenticationRequired(App)

export const Head = () => (
  <SEO title="FORM Projects" description="FORM project view" />
)
