import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import Header from 'components/app/project/header/header-basic'
import { isDesignApproved } from 'context/design/utils'
import useOrder from 'context/order/use'
import AppLayout from 'design-system/containers/app-layout/app-layout'

import OrderCheckout from './checkout'
import OrderPlaced from './placed'

export default (
  props: RouteComponentProps<{
    project_id: string
    order_id: string
    location: {
      state: {
        fromHandoverGuide?: boolean
        toResolutionOrders?: boolean
      }
    }
  }>,
) => {
  const { fromHandoverGuide, toResolutionOrders } = props.location?.state || {}
  const { order, isAddOnOrder, designs } = useOrder()

  useEffect(() => {
    if (toResolutionOrders)
      navigate(`/app/projects/${props.project_id}/add-ons-and-remedials`)
    if (
      isAddOnOrder &&
      !!order.placedAt &&
      designs?.every((d) => isDesignApproved(d))
    )
      navigate(`/app/projects/${props.project_id}/add-ons-and-remedials`)
  }, [order.placedAt])

  return (
    <AppLayout
      header={
        <Header
          onBack={() =>
            fromHandoverGuide
              ? navigate(
                  `/app/projects/${props.project_id}/orders/${props.order_id}/handover#orders`,
                )
              : navigate(`/app/projects/${props.project_id}/home`)
          }
        />
      }
    >
      {order.placedAt ? <OrderPlaced /> : <OrderCheckout />}
    </AppLayout>
  )
}
