import React, { useState, useEffect, useRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { Search, SearchResultData, SearchProps } from 'semantic-ui-react'
import styled from 'styled-components'

import { Colors } from 'styles/app/system'
import { Address } from 'types/utils'

import { addressComponentsToAddress } from './parser'
import ScriptLoader from './places-api-loader'

export interface PlaceResult {
  title: string
  placeid: string
}

export interface AutocompleteProps {
  defaultAddress?: string
  onAddressSelected: (a: Address) => void
}

let autoCompleteService: google.maps.places.AutocompleteService
let placesService: google.maps.places.PlacesService
let sessionToken: google.maps.places.AutocompleteSessionToken

const AddressesAutocomplete = (props: AutocompleteProps) => {
  const placesDiv = useRef<HTMLDivElement>(null)
  useEffect(() => {
    autoCompleteService = new google.maps.places.AutocompleteService()
    placesService = new google.maps.places.PlacesService(
      placesDiv.current as HTMLDivElement,
    )
    sessionToken = new google.maps.places.AutocompleteSessionToken()
  }, [])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [value, setValue] = useState(props.defaultAddress)
  const [results, setResults] = useState<PlaceResult[]>([])

  const onResultSelect = (
    _: React.MouseEvent,
    { result }: SearchResultData,
  ) => {
    setValue(result.title)
    placesService.getDetails(
      { placeId: result.placeid, sessionToken },
      (r, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          props.onAddressSelected(
            addressComponentsToAddress(r.address_components ?? []),
          )
          sessionToken = new google.maps.places.AutocompleteSessionToken()
        }
      },
    )
  }

  const onSearchChange = (
    _: React.MouseEvent,
    { value: valueSearch }: SearchProps,
  ) => {
    setIsLoading(true)
    setValue(valueSearch)

    if ((valueSearch ?? '').length < 3) {
      setIsLoading(false)
      setResults([])
      return
    }

    if (isLoading) return

    autoCompleteService.getPlacePredictions(
      {
        input: valueSearch ?? '',
        sessionToken,
        types: ['address'],
      },
      (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const resultsAsPlaceResults = results.map((r) => ({
            title: r.description,
            placeid: r.place_id,
          }))
          setIsLoading(false)
          setResults(resultsAsPlaceResults)
        }
      },
    )
  }

  return (
    <>
      <div ref={placesDiv} />
      <StyledSearch
        fluid
        icon={<FontAwesomeIcon icon={['fal', 'map-marker-alt']} />}
        loading={isLoading}
        placeholder="Start typing your address"
        onResultSelect={onResultSelect}
        onSearchChange={_.debounce(onSearchChange, 500, {
          leading: true,
        })}
        results={results}
        value={value}
      />
    </>
  )
}

const AutocompleteAddresses = (props: AutocompleteProps) => (
  <ScriptLoader>
    <AddressesAutocomplete {...props} />
  </ScriptLoader>
)

export default AutocompleteAddresses

const StyledSearch = styled(Search)`
  &&& {
    svg {
      font-size: 16px;
      position: absolute;
      right: 17px;
    }

    .results {
      background: #ffffff;
      border: none;
      box-shadow: none;
      border-radius: 8px;

      .result {
        border: none;

        :hover {
          background: ${Colors.gray50};
        }
      }

      .result .title,
      .message .header {
        font-family: 'GT America' !important;
        font-size: 15px;
        font-weight: normal;
        line-height: 175%;
      }
    }
  }
`
