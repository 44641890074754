import { Font, StyleSheet } from '@react-pdf/renderer'

import { Colors } from 'styles/app/system'

Font.register({
  family: 'GT America',
  fontWeight: 300,
  src: `${process.env.GATSBY_CDN_ROOT}/assets/GT-America-Standard-Light.woff2`,
})

Font.register({
  family: 'GT Super',
  fontWeight: 300,
  src: `${process.env.GATSBY_CDN_ROOT}/assets/GT-Super-Display-Light.woff2`,
})

export const ReceiptStyles = StyleSheet.create({
  body: {
    color: Colors.black,
    fontFamily: 'GT America',
    fontSize: '11px',
    fontWeight: 100,
    padding: '35 35 65',
    lineHeight: 1.5,
  },
  cell: {
    fontSize: 10,
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 10,
    textAlign: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    margin: 20,
  },
  footer: {
    borderTop: `1px solid ${Colors.black}`,
    bottom: 0,
    padding: 8,
    paddingLeft: 20,
    position: 'absolute',
    width: 600,
  },
  grayRow: {
    backgroundColor: Colors.gray50,
  },
  halfRow: {
    alignSelf: 'flex-end',
    borderTop: `1px solid ${Colors.black}`,
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 200,
  },
  mainCell: {
    minWidth: 200,
    textAlign: 'left',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: 25,
  },
  rowBody: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 35,
    flexWrap: 'nowrap',
  },
  rowHeader: {
    borderBottom: `1px solid ${Colors.black}`,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 24,
    fontFamily: 'GT Super',
  },
})
