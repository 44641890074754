import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import HexImage from 'design-system/components/guide/hex-image'
import { Section } from 'design-system/components/guide/utils'

const CustomerSuccess = () => {
  return (
    <Section id="customer-success">
      <Grid relaxed="very" centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">
              Meet our wonderful customer success team who will continue to take
              care of you going forward.
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row centered>
          <Grid.Column
            largeScreen={3}
            computer={4}
            tablet={5}
            mobile={8}
            textAlign="center"
          >
            <HexImage
              rotated
              src={`${process.env.GATSBY_CDN_ROOT}/images/app/designers/charlie.webp`}
            />
            <p style={{ margin: '16px 0' }}>Charlie</p>
          </Grid.Column>
          <Grid.Column
            largeScreen={3}
            computer={4}
            tablet={5}
            mobile={8}
            textAlign="center"
          >
            <HexImage
              rotated
              src={`${process.env.GATSBY_CDN_ROOT}/images/app/designers/rob.webp`}
            />
            <p style={{ margin: '16px 0' }}>Rob</p>
          </Grid.Column>
          <Grid.Column
            largeScreen={3}
            computer={4}
            tablet={5}
            mobile={8}
            textAlign="center"
          >
            <HexImage
              rotated
              src={`${process.env.GATSBY_CDN_ROOT}/images/app/designers/nick.webp`}
            />
            <p style={{ margin: '16px 0' }}>Nick</p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default CustomerSuccess
