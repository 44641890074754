import React from 'react'

import { Grid, Image } from 'semantic-ui-react'

import SelectImageCard from 'design-system/components/cards/selection/select-image-card'

export const DeliveryCreateReport = () => {
  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <Image
            circular
            centered
            src={`${process.env.GATSBY_CDN_ROOT}/images/app/charlie.png`}
            style={{ width: '50px', marginBottom: '8px' }}
          />
          <p>Charlie Ward, Customer Success</p>
          <h3>
            We are sorry there are issues, please create a report so we can
            solve them
          </h3>
          <p>
            Show us in detail the condition of all palettes and packages. This
            will help us protect your order if anything is damaged.
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="center">
          <p className="overline">
            get your phone camera ready, we’ll need pictures like these:
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column computer={4} tablet={6} mobile={8}>
          <SelectImageCard
            image={`${process.env.GATSBY_CDN_ROOT}/images/app/id-sticker.webp`}
            text="ID Sticker"
            noHover
          />
        </Grid.Column>
        <Grid.Column computer={4} tablet={6} mobile={8}>
          <SelectImageCard
            image={`${process.env.GATSBY_CDN_ROOT}/images/app/damage.webp`}
            text="Issue photo"
            noHover
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
