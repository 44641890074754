import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import { parseMedia } from 'context/media/utils-many'
import RoomElementOptionProvider from 'context/room-element-option/provider'
import useRoomElementOption from 'context/room-element-option/use'

import { Slider, StyledGrid } from './utils'

const Module = () => {
  const { roomElementOption } = useRoomElementOption()
  const { reference, imageClose, imageMedium } = parseMedia(
    roomElementOption.media,
  )
  const gallery = [...reference, ...imageClose, ...imageMedium].slice(0, 4)

  return (
    <StyledGrid stackable>
      <Grid.Row>
        <Grid.Column computer={10} tablet={9} mobile={16}>
          <Slider items={gallery} />
        </Grid.Column>
        <Grid.Column computer={5} tablet={6} mobile={16}>
          <h2>{roomElementOption.description}</h2>
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  )
}

export default (
  props: RouteComponentProps<{
    option_id: string
    feature_id: string
  }>,
) => {
  return (
    <RoomElementOptionProvider room_element_option_id={props.option_id}>
      <Module />
    </RoomElementOptionProvider>
  )
}
