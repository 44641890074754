import React, { useEffect, useMemo, useState } from 'react'

import { Router } from '@reach/router'

import { NavFooter } from 'components/app/nav-footer'
import Header from 'components/app/project/header/header-basic'
import Loader from 'components/shared/loader'
import useDelivery from 'context/delivery/use'
import { StepName } from 'design-system/containers/flow-layout'
import FlowLayout from 'design-system/containers/flow-layout/flow-layout'

import {
  DELIVERY_FLOW_STEPS,
  DELIVERY_FLOW_STEPS_ARRAY as STEPS,
} from './steps'
import { DeliveryFlowRouteProps } from './types'
import { DeliveryThankYouView } from './views/thank-you'

type DeliveryFlowIndexProps = DeliveryFlowRouteProps

const DeliveryFlowIndex = ({
  path: _,
  ...routeProps
}: DeliveryFlowIndexProps) => {
  const {
    '*': subroute,
    project_id: projectId,
    order_id: orderId,
    delivery_id: deliveryId,
    location,
    navigate,
  } = routeProps
  const [nextDisabled, setNextDisabled] = useState(true)
  const [onNext, setOnNext] = useState<() => Promise<string | void>>()
  const { delivery } = useDelivery()

  const currentStepIndex = useMemo(
    () => STEPS.findIndex((step) => step.subroute === subroute),
    [subroute],
  )

  const isFirstStep = currentStepIndex <= 0
  const isLastStep = currentStepIndex >= STEPS.length - 1

  useEffect(() => {
    if (delivery?.data?.damagesSelection === 'damaged') {
      navigate?.(
        `/app/projects/${projectId}/orders/${orderId}/deliveries/${deliveryId}/issues`,
      )
      return
    }
    if (!delivery?.data?.damagesSelection) {
      navigate?.(
        `/app/projects/${projectId}/orders/${orderId}/deliveries/${deliveryId}/confirm-delivery/${DELIVERY_FLOW_STEPS.orderStatus.subroute}`,
      )
      return
    }
    if (!delivery?.data?.palletesCompletedAt) {
      navigate?.(
        `/app/projects/${projectId}/orders/${orderId}/deliveries/${deliveryId}/confirm-delivery/${DELIVERY_FLOW_STEPS.photos.subroute}`,
      )
      return
    }

    if (subroute !== 'thank-you') {
      navigate?.(
        `/app/projects/${projectId}/orders/${orderId}/deliveries/${deliveryId}/confirm-delivery/thank-you`,
      )
    }
  }, [])

  const handleNext = async () => {
    const redirectOnNext = await onNext?.()
    if (redirectOnNext) {
      navigate?.(redirectOnNext, { state: location?.state })
      return
    }
    if (!isLastStep) {
      navigate?.(
        `/app/projects/${projectId}/orders/${orderId}/deliveries/${deliveryId}/confirm-delivery/${
          STEPS[currentStepIndex + 1].subroute
        }`,
      )
    } else {
      //* Go to thank you page
      navigate?.(
        `/app/projects/${projectId}/orders/${orderId}/deliveries/${deliveryId}/confirm-delivery/thank-you`,
        { state: location?.state },
      )
    }
  }

  if (subroute === 'thank-you') {
    return <DeliveryThankYouView {...routeProps} />
  }

  if (currentStepIndex === -1) {
    return <Loader />
  }

  return (
    <FlowLayout
      header={
        <Header
          onBack={() =>
            navigate?.(
              location?.state?.toResolutionOrders
                ? `/app/projects/${projectId}/resolution-orders`
                : `/app/projects/${projectId}/orders/${orderId}`,
            )
          }
        />
      }
      main={
        <>
          <StepName>{STEPS[currentStepIndex].label}</StepName>
          <Router>
            {STEPS.map(({ View, subroute }) => (
              <View
                key={subroute}
                path={subroute}
                setOnNext={setOnNext}
                setNextDisabled={setNextDisabled}
                {...routeProps}
              />
            ))}
          </Router>
        </>
      }
      actions={
        <NavFooter
          hideBack={isFirstStep}
          backDisabled={isFirstStep}
          nextDisabled={nextDisabled}
          onBack={() =>
            navigate?.(
              `/app/projects/${projectId}/orders/${orderId}/deliveries/${deliveryId}/confirm-delivery/${
                STEPS[currentStepIndex - 1].subroute
              }`,
            )
          }
          onNext={handleNext}
          nextText={isLastStep ? 'Finish' : 'Continue'}
        />
      }
      stepperProps={{
        currentStep: currentStepIndex,
        steps: STEPS,
      }}
    />
  )
}

export default DeliveryFlowIndex
