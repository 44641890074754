import React from 'react'

import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import RoomCard from 'design-system/components/cards/room-card'
import SelectionCover from 'design-system/components/guide/selection-cover'
import { Section } from 'design-system/components/guide/utils'
import { RoomType, RoomTypeImage } from 'types/room'

const AdditionalRooms = () => {
  const { isFORMStaff } = useAuth()
  const { roomsAdditional, project } = useProject()

  return (
    <Section id="add-rooms">
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Additional rooms</h3>
          </Grid.Column>
        </Grid.Row>
        {roomsAdditional ? (
          <>
            <Grid.Row className="row-gap">
              {roomsAdditional.map((r) => {
                const typeIndex = Object.values(RoomType).indexOf(r.type)

                return (
                  <Grid.Column key={r.id} computer={5} tablet={8} mobile={16}>
                    <RoomCard
                      color={r.data.tagColor}
                      image={`${
                        process.env.GATSBY_CDN_ROOT
                      }/images/app/illustrations/${
                        Object.values(RoomTypeImage)[typeIndex]
                      }`}
                      noActions
                      subtitle={r.data?.descriptionAdditional}
                      title={r.type}
                    />
                  </Grid.Column>
                )
              })}
            </Grid.Row>
            {isFORMStaff ? (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Button
                    color="blue"
                    fontAwesomeIcon="arrow-right"
                    kind="solid"
                    onClick={() =>
                      navigate(`/app/projects/${project.id}/create-room`)
                    }
                    text="Edit Rooms"
                    size="medium"
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}
          </>
        ) : (
          <Grid.Row>
            <Grid.Column>
              <SelectionCover
                buttonText="Create"
                image="extra-room/additional-rooms.svg"
                onClick={() =>
                  navigate(`/app/projects/${project.id}/create-room`)
                }
                title="Add rooms"
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Section>
  )
}

export default AdditionalRooms
