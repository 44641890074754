import React from 'react'

import styled from 'styled-components'

import PaymentConfirmation from 'components/app/project/payment/confirmation'
import { Payment } from 'types/payment'

import { ReceiptPDFDownload } from '../receipt'

const Paid = ({
  noReceipt,
  payment,
}: {
  noReceipt?: boolean
  payment: Payment
}) => (
  <PaymentContainer>
    <PaymentConfirmation payment={payment} />
    {!noReceipt ? <ReceiptPDFDownload payment={payment} /> : null}
  </PaymentContainer>
)

export default Paid

const PaymentContainer = styled.div`
  align-items: center;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 15px -1px rgba(120, 113, 108, 0.09);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 420px;
  padding: 50px 16px;
  text-align: center;
  width: 100%;

  p {
    margin: 0;
  }
`
