import React from 'react'

import { Container, Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import DisconnectedOptionSelectionCard from 'components/shared/catalog/disconnected-option/card'
import NobiliaOptionSelectionCard from 'components/shared/catalog/nobilia-option/card'
import useRoom from 'context/room/use'
import {
  SelectionCardVariation,
  SelectionCardExpandSelectProps,
} from 'design-system/components/selection-card'
import { Breakpoint } from 'styles/app/system'

import SectionTitle from './section-title'

const Finishes = () => {
  const {
    carcaseColorsExterior,
    carcaseColorsInterior,
    fronts,
    glassColors,
    glassDesigns,
    glassFrameColors,
    glassFrameDesigns,
    handles,
    nobiliaCarcaseExteriorsDiscontinued,
    nobiliaFrontsDiscontinued,
    nobiliaHandlesDiscontinued,
    nobiliaPlinthColorsDiscontinued,
    plinthColors,
  } = useRoom()

  return (
    <MainContainer>
      <Container>
        <Grid centered>
          <SectionTitle
            title="FORM finishes"
            subtitle="These are the FORM cabinet finishes selected for the initial design."
          />
          <Grid.Row style={{ rowGap: '30px' }}>
            {fronts?.map((f) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Front</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={f}
                  selectionCardProps={
                    {
                      imageRatio: '2/3',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {nobiliaFrontsDiscontinued?.map((f) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Front (discontinued)</p>
                <DisconnectedOptionSelectionCard
                  disconnectedOption={f}
                  selectionCardProps={
                    {
                      imageRatio: '2/3',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {handles?.map((h) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Handle</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={h}
                  selectionCardProps={
                    {
                      imageRatio: '3/2',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {nobiliaHandlesDiscontinued?.map((f) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Handle (discontinued)</p>
                <DisconnectedOptionSelectionCard
                  disconnectedOption={f}
                  selectionCardProps={
                    {
                      imageRatio: '3/2',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {carcaseColorsExterior?.map((ce) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Carcase exterior</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={ce}
                  selectionCardProps={
                    {
                      imageRatio: '2/3',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {nobiliaCarcaseExteriorsDiscontinued?.map((f) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">
                  Carcase exterior (discontinued)
                </p>
                <DisconnectedOptionSelectionCard
                  disconnectedOption={f}
                  selectionCardProps={
                    {
                      imageRatio: '2/3',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {carcaseColorsInterior?.map((ci) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Carcase interior</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={ci}
                  selectionCardProps={
                    {
                      imageRatio: '2/3',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {plinthColors?.map((pc) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Toe kick</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={pc}
                  selectionCardProps={
                    {
                      imageRatio: '2/3',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {nobiliaPlinthColorsDiscontinued?.map((f) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Toe kick (discontinued)</p>
                <DisconnectedOptionSelectionCard
                  disconnectedOption={f}
                  selectionCardProps={
                    {
                      imageRatio: '2/3',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {glassFrameDesigns?.map((fd) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Frame design</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={fd}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {glassFrameColors?.map((fc) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Frame color</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={fc}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {glassColors?.map((gc) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Glass color</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={gc}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {glassDesigns?.map((gd) => (
              <Grid.Column
                computer={4}
                tablet={5}
                mobile={8}
                textAlign="center"
              >
                <p className="no-margin caption">Glass design</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={gd}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Container>
      <Divider />
    </MainContainer>
  )
}

export default Finishes

const MainContainer = styled.section`
  .container {
    padding: 70px 0;
  }

  div.divider {
    margin: 0 80px;
    @media ${Breakpoint.onlyMobile} {
      margin: 0;
    }
  }
`
