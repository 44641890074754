import React from 'react'

import moment from 'moment'
import { useMedia } from 'react-use'
import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import StepTitle from 'components/app/step-title'
import CatalogFeatureSelectionCard from 'components/shared/catalog/catalog-feature/card'
import GenericOptionSelectionCard from 'components/shared/catalog/generic-option/card'
import NobiliaOptionSelectionCard from 'components/shared/catalog/nobilia-option/card'
import { Chip } from 'components/shared/chip'
import NotificationBar from 'components/shared/notification-bar'
import useDesign from 'context/design/use'
import { formatDollars } from 'context/dollar-utils'
import { getCabinetryDiscountText } from 'context/project/utils-discounts'
import useRoom from 'context/room/use'
import { getRoomTypeWithDescription } from 'context/room/utils'
import ImageCarousel from 'design-system/components/carousel/image-carousel'
import {
  SelectionCardExpandSelectProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import Antislip from 'icons/estimate/anti-slip.svg'
import Fitted from 'icons/estimate/fitted.svg'
import Flood from 'icons/estimate/flood.svg'
import Lifetime from 'icons/estimate/lifetime.svg'
import Soft from 'icons/estimate/soft.svg'
import { Breakpoint, Colors, FormDivider } from 'styles/app/system'
import { Design } from 'types/design'

const VisualQuote = ({ isTradeProject }: { isTradeProject?: boolean }) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const {
    backsplashMaterials,
    carcaseColorsExterior,
    carcaseColorsInterior,
    catalogFeatures, //accessories
    countertopMaterials,
    design,
    fronts,
    glassColors,
    glassDesigns,
    glassFrameColors,
    glassFrameDesigns,
    handles,
    plinthColors, //toeKicks
    rendersReleased,
  } = useDesign()

  const { room } = useRoom()

  const renders = rendersReleased

  const { formDiscount, price } = (design as Design).metadata

  const priceValid =
    moment(design?.createdAt).isBefore(moment('12/01/2023', 'MM/DD/YYYY')) &&
    design?.metadata.catalog === '2023'

  const hasFinishes =
    carcaseColorsExterior ||
    carcaseColorsInterior ||
    fronts ||
    glassColors ||
    glassDesigns ||
    glassFrameColors ||
    glassFrameDesigns ||
    handles ||
    plinthColors

  const hasCountertopOrBacksplash = countertopMaterials || backsplashMaterials

  return (
    <Grid>
      {formDiscount ? (
        <Grid.Row centered style={{ paddingBottom: '0' }}>
          <Grid.Column computer={6} tablet={16} mobile={16}>
            <NotificationBar
              isDiscount
              text={getCabinetryDiscountText(formDiscount)}
            />
          </Grid.Column>
        </Grid.Row>
      ) : null}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 24,
          margin: '0 auto',
        }}
      >
        <Chip name={getRoomTypeWithDescription(room)} color="#FDCD81" />
        <StepTitle title="Visual quote" />
      </div>
      {isTradeProject ? (
        <Grid.Row centered>
          <Grid.Column textAlign="center">
            <Pricing>
              <div className="prices">
                <div>
                  <p className="brand">FORM Kitchens</p>
                  <p className="price">{formatDollars(price)}</p>
                </div>
              </div>
              <p className="caption small">Excluding Sales Tax</p>
            </Pricing>
            {priceValid ? (
              <p className="small" style={{ marginTop: 14 }}>
                Price valid until Dec 1st, 2023
              </p>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      ) : null}
      <Grid.Row>
        <Grid.Column width={16}>
          <ImageCarousel
            images={renders.map(
              (r) =>
                `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                  r.metadata?.keyCompressed || r.key,
                )}`,
            )}
          />
          <p
            style={{
              fontSize: isMobile ? '14px' : '16px',
              marginTop: isMobile ? '20px' : 0,
            }}
          >
            Please note this is an artistic representation, accuracy cannot be
            guaranteed. For the actual cabinet finish, refer to the physical
            samples. Countertop patterning and other rendered elements may vary
            depending on the fabricator. Your detailed quote will reflect the
            final order specifications.
          </p>
        </Grid.Column>
      </Grid.Row>
      {hasFinishes ? (
        <>
          <Grid.Row>
            <Grid.Column>
              <p className="subtitle">Finishes</p>
            </Grid.Column>
          </Grid.Row>
          <FormDivider margin="0 0 30px" />
          <Grid.Row style={{ rowGap: '30px' }} textAlign="center">
            {fronts?.map((f) => (
              <Grid.Column computer={4} tablet={5} mobile={8} key={f.id}>
                <p className="caption no-margin">Front</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={f}
                  selectionCardProps={
                    {
                      imageFitContain: true,
                      imageRatio: '2/3',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {handles?.map((h) => (
              <Grid.Column computer={4} tablet={5} mobile={8} key={h.id}>
                <p className="caption no-margin">Handle</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={h}
                  selectionCardProps={
                    {
                      imageFitContain: true,
                      imageRatio: '3/2',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {carcaseColorsExterior?.map((ce) => (
              <Grid.Column computer={4} tablet={5} mobile={8} key={ce.id}>
                <p className="caption no-margin">Carcase exterior</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={ce}
                  selectionCardProps={
                    {
                      imageFitContain: true,
                      imageRatio: '2/3',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {carcaseColorsInterior?.map((ci) => (
              <Grid.Column computer={4} tablet={5} mobile={8} key={ci.id}>
                <p className="caption no-margin">Carcase interior</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={ci}
                  selectionCardProps={
                    {
                      imageFitContain: true,
                      imageRatio: '2/3',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {plinthColors?.map((pc) => (
              <Grid.Column computer={4} tablet={5} mobile={8} key={pc.id}>
                <p className="caption no-margin">Toekick</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={pc}
                  selectionCardProps={
                    {
                      imageFitContain: true,
                      imageRatio: '2/3',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {glassColors?.map((fg) => (
              <Grid.Column computer={4} tablet={5} mobile={8} key={fg.id}>
                <p className="caption no-margin">Glass color</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={fg}
                  selectionCardProps={
                    {
                      imageFitContain: true,
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {glassDesigns?.map((fg) => (
              <Grid.Column computer={4} tablet={5} mobile={8} key={fg.id}>
                <p className="caption no-margin">Glass design</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={fg}
                  selectionCardProps={
                    {
                      imageFitContain: true,
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {glassFrameColors?.map((fg) => (
              <Grid.Column computer={4} tablet={5} mobile={8} key={fg.id}>
                <p className="caption no-margin">Glass frame color</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={fg}
                  selectionCardProps={
                    {
                      imageFitContain: true,
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {glassFrameDesigns?.map((fg) => (
              <Grid.Column computer={4} tablet={5} mobile={8} key={fg.id}>
                <p className="caption no-margin">Glass frame design</p>
                <NobiliaOptionSelectionCard
                  nobiliaOption={fg}
                  selectionCardProps={
                    {
                      imageFitContain: true,
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </>
      ) : null}
      {hasCountertopOrBacksplash ? (
        <>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              <p className="subtitle">Countertop and Backsplash</p>
            </Grid.Column>
          </Grid.Row>
          <FormDivider margin="0 0 30px" />
          <Grid.Row style={{ rowGap: '30px' }} textAlign="center">
            {countertopMaterials?.map((f) => (
              <Grid.Column key={f.id} computer={4} tablet={5} mobile={8}>
                <p className="caption no-margin">Countertop</p>
                <GenericOptionSelectionCard
                  genericOption={f}
                  selectionCardProps={{
                    expandOnly: true,
                    imageRatio: '1/1',
                  }}
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
            {backsplashMaterials?.map((f) => (
              <Grid.Column key={f.id} computer={4} tablet={5} mobile={8}>
                <p className="caption no-margin">Backsplash</p>
                <GenericOptionSelectionCard
                  genericOption={f}
                  selectionCardProps={{
                    expandOnly: true,
                    imageRatio: '1/1',
                  }}
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </>
      ) : null}
      {catalogFeatures?.length ? (
        <>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              <p className="subtitle">Features</p>
            </Grid.Column>
          </Grid.Row>
          <FormDivider margin="0 0 30px" />
          <Grid.Row style={{ rowGap: '30px' }}>
            {catalogFeatures?.map((cf) => (
              <Grid.Column computer={4} tablet={5} mobile={8} key={cf.id}>
                <CatalogFeatureSelectionCard
                  catalogFeature={cf}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </>
      ) : null}
      <Divider hidden />
      <Grid.Row>
        <Grid.Column>
          <p className="subtitle">Standard with Form</p>
        </Grid.Column>
      </Grid.Row>
      <FormDivider margin="0 0 30px" />
      <Grid.Row columns="equal" style={{ rowGap: '24px' }}>
        <Grid.Column computer={4} mobile={8}>
          <Lifetime />
          <h4>25 year manufacturing warranty </h4>
        </Grid.Column>
        <Grid.Column computer={3} mobile={8}>
          <Soft />
          <h4>Soft close doors & drawers</h4>
        </Grid.Column>
        <Grid.Column computer={3} mobile={8}>
          <Flood />
          <h4>Flood protection package</h4>
        </Grid.Column>
        <Grid.Column computer={3} mobile={8}>
          <Antislip />
          <h4>Anti-Slip Mats</h4>
        </Grid.Column>
        <Grid.Column computer={3} mobile={8}>
          <Fitted />
          <h4>Fitted cutlery tray</h4>
        </Grid.Column>
      </Grid.Row>
      {!isTradeProject ? (
        <>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              <p className="subtitle">Pricing</p>
            </Grid.Column>
          </Grid.Row>
          <FormDivider margin="0 0 30px" />
          <Grid.Row centered>
            <Grid.Column textAlign="center">
              <Pricing>
                <div className="prices">
                  <div>
                    <p className="brand">FORM Kitchens</p>
                    <p className="price">{formatDollars(price)}</p>
                  </div>
                  <div className="opaque">
                    <p className="brand">Suggested Retail</p>
                    {price ? (
                      <p className="price">
                        {formatDollars(
                          price / ((100 - (formDiscount || 0)) / 100) / 0.7,
                        )}
                      </p>
                    ) : null}
                    <div className="strike" />
                  </div>
                </div>
                <p className="caption small">Excluding Sales Tax</p>
              </Pricing>
              {priceValid ? (
                <p className="small" style={{ marginTop: 14 }}>
                  Price valid until Dec 1st, 2023
                </p>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </>
      ) : null}
    </Grid>
  )
}

export default VisualQuote

const Pricing = styled.div`
  background: ${Colors.white};
  border: none;
  border-radius: 4px;
  box-shadow:
    0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  margin-bottom: 14px;
  margin: 0 auto;
  padding: 25px 38px 18px;
  text-align: center;
  width: fit-content;

  div.prices {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    gap: 60px;
  }

  p.price {
    color: ${Colors.black};
    font-family: 'GT America';
    font-size: 30px;
    margin-bottom: 5px;
  }

  div.strike {
    border-bottom: 1px solid ${Colors.orange500};
    transform: translateY(-30px) rotate(10deg);
    width: 95%;
  }

  p.brand {
    color: ${Colors.black};
    font-size: 18px;
    margin: 0;
  }

  div.opaque p {
    color: ${Colors.gray400};
  }

  @media ${Breakpoint.onlyMobile} {
    min-width: -webkit-fill-available;

    div.prices {
      flex-direction: column;
      min-width: unset;
      width: 100%;
    }

    p.brand {
      font-size: 18px !important;
    }

    p.price {
      font-size: 26px !important;
    }
  }
`
