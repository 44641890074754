import React from 'react'

import { Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import NextStepsCarousel from 'design-system/components/guide/next-steps-carousel'
import { Section } from 'design-system/components/guide/utils'

const nextSteps = [
  {
    leadText: 'First <span class="bold">3 extra rooms</span> are included.',
    description:
      'Additional or complex room designs are available ($199 per room, redeemable towards that room).',
    image: `images/interactive-guides/extra-room/next-step-extra-rooms-01.svg`,
  },
  {
    leadText:
      'Shipping is always included <span class="bold">if extra rooms are ordered at the same time as the kitchen.</span>',
    description:
      'There may be additional shipping charges for separate orders below $15k.',
    image: `images/interactive-guides/extra-room/next-step-extra-rooms-02.svg`,
  },
  {
    leadText:
      'We encourage you to start <span class="bold">extra rooms while we refine your kitchen</span> so everything can be <span class="bold">ordered at the same time.</span>',
    image: `images/interactive-guides/extra-room/next-step-extra-rooms-03.svg`,
  },
  {
    leadText:
      'If a room is especially complex, <span class="bold">we may require approval for extra design time.</span>',
    image: `images/interactive-guides/extra-room/next-step-extra-rooms-04.svg`,
  },
]

const NextSteps = () => {
  const { isTradeProject } = useProject()

  return (
    <Section id="next-steps">
      <Grid centered>
        <NextStepsCarousel
          isTradeProject={isTradeProject}
          slideData={nextSteps}
        />
      </Grid>
    </Section>
  )
}

export default NextSteps
