import { useContext } from 'react'

import { DisconnectedOptionContext } from './provider'

const useDisconnectedOption = () => {
  const { catalog, disconnectedOption, refetch } = useContext(
    DisconnectedOptionContext,
  )

  return {
    catalog,
    disconnectedOption,
    refetch,
  }
}

export default useDisconnectedOption
