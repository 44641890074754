import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Divider, Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import StepTitle from 'components/app/step-title'
import useCompany from 'context/company/use'
import AppLayout from 'design-system/containers/app-layout'
import { Colors } from 'styles/app/system'
import { capsSnakeCaseToTitleCase, getNameForUser } from 'views/utils'

const Module = (_: RouteComponentProps) => {
  const { company } = useCompany()

  return (
    <AppLayout>
      <Grid stackable>
        <StepTitle title="Company Team" />
        <Divider hidden />
        <Grid.Row className="row-gap">
          {company.users.map((u) => (
            <Grid.Column key={u.id} computer={4} tablet={5} mobile={16}>
              <TeamMember>
                <Image src={u.picture} circular />
                <div>
                  <p className="no-margin">{getNameForUser(u)}</p>
                  <p className="gray small no-margin">{u.email}</p>
                  <p className="gray small no-margin">
                    {capsSnakeCaseToTitleCase(u.type)}
                  </p>
                </div>
              </TeamMember>
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default Module

const TeamMember = styled.div`
  display: flex;
  gap: 10px;
  border-bottom: 1px solid ${Colors.gray200};
  padding-bottom: 16px;

  img {
    width: 35px;
    height: 35px;
  }
`
