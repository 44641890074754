import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from 'gatsby'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import {
  getProgressStatusLabel,
  getProjectName,
  parseOwners,
  parseStaff,
} from 'context/project/utils'
import { StyledCard } from 'design-system/components/cards/secondary-card'
import StatusLabel from 'design-system/components/status-label'
import { Project } from 'types/project'

const ProjectCardTrade = ({
  project,
  showUsers,
}: {
  project: Project
  showUsers?: boolean
}) => {
  const { primaryOwner } = parseOwners(project)
  const { primaryDesigner } = parseStaff(project)

  return (
    <StyledCard onClick={() => navigate(`/app/projects/${project.id}`)}>
      <div className="content">
        <Users>
          {showUsers && <Image src={primaryOwner.picture} circular />}
          <Image src={primaryDesigner?.picture} circular />
        </Users>
        <div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
          <h6 className="no-margin">{getProjectName(project)}</h6>
          <StatusLabel {...getProgressStatusLabel(project)} />
        </div>
      </div>
      <div className="actions">
        <FontAwesomeIcon className="chevron" icon={['fal', 'chevron-right']} />
      </div>
    </StyledCard>
  )
}

export default ProjectCardTrade

const Users = styled.div`
  &&& {
    img {
      height: 40px;
      width: 40px;
      max-width: 40px;
    }
    img:nth-child(2) {
      margin-top: -20px;
    }
  }
`
