import React, { useEffect, useState } from 'react'

import { isEqual } from 'lodash'
import { Divider, Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import { BuildZoomData } from 'types/utils'

import { StepViewProps } from './steps'

const Flooring = ({
  setLoading,
  setNextDisabled,
  setOnNext,
}: StepViewProps) => {
  const { buildZoomData, project } = useProject()
  const { loadingUpdate, updateProjectAndRefetch } = useProjectMutate()

  const [state, setState] = useState(buildZoomData?.newFlooring)

  useEffect(() => {
    setLoading(loadingUpdate)
    setNextDisabled(!state)

    const onNext = async () => {
      if (!isEqual(buildZoomData?.newFlooring, state))
        await updateProjectAndRefetch({
          variables: {
            data: {
              data: {
                buildZoomData: {
                  ...(project.data.buildZoomData as BuildZoomData),
                  newFlooring: state as string,
                },
              },
            },
            where: {
              id: project.id,
            },
          },
        })
    }
    setOnNext(() => onNext)
  }, [state, loadingUpdate])

  return (
    <Grid>
      <Grid.Row centered>
        <Grid.Column computer={8} tablet={14} mobile={16} textAlign="center">
          <h3>Are you replacing the existing flooring?</h3>
        </Grid.Column>
      </Grid.Row>
      <Divider hidden />
      <Grid.Row centered>
        <Grid.Column computer={3} tablet={4} mobile={8}>
          <SelectIconCard
            icon="check"
            onClick={() => setState('yes')}
            selected={state === 'yes'}
            text="Yes"
          />
        </Grid.Column>
        <Grid.Column computer={3} tablet={4} mobile={8}>
          <SelectIconCard
            icon="times"
            onClick={() => setState('no')}
            selected={state === 'no'}
            text="No"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Flooring
