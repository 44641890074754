import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import TaskCheck from 'design-system/components/guide/task-check'
import {
  UnderlineSubtitle,
  Section,
} from 'design-system/components/guide/utils'

const questions = [
  { label: 'Which rooms are you interested in?' },
  { label: 'Functionality, Finishes, Handles' },
  { label: 'Budget' },
]

const DesignOptions = () => {
  return (
    <Section id="discuss-options">
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">What are the main things to cover today?</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <UnderlineSubtitle>Important Items</UnderlineSubtitle>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched className="row-gap">
          {questions.map((q, idx) => (
            <Grid.Column key={idx} computer={5} tablet={5} mobile={16}>
              <TaskCheck label={q.label} />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default DesignOptions
