import React from 'react'

import { navigate } from 'gatsby'

import { SecondaryCard, TertiaryCard } from 'design-system/components/cards'

const S3_ROOT = process.env.GATSBY_CDN_ROOT

type CardFactoryProps = {
  getUrlForDrawerType: (
    cardType: DrawerCardTypes,
    roomId: string,
  ) => string | null
  roomId: string
}

export enum DrawerCardTypes {
  ADDRESS = 'ADDRESS',
  APPLIANCES = 'APPLIANCES',
  CATALOGS = 'CATALOGS',
  COLLABORATORS = 'COLLABORATORS',
  DESIGN = 'DESIGN',
  FLOOR_PLAN = 'FLOOR_PLAN',
  INITIAL_COSTING = 'INITIAL_COSTING',
  INSPIRATION = 'INSPIRATION',
  MEASUREMENTS = 'MEASUREMENTS',
  PRESENTATION = 'PRESENTATION',
  PROJECT_CONSULTATION = 'PROJECT_CONSULTATION',
  PROJECT_PROPOSAL = 'PROJECT_PROPOSAL',
  PROJECT_PROPOSAL_GUIDE = 'PROJECT_PROPOSAL_GUIDE',
  ROOM_PHOTOS = 'ROOM_PHOTOS',
  SAMPLE_ORDER = 'SAMPLE_ORDER',
}

export const cardFactory = ({
  getUrlForDrawerType,
  roomId,
}: CardFactoryProps) => {
  return (cardType: DrawerCardTypes, index: number) => {
    const key = `${cardType}#${index}`
    switch (cardType) {
      case DrawerCardTypes.ADDRESS:
        return (
          <TertiaryCard
            key={key}
            title="Delivery Address"
            icon="map-pin"
            onClick={() => {
              const url = getUrlForDrawerType(DrawerCardTypes.ADDRESS, roomId)
              if (url) navigate(url)
            }}
          />
        )
      case DrawerCardTypes.APPLIANCES:
        return (
          <SecondaryCard
            key={key}
            basic
            onClick={() => {
              const url = getUrlForDrawerType(
                DrawerCardTypes.APPLIANCES,
                roomId,
              )
              if (url) navigate(url)
            }}
            title="Appliances"
            image={`${S3_ROOT}/images/app/illustrations/appliances.svg`}
          />
        )
      case DrawerCardTypes.MEASUREMENTS:
        return (
          <SecondaryCard
            key={key}
            basic
            disabled={
              !getUrlForDrawerType(DrawerCardTypes.MEASUREMENTS, roomId)
            }
            onClick={() => {
              const url = getUrlForDrawerType(
                DrawerCardTypes.MEASUREMENTS,
                roomId,
              )

              if (url) navigate(url)
            }}
            title="Measurements"
            image={`${S3_ROOT}/images/app/illustrations/measurements.svg`}
          />
        )
      case DrawerCardTypes.DESIGN:
        return (
          <SecondaryCard
            key={key}
            basic
            onClick={() => {
              const url = getUrlForDrawerType(DrawerCardTypes.DESIGN, roomId)
              if (url) window.open(url)
            }}
            title="Initial Call"
            image={`${S3_ROOT}/images/interactive-guides/design-call/kickoff-guide.webp`}
          />
        )
      case DrawerCardTypes.PRESENTATION: {
        const url = getUrlForDrawerType(DrawerCardTypes.PRESENTATION, roomId)
        return (
          <SecondaryCard
            key={key}
            basic
            disabled={!url}
            onClick={() => (url ? window.open(url) : undefined)}
            title="Design Presentation"
            image={`${S3_ROOT}/images/interactive-guides/design-presentation/design-presentation.webp`}
          />
        )
      }
      case DrawerCardTypes.PROJECT_CONSULTATION: {
        const url = getUrlForDrawerType(
          DrawerCardTypes.PROJECT_CONSULTATION,
          roomId,
        )
        return (
          <SecondaryCard
            key={key}
            basic
            disabled={!url}
            onClick={() => (url ? window.open(url) : undefined)}
            title="Project Consultation"
            image={`${S3_ROOT}/images/interactive-guides/project-consultation/trade-project-consultation.webp`}
          />
        )
      }
      case DrawerCardTypes.PROJECT_PROPOSAL: {
        const url = getUrlForDrawerType(
          DrawerCardTypes.PROJECT_PROPOSAL,
          roomId,
        )
        return (
          <SecondaryCard
            key={key}
            disabled={!url}
            basic
            onClick={() => (url ? window.open(url) : undefined)}
            title="Project Proposal"
            image={`${S3_ROOT}/images/app/trade-project-proposal.webp`}
          />
        )
      }
      case DrawerCardTypes.PROJECT_PROPOSAL_GUIDE: {
        const url = getUrlForDrawerType(
          DrawerCardTypes.PROJECT_PROPOSAL_GUIDE,
          roomId,
        )
        return (
          <SecondaryCard
            key={key}
            basic
            disabled={!url}
            onClick={() => (url ? window.open(url) : undefined)}
            title="Project Proposal"
            image={`${S3_ROOT}/images/interactive-guides/project-proposal/trade-project-proposal.webp`}
          />
        )
      }
      case DrawerCardTypes.SAMPLE_ORDER:
        return (
          <SecondaryCard
            key={key}
            basic
            onClick={() => {
              const url = getUrlForDrawerType(
                DrawerCardTypes.SAMPLE_ORDER,
                roomId,
              )

              if (url) navigate(url)
            }}
            title="Samples"
            image={`${S3_ROOT}/images/app/samples.webp`}
          />
        )
      case DrawerCardTypes.CATALOGS:
        return (
          <SecondaryCard
            key={key}
            title="Catalogs"
            basic
            onClick={() => {
              const url = getUrlForDrawerType(DrawerCardTypes.CATALOGS, roomId)

              if (url) navigate(url)
            }}
            image={`${S3_ROOT}/images/app/catalogs.webp`}
          />
        )
      case DrawerCardTypes.FLOOR_PLAN:
        return (
          <TertiaryCard
            key={key}
            title="Floor Plan"
            icon="square"
            onClick={() => {
              const url = getUrlForDrawerType(
                DrawerCardTypes.FLOOR_PLAN,
                roomId,
              )

              if (url) navigate(url)
            }}
          />
        )
      case DrawerCardTypes.ROOM_PHOTOS:
        return (
          <TertiaryCard
            key={key}
            title="Room Photos"
            icon="camera"
            onClick={() => {
              const url = getUrlForDrawerType(
                DrawerCardTypes.ROOM_PHOTOS,
                roomId,
              )

              if (url) navigate(url)
            }}
          />
        )
      case DrawerCardTypes.INSPIRATION:
        return (
          <TertiaryCard
            key={key}
            title="Inspiration"
            icon="heart"
            onClick={() => {
              const url = getUrlForDrawerType(
                DrawerCardTypes.INSPIRATION,
                roomId,
              )

              if (url) navigate(url)
            }}
          />
        )
      case DrawerCardTypes.COLLABORATORS:
        return (
          <TertiaryCard
            key={key}
            title="Collaborators"
            icon="users"
            onClick={() => {
              const url = getUrlForDrawerType(
                DrawerCardTypes.COLLABORATORS,
                roomId,
              )

              if (url) navigate(url)
            }}
          />
        )
      case DrawerCardTypes.INITIAL_COSTING: {
        const url = getUrlForDrawerType(DrawerCardTypes.INITIAL_COSTING, roomId)
        return (
          <SecondaryCard
            key={key}
            disabled={!url}
            basic
            onClick={() => (url ? window.open(url) : undefined)}
            title="Initial Costing"
            image={`${S3_ROOT}/images/app/trade-initial-costing.webp`}
          />
        )
      }

      default:
        return null
    }
  }
}
