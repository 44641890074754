import React from 'react'

import { RouteComponentProps } from '@reach/router'
import Helmet from 'react-helmet'

import ProjectMessageManyProvider from 'context/project/message/provider-many'
import ProjectProvider from 'context/project/provider'

interface ProjectRouteProps {
  project_id: string
}

interface ProjectProps extends RouteComponentProps<ProjectRouteProps> {
  children?: React.ReactNode
}

const Project = (props: ProjectProps) => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"
        ></meta>
        <script src="https://kit.fontawesome.com/400e44c8dd.js"></script>
      </Helmet>
      <ProjectProvider project_id={props.project_id}>
        <ProjectMessageManyProvider>
          {props.children}
        </ProjectMessageManyProvider>
      </ProjectProvider>
    </>
  )
}

export default Project
