import React from 'react'

import { RouteComponentProps } from '@reach/router'

import RoomProvider from 'context/room/provider'

interface RoomRouteProps {
  project_id: string
  room_id: string
}

interface RoomProps extends RouteComponentProps<RoomRouteProps> {
  children?: React.ReactNode
}

const Room = (props: RoomProps) => {
  return <RoomProvider room_id={props.room_id}>{props.children}</RoomProvider>
}

export default Room
