import React from 'react'

import { Text, View } from '@react-pdf/renderer'
import moment from 'moment'

import { formatDollars } from 'context/dollar-utils'
import * as checkoutUtils from 'context/order/utils-checkout'
import { Design } from 'types/design'
import { NobiliaSampleGraphQL } from 'types/nobilia-sample'
import { Payment } from 'types/payment'

import { ReceiptStyles } from './styles'

// Render helpers PDF
export const renderPaymentItemForPdf = ({
  paidAt,
  item,
}: {
  paidAt?: string
  item: any
}) => {
  const total = item.price * item.quantity
  return (
    <>
      <Text style={[ReceiptStyles.cell, ReceiptStyles.mainCell]}>
        {`${item.name}${paidAt ? ` paid on ${paidAt}` : ''}`}{' '}
      </Text>
      <Text style={ReceiptStyles.cell}>{item.quantity}</Text>
      <Text style={ReceiptStyles.cell}>{formatDollars(item.price)} </Text>
      <Text style={ReceiptStyles.cell}>{formatDollars(total)} </Text>
    </>
  )
}

export const renderPaymentItemForPdfMultiple = ({
  paidAt,
  item,
  key,
}: {
  paidAt?: string
  item: any
  key: number
}) => {
  const total = item.price * item.quantity
  return (
    <View key={key} style={ReceiptStyles.rowBody}>
      <Text style={[ReceiptStyles.cell, ReceiptStyles.mainCell]}>{`${
        item.name
      }${paidAt ? ` paid on ${paidAt}` : ''}`}</Text>
      <Text style={ReceiptStyles.cell}>{item.quantity}</Text>
      <Text style={ReceiptStyles.cell}>{formatDollars(item.price)} </Text>
      <Text style={ReceiptStyles.cell}>{formatDollars(total)} </Text>
    </View>
  )
}

export const renderDesignPdf = (design: Design, key: number) => {
  return renderPaymentItemForPdfMultiple({
    item: checkoutUtils.designToPaymentItem(design),
    key: key,
  })
}

export const renderProductPdf = (
  product: NobiliaSampleGraphQL,
  key: number,
) => {
  return renderPaymentItemForPdfMultiple({
    item: checkoutUtils.nobiliaSampleToPaymentItem(product),
    key: key,
  })
}

const madePaymentToPaymentItem = (payment: Payment) => {
  return {
    name: `${payment.metadata.name || payment.description}`,
    price: -payment.amount,
    quantity: 1,
  }
}

export const renderPaymentMadePdfMultiple = (payment: Payment, key: number) => {
  return renderPaymentItemForPdfMultiple({
    item: madePaymentToPaymentItem(payment),
    key: key,
    paidAt: moment(payment.metadata?.paidAt).format('ll'),
  })
}

export const renderPaymentItemFromPricePdf = (params: {
  name: string
  price: number
}) => {
  return renderPaymentItemForPdf({
    item: {
      name: params.name,
      price: params.price,
      quantity: 1,
    },
  })
}
