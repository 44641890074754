import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { StepperProps } from 'design-system/components/stepper'

import { StepViewProps } from '../types'
import AppliancesView from '../views/appliances'
import FloorPlanView from '../views/floor-plan'
import InspirationView from '../views/inspiration'
import ExtraRoomPaymentView from '../views/payment'
import PhotosView from '../views/photos'

type NavigationStep = StepperProps['steps'][number] & {
  subroute: string
  View: React.ComponentType<StepViewProps>
}

type ExtraRoomStepsType =
  | 'payment'
  | 'floorPlan'
  | 'photos'
  | 'inspiration'
  | 'appliances'

export const EXTRA_ROOM_STEPS: Record<ExtraRoomStepsType, NavigationStep> = {
  payment: {
    label: 'payment',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'payment',
    View: ExtraRoomPaymentView,
  },
  floorPlan: {
    label: 'floor plan',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'floor-plan',
    View: FloorPlanView,
  },
  photos: {
    label: 'photos',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'photos',
    View: PhotosView,
  },
  inspiration: {
    label: 'inspiration',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'inspiration-images',
    View: InspirationView,
  },
  appliances: {
    label: 'appliances',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'appliances',
    View: AppliancesView,
  },
} as const
