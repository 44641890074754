import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import HeaderBasic from 'components/app/project/header/header-basic'
import DesignBrief from 'components/app/project/room/design-brief'
import ApprovalModal from 'components/app/project/room/design-brief/approval-modal'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import Button from 'design-system/components/button'
import { Breakpoint } from 'styles/app/system'

type PropsModule = RouteComponentProps<{
  project_id: string
  room_id: string
  location: { state: { isAdmin: boolean } }
}>

const Module = (props: PropsModule) => {
  let isAdmin = false
  if (props.location?.state) {
    isAdmin = props.location.state.isAdmin
  }
  const { project } = useProject()
  const { room } = useRoom()

  const [showApprovalModal, toggleApprovalModal] = useState<boolean>(false)

  const designBriefApproved = room?.data?.designBriefApprovedAt

  useEffect(() => {
    const top = document.getElementById('design-brief-page')?.scrollTop
    if (!history.state?.keepScroll)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.getElementById('design-brief-page').scrollTop = 0
    else
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementById('design-brief-page').scrollTop = top
      }, 50)

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.history.replaceState({ ...history.state, keepScroll: true }, null)
    }
  }, [])

  return (
    <>
      <HeaderBasic />
      {room ? <DesignBrief project={project} room={room} /> : null}
      {!isAdmin && !designBriefApproved && !showApprovalModal && (
        <Container>
          <p className="no-margin">Ready to approve?</p>
          <Button
            kind="solid"
            text="Yes, Approve"
            size="large"
            onClick={() => toggleApprovalModal(true)}
          />
          <p className="no-margin up-mobile">or</p>
          <Button
            kind="ghost"
            text="Request Changes"
            fontAwesomeIcon="envelope"
            iconAnimation="scale"
            color="gray"
            onClick={() => navigate(`/app/projects/${project.id}/messages`)}
          />
        </Container>
      )}
      {room ? (
        <ApprovalModal
          open={showApprovalModal}
          onClose={() => toggleApprovalModal(false)}
          room={room}
        />
      ) : null}
    </>
  )
}

export default Module

const Container = styled.div`
  align-items: center;
  background: white;
  border-radius: 8px;
  bottom: 40px;
  box-shadow: 0px 4px 10px rgba(205, 205, 205, 0.6);
  display: flex;
  gap: 20px;
  justify-content: center;
  left: 50%;
  padding: 20px;
  position: fixed;
  transform: translateX(-50%);
  z-index: 10;

  & > p {
    white-space: nowrap;
  }

  @media ${Breakpoint.onlyMobile} {
    bottom: 24px;
    box-shadow: 0px 0px 13px rgba(205, 205, 205, 0.7);
    flex-direction: column;
    padding: 14px;
    width: calc(100vw - 28px);
  }
`
