import React, { useState } from 'react'

import styled from 'styled-components'

import FormVideo from 'components/app/form-video'
import HelpMessage from 'components/shared/help-message'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'

export type HowToVideoProps = {
  type: 'floorPlan' | 'photos'
}

const HowToVideo = ({ type }: HowToVideoProps) => {
  const { isTradeProject } = useProject()
  const [showModal, toggleModal] = useState<boolean>(false)

  return (
    <Modal
      onClose={() => toggleModal(false)}
      onOpen={() => toggleModal(true)}
      open={showModal}
      size="medium"
      trigger={
        <Button
          fontAwesomeIcon="circle-play"
          iconAnimation="scale"
          iconPosition="left"
          kind="ghost"
          text={
            type === 'floorPlan'
              ? 'How to make my Floor Plan?'
              : 'Taking my room pictures'
          }
        />
      }
      title={
        type === 'floorPlan'
          ? 'How to make my Floor Plan?'
          : 'How to take my room pictures?'
      }
    >
      {type === 'floorPlan' ? (
        <>
          <p className="subtitle">
            Important things to consider on your Floor Plan:
          </p>
          <ul>
            <li>
              <p>All wall lengths that could have cabinets or appliances</p>
            </li>
            <li>
              <p>Water points, gas points and electrical sockets</p>
            </li>
            <li>
              <p>Doors and windows</p>
            </li>
            <li>
              <p>Ceiling heights</p>
            </li>
            {isTradeProject ? (
              <li>
                <p>Ceiling heights</p>
              </li>
            ) : null}
          </ul>
          <FormVideo src="https://player.vimeo.com/video/738065548?h=4d73736535&amp;badge=0" />
          <br />
          <HelpMessage
            message="Don’t worry about having the perfect floor plan. Approximate measurements are welcome for this stage. You’ll be able to update them later on."
            title="PRO TIP"
          />
        </>
      ) : (
        <>
          <p>
            Please take at least 8 photos of your current space. If you would
            like to take more, that's even better! If your space is unbuilt,
            please upload any drawings you have to proceed.
          </p>
          <ul>
            <li>
              <p>Take photos from all the primary corners of your room.</p>
            </li>
            <li>
              <p>Take photos from the center of each main wall.</p>
            </li>
            <li>
              <p>
                Point the camera straight ahead, it’s best if you can get both
                the ceiling and floor.
              </p>
            </li>
          </ul>
          <p>
            We use these photos to recreate your space in our photorealistic
            visuals.
          </p>
          <ImageContainer>
            <img
              src={`${process.env.GATSBY_CDN_ROOT}/images/app/how-to-upload-room-photos.webp`}
            />
          </ImageContainer>
        </>
      )}
    </Modal>
  )
}

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 16px;

  img {
    width: 100%;
    max-width: 350px;
  }
`

export default HowToVideo
