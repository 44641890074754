import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Divider, Grid, Image } from 'semantic-ui-react'

import DisconnectedOptionProvider from 'context/disconnected-option/provider'
import useDisconnectedOption from 'context/disconnected-option/use'

import { ImageContainer, SliderContainer, StyledGrid } from './utils'

const Module = () => {
  const { disconnectedOption } = useDisconnectedOption()
  return (
    <StyledGrid stackable>
      <Grid.Row style={{ marginTop: '100px' }}>
        <Grid.Column width={11}>
          <SliderContainer>
            <ImageContainer>
              <Image
                fluid
                src={`${process.env.GATSBY_CDN_ROOT}/${disconnectedOption?.data?.imageUrl}`}
              />
            </ImageContainer>
          </SliderContainer>
        </Grid.Column>
        <Grid.Column width={5}>
          <Grid.Row>
            <Grid.Column>
              <h2>{disconnectedOption.description}</h2>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Divider className="divider" />
            </Grid.Column>
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  )
}

export default (
  props: RouteComponentProps<{
    option_id: string
    feature_id: string
  }>,
) => {
  return (
    <DisconnectedOptionProvider disconnected_option_id={props.option_id}>
      <Module />
    </DisconnectedOptionProvider>
  )
}
