import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { isEqual } from 'lodash'
import moment from 'moment'
import { DateInput, DateInputProps } from 'semantic-ui-calendar-react'
import {
  Divider,
  DropdownProps,
  Form,
  FormInputProps,
  Grid,
  Label,
} from 'semantic-ui-react'

import StepTitle from 'components/app/step-title'
import { UploadCompanyFileCard } from 'components/shared/company/upload-file-card'
import CardFile from 'components/shared/project/project-file/card'
import { UnderlineSubtitle } from 'components/shared/utils'
import useCompany from 'context/company/use'
import useCompanyMutateNoContext from 'context/company/use-mutate-no-context'
import useProjectFileManyNoContext from 'context/project-file/use-mutate-no-context'
import { parseFiles } from 'context/project-file/utils-many'
import SaveToast from 'design-system/components/save-toast'
import AppLayout from 'design-system/containers/app-layout'
import { StyledForm } from 'styles/app/components/form'
import {
  ExemptionDocumentationType,
  ProfessionalDesignAssociationTypes,
} from 'types/company'
import { ProjectFileType } from 'types/project-file'
import { getDropdownOptionsFromEnum, validateEINNumber } from 'views/utils'

const Module = (_: RouteComponentProps) => {
  const { company: companyDB, refetch } = useCompany()
  const { updateCompany } = useCompanyMutateNoContext()
  const { deleteProjectFile } = useProjectFileManyNoContext()

  const initialState = {
    EINNumber: companyDB?.data?.EINNumber || '',
    membershipNumber: companyDB?.data?.membershipNumber || '',
    professionalDesignAssociation:
      companyDB?.data?.professionalDesignAssociation ||
      ('' as ProfessionalDesignAssociationTypes),
    exemptionDocumentationType:
      companyDB?.data?.exemptionDocumentationType ||
      ('' as ExemptionDocumentationType),
    exemptionTaxId: companyDB?.data?.exemptionTaxId || '',
    exemptionExpirationDate: companyDB?.data?.exemptionExpirationDate || '',
  }
  const [company, setCompany] = useState(initialState)
  const [inputError, setInputError] = useState<boolean>(false)
  const [isValid, setValid] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const { businessLicenses, taxExemptionCertificates } = parseFiles(
    companyDB?.files,
  )

  const handleSave = async () => {
    setLoading(true)

    if (companyDB?.id) {
      await updateCompany({
        variables: {
          data: {
            data: {
              ...(company.EINNumber ? { EINNumber: company.EINNumber } : null),
              ...(company.membershipNumber
                ? { membershipNumber: company.membershipNumber }
                : null),
              ...(company.professionalDesignAssociation
                ? {
                    professionalDesignAssociation:
                      company.professionalDesignAssociation,
                  }
                : null),
              ...(company.exemptionDocumentationType
                ? {
                    exemptionDocumentationType:
                      company.exemptionDocumentationType,
                  }
                : null),
              ...(company.exemptionTaxId
                ? { exemptionTaxId: company.exemptionTaxId }
                : null),
              ...(company.exemptionExpirationDate
                ? { exemptionExpirationDate: company.exemptionExpirationDate }
                : null),
            },
          },
          where: {
            id: companyDB.id,
          },
        },
      })
    }
    refetch()
    setLoading(false)
  }

  const onChangeInput = (
    _: React.ChangeEvent<HTMLInputElement>,
    { value, name }: { value: string; name: string },
  ) => {
    setCompany({ ...company, [name]: value })
  }

  const formChanged = !isEqual(initialState, company)

  useEffect(() => {
    if (company.EINNumber || company.membershipNumber) setValid(true)
    else setValid(false)
  }, [company])

  return (
    <AppLayout>
      <Grid>
        <StepTitle title="Company Documents" />
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <UnderlineSubtitle>Business Details</UnderlineSubtitle>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <StyledForm styled={{ whiteInputs: true }}>
              <Form.Group widths="equal">
                <Form.Input
                  error={
                    inputError && (
                      <Label pointing prompt>
                        <p className="red small">
                          Please enter a valid EIN number, e.g. 12-3456789
                        </p>
                      </Label>
                    )
                  }
                  label="EIN Number"
                  name="EINNumber"
                  onBlur={() => {
                    if (
                      company.EINNumber &&
                      !validateEINNumber(company.EINNumber)
                    )
                      setInputError(true)
                    else {
                      setInputError(false)
                    }
                  }}
                  onChange={onChangeInput as FormInputProps['onChange']}
                  placeholder="EIN Number"
                  value={company.EINNumber}
                />
                <Form.Dropdown
                  label="Professional Design Association"
                  name="professionalDesignAssociation"
                  onChange={onChangeInput as DropdownProps['onChange']}
                  options={getDropdownOptionsFromEnum(
                    ProfessionalDesignAssociationTypes,
                    true,
                  )}
                  selection
                  upward
                  value={company.professionalDesignAssociation}
                />
                <Form.Input
                  disabled={!company.professionalDesignAssociation}
                  label="Membership Number"
                  name="membershipNumber"
                  onChange={onChangeInput as FormInputProps['onChange']}
                  placeholder="Membership Number"
                  value={company.membershipNumber}
                />
              </Form.Group>
              <Form.Group widths={3}>
                <Form.Field>
                  <label>Business License</label>
                  {businessLicenses.length > 0 ? (
                    <CardFile
                      file={businessLicenses?.[0]}
                      onFileRemoved={async (f) => {
                        await deleteProjectFile({
                          variables: {
                            where: {
                              id: f.id ?? '',
                            },
                          },
                          onCompleted: refetch,
                        })
                      }}
                      showDeleteBtn
                    />
                  ) : (
                    <UploadCompanyFileCard
                      fileType={ProjectFileType.BUSINESS_LICENSE}
                      onFilesUploaded={() => refetch()}
                      companyId={companyDB?.id}
                    />
                  )}
                </Form.Field>
              </Form.Group>
            </StyledForm>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <UnderlineSubtitle>Tax Exemption Information</UnderlineSubtitle>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <StyledForm styled={{ whiteInputs: true }}>
              <Form.Group widths="equal">
                <Form.Dropdown
                  label="Documentation Type"
                  name="exemptionDocumentationType"
                  onChange={onChangeInput as DropdownProps['onChange']}
                  options={getDropdownOptionsFromEnum(
                    ExemptionDocumentationType,
                    true,
                  )}
                  selection
                  upward
                  value={company.exemptionDocumentationType}
                />
                <Form.Input
                  label="Tax ID #"
                  name="exemptionTaxId"
                  onChange={onChangeInput as FormInputProps['onChange']}
                  placeholder="Tax ID Number"
                  value={company.exemptionTaxId}
                />
                <Form.Field>
                  <label>Expiration Date</label>
                  <DateInput
                    closable
                    dateFormat={'MM/DD/YYYY'}
                    duration={0}
                    initialDate={moment().format('MM/DD/YYYY')}
                    name="exemptionExpirationDate"
                    onChange={onChangeInput as DateInputProps['onChange']}
                    value={company.exemptionExpirationDate}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={3}>
                <Form.Field>
                  <label>Tax Exempt documentation</label>
                  {taxExemptionCertificates.length > 0 ? (
                    <CardFile
                      file={taxExemptionCertificates?.[0]}
                      onFileRemoved={async (f) => {
                        await deleteProjectFile({
                          variables: {
                            where: {
                              id: f.id ?? '',
                            },
                          },
                        })
                        refetch()
                      }}
                      showDeleteBtn
                    />
                  ) : (
                    <UploadCompanyFileCard
                      fileType={ProjectFileType.TAX_EXEMPTION_CERTIFICATE}
                      onFilesUploaded={() => refetch()}
                      companyId={companyDB?.id}
                    />
                  )}
                </Form.Field>
              </Form.Group>
            </StyledForm>
          </Grid.Column>
        </Grid.Row>
        <SaveToast
          disabled={!isValid}
          loading={loading}
          onClick={async () => await handleSave()}
          show={formChanged}
        />
      </Grid>
    </AppLayout>
  )
}

export default Module
