import React from 'react'

import { Text, View } from '@react-pdf/renderer'
import moment from 'moment'

import Receipt from 'components/shared/receipt'
import { ReceiptStyles } from 'components/shared/receipt/styles'
import * as receiptUtils from 'components/shared/receipt/utils'
import { formatDollars } from 'context/dollar-utils'
import * as checkoutUtils from 'context/order/utils-checkout'
import { Order } from 'types/order'
import { PaymentType } from 'types/payment'

const parseOrder = (order: Order) => {
  const designs = order.designs || []
  const discounts = order.discounts || []
  const nobiliaSamples = order.nobiliaSamples || []

  const { totalOrderPayment, paymentMethods } = (order.payments ?? [])
    .filter((p) => p.metadata?.type === PaymentType.ORDER_PAYMENT)
    .reduce(
      (acc, p) => {
        const isCardPayment = !!p.stripeCharge?.payment_method_details?.card
        const method = isCardPayment
          ? `${p?.stripeCharge?.payment_method_details?.card?.brand?.toUpperCase()}-${p
              ?.stripeCharge?.payment_method_details?.card.last4}`
          : 'Invoice'
        const paymentMethods = !acc.paymentMethods.some(
          (pm: string) => pm === method,
        )
          ? [...acc.paymentMethods, method]
          : acc.paymentMethods
        return {
          totalOrderPayment: acc.totalOrderPayment + p.amount,
          paymentMethods,
        }
      },
      {
        totalOrderPayment: 0,
        paymentMethods: [] as string[],
      },
    )

  const methodString = paymentMethods.join(', ')
  const payments = order.payments || []
  const totalBeforeTaxes = checkoutUtils.calculateTotalBeforeTaxes(order)
  const taxes = checkoutUtils.calculateTaxes(order)
  const amount = formatDollars(totalOrderPayment ? totalOrderPayment : 0)
  const amountDue = checkoutUtils.calculateAmountDue(order)
  const orderPlacedAt = moment(order.placedAt).format('ll')

  return {
    amount,
    amountDue,
    designs,
    discounts,
    methodString,
    nobiliaSamples,
    orderPlacedAt,
    payments,
    totalBeforeTaxes,
    taxes,
  }
}

export const ReceiptPDF = ({ order }: { order: Order }) => {
  const {
    amount,
    amountDue,
    designs,
    discounts,
    methodString,
    nobiliaSamples,
    orderPlacedAt,
    payments,
    totalBeforeTaxes,
    taxes,
  } = parseOrder(order)

  return (
    <Receipt
      rightColumn={
        <>
          <Text>Date paid: {orderPlacedAt}</Text>
          <Text>Payment method: {methodString}</Text>
        </>
      }
      title={`${amount} paid on ${orderPlacedAt}`}
    >
      {designs.map(receiptUtils.renderDesignPdf)}
      {nobiliaSamples.length ? (
        <View style={ReceiptStyles.rowBody}>
          {nobiliaSamples.map(receiptUtils.renderProductPdf)}
        </View>
      ) : null}
      {discounts.length ? (
        <View style={ReceiptStyles.rowBody}>
          {discounts.map((d) =>
            receiptUtils.renderPaymentItemForPdf({
              item: d,
            }),
          )}
        </View>
      ) : null}
      <View style={[ReceiptStyles.rowBody, ReceiptStyles.grayRow]}>
        {totalBeforeTaxes
          ? receiptUtils.renderPaymentItemFromPricePdf({
              name: 'Total Before Tax',
              price: totalBeforeTaxes,
            })
          : null}
      </View>
      <View style={ReceiptStyles.rowBody}>
        {totalBeforeTaxes
          ? receiptUtils.renderPaymentItemFromPricePdf({
              name: `Estimated Tax ${
                order.taxRate ? `(${order.taxRate}%)` : ''
              }`,
              price: taxes as number,
            })
          : null}
      </View>
      {totalBeforeTaxes
        ? payments.map(receiptUtils.renderPaymentMadePdfMultiple)
        : null}
      <View style={[ReceiptStyles.halfRow, ReceiptStyles.grayRow]}>
        <Text style={ReceiptStyles.cell}>Amount Due</Text>
        <Text style={ReceiptStyles.cell}>{formatDollars(amountDue)}</Text>
      </View>
    </Receipt>
  )
}
