import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import {
  OverflowContainer,
  Section,
  UnderlineSubtitle,
} from 'design-system/components/guide/utils'
import { Breakpoint, Colors } from 'styles/app/system'

const CDN = `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-call`

const reviews = [
  {
    brand: ' Good-Housekeepin',
    logo: CDN + '/good-housekeeping.svg',
    review: 'Stunning, well-built, and definitely saved me the cost of custom.',
  },
  {
    brand: 'domino',
    logo: CDN + '/domino.svg',
    review:
      'These New Kitchen Cabinets Cost 40% Less Than Typical Made-to-Order Ones',
  },
  {
    brand: 'elle decor',
    logo: CDN + '/elle-decor.svg',
    review: 'FORM is the online innovator in kitchen design',
  },
  {
    brand: 'Better Homes and Gardens',
    logo: CDN + '/bhg.svg',
    review: 'The brand specializing in direct-to-consumer cabinetry.',
  },
  {
    brand: 'business of home',
    logo: CDN + '/business-of-home.svg',
    review:
      'High-performance, sleek cabinetry at a fraction of the showroom price.',
  },
]

const customerReviews = [
  {
    brand: 'houzz',
    logo: CDN + '/review-houzz.svg',
    review:
      '“The communication was amazing, and every detail was accounted for.”',
  },
  {
    brand: 'bbb',
    logo: CDN + '/review-bbb.svg',
    review:
      '“Build trust, Advertise Honestly, Tell the Truth, Be Transparent, Honor Promises, Be Responsive, Safeguard Privacy, Enbody Integrity.”',
  },
  {
    brand: 'google',
    logo: CDN + '/review-google.svg',
    review:
      '“Higher quality than most retail kitchen design stores, at half the cost.”',
  },
]

const BrandReviews = () => {
  return (
    <Section>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Our rating and reviews</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column textAlign="center">
            <UnderlineSubtitle>FORM customers</UnderlineSubtitle>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <OverflowContainer overflowX>
              {customerReviews.map((r) => (
                <Review key={r.brand} {...r} />
              ))}
            </OverflowContainer>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column textAlign="center">
            <UnderlineSubtitle>FORM in the media</UnderlineSubtitle>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <OverflowContainer overflowX>
              {reviews.map((r) => (
                <Review key={r.brand} {...r} inQuotes />
              ))}
            </OverflowContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default BrandReviews

type ReviewProps = {
  brand: string
  inQuotes?: boolean
  logo: string
  review: string
}

const Review = ({ brand, inQuotes, logo, review }: ReviewProps) => {
  return (
    <ReviewContainer>
      <img src={logo} alt={brand} />
      <FlexRow>
        {inQuotes ? (
          <FontAwesomeIcon
            icon={['fas', 'quote-left']}
            color={Colors.blue200}
          />
        ) : null}
        <p className="small">
          {review}
          {inQuotes && '"'}
        </p>
      </FlexRow>
    </ReviewContainer>
  )
}

const ReviewContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 30%;
  gap: 20px;
  justify-content: flex-start;
  max-width: 290px;
  min-width: min-content;
  text-align: left;

  img {
    display: block;
    height: 60px;
    margin: 0 auto;
    max-width: 160px;
  }

  @media ${Breakpoint.onlyMobile} {
    min-width: 230px;
  }
`

const FlexRow = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;
`
