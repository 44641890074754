import React, { createContext, useEffect, useMemo } from 'react'

import { navigate } from 'gatsby'
import { sortBy } from 'lodash'

import Loader from 'components/shared/loader'
import {
  isMeetingUpcoming,
  isMeetingCanceledOrNoShow,
} from 'context/meeting/utils'
import useProject from 'context/project/use'
import useProjectMeetingMutate from 'context/project/use-meeting-mutate'
import { Meeting, MeetingMetadata, MeetingType } from 'types/meeting'
import { User } from 'types/user'

import {
  meetingTypeDuration,
  meetingTypeName,
  meetingTypeVideo,
} from './mappers'
import { getDefaultMeeting } from './utils'

interface IMeetingContext {
  meeting: Meeting
  meetingName: string
  video: string
}

//* This type is really { meetingType: MeetingType; meetingDuration?: number; calendar?: string } | { meetingId: string }
//* Meeting context can be initiated by type or id
//* If by id, then we always want that meeting
//* If by type, then we return if unscheduled or upcoming, if it is passed or canceled then we create a new one
type MeetingContextProps = {
  calendar?: 'normal' | 'urgent'
  children: React.ReactNode
  meetingType?: MeetingType
  meetingDuration?: number
  meetingId?: string
}

export const MeetingContext = createContext<IMeetingContext>(
  {} as IMeetingContext,
)

const areCalendarsEqual = (a: string, b: string) => {
  return (!a && !b) || a === b
}

const MeetingProvider = ({
  calendar,
  meetingType: meetingTypeUrl,
  meetingDuration,
  meetingId,
  children,
}: MeetingContextProps) => {
  const {
    meetings,
    primaryDesigner,
    project,
    stopPolling,
    meetingUpcoming,
    meetingLatest,
  } = useProject()
  const { createMeetingAndRefetch, loadingCreate } = useProjectMeetingMutate()

  const meeting = useMemo(() => {
    //* first try by meeting type
    if (meetingTypeUrl) {
      const duration = meetingDuration ?? meetingTypeDuration[meetingTypeUrl]
      //* get all meetings of type and duration that are not cancelled
      const meetingsByType = meetings.filter(
        (m) =>
          m.duration === duration &&
          areCalendarsEqual(m.metadata?.calendar ?? '', calendar ?? '') &&
          m.metadata?.type === meetingTypeUrl &&
          !isMeetingCanceledOrNoShow(m),
      )
      //* if one is unscheduled, return it
      const unscheduled = meetingsByType.find((m) => !m.startTime)
      if (unscheduled) return unscheduled
      //* if one is upcoming or ongoing, return it
      const [latestMeeting] = sortBy(meetingsByType, 'startTime').reverse()
      if (latestMeeting && isMeetingUpcoming(latestMeeting))
        return latestMeeting
      //* if all have passed, return undefined and create new
      return undefined
    }
    //* if matched by id, always return it
    return meetings.find((m) => m.id === meetingId)
  }, [meetingTypeUrl, meetingId, meetingDuration, meetings])

  const video = useMemo(() => {
    const meetingType =
      meetingTypeUrl || (meeting?.metadata?.type as MeetingType)
    return meetingTypeVideo[meetingType]
  }, [meetingTypeUrl, meeting])

  useEffect(() => {
    //* if no meeting type and no meetingId with matching meeting go home
    if (!meetingTypeUrl && !(meetingId && meeting)) {
      navigate(`/app/projects/${project.id}`)
      return
    }

    //* found a meeting using the meeting id
    if (meetingId && meeting) {
      //* check if meeting is no longer valid
      if (isMeetingCanceledOrNoShow(meeting)) {
        navigate(`/app/projects/${project.id}`)
        return
      }

      //* if meeting has already passed and there is another meeting upcoming, sent to dashboard
      const nextMeeting = meetingUpcoming ?? meetingLatest
      if (nextMeeting && meeting.id !== nextMeeting.id) {
        navigate(
          `/app/projects/${project.id}/meeting?meetingId=${nextMeeting.id}`,
        )
        return
      }
    }

    if (!meeting && !loadingCreate) {
      const defaultMeeting = getDefaultMeeting(
        meetingTypeUrl as MeetingType,
        primaryDesigner as User,
        meetingDuration,
        calendar,
      )

      createMeetingAndRefetch({
        variables: {
          data: {
            calendlyUrl: defaultMeeting?.calendlyUrl,
            duration: defaultMeeting?.duration,
            metadata: defaultMeeting?.metadata as MeetingMetadata,
            project: {
              connect: {
                id: project.id,
              },
            },
            staffMember: {
              connect: {
                id: primaryDesigner?.id ?? '',
              },
            },
          },
        },
      })
      return
    }

    //* failsafe
    if (meeting?.startTime) {
      stopPolling()
    }
  }, [meeting, meetingTypeUrl, meetingDuration, meetingId])

  if (!meeting) return <Loader />

  return (
    <MeetingContext.Provider
      value={{
        meeting,
        meetingName: meetingTypeName[meeting.metadata.type],
        video,
      }}
    >
      {children}
    </MeetingContext.Provider>
  )
}

export default MeetingProvider
