import React from 'react'

import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import { getReferenceImage } from 'context/media/utils-many'
import useRoom from 'context/room/use'
import { GenericOption } from 'types/generic-option'
import { RoomGraphQL } from 'types/room'
import { RoomElementOption } from 'types/room-element-option'

const getCustomImageUrlOrReferenceImage = (
  option: Partial<GenericOption> | Partial<RoomElementOption>,
  defaultImg?: string,
) => {
  return option?.data?.customImageUrl
    ? `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
        option.data.customImageUrl,
      )}`
    : getReferenceImage(option?.media ?? [], defaultImg)
}

interface MoodboardOptionsProps {
  border?: boolean
  room: RoomGraphQL
}

const Moodboard = ({ border }: MoodboardOptionsProps) => {
  const {
    backsplashMaterials = [],
    countertopMaterials = [],
    floorMaterials = [],
    fronts = [],
    handles = [],
  } = useRoom()

  const mainInternalGrid = () => {
    if (fronts?.length === 0 || fronts?.length === 1)
      return (
        <InnerGrid columns="equal">
          <StyledRow>
            <StyledColumn>
              <MoodboardImage
                styled={{
                  aspectRatio: 1.45,
                  height: '74%',
                  image: getReferenceImage(
                    fronts[0]?.media ?? [],
                    'https://via.placeholder.com/500/FFFFFF/000000?text=Front',
                  ),
                }}
              />
            </StyledColumn>
            <StyledColumn>
              <MoodboardImage
                styled={{
                  aspectRatio:
                    (countertopMaterials[1] && backsplashMaterials[0]) ||
                    countertopMaterials[2]
                      ? 1.45 / 3
                      : countertopMaterials[1] || backsplashMaterials[0]
                      ? 1.45 / 2
                      : 1.45,
                  height:
                    (countertopMaterials[1] && backsplashMaterials[0]) ||
                    countertopMaterials[2]
                      ? '24.6%'
                      : countertopMaterials[1] || backsplashMaterials[0]
                      ? '37%'
                      : '74%',
                  image: getCustomImageUrlOrReferenceImage(
                    countertopMaterials[0],
                    'https://via.placeholder.com/500/E8E3D4/000000?text=Countertop',
                  ),
                }}
              />
              {(countertopMaterials[1] || backsplashMaterials[0]) && (
                <MoodboardImage
                  styled={{
                    aspectRatio:
                      (countertopMaterials[1] && backsplashMaterials[0]) ||
                      countertopMaterials[2]
                        ? 1.45 / 3
                        : 1.45 / 2,
                    height:
                      (countertopMaterials[1] && backsplashMaterials[0]) ||
                      countertopMaterials[2]
                        ? '24.6%'
                        : '37%',
                    image:
                      backsplashMaterials[0] && !countertopMaterials[1]
                        ? getCustomImageUrlOrReferenceImage(
                            backsplashMaterials[0],
                          )
                        : getCustomImageUrlOrReferenceImage(
                            countertopMaterials[1],
                          ),
                  }}
                />
              )}
              {((countertopMaterials[1] && backsplashMaterials[0]) ||
                countertopMaterials[2]) && (
                <MoodboardImage
                  styled={{
                    aspectRatio: 1.45 / 3,
                    height: '24.6%',
                    image: backsplashMaterials[0]
                      ? getCustomImageUrlOrReferenceImage(
                          backsplashMaterials[0],
                        )
                      : getCustomImageUrlOrReferenceImage(
                          countertopMaterials[2],
                        ),
                  }}
                />
              )}
            </StyledColumn>
          </StyledRow>
          <StyledRow>
            {handles[0] ? (
              <StyledColumn>
                <MoodboardImage
                  styled={{
                    aspectRatio: 0.5,
                    image: getReferenceImage(handles[0]?.media ?? []),
                  }}
                />
              </StyledColumn>
            ) : null}
            <StyledColumn>
              <MoodboardImage
                styled={{
                  aspectRatio: handles[0] ? 0.5 : 0.25,
                  image: getCustomImageUrlOrReferenceImage(
                    floorMaterials[0],
                    'https://via.placeholder.com/500/F0EEE8/000000?text=Floor',
                  ),
                }}
              />
            </StyledColumn>
          </StyledRow>
        </InnerGrid>
      )

    if (fronts.length === 2)
      return (
        <InnerGrid columns="equal">
          <StyledRow>
            <StyledColumn width={12}>
              <InnerGrid columns="equal">
                <StyledRow>
                  <StyledColumn>
                    <MoodboardImage
                      styled={{
                        aspectRatio: 1.45,
                        height: '74%',
                        image: getReferenceImage(fronts[0]?.media ?? []),
                      }}
                    />
                  </StyledColumn>
                  <StyledColumn>
                    <MoodboardImage
                      styled={{
                        aspectRatio: 1.45,
                        height: '74%',
                        image: getReferenceImage(fronts[1]?.media ?? []),
                      }}
                    />
                  </StyledColumn>
                </StyledRow>
                <StyledRow>
                  {handles[0] ? (
                    <StyledColumn>
                      <MoodboardImage
                        styled={{
                          aspectRatio: 0.5,
                          image: getReferenceImage(handles[0]?.media ?? []),
                        }}
                      />
                    </StyledColumn>
                  ) : null}
                  <StyledColumn>
                    <MoodboardImage
                      styled={{
                        aspectRatio: handles[0] ? 0.5 : 0.25,
                        image: getCustomImageUrlOrReferenceImage(
                          floorMaterials[0],
                        ),
                      }}
                    />
                  </StyledColumn>
                </StyledRow>
              </InnerGrid>
            </StyledColumn>
            <StyledColumn width={4}>
              <MoodboardImage
                styled={{
                  aspectRatio:
                    (countertopMaterials[1] && backsplashMaterials[0]) ||
                    countertopMaterials[2]
                      ? 1.45 / 3
                      : countertopMaterials[1] || backsplashMaterials[0]
                      ? 1.45 / 2
                      : 1.45,
                  height:
                    (countertopMaterials[1] && backsplashMaterials[0]) ||
                    countertopMaterials[2]
                      ? '33.3%'
                      : countertopMaterials[1] || backsplashMaterials[0]
                      ? '50%'
                      : '100%',
                  image: getCustomImageUrlOrReferenceImage(
                    countertopMaterials[0],
                  ),
                }}
              />
              {(countertopMaterials[1] || backsplashMaterials[0]) && (
                <MoodboardImage
                  styled={{
                    aspectRatio:
                      (countertopMaterials[1] && backsplashMaterials[0]) ||
                      countertopMaterials[2]
                        ? 1.45 / 3
                        : 1.45 / 2,
                    height:
                      (countertopMaterials[1] && backsplashMaterials[0]) ||
                      countertopMaterials[2]
                        ? '33.3%'
                        : '50%',
                    image:
                      backsplashMaterials[0] && !countertopMaterials[1]
                        ? getCustomImageUrlOrReferenceImage(
                            backsplashMaterials[0],
                          )
                        : getCustomImageUrlOrReferenceImage(
                            countertopMaterials[1],
                          ),
                  }}
                />
              )}
              {((countertopMaterials[1] && backsplashMaterials[0]) ||
                countertopMaterials[2]) && (
                <MoodboardImage
                  styled={{
                    aspectRatio: 1.45 / 3,
                    height: '33.3%',
                    image: backsplashMaterials[0]
                      ? getCustomImageUrlOrReferenceImage(
                          backsplashMaterials[0],
                        )
                      : getCustomImageUrlOrReferenceImage(
                          countertopMaterials[2],
                        ),
                  }}
                />
              )}
            </StyledColumn>
          </StyledRow>
        </InnerGrid>
      )

    if (fronts.length > 2)
      return (
        <InnerGrid columns="equal">
          <StyledRow style={{ height: '20%' }}>
            <StyledColumn>
              <MoodboardImage
                styled={{
                  aspectRatio:
                    backsplashMaterials[0] || countertopMaterials[2]
                      ? 0.6
                      : countertopMaterials[1]
                      ? 0.4
                      : 0.2,
                  image: getCustomImageUrlOrReferenceImage(
                    countertopMaterials[0],
                    'https://via.placeholder.com/500/E8E3D4/000000?text=Countertop',
                  ),
                }}
              />
            </StyledColumn>
            {countertopMaterials[1] && (
              <StyledColumn>
                <MoodboardImage
                  styled={{
                    aspectRatio:
                      backsplashMaterials[0] || countertopMaterials[2]
                        ? 0.6
                        : 0.4,
                    image: getCustomImageUrlOrReferenceImage(
                      countertopMaterials[1],
                    ),
                  }}
                />
              </StyledColumn>
            )}
            {(backsplashMaterials[0] || countertopMaterials[2]) && (
              <StyledColumn>
                <MoodboardImage
                  styled={{
                    aspectRatio: 0.4,
                    image: backsplashMaterials[0]
                      ? getCustomImageUrlOrReferenceImage(
                          backsplashMaterials[0],
                        )
                      : getCustomImageUrlOrReferenceImage(
                          countertopMaterials[2],
                        ),
                  }}
                />
              </StyledColumn>
            )}
          </StyledRow>
          <StyledRow stretched>
            <StyledColumn>
              <MoodboardImage
                styled={{
                  aspectRatio: 1.4,
                  image: getReferenceImage(fronts[0]?.media ?? []),
                }}
              />
            </StyledColumn>
            <StyledColumn>
              <MoodboardImage
                styled={{
                  aspectRatio: 1.45,
                  image: getReferenceImage(fronts[1]?.media ?? []),
                }}
              />
            </StyledColumn>
            <StyledColumn>
              <MoodboardImage
                styled={{
                  aspectRatio: 1.45,
                  image: getReferenceImage(fronts[2]?.media ?? []),
                }}
              />
            </StyledColumn>
          </StyledRow>
          <StyledRow>
            {handles[0] ? (
              <StyledColumn className="small">
                <MoodboardImage
                  styled={{
                    aspectRatio: 0.35,
                    image: getReferenceImage(
                      handles[0]?.media ?? [],
                      'https://via.placeholder.com/500/F0EEE8/000000?text=Handle',
                    ),
                  }}
                />
              </StyledColumn>
            ) : null}
            <StyledColumn className="big">
              <MoodboardImage
                styled={{
                  aspectRatio: handles[0] ? 0.35 : 0.2,
                  image: getCustomImageUrlOrReferenceImage(
                    floorMaterials[0],
                    'https://via.placeholder.com/500/F0EEE8/000000?text=Floor',
                  ),
                }}
              />
            </StyledColumn>
          </StyledRow>
        </InnerGrid>
      )
  }
  return (
    <StyledGrid columns="equal" styled={{ border: border }} stretched>
      <StyledRow>
        <StyledColumn width={16}>{mainInternalGrid()}</StyledColumn>
      </StyledRow>
    </StyledGrid>
  )
}
export default Moodboard

const InnerGrid = styled(Grid)`
  margin: 0 !important;
`

const MoodboardImage = styled.div<{
  styled?: { aspectRatio?: number; height?: string; image?: string }
}>`
  background: url('${({ styled }) => styled?.image}') no-repeat center;
  background-size: cover;
  height: ${({ styled }) => (styled?.height ? styled.height : '100%')};
  padding-top: ${({ styled }) =>
    styled?.aspectRatio ? `${styled.aspectRatio * 100}%` : '100%'};
  position: relative;
  width: 100%;
`

const StyledColumn = styled(Grid.Column)`
  &&& {
    padding: 0 !important;

    .big {
      width: 50% !important;
    }

    .small {
      width: 16.5% !important;
    }
  }
`

const StyledGrid = styled(Grid)<{ styled?: { border?: boolean } }>`
  &&& {
    border: ${({ styled }) => (styled?.border ? '1px solid black' : 'none')};
    margin: 0 !important;
  }
`

const StyledRow = styled(Grid.Row)`
  &&& {
    padding: 0 !important;
  }
`
