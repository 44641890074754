import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { StepperProps } from 'design-system/components/stepper'

import { DeliveryStepViewProps } from './types'
import { OrderStatusView } from './views/order-status'
import DeliverPaletteView from './views/palette'

type NavigationStep = StepperProps['steps'][number] & {
  subroute: string
  View: React.ComponentType<DeliveryStepViewProps>
}

type DeliveryFlowStepsType = 'orderStatus' | 'photos'

export const DELIVERY_FLOW_STEPS: Record<
  DeliveryFlowStepsType,
  NavigationStep
> = {
  orderStatus: {
    label: 'Order Status',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'order-status',
    View: OrderStatusView,
  },
  photos: {
    label: 'photos',
    completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
    subroute: 'photos',
    View: DeliverPaletteView,
  },
} as const

export const DELIVERY_FLOW_STEPS_ARRAY = [
  DELIVERY_FLOW_STEPS.orderStatus,
  DELIVERY_FLOW_STEPS.photos,
]
