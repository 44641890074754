import { useMutation } from '@apollo/client'

import { notifyError } from 'context/notifications/trigger'
import {
  CREATE_PAYMENT,
  CreatePaymentPayload,
  CreatePaymentVariables,
  DELETE_PAYMENT,
  DeletePaymentPayload,
  DeletePaymentVariables,
  UPDATE_PAYMENT,
  UpdatePaymentPayload,
  UpdatePaymentVariables,
} from 'queries/payment'

const usePaymentMutate = () => {
  const [createPayment, { loading: loadingCreate }] = useMutation<
    CreatePaymentPayload,
    CreatePaymentVariables
  >(CREATE_PAYMENT, {
    onError: ({ message }) => {
      notifyError(message)
    },
  })

  const [deletePayment, { loading: loadingDelete }] = useMutation<
    DeletePaymentPayload,
    DeletePaymentVariables
  >(DELETE_PAYMENT, {
    onError: ({ message }) => {
      notifyError(message)
    },
  })

  const [updatePayment, { loading: loadingUpdate }] = useMutation<
    UpdatePaymentPayload,
    UpdatePaymentVariables
  >(UPDATE_PAYMENT, {
    onError: ({ message }) => {
      notifyError(message)
    },
  })

  return {
    createPayment,
    deletePayment,
    loadingCreate,
    loadingDelete,
    loadingUpdate,
    updatePayment,
  }
}

export default usePaymentMutate
