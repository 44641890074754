import React, { useEffect } from 'react'

import RoomProjectFileUploads from 'components/app/project/room/project-file-uploads'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import { ProjectFileType } from 'types/project-file'

import { StepViewProps } from '../types'

const PhotosView = ({
  setNextDisabled,
  setOnNext,
  setSkip,
  chooseProspectFiles,
}: StepViewProps) => {
  const { isTradeProject } = useProject()
  const { roomPhotos } = useRoom()

  useEffect(() => {
    setOnNext(undefined)
    setNextDisabled(false)
  }, [])

  useEffect(() => {
    //*Skip for now message if no photos have been added
    setSkip(!roomPhotos.length)
  }, [roomPhotos])

  return (
    <RoomProjectFileUploads
      chooseProspectFiles={chooseProspectFiles}
      howToVideo="photos"
      key="photos"
      path="photos"
      projectFiles={roomPhotos}
      projectFileType={ProjectFileType.ROOM_PHOTO}
      subTitle={
        isTradeProject
          ? 'Add here any pictures of the space you might have'
          : 'Try adding at least 8 pictures. Is your space under-construction? Feel free to add any sketches or drawings.'
      }
      title={
        isTradeProject
          ? 'Upload pictures of the space'
          : 'Upload as many pictures of your space as you wish'
      }
    />
  )
}

export default PhotosView
