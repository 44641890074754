import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Divider, Grid } from 'semantic-ui-react'

import NobiliaOptionSelectionCard from 'components/shared/catalog/nobilia-option/card'
import useAuth from 'context/auth/use'
import useRoom from 'context/room/use'
import Button from 'design-system/components/button'
import Carousel from 'design-system/components/carousel'
import ExpandImage from 'design-system/components/guide/expand-image'
import SelectionCover from 'design-system/components/guide/selection-cover'
import { Section, Spacer } from 'design-system/components/guide/utils'
import {
  SelectionCardExpandSelectProps,
  SelectionCardVariation,
} from 'design-system/components/selection-card'
import { Breakpoint } from 'styles/app/system'
import { GuideType } from 'types/utils'

const CDN = process.env.GATSBY_CDN_ROOT
const frontsConfig = [
  {
    src: `${CDN}/images/interactive-guides/design-call/front-style-glossy.webp`,
    title: 'Glossy',
  },
  {
    src: `${CDN}/images/interactive-guides/design-call/front-style-natural-reproduction.webp`,
    title: 'Natural Reproduction',
  },
  {
    src: `${CDN}/images/interactive-guides/design-call/front-style-matte.webp`,
    title: 'Matte',
  },
  {
    src: `${CDN}/images/interactive-guides/design-call/front-style-shaker.webp`,
    title: 'Shaker',
  },
]

interface FrontsProps {
  guideType: GuideType
  header?: JSX.Element
  projectId: string
}

const Fronts = (props: FrontsProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const [frontStyleIdx, setFrontStyleIdx] = useState<number | null>(null)
  const { isFORMStaff } = useAuth()
  const { room, fronts } = useRoom()

  return (
    <>
      <Section id="fronts">
        <Grid centered>
          {props.header}
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h3 className="gray">Our front styles</h3>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row stretched className="row-gap">
            {isMobile ? (
              <Grid.Column>
                <Carousel
                  slides={frontsConfig.map((v, idx) => ({
                    id: idx,
                    item: (
                      <ExpandImage
                        activeIndex={frontStyleIdx}
                        idx={idx}
                        images={frontsConfig.map((v) => v.src)}
                        onClose={() => setFrontStyleIdx(null)}
                        src={v.src}
                        title={v.title}
                      />
                    ),
                  }))}
                  hideNavButtons
                  slideSize={80}
                />
              </Grid.Column>
            ) : (
              frontsConfig.map((v, idx) => (
                <Grid.Column key={v.title} computer={4} tablet={8}>
                  <ExpandImage
                    activeIndex={frontStyleIdx}
                    idx={idx}
                    images={frontsConfig.map((v) => v.src)}
                    onClose={() => setFrontStyleIdx(null)}
                    src={v.src}
                    title={v.title}
                  />
                </Grid.Column>
              ))
            )}
          </Grid.Row>
          <Spacer />
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h3 className="gray">Front selection</h3>
              {fronts?.length ? (
                <p>You’ve selected {fronts.length} fronts for your project.</p>
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          {!fronts?.length ? (
            <Grid.Row>
              <Grid.Column>
                <SelectionCover
                  buttonEnabled
                  buttonText={isFORMStaff ? 'Select' : 'See options'}
                  image="design-call/kickoff-fronts.png"
                  onClick={() =>
                    navigate(
                      `/app/projects/${props.projectId}/rooms/${room?.id}/selection/front-selection`,
                      {
                        state: {
                          fromGuide: props.guideType,
                        },
                      },
                    )
                  }
                  title="Select fronts"
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <>
              <Grid.Row centered>
                {fronts.map((f) => (
                  <Grid.Column key={f.id} computer={3} tablet={5} mobile={8}>
                    <NobiliaOptionSelectionCard
                      nobiliaOption={f}
                      selectionCardProps={
                        {
                          fitWidth: true,
                          expandOnly: true,
                          imageRatio: '2/3',
                        } as Partial<SelectionCardExpandSelectProps>
                      }
                      selectionCardVariation={
                        SelectionCardVariation.EXPAND_SELECT
                      }
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Button
                    color="blue"
                    fontAwesomeIcon="arrow-right"
                    kind="solid"
                    onClick={() =>
                      navigate(
                        `/app/projects/${props.projectId}/rooms/${room?.id}/selection/front-selection`,
                        {
                          state: {
                            fromGuide: props.guideType,
                          },
                        },
                      )
                    }
                    text={isFORMStaff ? 'Change selection' : 'See options'}
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          {!isMobile && <Spacer height={300} />}
        </Grid>
      </Section>
    </>
  )
}

export default Fronts
