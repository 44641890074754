import React from 'react'

import { navigate, RouteComponentProps, useMatch } from '@reach/router'
import { Divider, Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import { UnderlineSubtitle } from 'design-system/components/guide/utils'
import AppLayout from 'design-system/containers/app-layout'
import { MeetingTypeProject } from 'types/meeting'

const URGENT_CALLS = [
  {
    title: 'Check-in 15 min',
    url: `meeting?meetingType=${MeetingTypeProject.CHECK_IN}&duration=15&calendar=urgent`,
  },
  {
    title: 'Check-in 30 min',
    url: `meeting?meetingType=${MeetingTypeProject.CHECK_IN}&calendar=urgent`,
  },
  {
    title: 'Check-in 60 min',
    url: `meeting?meetingType=${MeetingTypeProject.CHECK_IN}&duration=60&calendar=urgent`,
  },
  {
    title: 'Initial call',
    url: `meeting?meetingType=${MeetingTypeProject.DESIGN}&calendar=urgent`,
  },
  {
    title: 'Design follow up',
    url: `meeting?meetingType=${MeetingTypeProject.DESIGN_FOLLOW_UP}&calendar=urgent`,
  },
  {
    title: 'Design presentation',
    url: `meeting?meetingType=${MeetingTypeProject.PRESENTATION}&calendar=urgent`,
  },
]

const Scheduling = (_: RouteComponentProps) => {
  const { isTradeProject } = useProject()
  const matchApp = useMatch('/app/projects/:project_id/*')

  const CALLS = [
    {
      title: 'Check-in 15 min',
      url: `meeting?meetingType=${MeetingTypeProject.CHECK_IN}&duration=15`,
    },
    {
      title: 'Check-in 30 min',
      url: `meeting?meetingType=${MeetingTypeProject.CHECK_IN}`,
    },
    {
      title: 'Check-in 60 min',
      url: `meeting?meetingType=${MeetingTypeProject.CHECK_IN}&duration=60`,
    },
    ...(isTradeProject
      ? [
          {
            title: 'Project Consultation',
            url: `meeting?meetingType=${MeetingTypeProject.PROJECT_CONSULTATION}`,
          },
          {
            title: 'Project Proposal',
            url: `meeting?meetingType=${MeetingTypeProject.PROJECT_PROPOSAL}`,
          },
        ]
      : [
          {
            title: 'Initial call',
            url: `meeting?meetingType=${MeetingTypeProject.DESIGN}`,
          },
          {
            title: 'Design follow up',
            url: `meeting?meetingType=${MeetingTypeProject.DESIGN_FOLLOW_UP}`,
          },
          {
            title: 'Design presentation',
            url: `meeting?meetingType=${MeetingTypeProject.PRESENTATION}`,
          },
        ]),
    {
      title: 'Handover call',
      url: `meeting?meetingType=${MeetingTypeProject.HANDOVER}`,
    },
    {
      title: 'Pre-Install Call',
      url: `meeting?meetingType=${MeetingTypeProject.PREINSTALL}`,
    },
  ]

  return (
    <AppLayout>
      <Grid>
        <Grid.Row centered>
          <Grid.Column computer={12} tablet={16} mobile={16}>
            <Grid>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <h3>Scheduling tool</h3>
                  <Divider hidden />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row stretched style={{ rowGap: '24px' }}>
                {CALLS.map((c, idx) => (
                  <Grid.Column key={idx} computer={5} tablet={5} mobile={16}>
                    <SelectIconCard
                      icon="video"
                      text={c.title}
                      onClick={() =>
                        navigate(
                          `/app/projects/${matchApp?.project_id}/${c.url}`,
                          { state: { comingFromHome: true } },
                        )
                      }
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>

              {!isTradeProject ? (
                <Grid.Row stretched style={{ rowGap: '24px' }}>
                  <Grid.Column width={16}>
                    <Divider hidden />

                    <UnderlineSubtitle>Urgent Calendar</UnderlineSubtitle>
                  </Grid.Column>

                  {URGENT_CALLS.map((c, idx) => (
                    <Grid.Column key={idx} computer={5} tablet={5} mobile={16}>
                      <SelectIconCard
                        icon="video"
                        text={c.title}
                        onClick={() =>
                          navigate(
                            `/app/projects/${matchApp?.project_id}/${c.url}`,
                            { state: { comingFromHome: true } },
                          )
                        }
                      />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              ) : null}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default Scheduling
