import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import StepTitle from 'components/app/step-title'
import AddressForm from 'components/shared/address/form'
import useProject from 'context/project/use'
import { Address } from 'types/utils'

type ProjectAddressProps = RouteComponentProps & {
  setAddress?: (address: Address) => void
  setNextDisabled?: (disabled: boolean) => void
  whiteInputs?: boolean
}

const ProjectAddress = ({
  setAddress,
  setNextDisabled,
  whiteInputs,
}: ProjectAddressProps) => {
  const { project } = useProject()
  const [addressState, setAddressState] = useState<Address>(
    project.projectAddress,
  )

  return (
    <StyledGrid>
      <StepTitle title="Project delivery address" />
      <Grid.Row centered>
        <Grid.Column
          widescreen={6}
          largeScreen={6}
          computer={9}
          tablet={10}
          mobile={16}
        >
          <AddressForm
            address={addressState}
            labels
            onChange={(completed, a) => {
              setAddressState(a)
              if (setAddress && completed) setAddress(a)
              if (setNextDisabled) setNextDisabled(!completed)
            }}
            whiteInputs={whiteInputs}
          />
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  )
}

const StyledGrid = styled(Grid)`
  row-gap: 8px;
`

export default ProjectAddress
