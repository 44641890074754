import React from 'react'

import { RouteComponentProps } from '@reach/router'

import Timeline from 'components/shared/timeline'
import {
  StepProps,
  TimelineStep,
  TIMELINE_STEPS,
} from 'components/shared/timeline/steps'
import LeadTimeProvider from 'context/lead-times/provider'
import useProject from 'context/project/use'
import useProjectTimeline from 'context/project/use-timeline'

const ProjectTimeline = (_: RouteComponentProps) => {
  const { project, orderCabinetryPlaced, isTradeProject } = useProject()
  const { timelineStepsCompleted } = useProjectTimeline()

  const TIMELINE_STEPS_ARRAY = Object.entries(TIMELINE_STEPS).reduce(
    (acc, [key, value], idx) => {
      acc.push({
        ...value,
        completed: timelineStepsCompleted[key as TimelineStep],
        inProgress:
          !timelineStepsCompleted[key as TimelineStep] &&
          (idx === 0 || acc[idx - 1]?.completed),
      })

      return acc
    },
    [] as StepProps[],
  )

  const completedSteps = Object.keys(timelineStepsCompleted) as TimelineStep[]
  const orderPlacedAt = orderCabinetryPlaced?.createdAt

  return (
    <LeadTimeProvider
      completedSteps={completedSteps}
      orderPlacedAt={orderPlacedAt}
      skipSettingsLoader
    >
      <Timeline
        isTradeProject={isTradeProject}
        steps={TIMELINE_STEPS_ARRAY}
        zipCode={project?.projectAddress?.postalCode}
      />
    </LeadTimeProvider>
  )
}

export default ProjectTimeline
