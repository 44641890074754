import React from 'react'

import { RouteComponentProps } from '@reach/router'

import CompanyProvider from 'context/company/provider'
import UserMessageManyProvider from 'context/project/message/provider-user-many'
import useUser from 'context/user/use'

const Index = (
  props: RouteComponentProps<{ company_id: string }> & {
    children: React.ReactNode
  },
) => {
  const { user } = useUser()

  if (!user) return null
  return (
    <CompanyProvider companyId={props.company_id || ''}>
      <UserMessageManyProvider>{props.children}</UserMessageManyProvider>
    </CompanyProvider>
  )
}

export default Index
