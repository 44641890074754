import React from 'react'

import styled from 'styled-components'

import { Breakpoint } from 'styles/app/system'

import { DrawerPanel, DrawerPanelProps } from './drawer-panel'

type DrawerPanelLayoutProps = React.PropsWithChildren & DrawerPanelProps

const DrawerPanelLayout = ({
  children,
  ...drawerPanelProps
}: DrawerPanelLayoutProps) => {
  return (
    <Container>
      <div>{children}</div>
      <div className="drawer-panel">
        <DrawerPanel {...drawerPanelProps} />
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 32px;
  justify-content: space-between;

  & > div {
    flex-grow: 1;
  }

  .drawer-panel {
    flex-grow: 1;
    display: flex;
    justify-content: center;

    @media ${Breakpoint.downFromSmallDesktop} and ${Breakpoint.upFromMobile} {
      justify-content: left;
    }
  }
`

export default DrawerPanelLayout
