import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate, useScrollRestoration } from 'gatsby'

import HeaderBasic from 'components/app/project/header/header-basic'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import Button from 'design-system/components/button'
import AppLayout from 'design-system/containers/app-layout'
import { ScrollContainer } from 'styles/app/system'

import VisualQuote from './module'

const QuoteViewer = (
  props: RouteComponentProps<{
    project_id: string
    room_id: string
    design_id: string
    location: { state: { isAdmin: boolean } }
  }>,
) => {
  let isAdmin = false
  if (props.location?.state) {
    isAdmin = props.location.state.isAdmin
  }

  const ulScrollRestoration = useScrollRestoration('scroll-page')
  const { project, isTradeProject } = useProject()
  const { room } = useRoom()

  useEffect(() => {
    if (document.getElementById('visual-quote-page')) {
      const top = document.getElementById('visual-quote-page')?.scrollTop
      if (!history.state?.keepScroll)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementById('visual-quote-page').scrollTop = 0
      else
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          document.getElementById('visual-quote-page').scrollTop = top
        }, 50)
    }
  }, [])

  return (
    <ScrollContainer id="visual-quote-page" {...(ulScrollRestoration as any)}>
      <AppLayout
        header={
          <HeaderBasic
            rightItems={
              isAdmin ? (
                <>
                  <Button
                    kind="ghost"
                    fontAwesomeIcon="download"
                    size="medium"
                    text="Download PDF"
                    onClick={() => window.print()}
                  />
                </>
              ) : null
            }
            onBack={() =>
              isAdmin
                ? navigate(
                    `/admin/projects/${project.id}/rooms/${room?.id}/designs`,
                  )
                : history.back()
            }
          />
        }
      >
        <VisualQuote isTradeProject={isTradeProject} />
      </AppLayout>
    </ScrollContainer>
  )
}

export default QuoteViewer
