import React from 'react'

import { PDFDownloadLink, Text } from '@react-pdf/renderer'
import moment from 'moment'

import Receipt from 'components/shared/receipt'
import * as receiptUtils from 'components/shared/receipt/utils'
import { formatDollars } from 'context/dollar-utils'
import Button from 'design-system/components/button'
import { Payment } from 'types/payment'

const parsePayment = (p: Payment) => {
  const isCardPayment = !!p.stripeCharge?.payment_method_details?.card
  const paymentMethod = isCardPayment
    ? `${p?.stripeCharge?.payment_method_details?.card?.brand?.toUpperCase()}-${p
        ?.stripeCharge?.payment_method_details?.card.last4}`
    : 'Invoice'

  const amount = formatDollars(p.amount)
  const placedAt = moment(p.metadata.paidAt).format('ll')

  return {
    amount,
    paymentMethod,
    placedAt,
  }
}

export const ReceiptPDF = ({ payment }: { payment: Payment }) => {
  const { amount, paymentMethod, placedAt } = parsePayment(payment)

  return (
    <Receipt
      title={`${amount} paid on ${placedAt}`}
      rightColumn={
        <>
          <Text>Date paid: {placedAt}</Text>
          <Text>Payment method: {paymentMethod}</Text>
        </>
      }
    >
      {receiptUtils.renderPaymentItemForPdfMultiple({
        key: 0,
        item: {
          name: `${payment.metadata.name || payment.description}`,
          price: payment.amount,
          quantity: 1,
        },
      })}
    </Receipt>
  )
}

export const ReceiptPDFDownload = ({ payment }: { payment: Payment }) => {
  return (
    <Button
      fontAwesomeIcon="download"
      kind="ghost"
      customContent={
        <PDFDownloadLink
          document={<ReceiptPDF payment={payment} />}
          fileName="FORM-Payment-Receipt.pdf"
        >
          Download PDF
        </PDFDownloadLink>
      }
      style={{ padding: '0 16px' }}
    />
  )
}
