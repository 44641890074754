import React, { useEffect, useRef, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { Container } from 'semantic-ui-react'
import styled from 'styled-components'

import Header from 'components/app/project/message/header'
import HelpMessage from 'components/shared/help-message'
import Loader from 'components/shared/loader'
import { MessageList } from 'components/shared/message'
import { useProjectMessageMany } from 'context/project/message/provider-many'
import useProjectMessageManyMutate from 'context/project/message/use-many-mutate'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import { Colors, ScrollBarStyle } from 'styles/app/system'

const scrollContainerId = 'messaging-page'

const MessagesHome = (_: RouteComponentProps<{ project_id: string }>) => {
  const { primaryDesigner } = useProject()
  const {
    count,
    fetchedCount,
    fetchMore,
    loading,
    loadingMore,
    messagesGroupedByDate,
    refetch,
    scrollToLastMessage,
    messagesFetched,
  } = useProjectMessageMany()
  const { acknowledgeAll, acknowledgeMessage } = useProjectMessageManyMutate()
  const [showLoader, setShowLoader] = useState(loading)
  const containerRef = useRef<HTMLDivElement>(null)
  const scrolledToBottom = useRef(false)

  const handleFetchMore = async () => {
    containerRef.current?.scrollTo({
      top: 1,
      behavior: 'auto',
    })
    await fetchMore()
  }

  useEffect(() => {
    // Prevents flashing unscrolled messages
    setTimeout(() => setShowLoader(loading), 0)
  }, [loading])

  useEffect(() => {
    return () => {
      acknowledgeAll()
    }
  }, [])

  useEffect(() => {
    if (!scrolledToBottom.current && messagesFetched) {
      scrollToLastMessage({ scrollContainerId })

      scrolledToBottom.current = true
    }
  }, [messagesFetched])
  const bannerMessage = primaryDesigner?.hubspotId
    ? `To serve you better, all project communications have moved to email. You can reach your designer at <a class="link" target="_blank" href="mailto:${primaryDesigner.email}">${primaryDesigner.email}</a> for a prompt response. For logistics and installation support, please contact <a class="link" target="_blank" href="mailto:support@formkitchens.com">support@formkitchens.com</a>.`
    : `To serve you better, all project communications have moved to email. You can reach us at <a class="link" target="_blank" href="mailto:team@formkitchens.com">team@formkitchens.com</a> for a prompt response. For logistics and installation support, please contact <a class="link" target="_blank" href="mailto:support@formkitchens.com">support@formkitchens.com</a>.`

  return (
    <>
      <Header />
      <MainContainer id="appMessagingView">
        <HelpMessage
          icon="triangle-exclamation"
          title="Notice: We've Moved Messaging to Email"
          message={bannerMessage}
        />
        <MessagesWrapper id={scrollContainerId} ref={containerRef}>
          <Container>
            {fetchedCount < count ? (
              <Button
                onClick={handleFetchMore}
                kind="solid"
                text="Load More"
                loading={loading || loadingMore}
                style={{ display: 'block', margin: '20px auto 0 auto' }}
              />
            ) : null}
            <MessageList
              messages={messagesGroupedByDate}
              acknowledgeMessage={acknowledgeMessage}
              refetch={refetch}
            />
          </Container>
        </MessagesWrapper>

        {showLoader ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : null}
      </MainContainer>
    </>
  )
}

export default MessagesHome

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 60px; // header height
  height: 100vh;
  gap: 5px;

  .ui.container {
    height: 100%;
  }
`

const MessagesWrapper = styled.div`
  ${ScrollBarStyle}

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 20px;
`

const LoaderContainer = styled.div`
  position: fixed;
  inset: 0;
  z-index: 3;
  background-color: ${Colors.gray50};
`
