import React, { useEffect, useState } from 'react'

import { isEqual } from 'lodash'
import { Container, Divider, Grid } from 'semantic-ui-react'

import ProjectFileUploads from 'components/shared/project/project-file/uploads'
import StyledTextArea from 'components/shared/styled-text-area'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import useProjectFileMutateNoContext from 'context/project-file/use-mutate-no-context'
import { parseFiles } from 'context/project-file/utils-many'
import { ProjectFile, ProjectFileType } from 'types/project-file'

import { StepViewProps } from './steps'

const Information = ({
  setLoading,
  setNextDisabled,
  setOnNext,
}: StepViewProps) => {
  const { buildZoomData, project, refetch } = useProject()
  const { loadingUpdate, updateProjectAndRefetch } = useProjectMutate()
  const { deleteProjectFile } = useProjectFileMutateNoContext()

  const buildZoomPhotosChosen = buildZoomData?.photos || []
  const buildZoomPhotosFound = project.files.filter((pf) =>
    buildZoomPhotosChosen.includes(pf.key),
  )
  const { buildzoomPhotos, floorPlans, roomPhotos } =
    buildZoomPhotosFound.length
      ? parseFiles(buildZoomPhotosFound)
      : parseFiles(project.files)
  const files = [...buildzoomPhotos, ...floorPlans, ...roomPhotos]
  const [state, setState] = useState<ProjectFile[]>(files)
  const [comments, setComments] = useState(buildZoomData?.extraInfo)

  useEffect(() => {
    setLoading(loadingUpdate)
    setNextDisabled(false)

    const onNext = async () => {
      if (
        !isEqual(buildZoomData?.extraInfo, comments) ||
        !isEqual(
          buildZoomData?.photos,
          state.map((s) => s.key),
        )
      )
        await updateProjectAndRefetch({
          variables: {
            data: {
              data: {
                buildZoomData: {
                  ...project.data.buildZoomData,
                  photos: state.map((s) => s.key),
                  extraInfo: comments ?? '',
                } as any,
              },
            },
            where: {
              id: project.id,
            },
          },
        })
    }

    setOnNext(() => onNext)
  }, [state, loadingUpdate])

  return (
    <Container>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3>
              Is there anything else you want to share about your project?
            </h3>
            <p>
              We’ve compiled your floor plan and room images and will send them
              to Buildzoom, please add any other photos you would like to share.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <ProjectFileUploads
          projectFiles={state}
          projectFileType={ProjectFileType.BUILDZOOM_PHOTO}
          projectId={project.id}
          onProjectFileRemoved={async (f) => {
            setState(state.filter((s) => s.id !== f.id))
            if (f.type === ProjectFileType.BUILDZOOM_PHOTO) {
              await deleteProjectFile({
                variables: {
                  where: {
                    id: f.id ?? '',
                  },
                },
              })
            }
          }}
          onProjectFilesUploaded={async (f) => {
            setState((s) => [...s, ...(f as ProjectFile[])])
          }}
          refetch={refetch}
        />
        <Grid.Row centered>
          <Grid.Column>
            <p>Additional Comments:</p>
            <StyledTextArea
              minRows={3}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setComments(e.target.value)
              }
              placeholder="For example: I’m also interested in remodeling a bathroom and moving a light in the dining room."
              value={comments}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

export default Information
