import React from 'react'

import { navigate, RouteComponentProps } from '@reach/router'

import { getAddressStreetAndLocalityString } from 'context/address/utils'
import useAuth from 'context/auth/use'
import PaymentSourceManyProvider from 'context/payment-source/provider-many'
import useProject from 'context/project/use'
import { parseOwners } from 'context/project/utils'
import { NavigationProps } from 'design-system/components/guide/navigation'
import GuideLayout from 'design-system/containers/guide-layout'
import { getNameForUser } from 'views/utils'

import DesignPlan from './sections/design'
import DesignBriefRecap from './sections/design-brief-recap'
import Feedback from './sections/feedback'
import NextCall from './sections/next-call'
import NextSteps from './sections/next-steps'
import ProjectDeposit from './sections/project-deposit'
import Samples from './sections/samples'
import Visuals from './sections/visuals'

type DesignPresentationProps = RouteComponentProps<{
  room_id: string
}>

const navItems: NavigationProps['items'] = [
  {
    linkTo: 'recap',
    title: 'Recap design brief, has anything changed?',
    icon: ['fal', 'ballot'],
  },
  {
    linkTo: 'samples',
    title: 'Check samples',
    icon: ['fal', 'rectangle-vertical'],
  },
  {
    linkTo: 'visuals',
    title: 'Your visuals',
    icon: ['fal', 'images'],
  },
  {
    linkTo: 'design',
    title: 'Your design',
    icon: ['fal', 'window-flip'],
  },
  {
    linkTo: 'next-steps',
    title: 'Next steps',
    icon: ['fal', 'circle-question'],
  },
  {
    linkTo: 'project-deposit',
    title: '$1,999 Project Deposit to move forward',
    icon: ['fal', 'badge-dollar'],
  },
  {
    linkTo: 'next-call',
    title: 'Schedule the next call ',
    icon: ['fal', 'video-plus'],
  },
]

const DesignPresentation = ({ room_id }: DesignPresentationProps) => {
  const { isFORMStaff } = useAuth()
  const { project } = useProject()

  const { primaryOwner } = parseOwners(project)

  return (
    <PaymentSourceManyProvider>
      <GuideLayout
        guideId="design-presentation-page"
        coverProps={{
          address: getAddressStreetAndLocalityString(project.projectAddress),
          src: '/images/interactive-guides/design-presentation/design-presentation.webp',
          subTitle: getNameForUser(primaryOwner),
          title: 'Design Presentation',
        }}
        handleLogoClick={() => navigate(`/app/projects/${project.id}`)}
        introProps={{
          agenda: [
            {
              timer: '60 mins',
              text: 'Review the design together, discuss options and decide whether to move forward or not.',
            },
          ],
        }}
        navItems={
          isFORMStaff
            ? navItems
            : navItems.filter((i) => i.linkTo !== 'next-call')
        }
      >
        <DesignBriefRecap />
        <Feedback />
        <Samples />
        <Visuals />
        <DesignPlan projectId={project.id} roomId={room_id ?? ''} />
        <Feedback noTitle />
        <NextSteps />
        <ProjectDeposit />
        {isFORMStaff ? <NextCall projectId={project.id} /> : null}
      </GuideLayout>
    </PaymentSourceManyProvider>
  )
}

export default DesignPresentation
