import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Divider, Grid } from 'semantic-ui-react'

import NobiliaOptionSelectionCard from 'components/shared/catalog/nobilia-option/card'
import useAuth from 'context/auth/use'
import useRoom from 'context/room/use'
import Button from 'design-system/components/button'
import Carousel from 'design-system/components/carousel'
import ExpandImage from 'design-system/components/guide/expand-image'
import SelectionCover from 'design-system/components/guide/selection-cover'
import { Section, Spacer } from 'design-system/components/guide/utils'
import { SelectionCardVariation } from 'design-system/components/selection-card'
import { Breakpoint } from 'styles/app/system'
import { GuideType } from 'types/utils'

const CDN = process.env.GATSBY_CDN_ROOT
const handlesConfig = [
  {
    src: `${CDN}/images/interactive-guides/design-call/handle-style-shaker.webp`,
    title: 'Handle',
  },
  {
    src: `${CDN}/images/interactive-guides/design-call/handle-style-integrated-handle.webp`,
    title: 'Integrated Handle',
  },
  {
    src: `${CDN}/images/interactive-guides/design-call/handle-style-handleless.webp`,
    title: 'Handleless',
  },
]

interface HandlesProps {
  guideType: GuideType
  header?: JSX.Element
  projectId: string
}

const Handles = (props: HandlesProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const [handleStyleIdx, sethandleStyleIdx] = useState<number | null>(null)
  const { isFORMStaff } = useAuth()
  const { handles, room } = useRoom()

  return (
    <>
      <Section id="handles">
        <Grid centered>
          {props.header}
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h3 className="gray">Our handle styles</h3>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          {isMobile ? (
            <Grid.Row>
              <Grid.Column>
                <Carousel
                  slides={handlesConfig.map((v, idx) => ({
                    id: idx,
                    item: (
                      <ExpandImage
                        activeIndex={handleStyleIdx}
                        idx={idx}
                        images={handlesConfig.map((v) => v.src)}
                        onClose={() => sethandleStyleIdx(null)}
                        src={v.src}
                        title={v.title}
                      />
                    ),
                  }))}
                  hideNavButtons
                  slideSize={80}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row stretched columns={3} className="row-gap">
              {handlesConfig.map((v, idx) => (
                <Grid.Column key={v.title}>
                  <ExpandImage
                    activeIndex={handleStyleIdx}
                    idx={idx}
                    images={handlesConfig.map((v) => v.src)}
                    onClose={() => sethandleStyleIdx(null)}
                    src={v.src}
                    title={v.title}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          )}
          <Spacer />
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h3 className="gray">Handle selection</h3>
              {handles?.length ? (
                <p>
                  You’ve selected {handles.length} handles for your project.
                </p>
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          {!handles?.length ? (
            <Grid.Row>
              <Grid.Column>
                <SelectionCover
                  buttonEnabled
                  buttonText={isFORMStaff ? 'Select' : 'See options'}
                  image="design-call/kickoff-handles.png"
                  onClick={() =>
                    navigate(
                      `/app/projects/${props.projectId}/rooms/${room?.id}/selection/handle-selection`,
                      {
                        state: {
                          fromGuide: props.guideType,
                        },
                      },
                    )
                  }
                  title="Select handles"
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <>
              <Grid.Row centered>
                {handles.map((f) => (
                  <Grid.Column key={f.id} computer={4} tablet={5} mobile={8}>
                    <NobiliaOptionSelectionCard
                      nobiliaOption={f}
                      selectionCardProps={{
                        expandOnly: true,
                        imageRatio: '3/2',
                      }}
                      selectionCardVariation={
                        SelectionCardVariation.EXPAND_SELECT
                      }
                    />
                  </Grid.Column>
                ))}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Button
                    color="blue"
                    fontAwesomeIcon="arrow-right"
                    kind="solid"
                    onClick={() =>
                      navigate(
                        `/app/projects/${props.projectId}/rooms/${room?.id}/selection/handle-selection`,
                        {
                          state: {
                            fromGuide: props.guideType,
                          },
                        },
                      )
                    }
                    text={isFORMStaff ? 'Change selection' : 'See options'}
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          {!isMobile && <Spacer height={300} />}
        </Grid>
      </Section>
    </>
  )
}

export default Handles
