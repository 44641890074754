import React from 'react'

import { Divider } from 'semantic-ui-react'
import styled from 'styled-components'

import { Breakpoint } from 'styles/app/system'

import HexagonStepper from '../hexagon-stepper/hexagon-stepper'

interface LogisticsCardProps {
  bottomInfo?: React.ReactNode
  detailsModal?: React.ReactNode
  finalInfo?: string | null
  finalTitle?: string
  iconHeader: React.ReactNode
  parts?: number
  steps: {
    done: boolean
    title: string
    extraInfo?: string | null
  }[]
  textHeader: string
}

const LogisticsCard = ({
  bottomInfo,
  detailsModal,
  finalInfo,
  finalTitle,
  iconHeader,
  parts,
  steps,
  textHeader,
}: LogisticsCardProps) => {
  return (
    <StyledCard>
      <div className="header-content">
        <h4 style={{ alignItems: 'center', display: 'inline-flex' }}>
          {iconHeader}
          {textHeader}
        </h4>
        {!!parts && (
          <div>
            <p className="gray no-margin">Includes: {parts} parts</p>
            {detailsModal}
          </div>
        )}
      </div>
      <Divider />
      <div>
        <HexagonStepper steps={steps} />
      </div>
      {finalTitle && (
        <div style={{ marginLeft: '30px' }}>
          <h6 className="no-margin">{finalTitle}</h6>
          {finalInfo ? (
            <p>{finalInfo}</p>
          ) : (
            <p className="light-gray" style={{ fontStyle: 'italic' }}>
              Pending
            </p>
          )}
        </div>
      )}
      {bottomInfo}
    </StyledCard>
  )
}

const StyledCard = styled.div<{ styled?: { disabled?: boolean } }>`
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 15px -1px rgba(120, 113, 108, 0.09);
  gap: 24px;
  padding: 24px;

  div.header-content {
    width: 100%;

    div {
      align-items: baseline;
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
    }
  }
  div.content {
    align-items: baseline;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 22px;
    justify-content: center;
    padding: 24px;
  }

  img {
    flex: 1;
  }

  @media ${Breakpoint.onlyMobile} {
    flex-direction: column-reverse;
    gap: 24px;

    div.content {
      padding: 0;
    }
  }
`

export default LogisticsCard
