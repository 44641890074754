import React, { useEffect, useState } from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import AppliancesEditView from 'components/app/project/room/appliances/edit'
import StepTitle from 'components/app/step-title'
import GenericProductClassManyProvider from 'context/generic-product-class/provider-many'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomMutate from 'context/room/use-mutate'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'

import { StepViewProps } from '../types'

const AppliancesView = ({
  setNextDisabled,
  setOnNext,
  setSkip,
  isExtraRoom,
}: StepViewProps) => {
  const { isTradeProject } = useProject()
  const { room, appliancesAll } = useRoom()
  const { updateRoomAndRefetch } = useRoomMutate()
  const [selection, setSelection] = useState(
    isExtraRoom || isTradeProject
      ? 'show-appliances'
      : room?.data.appliancesFlowSelection,
  )

  useEffect(() => {
    setOnNext(undefined)
    setNextDisabled(false)
  }, [])

  useEffect(() => {
    //* If no selection, show skip button
    if (!selection) {
      setSkip(true)
      return
    }
    //* If needs help, show Next button
    if (selection === 'need-help') {
      setSkip(false)
      return
    }

    //* selection === 'show-appliances'
    //* If no appliance has been added, show skip for now message
    setSkip(!appliancesAll.length)
  }, [selection, appliancesAll])

  useEffect(() => {
    const handleNext = async () => {
      await updateRoomAndRefetch({
        variables: {
          data: {
            data: {
              appliancesFlowSelection: selection,
            },
          },
          where: {
            id: room?.id ?? '',
          },
        },
      })
    }

    setOnNext(() => handleNext)
  }, [selection])

  return (
    <Grid stackable>
      {selection === 'show-appliances' || appliancesAll.length ? (
        <>
          <StepTitle
            title={
              isTradeProject ? 'Add kitchen appliances' : 'Add your appliances'
            }
            subTitle={
              isTradeProject
                ? 'In case you know which ones the project will be using for the main kitchen'
                : "Feel free to skip this step if you don't have your appliances selected. We'll discuss them in our next call"
            }
          />
          <GenericProductClassManyProvider
            loaderProps={{
              fullScreen: false,
              flex: true,
              style: {
                marginTop: 40,
              },
            }}
            defaultFilters={{
              identifierNotIn: ['backsplash', 'countertop', 'plinth'],
              sortBy: 'identifier',
              sortDirection: 'ascending',
              take: undefined,
            }}
          >
            <AppliancesEditView deletionEnabled editEnabled />
          </GenericProductClassManyProvider>
        </>
      ) : (
        <>
          <Grid.Row textAlign="center">
            <Grid.Column>
              <h3>How would you like us to handle appliances?</h3>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row centered stretched style={{ rowGap: 24 }}>
            <Grid.Column
              widescreen={3}
              largeScreen={3}
              computer={5}
              tablet={5}
              mobile={16}
            >
              <SelectIconCard
                icon="messages"
                onClick={() => setSelection('need-help')}
                selected={selection === 'need-help'}
                text="I need help from my designer"
              />
            </Grid.Column>
            <Grid.Column
              widescreen={3}
              largeScreen={3}
              computer={5}
              tablet={5}
              mobile={16}
            >
              <SelectIconCard
                icon="lightbulb"
                onClick={() => setSelection('show-appliances')}
                text="I know which appliances I’ll be using "
              />
            </Grid.Column>
          </Grid.Row>
        </>
      )}
    </Grid>
  )
}

export default AppliancesView
