import React from 'react'

import { Container, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import Carousel from 'components/shared/carousel/carousel'
import useRoom from 'context/room/use'
import { Colors } from 'styles/app/system'

import SectionTitle from './section-title'

const Inspiration = () => {
  const { inspirationElements = [], inspirationGenerals = [] } = useRoom()
  return !!inspirationElements?.length || !!inspirationGenerals?.length ? (
    <MainContainer>
      <Container textAlign="center">
        <Grid stackable>
          <SectionTitle
            subtitle="These images showcase the design direction we discussed in our call."
            title="Inspiration"
          />
          <Grid.Row>
            <Grid.Column>
              <Carousel
                items={[...inspirationGenerals, ...inspirationElements].map(
                  (e) =>
                    `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                      e.data?.customImageUrl ||
                        e.data?.imageUrl ||
                        'images/app/catalog_missing_file.png',
                    )}`,
                )}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </MainContainer>
  ) : null
}

export default Inspiration

const MainContainer = styled.section`
  background: ${Colors.gray50};
  padding: 65px 0 50px;

  .row {
    padding: 0;
  }
`
