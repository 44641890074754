import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import OrderCard from 'components/app/project/order/card'
import StepTitleRow from 'components/app/step-title'
import PaymentSources from 'components/app/user/payment-source'
import Loader from 'components/shared/loader'
import OrdersCard from 'components/shared/project/order-samples/card-many'
import TermsAndConditions from 'components/shared/terms-and-conditions'
import { notifyError } from 'context/notifications/trigger'
import useOrderMutateNoContext from 'context/order/use-mutate-no-context'
import { getOrderFormattedName } from 'context/order/utils'
import * as checkoutUtils from 'context/order/utils-checkout'
import usePaymentSourceMany from 'context/payment-source/use-many'
import useProjectOrderSamplesMany from 'context/project/order-samples/use-many'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import AppLayout from 'design-system/containers/app-layout'
import { PaymentType, PaymentMethodType } from 'types/payment'

const Module = (
  props: RouteComponentProps<{
    location: {
      state: {
        fromDesignCall?: boolean
      }
    }
  }>,
) => {
  const { project } = useProject()
  const { ordersPending, refetch } = useProjectOrderSamplesMany()
  const { updateOrder } = useOrderMutateNoContext()
  const { paymentSourceCard, refetch: refetchPaymentSources } =
    usePaymentSourceMany()

  const [loading, setLoading] = useState<boolean>(false)
  const [termsAndConditionsRead, setTermsAndConditionsRead] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType | null>()

  if (loading) return <Loader />

  const amountDue = ordersPending.reduce(
    (acc, p) => acc + checkoutUtils.calculateAmountDue(p),
    0,
  )

  const onPlaceOrders = async () => {
    setLoading(true)
    for (const order of ordersPending) {
      const amountDue = checkoutUtils.calculateAmountDue(order)
      if (amountDue)
        await updateOrder({
          variables: {
            data: {
              payments: {
                create: [
                  {
                    amount: amountDue,
                    description: `${getOrderFormattedName(
                      order,
                    )} Balance Payment`,
                    metadata: {
                      type: PaymentType.ORDER_PAYMENT,
                      method: PaymentMethodType.CREDIT_CARD,
                      name: `${getOrderFormattedName(order)} Balance Payment`,
                    },
                    stripeSourceId: paymentSourceCard?.id,
                    project: { connect: { id: project.id } },
                  },
                ],
              },
              placedAt: new Date(),
            },
            where: {
              id: order.id ?? '',
            },
          },
          onError: ({ message }) => {
            notifyError(message)
            navigate(`/app/projects/${project.id}/sample-orders`, {
              state: props.location?.state,
            })
          },
        })
      else
        await updateOrder({
          variables: {
            data: {
              placedAt: new Date(),
            },
            where: {
              id: order.id ?? '',
            },
          },
        })
    }
    // refetch before navigating
    await refetch()
    navigate(`/app/projects/${project.id}/sample-orders`, {
      state: props.location?.state,
    })
  }

  const disablePayOrder =
    !termsAndConditionsRead ||
    ordersPending.some((o) => !o.shippingAddress) ||
    (amountDue && !paymentSourceCard) ||
    loading

  return (
    <AppLayout>
      <Grid stackable>
        <StepTitleRow
          subTitle="Sample orders cannot be changed after placement, so please double check your selections. Samples typically take 2-7 business days to arrive."
          title="Place order"
        />
        {amountDue > 0 ? (
          <Grid.Row centered>
            <Grid.Column computer={8} tablet={14} mobile={16}>
              <PaymentSources
                includeInvoice={false}
                onPaymentSourceCreated={refetchPaymentSources}
                onPaymentSourceDeleted={refetchPaymentSources}
                paymentMethod={paymentMethod}
                paymentSourceCard={paymentSourceCard}
                setPaymentMethod={setPaymentMethod}
              />
            </Grid.Column>
          </Grid.Row>
        ) : null}
        <Grid.Row centered>
          <Grid.Column computer={8} tablet={14} mobile={16}>
            <p className="subtitle light-gray">Summary</p>
            {ordersPending.length > 1 ? (
              <OrdersCard
                orders={ordersPending}
                showPayments
                title="Samples Orders"
              />
            ) : (
              <OrderCard order={ordersPending[0]} showPayments />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered style={{ paddingBottom: '0' }}>
          <Grid.Column computer={8} tablet={8} mobile={16}>
            <TermsAndConditions
              checked={termsAndConditionsRead}
              onChange={() =>
                setTermsAndConditionsRead(!termsAndConditionsRead)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          centered
          style={{ justifyContent: 'center', paddingTop: '0' }}
        >
          <Grid.Column computer={8} tablet={8} mobile={16}>
            <div style={{ display: 'flex', gap: '16px' }}>
              <Button
                loading={loading}
                onClick={() =>
                  navigate(`/app/projects/${project.id}/sample-orders`, {
                    state: props.location?.state,
                  })
                }
                color="blue"
                kind="solid"
                size="fluid"
                text="Back to Edit"
              />
              <Button
                disabled={disablePayOrder}
                kind="solid"
                loading={loading}
                onClick={onPlaceOrders}
                size="fluid"
                text={`${amountDue > 0 ? 'Pay and' : ''} Order`}
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default Module
