import React from 'react'

import { Image } from 'semantic-ui-react'

import Button from 'design-system/components/button/button'
import { Modal } from 'design-system/components/modal'
import { User } from 'types/user'
import { getNameForUser } from 'views/utils'

type NewProjectModalProps = {
  designer: User | undefined
  loadingSave?: boolean
  onClose?: () => void
  onRequest?: () => void
  open: boolean
}

const NewProjectModal = ({
  designer,
  loadingSave,
  onClose,
  onRequest,
  open,
}: NewProjectModalProps) => {
  return (
    <Modal
      customActions={
        <Button
          kind="solid"
          loading={loadingSave}
          onClick={onRequest}
          size="fluid"
          text="Request"
        />
      }
      hideCancelButton
      onClose={onClose}
      open={open}
      size="small"
      title="Request new project"
    >
      <h6 style={{ margin: '8px 0 24px' }}>
        Your designer will be notified that you are requesting a new project.
        They will follow up with next steps.
      </h6>
      <p className="small">Your designer</p>
      <div className="flex" style={{ gap: '8px', marginBottom: '24px' }}>
        <Image circular src={designer?.picture} style={{ width: '40px' }} />
        <h4 className="no-margin">{getNameForUser(designer)}</h4>
      </div>
      <p className="small gray">
        Note that a new project means it will have a new delivery address.
      </p>
    </Modal>
  )
}

export default NewProjectModal
