import React from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import StepTitle from 'components/app/step-title'
import Paid from 'components/shared/project/payment/versions/paid'
import useProject from 'context/project/use'
import AppLayout from 'design-system/containers/app-layout'
import { PaymentType } from 'types/payment'

const depositTypes = [
  PaymentType.DESIGN_DEPOSIT,
  PaymentType.PROJECT_DEPOSIT,
  PaymentType.EXTRA_ROOM_DEPOSIT,
]

const paymentTypes = [
  PaymentType.VISUALS_UPGRADE_PAYMENT,
  PaymentType.EXTRA_REVISION_PAYMENT,
  PaymentType.STORAGE_PAYMENT,
]

const orderPaymentTypes = [PaymentType.ORDER_PAYMENT]

const Module = ({
  type,
}: RouteComponentProps<{
  project_id: string
  type: 'payments' | 'deposits' | 'order-payments'
}>) => {
  const { paymentsAll } = useProject()
  const payments = paymentsAll.filter((p) => {
    if (p.status !== 'succeeded') return
    const paymentsTypes =
      type === 'payments'
        ? paymentTypes
        : type === 'order-payments'
        ? orderPaymentTypes
        : depositTypes

    return paymentsTypes.includes(p.metadata?.type)
  })

  return (
    <AppLayout>
      <Grid stackable>
        <StepTitle
          title={
            type === 'payments'
              ? 'Payments'
              : type === 'deposits'
              ? 'Deposits'
              : 'Order Payments'
          }
        />
        <Grid.Row stretched centered className="row-gap">
          {payments.map((p) => (
            <Grid.Column key={p.id} computer={5} tablet={8} mobile={16}>
              <Paid payment={p} />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </AppLayout>
  )
}

export default Module
