import React from 'react'

import styled from 'styled-components'

import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import Button from 'design-system/components/button'
import { ModalBasic } from 'design-system/components/modal'

const ReviewDocumentModal = ({
  sidebarToggle,
  showReviewModal,
  toggleReviewModal,
}: {
  showReviewModal: boolean
  sidebarToggle: () => void
  toggleReviewModal: (toggle: boolean) => void
}) => {
  const { project } = useProject()
  const { updateProjectAndRefetch } = useProjectMutate()

  const handleStart = async () => {
    sidebarToggle()
    await updateProjectAndRefetch({
      variables: {
        data: {
          data: {
            fileReviewModalDone: true,
          },
        },
        where: {
          id: project.id,
        },
      },
    })
    toggleReviewModal(false)
  }

  return (
    <ModalBasic
      dark
      open={showReviewModal}
      onClose={() => toggleReviewModal(false)}
      size="large"
    >
      <Container>
        <Button
          onClick={handleStart}
          kind="ghost"
          color="gray"
          fontAwesomeIcon="times"
          className="close"
        />
        <h2>Welcome to FORM document review</h2>
        <p>
          To comment on your design, simply click on what you’d like to revise
          and write
          <br />
          in the message you’d like to send to your designer.
        </p>
        <video
          autoPlay
          className="video"
          loop
          muted
          playsInline
          width="95%"
          src={`${process.env.GATSBY_CDN_ROOT}/videos/revision-functionality.mp4`}
        />
        <Button onClick={handleStart} kind="solid" text="Start" size="large" />
      </Container>
    </ModalBasic>
  )
}

const Container = styled.div`
  padding: 32px;
  text-align: center;
  position: relative;

  & > video {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  & > button.close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`

export default ReviewDocumentModal
