import React, { useEffect } from 'react'

import RoomProjectFileUploads from 'components/app/project/room/project-file-uploads'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import { ProjectFileType } from 'types/project-file'

import { StepViewProps } from '../types'

const InspirationView = ({
  setNextDisabled,
  setOnNext,
  setSkip,
  chooseProspectFiles,
}: StepViewProps) => {
  const { isTradeProject } = useProject()
  const { inspirationImages } = useRoom()

  useEffect(() => {
    setOnNext(undefined)
    setNextDisabled(false)
  }, [])

  useEffect(() => {
    //*Skip for now message if no images have been added
    setSkip(!inspirationImages.length)
  }, [inspirationImages])

  return (
    <RoomProjectFileUploads
      chooseProspectFiles={chooseProspectFiles}
      key="inspiration-images"
      path="inspiration-images"
      projectFiles={inspirationImages}
      projectFileType={ProjectFileType.INSPIRATION_IMAGE}
      title={
        isTradeProject
          ? 'Are there any inspiration images you want us to look at?'
          : 'Are there any images that would help us see your vision?'
      }
    />
  )
}

export default InspirationView
