import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Divider, Grid } from 'semantic-ui-react'

import CatalogFeatureProvider from 'context/catalog-feature/provider'
import useCatalogFeature from 'context/catalog-feature/use'
import { parseMedia } from 'context/media/utils-many'
import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'
import { getProductFeatureCategoryFromGroupId } from 'views/utils'

import { Slider, StyledGrid } from './utils'

const Module = () => {
  const { catalogFeature } = useCatalogFeature()
  const { reference, imageClose, imageMedium } = parseMedia(
    catalogFeature.media,
  )
  const gallery = [...reference, ...imageClose, ...imageMedium].slice(0, 4)

  return (
    <StyledGrid stackable>
      <Grid.Row>
        <Grid.Column computer={10} tablet={9} mobile={16}>
          <Slider items={gallery} />
        </Grid.Column>
        <Grid.Column computer={5} tablet={6} mobile={16}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <h2>{catalogFeature.description}</h2>
                {catalogFeature.data?.showOnWebsiteCatalog ? (
                  <Button
                    kind="ghost"
                    fontAwesomeIcon="arrow-right"
                    text="Open in Catalog"
                    onClick={() =>
                      window.open(
                        `${process.env.GATSBY_HOME_URL}/catalog/features/#${catalogFeature.data?.displayName}`,
                      )
                    }
                  />
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column>
                <div className="flex-start">
                  <FontAwesomeIcon
                    className="icon orange"
                    icon={['fal', 'tag']}
                  />
                  <div>
                    <p className="caption no-margin">Product</p>
                    <p style={{ color: Colors.gray600 }}>
                      {getProductFeatureCategoryFromGroupId(
                        catalogFeature?.groupIdentifier,
                      )}
                    </p>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            {catalogFeature?.data?.usage ? (
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className="flex-start">
                    <FontAwesomeIcon
                      className="icon orange"
                      icon={['fal', 'hexagon']}
                    />
                    <div>
                      <p className="caption small no-margin">Usage</p>
                      <p style={{ color: Colors.gray600 }}>
                        {catalogFeature?.data.usage}
                      </p>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            ) : null}
            {catalogFeature?.data?.priceRange ? (
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className="flex-start">
                    <FontAwesomeIcon
                      className="icon orange"
                      icon={['fal', 'game-board']}
                    />
                    <div>
                      <p className="caption small no-margin">Price Range</p>
                      <p style={{ color: Colors.gray600 }}>
                        {catalogFeature?.data.priceRange}
                      </p>
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
            ) : null}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  )
}

export default (
  props: RouteComponentProps<{
    option_id: string
    feature_id: string
  }>,
) => {
  return (
    <CatalogFeatureProvider catalog_feature_id={props.feature_id}>
      <Module />
    </CatalogFeatureProvider>
  )
}
