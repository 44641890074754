import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import useDesign from 'context/design/use'
import ImageCarousel from 'design-system/components/carousel/image-carousel'
import { Section } from 'design-system/components/guide/utils'

const Visuals = () => {
  const { rendersReleased } = useDesign()

  return (
    <Section id="visuals">
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Your visuals</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <ImageCarousel
              images={rendersReleased.map(
                (r) =>
                  `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    r.metadata?.keyCompressed || r.key,
                  )}`,
              )}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default Visuals
