import React, { useState } from 'react'

import { Grid } from 'semantic-ui-react'

import OrderCard from 'components/app/project/order/card'
import Checkout from 'components/app/user/checkout'
import useOrder from 'context/order/use'
import useOrderMutate from 'context/order/use-mutate'
import { getOrderFormattedName, getWireACHDiscount } from 'context/order/utils'
import { calculateAmountDue } from 'context/order/utils-checkout'
import useProject from 'context/project/use'
import fireEvent from 'context/tracking/events'
import { PaymentMethodType, PaymentType } from 'types/payment'
import { PaymentSource } from 'types/payment-source'

const OrderCheckoutPay = ({ disabled }: { disabled?: boolean }) => {
  const { project } = useProject()
  const { amountDue: amountDueInitial, order, refetch } = useOrder()
  const { updateOrderAndRefetch, loadingUpdate } = useOrderMutate()
  const [discounts, setDiscounts] = useState(order.discounts || [])
  const [amountDue, setAmountDue] = useState(amountDueInitial)

  const onUpdatePaymentMethod = (paymentMethod?: PaymentMethodType | null) => {
    const wireACHDiscount = getWireACHDiscount(order)
    if (paymentMethod !== PaymentMethodType.INVOICE) {
      const discountsUpdated = discounts.filter(
        (d) => d.name !== wireACHDiscount.name,
      )
      setDiscounts(discountsUpdated)
      setAmountDue(
        calculateAmountDue({
          ...order,
          discounts: discountsUpdated,
        }),
      )
    } else {
      const discountsUpdated = [wireACHDiscount, ...discounts]
      setDiscounts(discountsUpdated)
      setAmountDue(
        calculateAmountDue({
          ...order,
          discounts: discountsUpdated,
        }),
      )
    }
  }

  const onPay = async (
    paymentSource?: PaymentSource,
    paymentMethod?: PaymentMethodType,
  ) => {
    const isPaymentMethodCard = paymentMethod === PaymentMethodType.CREDIT_CARD
    const isPaymentMethodInvoice = paymentMethod === PaymentMethodType.INVOICE
    const stripeSourceId = isPaymentMethodCard ? paymentSource?.id : null
    await updateOrderAndRefetch({
      variables: {
        data: {
          discounts,
          placedAt: isPaymentMethodCard ? new Date() : null,
          metadata: {
            ...order.metadata,
            termsAndConditionsRead: true,
          },
          payments: {
            create: [
              {
                amount: Math.round(amountDue ?? 0),
                description: `${getOrderFormattedName(order)} Balance Payment`,
                metadata: {
                  method: paymentMethod,
                  type: PaymentType.ORDER_PAYMENT,
                  name: `${getOrderFormattedName(order)} Balance Payment`,
                },
                ...(isPaymentMethodInvoice && {
                  status: 'pending',
                }),
                stripeSourceId,
                project: { connect: { id: project.id } },
              },
            ],
          },
        },
        where: { id: order.id ?? '' },
      },
      onCompleted: async () => {
        refetch()
        fireEvent({
          type: 'order_payment_completed',
          value: amountDue ? Math.round(amountDue / 100) : 0,
        })
      },
    })
  }

  return (
    <Grid.Column computer={8} tablet={16} mobile={16}>
      <Checkout
        buttonText="Pay and send to production"
        disabled={disabled}
        includeInvoice
        includeTermsAndConditions
        loading={loadingUpdate}
        onPay={onPay}
        onUpdatePaymentMethod={onUpdatePaymentMethod}
        summary={<OrderCard order={{ ...order, discounts }} showPayments />}
        summaryTitle="Summary"
      />
    </Grid.Column>
  )
}

export default OrderCheckoutPay
