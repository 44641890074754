import React, { useState } from 'react'

import { navigate } from 'gatsby'
import moment from 'moment'
import { Grid } from 'semantic-ui-react'

import Checkout from 'components/app/user/checkout'
import ConfirmationModal from 'components/shared/confirmation-modal'
import PaymentModule from 'components/shared/payment-module'
import PaymentCard from 'components/shared/project/payment/card'
import useAuth from 'context/auth/use'
import PaymentSourceManyProvider from 'context/payment-source/provider-many'
import useProject from 'context/project/use'
import useProjectPaymentMutate from 'context/project/use-payment-mutate'
import fireEvent from 'context/tracking/events'
import Button from 'design-system/components/button'
import ThankYouCard from 'design-system/components/guide/thank-you-card'
import { Section, Spacer } from 'design-system/components/guide/utils'
import StatusLabel from 'design-system/components/status-label'
import { PaymentType } from 'types/payment'
import { StatusMode } from 'types/utils'

const DesignDeposit = () => {
  const { isFORMStaff } = useAuth()
  const {
    paymentsDesignDepositSucceeded,
    paymentsDesignDepositNotSucceeded,
    project,
  } = useProject()
  const { updatePaymentAndRefetch, loadingUpdate } = useProjectPaymentMutate()
  const [confirmPaymentLink, toggleConfirmPaymentLink] =
    useState<boolean>(false)

  const payment =
    paymentsDesignDepositNotSucceeded?.[0] ||
    paymentsDesignDepositSucceeded?.[0]

  return (
    <Section id="design-deposit" style={{ flexDirection: 'column' }}>
      <PaymentSourceManyProvider>
        <PaymentModule
          hidePaymentRequest={
            (!paymentsDesignDepositSucceeded.length && isFORMStaff) || !payment
          }
          paymentRequest={
            paymentsDesignDepositSucceeded.length ? (
              <ThankYouCard
                onClick={() => navigate(`/app/projects/${project.id}`)}
              />
            ) : (
              <Checkout
                buttonText="Pay and Start Project"
                includeInvoice={false}
                loading={loadingUpdate}
                onPay={async (paymentSource, paymentMethod) => {
                  await updatePaymentAndRefetch({
                    variables: {
                      data: {
                        metadata: {
                          ...payment.metadata,
                          method: paymentMethod,
                        },
                        stripeSourceId: paymentSource?.id,
                      },
                      where: {
                        id: payment.id ?? '',
                      },
                    },
                    onCompleted: () => {
                      fireEvent({
                        type: 'deposit_completed',
                      })
                    },
                  })
                }}
                summary={<PaymentCard payment={payment} />}
                summaryTitle="Summary"
              />
            )
          }
          paymentType={PaymentType.DESIGN_DEPOSIT}
          title="Design your kitchen with FORM for $299"
        />
        {isFORMStaff &&
          !paymentsDesignDepositSucceeded?.length &&
          !!paymentsDesignDepositNotSucceeded?.length && (
            <Grid centered>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Spacer height={60} />
                  <div style={{ background: 'white' }}>
                    <Button
                      disabled={!!payment.metadata?.sendPaymentLinkAt}
                      kind="solid"
                      loading={loadingUpdate}
                      onClick={() => toggleConfirmPaymentLink(true)}
                      text="Send Payment Link"
                    />
                  </div>
                  {payment.metadata?.sendPaymentLinkAt && (
                    <div
                      style={{
                        background: 'white',
                        margin: '30px auto 0',
                        width: 'fit-content',
                      }}
                    >
                      <StatusLabel
                        type={StatusMode.Confirmed}
                        text={`Sent on ${moment(
                          payment.metadata?.sendPaymentLinkAt,
                        ).format('MMM DD YYYY')}`}
                      />
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
      </PaymentSourceManyProvider>
      <ConfirmationModal
        firstTitle="Are you sure you want to send the Design Deposit payment link?"
        onCancel={() => toggleConfirmPaymentLink(false)}
        onConfirm={async () => {
          await updatePaymentAndRefetch({
            variables: {
              data: {
                metadata: {
                  ...payment.metadata,
                  sendPaymentLinkAt: new Date(),
                },
              },
              where: {
                id: payment.id ?? '',
              },
            },
          })
          toggleConfirmPaymentLink(false)
        }}
        open={!!confirmPaymentLink}
      />
    </Section>
  )
}

export default DesignDeposit
