import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import { parseFilesByType } from 'context/project-file/utils'
import { parseFiles } from 'context/project-file/utils-many'
import FilePlaceholderCard from 'design-system/components/cards/file-placeholder-card'
import ImageCarousel from 'design-system/components/carousel/image-carousel'
import TaskCheck from 'design-system/components/guide/task-check'
import {
  Section,
  Spacer,
  UnderlineSubtitle,
} from 'design-system/components/guide/utils'
import { ProjectFile } from 'types/project-file'

const questions = [
  { label: 'Scope' },
  { label: 'Finishes and Functionality' },
  { label: 'Budget' },
  { label: 'Timeline & Project Status' },
]

const ProjectSpace = () => {
  const { roomsUnarchived } = useProject()

  const { allFloorPlans, allRoomPhotos } = roomsUnarchived.reduce<{
    allFloorPlans: ProjectFile[]
    allRoomPhotos: ProjectFile[]
  }>(
    (acc, r) => {
      const { floorPlans, roomPhotos } = parseFiles(r.files)
      acc.allFloorPlans.push(...floorPlans)
      acc.allRoomPhotos.push(...roomPhotos)
      return acc
    },
    {
      allFloorPlans: [],
      allRoomPhotos: [],
    },
  )

  const { imageFiles: planImages, otherFiles: planFiles } =
    parseFilesByType(allFloorPlans)
  const { imageFiles: photoImages, otherFiles: photoFiles } =
    parseFilesByType(allRoomPhotos)

  return (
    <Section id="project-space">
      <Grid>
        {planImages.length || planFiles.length ? (
          <>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <h3 className="gray">Project plans</h3>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            {planImages.length ? (
              <Grid.Row>
                <Grid.Column>
                  <ImageCarousel
                    images={planImages.map(
                      (file) =>
                        `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                          file.metadata?.keyCompressed || file.key,
                        )}`,
                    )}
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}
            {planFiles.length ? (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <UnderlineSubtitle>Files</UnderlineSubtitle>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  {planFiles.map((f) => (
                    <Grid.Column key={f.id} computer={4} tablet={8} mobile={16}>
                      <FilePlaceholderCard file={f} />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </>
            ) : null}
            <Spacer height={300} />
          </>
        ) : null}
        {photoImages.length || photoFiles.length ? (
          <>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <h3 className="gray">Room photos</h3>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            {photoImages.length ? (
              <Grid.Row>
                <Grid.Column>
                  <ImageCarousel
                    images={photoImages.map(
                      (file) =>
                        `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                          file.metadata?.keyCompressed || file.key,
                        )}`,
                    )}
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}
            {photoFiles.length ? (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <UnderlineSubtitle>Files</UnderlineSubtitle>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  {photoFiles.map((f) => (
                    <Grid.Column key={f.id} computer={4} tablet={8} mobile={16}>
                      <FilePlaceholderCard file={f} />
                    </Grid.Column>
                  ))}
                </Grid.Row>
              </>
            ) : null}
            <Spacer height={150} />
          </>
        ) : null}
        <Grid.Row>
          <Grid.Column textAlign="center">
            <UnderlineSubtitle>Tell us about your project</UnderlineSubtitle>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row stretched className="row-gap">
          {questions.map(({ label }) => {
            return (
              <Grid.Column key={label} computer={5} tablet={5} mobile={16}>
                <TaskCheck label={label} />
              </Grid.Column>
            )
          })}
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default ProjectSpace
