import React from 'react'

import { Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import TimelineCard from 'design-system/components/guide/timeline-card'
import { Section } from 'design-system/components/guide/utils'

const Timeline = () => {
  const { isTradeProject, project, projectAddress } = useProject()
  return (
    <Section id="timeline">
      <Grid centered>
        <Grid.Row>
          <Grid.Column largeScreen={8} computer={7} tablet={16} mobile={16}>
            <p className="subtitle light-gray">Timeline</p>
            <h3 className="gray no-margin">
              {isTradeProject ? 'Check in on timeline' : 'Review your timeline'}
            </h3>
          </Grid.Column>
          <Grid.Column largeScreen={8} computer={9} tablet={16} mobile={16}>
            <TimelineCard
              postalCode={projectAddress?.postalCode}
              url={`/app/projects/${project.id}/timeline`}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default Timeline
