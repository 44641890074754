import React, { useState } from 'react'

import { useQuery } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Divider, Grid, Image } from 'semantic-ui-react'

import Loader from 'components/shared/loader'
import { useDesignLayout } from 'content-queries/cx/design-layout'
import { useHandleType } from 'content-queries/cx/handle-type'
import Button from 'design-system/components/button'
import {
  FindUniqueDesignPayload,
  FindUniqueDesignVariables,
  FIND_UNIQUE_DESIGN_CONTEXT,
} from 'queries/design'
import { Colors } from 'styles/app/system'
import { Design } from 'types/design'

import {
  filterAndSortRendersForGallery,
  ImageContainer,
  SliderContainer,
  StyledGrid,
  ThumbnailsContainer,
} from './utils'

const Module = ({ design }: { design: Design | null }) => {
  const [currentSlide, setCurrentSlide] = useState(0)

  const photoGalleryRenders = filterAndSortRendersForGallery(
    design?.renders ?? [],
  )
  const photoGallery = [] as string[]
  for (const render of photoGalleryRenders) {
    photoGallery.push(render.metadata?.keyCompressed || render.key)
  }

  const { data: allHandleTypes } = useHandleType()
  const handleTypesTitle = design?.metadataGallery?.handleTypes?.map((ht) => {
    return allHandleTypes.find(
      (aht: { contentful_id: string }) => ht === aht.contentful_id,
    )?.title
  })

  const { data: allDesignLayouts } = useDesignLayout()
  const designLayoutTitle = allDesignLayouts.find(
    (ach: { contentful_id: string }) =>
      design?.metadataGallery?.layout === ach.contentful_id,
  )?.title

  return (
    <StyledGrid stackable>
      <Grid.Row>
        <Grid.Column computer={10} tablet={9} mobile={16}>
          <SliderContainer>
            <ImageContainer>
              <Image
                className="image"
                fluid
                src={`${process.env.GATSBY_CDN_ROOT}/${photoGallery[currentSlide]}`}
              />
            </ImageContainer>
            {photoGallery?.length > 1 ? (
              <ThumbnailsContainer>
                <Button
                  kind="solid"
                  color="blue"
                  className="arrow-button"
                  fontAwesomeIcon="arrow-left"
                  onClick={() => {
                    currentSlide === 0
                      ? setCurrentSlide(photoGallery.length - 1)
                      : setCurrentSlide(currentSlide - 1)
                  }}
                  style={{ marginRight: '30px' }}
                />
                {photoGallery?.map((_, index: number) => (
                  <div key={index}>
                    <Image
                      className="thumbnail"
                      onClick={() => setCurrentSlide(index)}
                      size="mini"
                      src={`${process.env.GATSBY_CDN_ROOT}/${photoGallery[index]}`}
                      style={{
                        outline:
                          currentSlide === index ? 'solid 1px' : 'none 0px',
                      }}
                    />
                  </div>
                ))}
                <Button
                  kind="solid"
                  color="blue"
                  fontAwesomeIcon="arrow-right"
                  className="arrow-button"
                  onClick={() => {
                    currentSlide === photoGallery.length - 1
                      ? setCurrentSlide(0)
                      : setCurrentSlide(currentSlide + 1)
                  }}
                  style={{ marginLeft: '30px' }}
                />
              </ThumbnailsContainer>
            ) : null}
          </SliderContainer>
        </Grid.Column>
        <Grid.Column computer={5} tablet={6} mobile={16}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <h2>Project {design?.metadataGallery?.name}</h2>
                <p className="sub-title">{design?.metadataGallery?.location}</p>
                <Button
                  kind="ghost"
                  fontAwesomeIcon="arrow-right"
                  text="Open in Catalog"
                  onClick={() =>
                    window.open(
                      `${process.env.GATSBY_HOME_URL}/gallery/${design?.name}`,
                    )
                  }
                />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column>
                <div className="flex-start">
                  <FontAwesomeIcon
                    className="icon orange"
                    icon={['fal', 'hexagon']}
                  />
                  <div>
                    <p className="caption no-margin">Fronts</p>
                    {design?.metadataGallery?.fronts?.map((f, idx: number) => (
                      <p
                        key={idx}
                        className="no-margin"
                        style={{ color: Colors.gray600 }}
                      >
                        {f.description}
                      </p>
                    ))}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="flex-start">
                  <FontAwesomeIcon
                    className="icon orange"
                    icon={['fal', 'window-maximize']}
                  />
                  <div>
                    <p className="caption no-margin">Handles</p>
                    {design?.metadataGallery?.handles?.map((h, idx: number) => (
                      <p
                        key={idx}
                        className="no-margin"
                        style={{ color: Colors.gray600 }}
                      >
                        {h.description}
                      </p>
                    ))}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="flex-start">
                  <FontAwesomeIcon
                    className="icon orange"
                    icon={['fal', 'rectangle-vertical']}
                  />
                  <div>
                    <p className="caption no-margin">Type</p>
                    {handleTypesTitle?.map((t: string, idx: number) => (
                      <p key={idx} style={{ color: Colors.gray600 }}>
                        {t}
                      </p>
                    ))}
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="flex-start">
                  <FontAwesomeIcon
                    className="icon orange"
                    icon={['fal', 'tag']}
                  />
                  <div>
                    <p className="caption no-margin">Cabinetry Cost</p>
                    <p style={{ color: Colors.gray600 }}>
                      {design?.metadataGallery?.priceRange} (
                      {design?.metadata.catalog})
                    </p>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="flex-start">
                  <FontAwesomeIcon
                    className="icon orange"
                    icon={['fal', 'square-dollar']}
                  />
                  <div>
                    <p className="caption no-margin">Features Level</p>
                    <p style={{ color: Colors.gray600 }}>
                      {design?.metadataGallery?.featuresLevel}
                    </p>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="flex-start">
                  <FontAwesomeIcon
                    className="icon orange"
                    icon={['fal', 'table-layout']}
                  />
                  <div>
                    <p className="caption no-margin">Layout</p>
                    <p style={{ color: Colors.gray600 }}>{designLayoutTitle}</p>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <div className="flex-start">
                  <FontAwesomeIcon
                    className="icon orange"
                    icon={['fal', 'expand']}
                  />
                  <div>
                    <p className="caption no-margin">Size</p>
                    <p style={{ color: Colors.gray600 }}>
                      {design?.metadataGallery?.size}
                    </p>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </StyledGrid>
  )
}

export default (
  props: RouteComponentProps<{
    design_id: string
  }>,
) => {
  const { data: { design } = { design: null }, loading } = useQuery<
    FindUniqueDesignPayload,
    FindUniqueDesignVariables
  >(FIND_UNIQUE_DESIGN_CONTEXT, {
    variables: {
      where: { id: props.design_id ?? '' },
    },
  })

  if (loading) return <Loader />

  // if (!design?.metadataGallery?.contentfulEntryId) return null

  return <Module design={design} />
}
