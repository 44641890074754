import React, { useState } from 'react'

import { Grid } from 'semantic-ui-react'

import { getFileImages } from 'context/project-file/utils'
import useRoom from 'context/room/use'
import useRoomProjectFileMutate from 'context/room/use-project-file-mutate'
import { Modal } from 'design-system/components/modal'
import { SelectionCardExpandSelect } from 'design-system/components/selection-card'
import { ProjectFile, ProjectFileType } from 'types/project-file'

const ProspectImageModal = ({
  onCloseModal,
  openModal,
  projectFileType,
}: {
  onCloseModal: () => void
  openModal: boolean
  projectFileType: ProjectFileType
}) => {
  const { prospectPhotosUnused } = useRoom()
  const { connectProjectFilesAndRefetch, loadingConnect } =
    useRoomProjectFileMutate()
  const [selectedFileIds, setSelectedFileIds] = useState<string[]>([])

  const toggleModal = () => {
    onCloseModal()
    setSelectedFileIds([])
  }

  const onSave = async () => {
    await connectProjectFilesAndRefetch(selectedFileIds, projectFileType)
    toggleModal()
  }

  const onSelect = (file: ProjectFile) => {
    const isSelected = selectedFileIds.includes(file.id)
    if (!isSelected) {
      setSelectedFileIds([...selectedFileIds, file.id])
    } else {
      setSelectedFileIds(selectedFileIds.filter((fileId) => fileId !== file.id))
    }
  }

  return (
    <Modal
      onClose={onCloseModal}
      open={openModal}
      size="large"
      saveButton={{
        text: 'Use Photos',
        loading: loadingConnect,
        disabled: !selectedFileIds.length,
        onClick: onSave,
      }}
      title="Your Uploads"
    >
      <Grid columns={4} stretched>
        <Grid.Row>
          {prospectPhotosUnused.map((file: ProjectFile) => (
            <Grid.Column
              key={file.id}
              computer={4}
              tablet={8}
              mobile={16}
              style={{ marginBottom: '20px', maxWidth: 'max-content' }}
            >
              <SelectionCardExpandSelect
                imageRatio="1/1"
                imageUrl={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                  getFileImages(file.key, file.numPages)[0],
                )}`}
                title={file.name}
                selectOnly={true}
                onSelect={() => onSelect(file)}
                selected={selectedFileIds.includes(file.id)}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Modal>
  )
}

export default ProspectImageModal
