import React, { useState } from 'react'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { Grid } from 'semantic-ui-react'

import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import { Section, Spacer } from 'design-system/components/guide/utils'

type Reason = {
  text: string
  icon: IconName
  featured?: boolean
}

const reasons: Reason[] = [
  {
    icon: 'star',
    text: "Don't know where to start and are looking for expert advice",
  },
  {
    icon: 'image',
    text: 'Struggling to visualize the end result',
  },
  { icon: 'tag', text: 'Modern functionality style for less' },
]

const WhyForm = () => {
  const [selected, setSelected] = useState<Array<string>>([])

  const handleSelect = (key: string) => {
    setSelected((prev) => {
      const copy = [...prev]
      const idx = selected.indexOf(key)

      if (idx > -1) copy.splice(idx, 1)
      if (idx <= -1) copy.push(key)

      return copy
    })
  }

  return (
    <Section>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">
              People typically choose FORM for one of three reasons.
            </h3>
          </Grid.Column>
        </Grid.Row>

        <Spacer height={40} />

        <Grid.Row centered className="row-gap" stretched>
          {reasons?.map(({ icon, text }, i) => (
            <Grid.Column key={i} computer={5} tablet={5} mobile={16}>
              <SelectIconCard
                active={selected.includes(icon)}
                selected={selected.includes(icon)}
                onClick={() => handleSelect(icon)}
                icon={icon}
                text={text}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default WhyForm
