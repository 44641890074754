import styled from 'styled-components'

import { Colors } from '../system'

export const CardCarousel = styled.div<{ oneCard?: boolean }>`
  &&& {
    margin: ${(props) => (props.oneCard ? '25px 0' : '24px -16px 18px 0')};

    div.background {
      background: #f3f3f3;
      height: 100%;
      left: -15%;
      position: absolute;
      top: 0;
      width: calc(100vw + 30%);
    }

    .carousel {
      position: relative;
    }

    .carousel__slider {
      padding-right: ${(props) => (props.oneCard ? '0' : '13%')};
    }

    .carousel-animation {
      transition: transform 300ms;
    }

    .dots {
      align-items: center;
      display: flex;
      justify-content: center;
      z-index: 1;

      button {
        background: ${Colors.gray300};
        border: none;
        border-radius: 50%;
        box-sizing: border-box;
        height: 4px !important;
        margin: 0 5px;
        padding: 0;
        width: 4px !important;

        :disabled {
          background: ${Colors.gray700};
        }

        :focus {
          outline: none !important;
        }
      }
    }
  }
`
