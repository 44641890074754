import React, { useEffect, useState } from 'react'

import { Grid } from 'semantic-ui-react'

import useDelivery from 'context/delivery/use'
import useDeliveryMutate from 'context/delivery/use-mutate'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'

import { DeliveryStepViewProps } from '../types'

type OrderStatusProps = DeliveryStepViewProps

export const OrderStatusView = ({
  setNextDisabled,
  setOnNext,
  project_id: projectId,
  order_id: orderId,
  delivery_id: deliveryId,
}: OrderStatusProps) => {
  const { delivery } = useDelivery()
  const { updateDeliveryAndRefetch } = useDeliveryMutate()
  const [orderStatus, setOrderStatus] = useState(
    delivery?.data?.damagesSelection,
  )

  useEffect(() => {
    if (!deliveryId) return
    setNextDisabled(!orderStatus)

    const handleOnNext = async () => {
      await updateDeliveryAndRefetch({
        variables: {
          data: {
            data: {
              ...delivery.data,
              damagesSelection: orderStatus,
            },
          },
          where: {
            id: deliveryId,
          },
        },
      })

      if (orderStatus === 'damaged') {
        return `/app/projects/${projectId}/orders/${orderId}/deliveries/${deliveryId}/issues`
      }
      return
    }

    setOnNext(() => handleOnNext)
  }, [orderStatus])

  return (
    <Grid>
      <Grid.Row textAlign="center">
        <Grid.Column>
          <h3>Is there anything wrong with your order?</h3>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row textAlign="center">
        <Grid.Column>
          <p>
            Are there any damages, design flaws, missing or wrong items in your
            order?
          </p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered stretched style={{ rowGap: 24 }}>
        <Grid.Column largeScreen={3} computer={5} tablet={5} mobile={16}>
          <SelectIconCard
            icon="heart-broken"
            onClick={() => setOrderStatus('damaged')}
            selected={orderStatus === 'damaged'}
            text="Yes, I see damages"
          />
        </Grid.Column>
        <Grid.Column largeScreen={3} computer={5} tablet={5} mobile={16}>
          <SelectIconCard
            icon="lightbulb"
            onClick={() => setOrderStatus('no-damage')}
            selected={orderStatus === 'no-damage'}
            text="No, all is good"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}
