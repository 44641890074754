import React, { useEffect, useMemo, useState } from 'react'

import { Router } from '@reach/router'

import { NavFooter } from 'components/app/nav-footer'
import Header from 'components/app/project/header/header-basic'
import StartFlowView from 'components/app/project/start-flow-view'
import Loader from 'components/shared/loader'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomMutate from 'context/room/use-mutate'
import FlowLayout, { StepName } from 'design-system/containers/flow-layout'
import { RoomData } from 'types/room'
import { parseStateWithPrevious } from 'utils/navigation'

import {
  COMPLETE_INFORMATION_STEPS,
  COMPLETE_INFORMATION_STEPS_ARRAY as STEPS,
} from './steps'
import { RoomFlowRouteProps } from '../types'

export const CompleteInformationFlowIndex = ({
  path: _,
  ...routeProps
}: RoomFlowRouteProps) => {
  const {
    '*': subroute,
    project_id: projectId,
    room_id: roomId,
    navigate,
    location,
  } = routeProps
  const { project, primaryDesigner } = useProject()
  const { room } = useRoom()
  const { updateRoomAndRefetch } = useRoomMutate()
  const [nextDisabled, setNextDisabled] = useState(true)
  const [onNext, setOnNext] = useState<() => Promise<void>>()
  const [loadingButton, setLoadingButton] = useState(false)
  const [loading, setLoading] = useState(true)
  const [skip, setSkip] = useState(false)

  const state = parseStateWithPrevious(location?.state, {
    isEffectRedirect: false,
  })
  const { isEffectRedirect, previousLocation } = state

  const currentStepIndex = useMemo(
    () => STEPS.findIndex((step) => step.subroute === subroute),
    [subroute],
  )

  useEffect(() => {
    let redirectSubroute = ''
    if (!project.projectAddress) {
      redirectSubroute = ''
    } else if (!room?.data.floorPlansCompletedAt) {
      redirectSubroute = COMPLETE_INFORMATION_STEPS.floorPlan.subroute
    } else if (!room?.data.roomPhotosCompletedAt) {
      redirectSubroute = COMPLETE_INFORMATION_STEPS.photos.subroute
    } else if (!room?.data.inspirationImagesCompletedAt) {
      redirectSubroute = COMPLETE_INFORMATION_STEPS.inspiration.subroute
    } else if (!room?.data.appliancesCompletedAt) {
      redirectSubroute = COMPLETE_INFORMATION_STEPS.appliances.subroute
    } else {
      navigate?.(previousLocation || `/app/projects/${projectId}/home`)
    }

    navigate?.(
      `/app/projects/${projectId}/rooms/${roomId}/complete-information/${redirectSubroute}`,
      { replace: true, state: { ...state, isEffectRedirect: true } },
    )
  }, [])

  useEffect(() => {
    isEffectRedirect && setLoading(false)
  }, [isEffectRedirect])

  const setStepCompleted = async () => {
    const roomData: RoomData = {}

    switch (subroute) {
      case COMPLETE_INFORMATION_STEPS.floorPlan.subroute: //* floor plan
        if (room?.data.floorPlansCompletedAt) return
        roomData.floorPlansCompletedAt = new Date()
        break
      case COMPLETE_INFORMATION_STEPS.photos.subroute: //* photos
        if (room?.data.roomPhotosCompletedAt) return
        roomData.roomPhotosCompletedAt = new Date()
        break
      case COMPLETE_INFORMATION_STEPS.inspiration.subroute: //* inspiration
        if (room?.data.inspirationImagesCompletedAt) return
        roomData.inspirationImagesCompletedAt = new Date()
        break
      case COMPLETE_INFORMATION_STEPS.appliances.subroute: //* appliances
        if (room?.data.appliancesCompletedAt) return
        roomData.appliancesCompletedAt = new Date()
        break
      default:
        //* address
        return
    }

    await updateRoomAndRefetch({
      variables: {
        data: { data: roomData },
        where: { id: roomId ?? '' },
      },
    })
  }

  const isFirstStep = currentStepIndex <= 0
  const isLastStep = currentStepIndex >= STEPS.length - 1

  const handleNext = async () => {
    await onNext?.()
    await setStepCompleted()
    if (isLastStep) {
      navigate?.(previousLocation || `/app/projects/${projectId}/home`)
      return
    }
    navigate?.(
      `/app/projects/${projectId}/rooms/${roomId}/complete-information/${
        STEPS[currentStepIndex + 1].subroute
      }`,
      { state: { ...state, isEffectRedirect: false } },
    )
  }

  if (loading) return <Loader />

  if (currentStepIndex === -1) {
    return (
      <StartFlowView
        header={
          <Header
            onBack={() => navigate?.(`/app/projects/${projectId}/home`)}
          />
        }
        designerInformation={{
          firstName: primaryDesigner?.firstName,
          picture: primaryDesigner?.picture ?? '',
          video: primaryDesigner?.data?.introVideoUrl,
        }}
        steps={STEPS}
        handleStart={() =>
          navigate?.(
            `/app/projects/${projectId}/rooms/${roomId}/complete-information/${STEPS[0].subroute}`,
            { state: { ...state, isEffectRedirect: false } },
          )
        }
        title={
          <h2>
            I'm <i>{primaryDesigner?.firstName}</i>, your designer
          </h2>
        }
        description="To start your project we need to collect some information that will help us understand your project and make the right design decisions."
        lateralText="Welcome to FORM"
      />
    )
  }

  return (
    <FlowLayout
      header={
        <Header
          onBack={() =>
            navigate?.(previousLocation || `/app/projects/${projectId}/home`)
          }
        />
      }
      main={
        <>
          {<StepName>{STEPS[currentStepIndex].label}</StepName>}
          <Router>
            {STEPS.map(({ View, subroute }) => (
              <View
                key={subroute}
                path={subroute}
                setOnNext={setOnNext}
                setNextDisabled={setNextDisabled}
                setLoading={setLoadingButton}
                setSkip={setSkip}
                chooseProspectFiles
                {...routeProps}
              />
            ))}
          </Router>
        </>
      }
      actions={
        <NavFooter
          hideBack={isFirstStep}
          backDisabled={isFirstStep}
          nextDisabled={nextDisabled}
          loading={loadingButton}
          nextText={skip ? 'Skip for now' : 'Continue'}
          onBack={() =>
            navigate?.(
              `/app/projects/${projectId}/rooms/${roomId}/complete-information/${
                STEPS[currentStepIndex - 1].subroute
              }`,
              { state: { ...state, isEffectRedirect: false } },
            )
          }
          onNext={handleNext}
        />
      }
      stepperProps={{
        currentStep: currentStepIndex,
        steps: STEPS,
      }}
    />
  )
}
