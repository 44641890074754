import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import TaskCheck from 'design-system/components/guide/task-check'
import {
  UnderlineSubtitle,
  Section,
} from 'design-system/components/guide/utils'

const questions = [
  { label: 'Measurements ' },
  { label: 'Appliances' },
  { label: 'Timelines' },
]

const tradeQuestions = [
  { label: 'Measurements ' },
  { label: 'Appliances' },
  { label: 'Timelines' },
  { label: 'Scope' },
  { label: 'Budget' },
]

const ImportantItems = () => {
  const { isTradeProject } = useProject()

  return (
    <Section id="important-items">
      <Grid centered={!isTradeProject}>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">
              {isTradeProject
                ? 'Any changes to discuss?'
                : 'Has anything changed since we last spoke?'}
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <UnderlineSubtitle>Important Items</UnderlineSubtitle>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="row-gap" stretched>
          {(isTradeProject ? tradeQuestions : questions).map(({ label }) => {
            return (
              <Grid.Column
                key={label}
                computer={isTradeProject ? 4 : 5}
                tablet={5}
                mobile={16}
              >
                <TaskCheck label={label} />
              </Grid.Column>
            )
          })}
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default ImportantItems
