import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { isEqual } from 'lodash'

import ProjectAddress from 'components/app/project/address'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import SaveToast from 'design-system/components/save-toast'
import AppLayout from 'design-system/containers/app-layout'
import { Address } from 'types/utils'

const Module = (_: RouteComponentProps) => {
  const { project } = useProject()
  const { loadingUpdate, updateProjectAndRefetch } = useProjectMutate()
  const projectAddress = project.projectAddress
  const [address, setAddress] = useState<Address>(projectAddress)
  const [nextDisabled, setNextDisabled] = useState(!projectAddress)
  const isChanged = !isEqual(projectAddress, address)

  useEffect(() => {
    setAddress(projectAddress)
    setNextDisabled(!projectAddress)
  }, [projectAddress])

  return (
    <AppLayout>
      <ProjectAddress
        setNextDisabled={setNextDisabled}
        setAddress={setAddress}
      />
      <SaveToast
        disabled={nextDisabled}
        loading={loadingUpdate}
        onClick={async () => {
          await updateProjectAndRefetch({
            variables: {
              data: {
                projectAddress: address,
              },
              where: {
                id: project.id,
              },
            },
          })
        }}
        show={isChanged}
      />
    </AppLayout>
  )
}

export default Module
