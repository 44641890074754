import { GenericOption } from 'types/generic-option'

const Soft = [
  'countertopMaterialsilestone_countertop_Yukon',
  'countertopMaterialsilestone_countertop_Et-Marfil',
  'countertopMaterialsilestone_countertop_Desert-Silver',
  'countertopMaterialsilestone_countertop_Cygnus',
  'countertopMaterialcaesarstone_countertop_Pure-White',
  'countertopMaterialsilestone_countertop_Alpina-White',
  'countertopMaterialsilestone_countertop_Et-Statuario',
  'countertopMaterialsilestone_countertop_Ocean-Jasper',
  'countertopMaterialsilestone_countertop_Serena',
  'countertopMaterialsilestone_countertop_Snowy-Ibiza',
  'countertopMaterialsilestone_countertop_Charcola-Soapstone',
]

const Visible = [
  'countertopMaterialsilestone_countertop_Pietra',
  'countertopMaterialsilestone_countertop_Helix',
  'countertopMaterialsilestone_countertop_Pearl-Jasmine',
  'countertopMaterialdekton_countertop_Arga',
  'countertopMaterialcaesarstone_countertop_Empira-White',
  'countertopMaterialcaesarstone_countertop_primordia',
  'countertopMaterialcaesarstone_countertop_Rugged-Concrete',
  'countertopMaterialcosentino_countertop_Ethereal-Dusk',
  'countertopMaterialsilestone_countertop_Et-Emperador',
  'countertopMaterialsilestone_countertop_Ocean-Storm',
  'countertopMaterialsilestone_countertop_Urban-Frost',
]

const Marbled = [
  'countertopMaterialsilestone_countertop_Classic-Calacatta',
  'countertopMaterialsilestone_countertop_Et-Dor',
  'countertopMaterialsilestone_countertop_Et-Noir',
  'countertopMaterialdekton_countertop_Liquid-Sky',
  'countertopMaterialsilestone_countertop_Ethereal-Haze',
  'countertopMaterialsilestone_countertop_Et-Marquina',
  'countertopMaterialsilestone_countertop_bianco-calacatta',
]

const Solid = [
  'countertopMaterialsilestone_countertop_Cemento-Spa',
  'countertopMaterialsilestone_countertop_Kensho',
  'countertopMaterialsilestone_countertop_Blanco-Matrix',
  'countertopMaterialsilestone_countertop_Iconic-white',
]

export const parseCountertops = (countertops: GenericOption[]) => {
  return countertops.reduce<{
    countertopsAll: GenericOption[]
    countertopsMarbled: GenericOption[]
    countertopsMarbledCount: number
    countertopsOnDesignCall: GenericOption[]
    countertopsSoft: GenericOption[]
    countertopsSoftCount: number
    countertopsSolid: GenericOption[]
    countertopsSolidCount: number
    countertopsVisible: GenericOption[]
    countertopsVisibleCount: number
  }>(
    (acc, go) => {
      if (go.optionIdentifier === 'custom') return acc
      acc.countertopsAll.push(go)
      if (go.data?.showOnDesignCall) acc.countertopsOnDesignCall.push(go)
      if (go.identifier && Soft.includes(go.identifier))
        return {
          ...acc,
          countertopsSoft: [...acc.countertopsSoft, go],
          countertopsSoftCount: acc.countertopsSoftCount + 1,
        }
      if (go.identifier && Visible.includes(go.identifier))
        return {
          ...acc,
          countertopsVisible: [...acc.countertopsVisible, go],
          countertopsVisibleCount: acc.countertopsVisibleCount + 1,
        }
      if (go.identifier && Marbled.includes(go.identifier))
        return {
          ...acc,
          countertopsMarbled: [...acc.countertopsMarbled, go],
          countertopsMarbledCount: acc.countertopsMarbledCount + 1,
        }
      if (go.identifier && Solid.includes(go.identifier))
        return {
          ...acc,
          countertopsSolid: [...acc.countertopsSolid, go],
          countertopsSolidCount: acc.countertopsSolidCount + 1,
        }
      return acc
    },
    {
      countertopsAll: [],
      countertopsMarbled: [],
      countertopsMarbledCount: 0,
      countertopsOnDesignCall: [],
      countertopsSoft: [],
      countertopsSoftCount: 0,
      countertopsSolid: [],
      countertopsSolidCount: 0,
      countertopsVisible: [],
      countertopsVisibleCount: 0,
    },
  )
}
