import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { isEqual } from 'lodash'
import { Divider, Grid } from 'semantic-ui-react'

import StepTitle from 'components/app/step-title'
import AddressForm from 'components/shared/address/form'
import useCompany from 'context/company/use'
import useCompanyMutateNoContext from 'context/company/use-mutate-no-context'
import SaveToast from 'design-system/components/save-toast'
import AppLayout from 'design-system/containers/app-layout'
import { Address } from 'types/utils'

const Module = (_: RouteComponentProps) => {
  const { company, refetch } = useCompany()
  const { updateCompany, loadingUpdate } = useCompanyMutateNoContext()

  const [address, setAddress] = useState<Address | null>(
    company?.address || null,
  )

  const [isDisabled, setDisabled] = useState<boolean>(!address)

  return (
    <AppLayout>
      <Grid centered>
        <StepTitle title="Company Address" />
        <Divider hidden />
        <Grid.Row centered>
          <Grid.Column
            widescreen={6}
            largeScreen={6}
            computer={9}
            tablet={10}
            mobile={16}
          >
            <AddressForm
              address={address}
              labels
              onChange={(completed, a) => {
                setAddress(a)
                setDisabled(!completed)
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SaveToast
        disabled={isDisabled}
        loading={loadingUpdate}
        onClick={async () => {
          if (company?.id && address) {
            await updateCompany({
              variables: {
                data: {
                  address: address,
                },
                where: {
                  id: company.id,
                },
              },
            })
            refetch()
          }
        }}
        show={!isEqual(company?.address, address)}
      />
    </AppLayout>
  )
}

export default Module
