import React, { useState } from 'react'

import { Form } from 'semantic-ui-react'
import styled from 'styled-components'

import TermsAndConditions from 'components/shared/terms-and-conditions'
import useProjectFile from 'context/project-file/use'
import useProjectFileMutate from 'context/project-file/use-mutate'
import { ModalApprovalBasic } from 'design-system/components/modal'
import { StyledForm } from 'styles/app/components/form'
import { Breakpoint, Colors } from 'styles/app/system'

const CONSIDERATIONS: { img: string; text: string }[] = [
  {
    img: `${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/design-plan2.svg`,
    text: 'Your order is based on the Design Plan alone.',
  },
  {
    img: `${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/products.svg`,
    text: 'Your order includes only elements and materials specified in your Detailed Quote.',
  },
  {
    img: `${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/kitchen.svg`,
    text: 'The renders we’ve provided are for reference only.',
  },
  {
    img: `${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/appliances.svg`,
    text: 'Some appliances and fixtures shown in your final renders may not be included in your order. ',
  },
]

const ApprovalModal = ({
  showApprovalModal,
  trigger,
  toggleApprovalModal,
}: {
  showApprovalModal: boolean
  trigger: React.ReactElement
  toggleApprovalModal: (toggle: boolean) => void
}) => {
  const [initialsAgree, setInitialsAgree] = useState<string>('')
  const [termsAndConditionsRead, setTermsAndConditionsRead] = useState(false)
  const { projectFile } = useProjectFile()
  const { loadingUpdate, updateProjectFileAndRefetch } = useProjectFileMutate()

  return (
    <ModalApprovalBasic
      cancelButton={{ text: 'Go back' }}
      onClose={() => toggleApprovalModal(false)}
      onOpen={() => toggleApprovalModal(true)}
      open={showApprovalModal}
      saveButton={{
        text: 'Approve Design & Send to Production',
        loading: loadingUpdate,
        disabled: !initialsAgree || !termsAndConditionsRead,
        onClick: async () => {
          await updateProjectFileAndRefetch({
            variables: {
              data: {
                approvedAt: new Date(),
                metadata: {
                  ...projectFile.metadata,
                  initialsAgree: initialsAgree,
                },
              },
              where: {
                id: projectFile.id,
              },
            },
          })
        },
      }}
      size="large"
      title="Approve design & send to production"
      trigger={trigger}
    >
      <p>
        By approving, you acknowledge that you have reviewed this design in full
        and would like to send it into production.
      </p>
      <p className="overline">important things to consider</p>

      <ConsiderationsContainer>
        {CONSIDERATIONS.map(({ img, text }) => (
          <div className="item" key={text}>
            <img src={img} alt={text} />
            <p>{text}</p>
          </div>
        ))}
      </ConsiderationsContainer>
      <p className="overline">add your initials if you fully agree</p>
      <StyledForm
        styled={{
          whiteInputs: false,
        }}
        style={{
          marginBottom: '30px',
          marginTop: '15px',
          width: '350px',
          textAlign: 'left',
        }}
      >
        <Form.Input
          label="I fully agree"
          name="agree initials"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setInitialsAgree(e.target.value)
          }
          placeholder="Add your initials here"
          value={initialsAgree}
        />
      </StyledForm>
      <TermsAndConditions
        checked={termsAndConditionsRead}
        onChange={() => setTermsAndConditionsRead(!termsAndConditionsRead)}
        style={{
          border: `1px solid ${Colors.gray500}`,
          background: Colors.gray50,
          padding: 14,
        }}
      />
    </ModalApprovalBasic>
  )
}

export default ApprovalModal

const ConsiderationsContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  margin-bottom: 32px;
  margin-top: 16px;

  & > div.item {
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 23%;

    @media ${Breakpoint.downFromTablet} {
      width: 48%;
    }

    @media ${Breakpoint.downFromSmallMobile} {
      width: 100%;
    }

    & > img {
      height: 120px;
    }
  }
`
