import React from 'react'

import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import { parseFiles } from 'context/project-file/utils-many'
import { getRoomTypeWithDescription, parseDesigns } from 'context/room/utils'
import FilePlaceholderCard from 'design-system/components/cards/file-placeholder-card'
import PrimaryCard from 'design-system/components/cards/primary-card'
import {
  Section,
  Spacer,
  UnderlineSubtitle,
} from 'design-system/components/guide/utils'

const Visuals = () => {
  const { project, roomsUnarchived, roomPrimary, designsPublished } =
    useProject()
  const { floorPlans } = parseFiles(roomPrimary?.files)

  if (!designsPublished.length && !floorPlans.length) return <></>

  return (
    <Section id="visuals">
      <Grid>
        {designsPublished.length ? (
          <>
            <Grid.Row>
              <Grid.Column>
                <UnderlineSubtitle>Rooms</UnderlineSubtitle>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              {roomsUnarchived.map((room) => {
                if (!room.designs.length) return
                const { latestDesignPublished } = parseDesigns(room)

                if (!latestDesignPublished) return

                return (
                  <Grid.Column computer={4} tablet={7} mobile={16}>
                    <PrimaryCard
                      image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/visual-quote.svg`}
                      onClick={() =>
                        navigate(
                          `/app/projects/${project.id}/rooms/${room.id}/designs/${latestDesignPublished.id}/quote`,
                        )
                      }
                      title="Visual Quote"
                      subtitle={getRoomTypeWithDescription(room)}
                    />
                  </Grid.Column>
                )
              })}
            </Grid.Row>
          </>
        ) : null}
        {floorPlans.length ? (
          <>
            <Spacer height={100} />
            <Grid.Row>
              <Grid.Column>
                <UnderlineSubtitle>Floor Plan</UnderlineSubtitle>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row stretched>
              {floorPlans.map((f) => (
                <Grid.Column key={f.id} computer={4} tablet={8} mobile={16}>
                  <FilePlaceholderCard file={f} />
                </Grid.Column>
              ))}
            </Grid.Row>
          </>
        ) : null}
      </Grid>
    </Section>
  )
}

export default Visuals
