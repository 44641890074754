import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import SecondaryCard from 'design-system/components/cards/secondary-card'

const KeyDocuments = () => {
  const { isTradeProject, initialCostings, projectProposals } = useProject()

  if (!isTradeProject || (!initialCostings.length && !projectProposals.length))
    return null

  return (
    <>
      <Divider hidden />
      <Grid.Row>
        <Grid.Column>
          <p className="overline">Key Documents</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row stretched className="row-gap">
        {!!projectProposals.length && (
          <Grid.Column
            widescreen={4}
            largeScreen={4}
            computer={5}
            tablet={8}
            mobile={16}
          >
            <SecondaryCard
              icon="browser"
              onClick={() =>
                window.open(
                  `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    projectProposals[0].key,
                  )}`,
                )
              }
              title="Project Proposal"
            />
          </Grid.Column>
        )}
        {!!initialCostings.length && (
          <Grid.Column
            widescreen={4}
            largeScreen={4}
            computer={5}
            tablet={8}
            mobile={16}
          >
            <SecondaryCard
              icon="images"
              onClick={() =>
                window.open(
                  `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                    initialCostings[0].key,
                  )}`,
                )
              }
              title="Initial Costing"
            />
          </Grid.Column>
        )}
      </Grid.Row>
    </>
  )
}

export default KeyDocuments
