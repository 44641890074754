import React from 'react'

import { useMedia } from 'react-use'
import { Grid } from 'semantic-ui-react'

import Carousel from 'design-system/components/carousel'
import StepCard from 'design-system/components/guide/step-card'
import { Section, Spacer } from 'design-system/components/guide/utils'
import { Breakpoint } from 'styles/app/system'

const stepsFORM = [
  {
    title: 'Project Proposal',
    description:
      "We'll develop a detailed Project Proposal that gives you and your client a clear vision of how we'll execute the design, so you can understand next steps and how to move forward.",
    step: 1,
    subtitle: '$99 Design Deposit',
    listItems: [
      'Initial Design, including rendering, plan and elevations (Typically 6 hours of design work)',
      'Pricing breakdown for the entire project scope',
      '3 full size samples shipped directly to you',
    ],
  },
  {
    title: 'Detailed Design',
    description:
      "We'll partner with you to engineer the design and finalize the details. At this point we'll also pull in our Technical Design team to double check fit and feasibility.",
    step: 2,
    subtitle: '5% Trade Deposit',
    listItems: [
      'Regular design consultations',
      'Accessible communication over email and through our App',
      '3 Design Revisions included',
      'Ability to add or subtract scope',
      '*Extra revisions available for $199',
    ],
  },
  {
    title: 'Deliver',
    description:
      "Our Customer Success Team is on hand to ensure you're prepped to receive your order and provide support throughout installation.",
    step: 3,
    subtitle: 'Balance Payment',
    listItems: [
      'Fully assembled cabinetry with award-winning hardware',
      '25 years Manufaturing warranty',
      'Free shipping to contiguous US',
    ],
  },
]

const stepSlides = stepsFORM.map((s) => ({
  id: s.step,
  item: (
    <div style={{ paddingTop: 40, height: '100%', paddingBottom: 20 }}>
      <StepCard {...s} />
    </div>
  ),
}))

const HowWeWork = () => {
  const isDownFromComputer = useMedia(Breakpoint.downFromComputer)
  const isMobile = useMedia(Breakpoint.onlyMobile)

  return (
    <Section id="how-form-works">
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">How FORM works</h3>
          </Grid.Column>
        </Grid.Row>
        <Spacer height={50} />
        {isDownFromComputer ? (
          <Carousel
            slides={stepSlides}
            hideNavButtons
            slideSize={isMobile ? 80 : 50}
          />
        ) : (
          <Grid.Row columns={16}>
            {stepsFORM.map((step) => (
              <Grid.Column key={step.title} width={5}>
                <StepCard {...step} />
              </Grid.Column>
            ))}
          </Grid.Row>
        )}
      </Grid>
    </Section>
  )
}

export default HowWeWork
