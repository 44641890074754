import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import ProjectFileNotFound from 'components/shared/project/project-file/file-not-found'
import ProjectFileLayout from 'components/shared/project/project-file/layout'
import { ScrollContainer } from 'components/shared/project/project-file/utils'
import ProjectFileProvider from 'context/project-file/provider'
import useProjectFile from 'context/project-file/use'
import Button from 'design-system/components/button'
import { StatusMode } from 'types/utils'

import SidebarApproval from './sidebar-approval'
import SidebarApproved from './sidebar-approved'
import SidebarComments from './sidebar-comments'
import SidebarSubmitted from './sidebar-submitted'

type ProjectFileProps = RouteComponentProps<{
  project_id: string
  room_id: string
  design_id: string
  file_id: string
}>

const DesignPlan = (_: ProjectFileProps) => {
  const { externalReviewMode, projectFile } = useProjectFile()
  const [sidebarShow, setSidebarShow] = useState(false)

  if (!projectFile) return <ProjectFileNotFound />

  const sidebarToggle = () => setSidebarShow(!sidebarShow)

  const mode = externalReviewMode

  return (
    <ProjectFileLayout
      commentsOnImageEnabled={mode === StatusMode.OpenForRevision}
      commentsOnImageShowing={
        mode === StatusMode.OpenForRevision || mode === StatusMode.Submitted
      }
      sidebar={(() => {
        if (mode === StatusMode.Confirmed)
          return (
            <SidebarApproved
              extraButton={
                <Button
                  fontAwesomeIcon="arrow-left"
                  iconAnimation="left"
                  iconPosition="left"
                  kind="solid"
                  onClick={() => navigate(-1)}
                  text="Go Back to Continue"
                />
              }
              sidebarToggle={sidebarToggle}
            />
          )
        if (mode === StatusMode.Confirmation)
          return (
            <ScrollContainer>
              <SidebarApproval sidebarToggle={sidebarToggle} />
            </ScrollContainer>
          )
        if (mode === StatusMode.Submitted)
          return (
            <ScrollContainer>
              <SidebarSubmitted sidebarToggle={sidebarToggle} />
            </ScrollContainer>
          )
        return (
          <ScrollContainer>
            <SidebarComments sidebarToggle={sidebarToggle} />
          </ScrollContainer>
        )
      })()}
      sidebarShow={sidebarShow}
      sidebarToggle={sidebarToggle}
      sidebarTrigger={(() => {
        if (mode === StatusMode.Confirmed)
          return (
            <Button color="blue" kind="solid" size="fluid" text="Approved" />
          )
        if (mode === StatusMode.Confirmation)
          return <Button kind="solid" size="fluid" text="Start Revision" />
        if (mode === StatusMode.Submitted)
          return (
            <Button color="blue" kind="solid" size="fluid" text="Submitted" />
          )
        return (
          <>
            <p style={{ marginBottom: '10px' }}>
              Tap wherever you want to add a comment.
            </p>
            <Button kind="solid" size="fluid" text="View or submit comments" />
          </>
        )
      })()}
    />
  )
}

export default (props: ProjectFileProps) => (
  <ProjectFileProvider file_id={props.file_id}>
    <DesignPlan {...props} />
  </ProjectFileProvider>
)
