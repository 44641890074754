import React from 'react'

import { navigate } from 'gatsby'
import { Grid } from 'semantic-ui-react'

import HelpMessage from 'components/shared/help-message'
import { isDesignApproved, parseLatestPlan } from 'context/design/utils'
import useOrder from 'context/order/use'
import { getOrderFormattedAdditional } from 'context/order/utils'
import PaymentSourceManyProvider from 'context/payment-source/provider-many'
import useProject from 'context/project/use'
import { getRoomFormattedType } from 'context/room/utils'
import { PrimaryCard } from 'design-system/components/cards'
import StatusLabel from 'design-system/components/status-label'
import { getRoomImageByType } from 'views/utils'

import FinalChecks from './final-checks'
import OrderCheckoutPaid from './paid'
import OrderCheckoutPay from './pay'
import OrderDesigns from '../designs'

const OrderCheckout = () => {
  const { isPaidInFull, order, isAddOnOrder, designs } = useOrder()
  const { project } = useProject()

  return (
    <PaymentSourceManyProvider>
      <Grid stackable>
        <Grid.Row columns="equal">
          <Grid.Column>
            <h2 className="no-margin">
              {isAddOnOrder ? 'Add-On Order' : 'Your Order'}
            </h2>
            {!!getOrderFormattedAdditional(order) && (
              <p className="light-gray subtitle">
                {getOrderFormattedAdditional(order)}
              </p>
            )}
          </Grid.Column>
        </Grid.Row>
        {isAddOnOrder && (
          <>
            <HelpMessage message="Please review and approve the design before sending to production" />
            <Grid.Row>
              {designs?.map((d) => {
                const {
                  latestPlanExternalReviewMode,
                  latestPlanExternalReviewText,
                  latestPlan,
                } = parseLatestPlan(d)

                return (
                  <Grid.Column key={d.id} computer={4} tablet={5} mobile={16}>
                    <PrimaryCard
                      image={getRoomImageByType(d.room.type)}
                      onClick={() =>
                        navigate(
                          `/app/projects/${project.id}/rooms/${d.room.id}/designs/${d.id}/plan/${latestPlan?.id}`,
                        )
                      }
                      title={d.name}
                      subtitle={getRoomFormattedType(d.room)}
                    >
                      <div style={{ marginTop: '4px' }}>
                        <StatusLabel
                          type={latestPlanExternalReviewMode}
                          text={
                            latestPlanExternalReviewText?.customerText ||
                            latestPlanExternalReviewText?.summary
                          }
                        />
                      </div>
                    </PrimaryCard>
                  </Grid.Column>
                )
              })}
            </Grid.Row>
          </>
        )}
        <Grid.Row centered columns="equal">
          {isPaidInFull ? (
            <OrderCheckoutPaid />
          ) : (
            <OrderCheckoutPay
              disabled={
                isAddOnOrder && !designs?.every((d) => isDesignApproved(d))
              }
            />
          )}
          <FinalChecks />
        </Grid.Row>
      </Grid>
      {!isAddOnOrder && <OrderDesigns />}
    </PaymentSourceManyProvider>
  )
}

export default OrderCheckout
