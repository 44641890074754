import React, { useEffect, useState } from 'react'

import { Divider } from 'semantic-ui-react'

import AddressForm from 'components/shared/address/form'
import { getAddressWithName } from 'context/address/utils'
import Button from 'design-system/components/button'
import { FormCard } from 'styles/app/components/card'
import { FormRadio } from 'styles/app/components/checkbox'
import { LineBorder } from 'styles/app/system'
import { Address } from 'types/utils'

interface AddressSelectProps {
  enableEdit?: boolean
  initialAddress: Address
  orderAddress: Address | null | undefined
  onChangeAddress?: (address: Address) => void
  whiteInputs?: boolean
}

const AddressSelect = ({
  enableEdit,
  initialAddress,
  orderAddress,
  onChangeAddress,
  whiteInputs = true,
}: AddressSelectProps) => {
  const [state, setState] = useState({
    address: initialAddress,
    formValid: false,
    confirmed: !!orderAddress,
  })
  const [showForm, toggleForm] = useState(false)
  useEffect(() => {
    setState({
      ...state,
      confirmed: !!orderAddress,
    })
  }, [orderAddress])

  return (
    <FormCard noPadding>
      {state.address ? (
        <FormRadio
          checked={state.confirmed}
          label={
            <label>
              <p
                dangerouslySetInnerHTML={{
                  __html: getAddressWithName(initialAddress, true),
                }}
              />
            </label>
          }
          onChange={() => {
            if (!state.confirmed && !orderAddress) {
              setState({
                ...state,
                confirmed: true,
              })
              onChangeAddress?.(state.address)
            } else {
              setState({
                ...state,
                confirmed: true,
              })
            }
            toggleForm(false)
            return
          }}
        />
      ) : (
        <p>No Address Specified</p>
      )}
      {enableEdit && (
        <>
          <div
            style={{
              borderBottom: LineBorder,
              margin: '16px 0',
            }}
          />
          <FormRadio
            checked={showForm}
            label={
              <label>
                <p>Send to other address</p>
              </label>
            }
            onChange={() => {
              if (!showForm) {
                toggleForm(true)
                setState({
                  ...state,
                  confirmed: false,
                })
              }
            }}
          />
          {showForm && (
            <>
              <Divider hidden />
              <AddressForm
                address={null}
                onChange={(completed, a) => {
                  setState({
                    ...state,
                    formValid: completed,
                    address: a,
                  })
                }}
                whiteInputs={whiteInputs}
                includeRecipientName
              />
              <Button
                style={{ float: 'right' }}
                disabled={!state.formValid}
                onClick={() => {
                  setState({
                    ...state,
                    formValid: false,
                    confirmed: true,
                  })
                  onChangeAddress?.(state.address)
                  toggleForm(false)
                }}
                kind="solid"
                color="blue"
                text="Save"
              />
            </>
          )}
        </>
      )}
    </FormCard>
  )
}

export default AddressSelect
