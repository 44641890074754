import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import Moodboard from 'components/app/project/room/design-brief/moodboard'
import useRoom from 'context/room/use'
import useRoomGuideOptions from 'context/room/use-guide-options'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import { Section } from 'design-system/components/guide/utils'

const DesignBriefRecap = () => {
  const { room } = useRoom()

  const {
    projectBudget,
    projectTimeline,
    reasonsForRenovatingSelected,
    projectActivitiesSelected,
  } = useRoomGuideOptions()

  return (
    <Section id="recap">
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Has anything changed since we last spoke?</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            {room ? <Moodboard room={room} /> : null}
          </Grid.Column>
          <Grid.Column computer={8} tablet={16} mobile={16}>
            <Grid>
              {projectBudget ? (
                <Grid.Row>
                  <Grid.Column computer={8} tablet={8} mobile={16}>
                    <SelectIconCard
                      active
                      icon="file-invoice-dollar"
                      text={`Budget ${projectBudget?.data?.budget}`}
                    />
                  </Grid.Column>
                </Grid.Row>
              ) : null}
              {projectTimeline ? (
                <Grid.Row>
                  <Grid.Column computer={8} tablet={8} mobile={16}>
                    <SelectIconCard
                      active
                      icon="stopwatch"
                      text={`Timeline ${projectTimeline?.data?.timeline}`}
                    />
                  </Grid.Column>
                </Grid.Row>
              ) : null}
              {projectActivitiesSelected.length ? (
                <>
                  <Divider hidden />
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <p className="gray">
                        What do you hope the impact of this project will be?
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{ rowGap: '24px' }}>
                    {projectActivitiesSelected.map((opt, key) => (
                      <Grid.Column key={key} width={8}>
                        <SelectIconCard
                          active
                          icon={opt.icon}
                          text={opt.title}
                        />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </>
              ) : null}
              {reasonsForRenovatingSelected.length ? (
                <>
                  <Divider hidden />
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <p className="gray">
                        What would you most like us to focus on?
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row stretched style={{ rowGap: '24px' }}>
                    {reasonsForRenovatingSelected.map((opt, key) => (
                      <Grid.Column key={key} width={8}>
                        <SelectIconCard
                          active
                          icon={opt.icon}
                          text={opt.title}
                        />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                </>
              ) : null}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default DesignBriefRecap
