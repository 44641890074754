import React, { useEffect, useState } from 'react'

import { isEqual } from 'lodash'
import { Divider, Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import SelectIconCard from 'design-system/components/cards/selection/select-icon-card'
import { BuildZoomData } from 'types/utils'

import { StepViewProps } from './steps'

const Scope = ({ setLoading, setNextDisabled, setOnNext }: StepViewProps) => {
  const { buildZoomData, project } = useProject()
  const { loadingUpdate, updateProjectAndRefetch } = useProjectMutate()

  const [state, setState] = useState(buildZoomData?.wholeProject)

  useEffect(() => {
    setLoading(loadingUpdate)
    setNextDisabled(!state)

    const onNext = async () => {
      if (!isEqual(buildZoomData?.wholeProject, state))
        await updateProjectAndRefetch({
          variables: {
            data: {
              data: {
                buildZoomData: {
                  ...(project.data.buildZoomData as BuildZoomData),
                  wholeProject: state as string,
                },
              },
            },
            where: {
              id: project.id,
            },
          },
        })
    }
    setOnNext(() => onNext)
  }, [state, loadingUpdate])

  return (
    <Grid stackable>
      <Grid.Row centered>
        <Grid.Column computer={10} tablet={16} mobile={16} textAlign="center">
          <h3>
            Are you looking for help with the whole project or just the
            cabinetry install?
          </h3>
        </Grid.Column>
      </Grid.Row>
      <Divider hidden />
      <Grid.Row centered stretched>
        <Grid.Column largeScreen={3} computer={4} tablet={5} mobile={16}>
          <SelectIconCard
            icon="cabinet-filing"
            onClick={() => setState('cabinetry_install')}
            selected={state === 'cabinetry_install'}
            text="Cabinetry Install"
          />
        </Grid.Column>
        <Grid.Column largeScreen={3} computer={4} tablet={5} mobile={16}>
          <SelectIconCard
            icon="puzzle-piece"
            onClick={() => setState('part_project')}
            selected={state === 'part_project'}
            text="Part of the project"
          />
        </Grid.Column>
        <Grid.Column largeScreen={3} computer={4} tablet={5} mobile={16}>
          <SelectIconCard
            icon="house-chimney"
            onClick={() => setState('whole_project')}
            selected={state === 'whole_project'}
            text="Whole project"
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default Scope
