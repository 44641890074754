import React from 'react'

import { navigate } from 'gatsby'
import { Divider, Grid } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import useRoom from 'context/room/use'
import Button from 'design-system/components/button'
import ImageCarousel from 'design-system/components/carousel/image-carousel'
import SelectionCover from 'design-system/components/guide/selection-cover'
import {
  Section,
  UnderlineSubtitle,
} from 'design-system/components/guide/utils'
import SelectionCardExpandSelect from 'design-system/components/selection-card/card-expand-select'
import { filterAndSortRendersForGallery } from 'views/app/catalog/utils'

interface InspirationProps {
  projectId: string
}

const Inspiration = (props: InspirationProps) => {
  const { isFORMStaff } = useAuth()
  const { inspirationImages, room } = useRoom()

  const formInspiration = room?.designsInspiration ?? []

  return (
    <Section id="inspiration">
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Your inspiration</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        {!inspirationImages.length && !formInspiration.length ? (
          <Grid.Row>
            <Grid.Column>
              <SelectionCover
                image="design-call/kickoff-cover.png"
                onClick={() =>
                  navigate(
                    `/app/projects/${props.projectId}/rooms/${room?.id}/selection/inspiration-selection`,
                  )
                }
                title="Select inspiration images"
              />
            </Grid.Column>
          </Grid.Row>
        ) : (
          <>
            {inspirationImages.length ? (
              <>
                <Divider hidden />
                <Grid.Row>
                  <Grid.Column>
                    <ImageCarousel
                      images={inspirationImages.map(
                        (file) =>
                          `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                            file.metadata?.keyCompressed || file.key,
                          )}`,
                      )}
                    />
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : null}
            {formInspiration.length ? (
              <>
                <Divider hidden />
                <Grid.Row>
                  <Grid.Column>
                    <UnderlineSubtitle>Form Inspiration</UnderlineSubtitle>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                  {formInspiration.map((d, key) => {
                    const galleryRender = filterAndSortRendersForGallery(
                      d.renders,
                    )[0]
                    return (
                      <Grid.Column
                        key={key}
                        computer={4}
                        tablet={5}
                        mobile={16}
                      >
                        <SelectionCardExpandSelect
                          imageRatio="1/1"
                          imageUrl={`${
                            process.env.GATSBY_CDN_ROOT
                          }/${encodeURIComponent(
                            galleryRender?.metadata?.keyCompressed ||
                              galleryRender?.key,
                          )}`}
                          title={d.metadataGallery?.name}
                        />
                      </Grid.Column>
                    )
                  })}
                </Grid.Row>
              </>
            ) : null}
            {isFORMStaff ? (
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Button
                    color="blue"
                    fontAwesomeIcon="plus"
                    kind="solid"
                    onClick={() =>
                      navigate(
                        `/app/projects/${props.projectId}/rooms/${room?.id}/selection/inspiration-selection`,
                      )
                    }
                    text={`${
                      formInspiration.length ? 'Edit' : 'Browse'
                    } FORM Inspiration`}
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}
          </>
        )}
      </Grid>
    </Section>
  )
}

export default Inspiration
