import React from 'react'

import { Divider, Grid, Image } from 'semantic-ui-react'

import { Section } from 'design-system/components/guide/utils'

const Feedback = () => {
  return (
    <Section>
      <Grid centered stackable>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">
              We're looking forward to understanding your honest feedback.
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Image
              className="up-mobile"
              src={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-presentation/rating-desktop.webp`}
            />
            <Image
              className="only-mobile"
              size="medium"
              src={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-presentation/rating-mobile.webp`}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default Feedback
