import React from 'react'

import moment from 'moment'

import IssuesAccordion from 'components/app/project/deliveries/issues-accordion'
import { ReportsTable } from 'components/app/project/deliveries/reports-table'
import useOrderCabinetryMany from 'context/order/use-many-cabinetry'
import { getSupplierOrdersIssues } from 'context/order/utils'
import { getShipmentSteps } from 'context/shipment/utils'
import { getCourierSupplierOrderSteps } from 'context/supplier-order/utils'
import StatusLabel from 'design-system/components/status-label'
import { Delivery } from 'types/delivery'
import { StatusMode } from 'types/utils'

type Step = {
  done: boolean
  title: string
  extraInfo: string
}

const IssuesResolved = ({
  activeIndex,
  setActiveIndex,
}: {
  activeIndex?: string[]
  setActiveIndex: React.Dispatch<React.SetStateAction<string[]>>
}) => {
  const { shipments, deliveriesByOrder, supplierOrdersByCourier } =
    useOrderCabinetryMany()

  const deliveries = deliveriesByOrder.reduce<Delivery[]>((acc, d) => {
    acc.push(...d.deliveries)
    return acc
  }, [])

  const getStatusProps = (steps: Step[] | NullableRecord<Step>[]) => {
    let statusLabelProps = {
      icon: 'industry',
      text: 'Sent to Production',
      type: StatusMode.OpenForRevision,
    }

    const stepsReversed = steps.reverse()
    if (stepsReversed.find((s) => s.done)) {
      const step = steps.find((s) => s.done)
      if (step) {
        statusLabelProps = {
          icon: 'check',
          text: `${step.title} ${step.extraInfo}`,
          type: StatusMode.Confirmed,
        }
      }
    }

    return statusLabelProps
  }

  const setIndex = (index: string) =>
    setActiveIndex((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    )

  return (
    <>
      {shipments.map((s) => {
        const issues = getSupplierOrdersIssues(s.supplierOrders)
        if (!issues?.length) return

        return (
          <div id={`order-accordion-${s.id}1`} key={s.id}>
            <IssuesAccordion
              onClick={() => setIndex(`${s.id}1`)}
              open={activeIndex?.includes(`${s.id}1`) ?? false}
              titleExtras={
                <StatusLabel {...getStatusProps(getShipmentSteps(s))} />
              }
              titleText="Ocean freight"
            >
              <ReportsTable mode="readOnly" issues={issues} />
            </IssuesAccordion>
          </div>
        )
      })}
      {deliveries.map((d) => {
        const issues = getSupplierOrdersIssues(d.supplierOrders)
        if (!issues?.length) return

        let statusLabelProps = {
          icon: 'clock',
          text: 'Pending Delivery Date',
          type: StatusMode.OpenForEdit,
        }

        if (d.date)
          statusLabelProps = {
            icon: 'check',
            text: `Scheduled ${moment(d.date).format('MM/DD/YYYY')}`,
            type: StatusMode.Confirmed,
          }

        return (
          <div id={`order-accordion-${d.id}2`} key={d.id}>
            <IssuesAccordion
              onClick={() => setIndex(`${d.id}2`)}
              open={activeIndex?.includes(`${d.id}2`) ?? false}
              titleExtras={<StatusLabel {...statusLabelProps} />}
              titleText="Delivery"
            >
              <ReportsTable mode="readOnly" issues={issues} />
            </IssuesAccordion>
          </div>
        )
      })}
      {supplierOrdersByCourier.map((s) => {
        const issues = getSupplierOrdersIssues([s])
        if (!issues?.length) return

        return (
          <div id={`order-accordion-${s.id}3`} key={s.id}>
            <IssuesAccordion
              onClick={() => setIndex(`${s.id}3`)}
              open={activeIndex?.includes(`${s.id}3`) ?? false}
              titleExtras={
                <StatusLabel
                  {...getStatusProps(getCourierSupplierOrderSteps(s))}
                />
              }
              titleText="Courier"
            >
              <ReportsTable mode="readOnly" issues={issues} />
            </IssuesAccordion>
          </div>
        )
      })}
    </>
  )
}

export default IssuesResolved
