import React from 'react'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { navigate } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AddressFormat from 'components/shared/address-format'
import { getSupplierOrdersIssues } from 'context/order/utils'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import LogisticsCard from 'design-system/components/cards/logistics-card'
import { Colors } from 'styles/app/system'
import { Delivery } from 'types/delivery'
import { Order } from 'types/order'

import DetailsModal from './modal-details'

const DeliveryCards = ({
  deliveries,
  fromAddOnsAndRemedials,
  onSeeIssues,
  order,
}: {
  deliveries: Delivery[]
  fromAddOnsAndRemedials?: boolean
  onSeeIssues?: (shipmentId: string) => void
  order: Order
}) => {
  const { project } = useProject()

  return (
    <>
      {deliveries.map((d) => {
        const { data, issues } = d
        const hasIssues =
          data?.damagesSelection === 'damaged' || !!issues?.length
        const hasSOIssues = !!getSupplierOrdersIssues(d.supplierOrders)?.length

        const hasConfirmed =
          data?.damagesSelection && !!data.palletesCompletedAt

        let btnText = 'Confirm Delivery'
        let redirectRoute = `/app/projects/${project.id}/orders/${order.id}/deliveries/${d.id}/confirm-delivery`
        let textHeader = 'Ready for delivery'
        let icon: IconName = 'star-exclamation'
        let title = 'Your delivery is at the local warehouse'
        let extraInfo = 'The warehouse will call you to schedule a delivery'

        if (hasIssues || hasConfirmed) {
          btnText = 'Report Issues'
          redirectRoute = `/app/projects/${project.id}/orders/${order.id}/deliveries/${d.id}/issues`
          textHeader = 'Delivery Confirmed'
          icon = 'badge-check'
          title = 'We’ve received your photos, this helps protect us both.'
          extraInfo = 'If you find any issues, please report them below.'
        }

        return (
          <Grid.Column
            widescreen={4}
            largeScreen={4}
            computer={5}
            tablet={8}
            mobile={16}
            key={d.id}
          >
            <LogisticsCard
              bottomInfo={
                <div style={{ marginTop: '50px' }}>
                  <div
                    style={{
                      display: 'inline-flex',
                      marginBottom: '10px',
                    }}
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'location-dot']}
                      style={{ padding: '6px 17px 0 3px' }}
                    />
                    <div
                      style={{
                        fontFamily: 'GT America',
                        fontSize: '16px',
                        fontWeight: '100',
                        lineHeight: '165%',
                      }}
                    >
                      <AddressFormat address={order.shippingAddress} />
                    </div>
                  </div>
                  <Button
                    kind="solid"
                    onClick={() => {
                      navigate(redirectRoute, {
                        state: {
                          toResolutionOrders: fromAddOnsAndRemedials,
                        },
                      })
                    }}
                    size="fluid"
                    text={btnText}
                  />
                </div>
              }
              detailsModal={<DetailsModal supplierOrders={d.supplierOrders} />}
              iconHeader={
                <FontAwesomeIcon
                  icon={['fal', icon]}
                  style={{
                    color: Colors.orange700,
                    marginRight: '16px',
                    fontSize: '16px',
                  }}
                />
              }
              parts={d.supplierOrders.length}
              steps={[
                {
                  done: true,
                  title,
                  extraInfo,
                },
              ]}
              textHeader={textHeader}
            />
            {fromAddOnsAndRemedials && hasSOIssues && (
              <Button
                color="blue"
                fontAwesomeIcon="file-magnifying-glass"
                kind="text"
                onClick={() => onSeeIssues?.(`${d.id}2`)}
                style={{ marginTop: '8px' }}
                text="See Issues Resolved"
              />
            )}
          </Grid.Column>
        )
      })}
    </>
  )
}

export default DeliveryCards
