import React, { useEffect, useState } from 'react'

import { Form } from 'semantic-ui-react'

import useProject from 'context/project/use'
import { getCollaboratorDropdownOptions } from 'context/user/utils'
import { PhoneInput, validatePhone } from 'design-system/components/form'
import { Modal } from 'design-system/components/modal'
import { StyledForm } from 'styles/app/components/form'
import { User, UserType, UserTypeHomeowner, UserTypeTrade } from 'types/user'
import { emailIsValid, toTitleCase } from 'views/utils'

type CollaboratorModalProps = {
  collaborator?: User
  loading?: boolean
  onClose?: () => void
  onSave: (user: User) => void
  open: boolean
  onExited?: () => void
}

const CollaboratorModal = ({
  collaborator,
  loading,
  onClose,
  onSave,
  open,
  onExited,
}: CollaboratorModalProps) => {
  const { allOwners, isTradeProject } = useProject()
  const [inputError, setInputError] = useState<boolean>(false)
  const [phoneError, setPhoneError] = useState<boolean>(false)
  const [newCollab, setCollab] = useState<
    NullableRecord<{
      id: string
      email: string
      firstName: string
      lastName: string
      phone: string
      type: UserType
    }>
  >(
    collaborator || {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      type: null,
    },
  )

  const collaboratorInProject =
    !collaborator?.id && allOwners.some((u) => u.email === newCollab.email)

  const clearCollaborator = () =>
    setCollab(
      collaborator || {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        type: null,
      },
    )

  const onCloseModal = () => {
    setInputError(false)
    onClose?.()
  }

  useEffect(() => {
    clearCollaborator()
  }, [collaborator])

  const onChangeInput = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCollab({ ...newCollab, [name]: value })
  }

  const onChangeInputToTitleCase = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setCollab({ ...newCollab, [name]: toTitleCase(value) })
  }

  const handleOnExited = () => {
    clearCollaborator()
    onExited?.()
  }

  const collaboratorInfoComplete =
    emailIsValid(newCollab.email) &&
    !collaboratorInProject &&
    !!newCollab.firstName &&
    !!newCollab.lastName &&
    !!newCollab.type &&
    (newCollab.type !== UserTypeHomeowner.INSTALLER
      ? validatePhone(newCollab.phone ?? '')
      : true)

  return (
    <Modal
      onClose={onCloseModal}
      open={open}
      size="small"
      title={collaborator ? 'Edit Collaborator' : 'Add a Collaborator'}
      saveButton={{
        loading,
        disabled: !collaboratorInfoComplete,
        onClick: () => {
          onSave(newCollab as User)
          onCloseModal()
        },
      }}
      onExited={handleOnExited}
    >
      <StyledForm>
        <Form.Dropdown
          fluid
          label="Role"
          selection
          onChange={(_, { value }) =>
            setCollab({
              ...newCollab,
              type: value as UserType,
            })
          }
          options={getCollaboratorDropdownOptions(
            isTradeProject ? UserTypeTrade : UserTypeHomeowner,
          )}
          placeholder="Select an option"
          value={newCollab.type as string}
        />
        <Form.Input
          disabled={!!collaborator}
          {...(inputError && {
            error: {
              content: (
                <p className="red small">
                  {collaboratorInProject
                    ? 'User is already on this project'
                    : 'Please enter a valid email address'}
                </p>
              ),
            },
          })}
          label="Email"
          name="email"
          onBlur={() =>
            setInputError(
              collaboratorInProject || !emailIsValid(newCollab.email),
            )
          }
          onChange={(target: React.ChangeEvent<HTMLInputElement>) => {
            onChangeInput(target)
            if (inputError)
              setInputError(
                collaboratorInProject || !emailIsValid(newCollab.email),
              )
          }}
          placeholder="Email"
          required
          type="email"
          value={newCollab.email}
        />
        <Form.Input
          label="First Name"
          name="firstName"
          onChange={onChangeInputToTitleCase}
          placeholder="First Name"
          required
          value={newCollab.firstName}
        />
        <Form.Input
          label="Last Name"
          name="lastName"
          onChange={onChangeInputToTitleCase}
          placeholder="Last Name"
          required
          value={newCollab.lastName}
        />
        <PhoneInput
          {...(phoneError && {
            error: {
              content: (
                <p className="red small">Please enter a valid phone number</p>
              ),
            },
          })}
          label="Phone"
          name="phone"
          placeholder="Phone"
          onBlur={() => setPhoneError(!validatePhone(newCollab.phone))}
          onChange={(value) => {
            setCollab({ ...newCollab, phone: value })
            if (phoneError) setPhoneError(!validatePhone(newCollab.phone))
          }}
          value={newCollab.phone ?? ''}
          disabled={newCollab.type === UserTypeHomeowner.INSTALLER}
          required
        />
        <Form.Field />
      </StyledForm>
    </Modal>
  )
}

export default CollaboratorModal
