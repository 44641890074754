import React from 'react'

import path from 'path'
import { Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { isImageFile } from 'context/project-file/utils'
import { Colors } from 'styles/app/system'
import { ProjectFile } from 'types/project-file'
import { responsiveText } from 'views/utils'

import Button from '../button'

const FilePlaceholderCard = ({ file }: { file: ProjectFile }) => {
  const ext = path.extname(file.key)
  const fileName = path.parse(file.key).name

  const isImage = isImageFile(file.key)

  return (
    <StyledCard
      onClick={() =>
        window.open(
          `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(file.key)}`,
        )
      }
    >
      {isImage ? (
        <Image
          src={`${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(file.key)}`}
        />
      ) : (
        <ImageContainer>
          <Image
            src={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/file-placeholder.svg`}
          />
        </ImageContainer>
      )}
      <div className="content">
        <p className="caption no-margin">
          {responsiveText(fileName, 15)}
          {ext}
        </p>
        <Button fontAwesomeIcon="arrow-up-right-from-square" kind="ghost" />
      </div>
    </StyledCard>
  )
}

export default FilePlaceholderCard

const ImageContainer = styled.div`
  align-items: center;
  background: ${Colors.gray50};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 28px;
  width: 100%;
`

const StyledCard = styled.div`
  border-radius: 4px;
  border: 1px solid ${Colors.gray200};
  cursor: pointer;
  padding: 16px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  img {
    object-fit: cover;
    height: 100%;
  }

  div.content {
    align-items: center;
    display: flex;
    justify-content: space-between;

    p {
      word-break: break-word;
    }
  }

  :hover {
    box-shadow:
      0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  }
`
