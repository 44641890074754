import React from 'react'

import { navigate, RouteComponentProps } from '@reach/router'

import { getAddressStreetAndLocalityString } from 'context/address/utils'
import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import { parseOwners } from 'context/project/utils'
import { NavigationProps } from 'design-system/components/guide/navigation'
import GuideLayout from 'design-system/containers/guide-layout'
import { getNameForUser } from 'views/utils'

import AdditionalRooms from './sections/additional-rooms'
import Feedback from './sections/feedback'
import NextCall from './sections/next-call'
import NextSteps from './sections/next-steps'
import ProjectDeposit from './sections/project-deposit'
import ProjectProposal from './sections/project-proposal'
import Samples from './sections/samples'
import Visuals from './sections/visuals'
import WhatWeOffer from './sections/what-we-offer'

const navItems: NavigationProps['items'] = [
  {
    linkTo: 'review-changes',
    title: 'Has anything changed?',
    icon: ['fal', 'hexagon'],
  },
  {
    linkTo: 'samples',
    title: 'Your samples',
    icon: ['fal', 'square'],
  },
  {
    linkTo: 'project-scope',
    title: 'Project Scope',
    icon: ['fal', 'file-invoice-dollar'],
  },
  {
    linkTo: 'visuals',
    title: 'Your visuals',
    icon: ['fal', 'images'],
  },
  {
    linkTo: 'what-we-offer',
    title: 'What we offer',
    icon: ['fal', 'hexagon'],
  },
  {
    linkTo: 'next-steps',
    title: 'Next steps',
    icon: ['fal', 'clipboard-list-check'],
  },
  {
    linkTo: 'next-call',
    title: 'Schedule next call',
    icon: ['fal', 'video-plus'],
  },
]

const GuideModule = (_: RouteComponentProps) => {
  const { isFORMStaff } = useAuth()
  const { project } = useProject()

  const { primaryOwner } = parseOwners(project)

  return (
    <>
      <GuideLayout
        guideId="project-proposal-page"
        coverProps={{
          address: getAddressStreetAndLocalityString(project.projectAddress),
          projectLabel: project.data?.projectLabel,
          src: '/images/interactive-guides/project-proposal/trade-project-proposal.webp',
          subTitle: getNameForUser(primaryOwner),
          title: 'Project Proposal Review',
        }}
        handleLogoClick={() => navigate(`/app/projects/${project.id}`)}
        introProps={{
          agenda: [
            {
              timer: '60 mins',
              text: 'Review the design, discuss options and decide on next steps.',
            },
          ],
        }}
        navItems={
          isFORMStaff
            ? navItems
            : navItems.filter((i) => i.linkTo !== 'next-call')
        }
      >
        <AdditionalRooms />
        <Feedback />
        <Samples />
        <ProjectProposal />
        <Visuals />
        <WhatWeOffer />
        <NextSteps />
        <ProjectDeposit />
        {isFORMStaff ? <NextCall projectId={project.id} /> : null}
      </GuideLayout>
    </>
  )
}

export default GuideModule
