import React from 'react'

import { useScrollRestoration } from 'gatsby'

import { ScrollContainer } from 'styles/app/system'
import { Project } from 'types/project'
import { RoomGraphQL } from 'types/room'

import Finishes from './finishes'
import Functionality from './functionality'
import Inspiration from './inspiration'
import Materials from './materials'
import MoodboardSection from './moodboard-section'
import WhatWeHeard from './what-we-heard'

interface ConceptDesignProps {
  project: Project
  room: RoomGraphQL
}

const ConceptDesign = ({ project, room }: ConceptDesignProps) => {
  const ulScrollRestoration = useScrollRestoration('page-design-brief')

  return (
    <ScrollContainer
      id="design-brief-page"
      {...(ulScrollRestoration as any)}
      style={{ background: 'white' }}
    >
      <MoodboardSection project={project} room={room} />
      {room.data?.designBriefWhatWeHeard && (
        <WhatWeHeard items={room.data.designBriefWhatWeHeard} />
      )}
      <Inspiration />
      <Finishes />
      <Materials />
      <Functionality />
      <div style={{ height: '110px' }} />
    </ScrollContainer>
  )
}

export default ConceptDesign
