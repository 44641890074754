import React from 'react'

import { RouteComponentProps, useLocation } from '@reach/router'
import { navigate } from 'gatsby'

import FrontsBrowser from 'components/shared/catalog/nobilia-feature/fronts-browser'
import useRoom from 'context/room/use'
import useRoomDesignOptionsMutate from 'context/room/use-design-options-mutate'
import { NobiliaOption } from 'types/nobilia-option'
import { GuideType } from 'types/utils'

type SelectionFrontsProps = RouteComponentProps<{
  project_id: string
  room_id: string
}>

const SelectionFronts = (props: SelectionFrontsProps) => {
  const { state } = useLocation()
  const stateGuide = state as {
    fromGuide?: GuideType
  }

  const { fronts: frontsSelected, room } = useRoom()
  const { onSaveNobiliaOptions } = useRoomDesignOptionsMutate()

  const onBack = () => {
    const backGuide =
      stateGuide?.fromGuide === GuideType.ProjectConsultation
        ? 'project-consultation'
        : 'design-call'

    navigate(
      `/app/projects/${props.project_id}/rooms/${props.room_id}/${backGuide}`,
    )
  }

  const onSave = (item: Partial<NobiliaOption>) => {
    let selection
    if (frontsSelected?.find((s) => s.id === item.id))
      selection = frontsSelected.filter((s) => s.id !== item.id)
    else selection = frontsSelected ? [...frontsSelected, item] : [item]

    onSaveNobiliaOptions(selection, frontsSelected ?? [])
  }

  return (
    <FrontsBrowser
      catalog={room?.data?.catalog}
      frontsSelected={frontsSelected}
      onBack={onBack}
      onSave={onSave}
    />
  )
}

export default SelectionFronts
