import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, List } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import useUser from 'context/user/use'
import useUserMutateNoContext from 'context/user/use-mutate-no-context'
import Button from 'design-system/components/button'
import { StyledForm } from 'styles/app/components/form'
import { Colors } from 'styles/app/system'
import { getNameForUser } from 'views/utils'

const OrderedBy = () => {
  const { isFORMStaff } = useAuth()
  const { user, refetch: refetchUser } = useUser()
  const { updateUser, loadingUpdate } = useUserMutateNoContext()
  const { refetch, primaryOwner } = useProject()

  const userOrdering = user || primaryOwner

  const [profile, setProfile] = useState({
    firstName: userOrdering?.firstName || '',
    lastName: userOrdering?.lastName || '',
  })

  const onChangeOwner = async () => {
    await updateUser({
      variables: {
        data: {
          firstName: profile.firstName,
          lastName: profile.lastName,
        },
        where: {
          id: userOrdering.id,
        },
      },
    })

    refetchUser()
    refetch()
  }

  const onChangeInput = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) =>
    setProfile({ ...profile, [name]: value })

  return (
    <List.Item>
      <List.Content>
        <p className="caption">Ordered by</p>
        {!userOrdering?.firstName || !userOrdering?.lastName ? (
          <>
            {isFORMStaff && (
              <p style={{ margin: '-8px 0 18px' }}>
                Complete profile for {userOrdering.email}
              </p>
            )}
            <StyledForm>
              <Form.Group widths="equal">
                <Form.Input
                  label="First Name"
                  name="firstName"
                  onChange={onChangeInput}
                  placeholder="First Name"
                  value={profile.firstName}
                  required
                />
                <Form.Input
                  label="Last Name"
                  name="lastName"
                  onChange={onChangeInput}
                  placeholder="Last Name"
                  value={profile.lastName}
                  required
                />
              </Form.Group>
            </StyledForm>
            <Button
              color="blue"
              disabled={!profile.firstName || !profile.lastName}
              loading={loadingUpdate}
              kind="solid"
              onClick={onChangeOwner}
              style={{ float: 'right' }}
              text="Save"
            />
          </>
        ) : (
          <p
            style={{
              alignItems: 'center',
              display: 'flex',
              gap: '20px',
            }}
          >
            <FontAwesomeIcon
              icon={['fal', 'user']}
              style={{ color: Colors.orange100 }}
            />
            {getNameForUser(userOrdering)}
          </p>
        )}
      </List.Content>
    </List.Item>
  )
}

export default OrderedBy
