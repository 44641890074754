import React, { useEffect, useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import * as CompanyEmailValidator from 'company-email-validator'
import { isEqual } from 'lodash'
import {
  Divider,
  DropdownProps,
  Form,
  FormInputProps,
  Grid,
  Input,
  Label,
} from 'semantic-ui-react'
import styled from 'styled-components'

import StepTitle from 'components/app/step-title'
import useCompany from 'context/company/use'
import useCompanyMutateNoContext from 'context/company/use-mutate-no-context'
import SaveToast from 'design-system/components/save-toast'
import AppLayout from 'design-system/containers/app-layout'
import { StyledForm } from 'styles/app/components/form'
import { Colors } from 'styles/app/system'
import { IndustryType } from 'types/company'
import { getDropdownOptionsFromEnum } from 'views/utils'

const Module = (_: RouteComponentProps) => {
  const { company: companyDB, refetch } = useCompany()
  const { updateCompany } = useCompanyMutateNoContext()

  const initialState = {
    name: companyDB?.name || '',
    domain: companyDB?.domain || '',
    industry: companyDB?.data?.industry || null,
    otherIndustry: companyDB?.data?.otherIndustry || '',
    instagram: companyDB?.data?.instagram || '',
    size: companyDB?.data?.size || '',
    website: companyDB?.data?.website || '',
  }
  const [company, setCompany] = useState(initialState)
  const [domainError, setDomainError] = useState<boolean>(false)
  const [isValid, setValid] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const handleSave = async () => {
    setLoading(true)
    const identifier = company.domain || company.name

    if (companyDB?.id) {
      await updateCompany({
        variables: {
          data: {
            name: company.name,
            ...(company.domain ? { domain: company.domain } : null),
            identifier,
            data: {
              industry: company.industry,
              ...(company.instagram ? { instagram: company.instagram } : null),
              ...(company.size ? { size: company.size } : null),
              ...(company.website ? { website: company.website } : null),
            },
          },
          where: {
            id: companyDB.id,
          },
        },
      })
    }
    refetch()
    setLoading(false)
  }

  const onChangeInput = (
    _: React.ChangeEvent<HTMLInputElement>,
    { value, name }: { value: string; name: string },
  ) => {
    setCompany({ ...company, [name]: value })
  }

  const formChanged = !isEqual(initialState, company)

  useEffect(() => {
    if (company.name && company.size && company.industry) setValid(true)
    else setValid(false)
  }, [company])

  return (
    <AppLayout>
      <Grid centered>
        <StepTitle title="Main Information" />
        <Divider hidden />
        <Grid.Row>
          <Grid.Column computer={11} tablet={16}>
            <StyledForm styled={{ whiteInputs: true }}>
              <Form.Group widths="equal">
                <Form.Input
                  label="Name"
                  disabled={!!companyDB?.name}
                  name="name"
                  onChange={onChangeInput as FormInputProps['onChange']}
                  placeholder="Company Name"
                  required
                  value={company.name}
                />
                <LabeledField>
                  <label>Email Domain</label>
                  <Input
                    disabled={!!companyDB?.domain}
                    name="domain"
                    onBlur={() => {
                      if (
                        company.domain &&
                        !CompanyEmailValidator.isCompanyDomain(company.domain)
                      )
                        setDomainError(true)
                      else {
                        setDomainError(false)
                      }
                    }}
                    onChange={onChangeInput as FormInputProps['onChange']}
                    label="@"
                    value={company.domain}
                  />
                  {domainError && (
                    <Label pointing prompt>
                      <p className="red small">Please enter a valid domain</p>
                    </Label>
                  )}
                </LabeledField>
              </Form.Group>
              <Form.Group
                inline
                style={{
                  alignItems: 'baseline',
                  padding: '0 7px',
                  margin: '40px 0',
                }}
              >
                <label style={{ marginRight: '30px' }}>
                  <p className="light-gray" style={{ fontSize: '15px' }}>
                    Size <span className="red">*</span>
                  </p>
                </label>
                <Form.Radio
                  checked={company.size === '1-10'}
                  label="1-10"
                  name="size"
                  onChange={() => setCompany({ ...company, size: '1-10' })}
                  value="1-10"
                />
                <Form.Radio
                  checked={company.size === '10-30'}
                  label="10-30"
                  name="size"
                  onChange={() => setCompany({ ...company, size: '10-30' })}
                  value="10-30"
                />
                <Form.Radio
                  checked={company.size === '30-70'}
                  label="30-70"
                  name="size"
                  onChange={() => setCompany({ ...company, size: '30-70' })}
                  value="30-70"
                />
                <Form.Radio
                  checked={company.size === '70+'}
                  label="70+"
                  name="size"
                  onChange={() => setCompany({ ...company, size: '70+' })}
                  value="70+"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Dropdown
                  label="Industry"
                  name="industry"
                  onChange={onChangeInput as DropdownProps['onChange']}
                  options={getDropdownOptionsFromEnum(IndustryType, true)}
                  required
                  selection
                  value={company.industry ?? undefined}
                />
                <Form.Input
                  disabled={company.industry !== IndustryType.OTHER}
                  label="Other Industry"
                  name="otherIndustry"
                  onChange={onChangeInput as FormInputProps['onChange']}
                  placeholder="Other Industry"
                  value={company.otherIndustry}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <LabeledField>
                  <label>Website</label>
                  <Input
                    name="website"
                    onChange={onChangeInput as FormInputProps['onChange']}
                    label="https://"
                    placeholder="mysite.com"
                    value={company.website}
                  />
                </LabeledField>
                <LabeledField>
                  <label>Instagram handle</label>
                  <Input
                    name="instagram"
                    onChange={onChangeInput as FormInputProps['onChange']}
                    label="@"
                    value={company.instagram}
                  />
                </LabeledField>
              </Form.Group>
            </StyledForm>
          </Grid.Column>
        </Grid.Row>
        <SaveToast
          disabled={!isValid}
          loading={loading}
          onClick={async () => await handleSave()}
          show={formChanged}
        />
      </Grid>
    </AppLayout>
  )
}

export default Module

const LabeledField = styled(Form.Field)`
  .labeled {
    .label {
      background: ${Colors.white};
      padding: 14px !important;

      font-family: 'GT America';
      font-size: 16px !important;
      font-weight: 100;
      color: ${Colors.gray600};
    }
  }
`
