import React from 'react'

import styled from 'styled-components'

import Button from 'design-system/components/button'
import ThankYou from 'design-system/containers/thank-you'
import { Breakpoint } from 'styles/app/system'

import { DeliveryFlowRouteProps } from '../types'

export const DeliveryThankYouView = ({
  navigate,
  project_id: projectId,
  order_id: orderId,
  delivery_id: deliveryId,
  location,
}: DeliveryFlowRouteProps) => {
  return (
    <ThankYou
      imageUrl={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/delivery-thank-you.svg`}
      lateralText="delivery confirmed"
      subtitle={
        <>
          We’ve received your photos, this helps protect us both.
          <br />
          If you find any issues, please report them below
        </>
      }
      mainButton={
        <ButtonContainer>
          <Button
            kind="solid"
            text="Back to Order"
            fontAwesomeIcon="arrow-left"
            iconAnimation="left"
            onClick={() =>
              navigate?.(`/app/projects/${projectId}/orders/${orderId}`, {
                state: location?.state,
              })
            }
            iconPosition="left"
            size="fluid"
          />
          <Button
            kind="solid"
            color="blue"
            text="Report Issues"
            fontAwesomeIcon="triangle-exclamation"
            size="fluid"
            onClick={() =>
              navigate?.(
                `/app/projects/${projectId}/orders/${orderId}/deliveries/${deliveryId}/issues`,
                { state: location?.state },
              )
            }
            iconAnimation="scale"
          />
        </ButtonContainer>
      }
    />
  )
}

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;

  & > button {
    min-width: max-content;
  }

  @media ${Breakpoint.downFromSmallMobile} {
    flex-direction: column;
  }
`
