import React, { useEffect, useMemo, useState } from 'react'

import { RouteComponentProps, Router } from '@reach/router'
import { useMedia } from 'react-use'
import styled from 'styled-components'

import { NavFooter } from 'components/app/nav-footer'
import HeaderBasic from 'components/app/project/header/header-basic'
import StartFlowView from 'components/app/project/start-flow-view'
import useProject from 'context/project/use'
import Button from 'design-system/components/button'
import FlowLayout from 'design-system/containers/flow-layout'
import ThankYou from 'design-system/containers/thank-you'
import { Breakpoint } from 'styles/app/system'

import {
  START_PROJECT_STEPS,
  START_PROJECT_STEPS_ARRAY as STEPS,
} from './steps'

export default ({
  path: _,
  ...routeProps
}: RouteComponentProps<{
  '*': string
  project_id: string
}>) => {
  const isMobile = useMedia(Breakpoint.downFromSmallDesktop)
  const { '*': subroute, project_id: projectId, navigate } = routeProps

  const { buildZoomData, meetingBuildZoomScheduled, project } = useProject()

  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [nextDisabled, setNextDisabled] = useState<boolean>(true)
  const [onNext, setOnNext] = useState<() => Promise<void>>()

  const currentStepIndex = useMemo(
    () => STEPS.findIndex((step) => step.subroute === subroute),
    [subroute],
  )

  useEffect(() => {
    let redirectSubroute = null

    if (!buildZoomData) return
    else if (!buildZoomData.largeProject) {
      redirectSubroute = START_PROJECT_STEPS.size.subroute
    } else if (!buildZoomData.wholeProject) {
      redirectSubroute = START_PROJECT_STEPS.scope.subroute
    } else if (!buildZoomData.newLayout) {
      redirectSubroute = START_PROJECT_STEPS.walls.subroute
    } else if (!buildZoomData.needsPlumber) {
      redirectSubroute = START_PROJECT_STEPS.appliances.subroute
    } else if (!buildZoomData.newFlooring) {
      redirectSubroute = START_PROJECT_STEPS.flooring.subroute
    } else if (!buildZoomData.needsElectrician) {
      redirectSubroute = START_PROJECT_STEPS.additions.subroute
    } else if (!buildZoomData.noSupplier) {
      redirectSubroute = START_PROJECT_STEPS.countertop_backsplash.subroute
    } else if (!buildZoomData.photos) {
      redirectSubroute = START_PROJECT_STEPS.information.subroute
    } else if (!meetingBuildZoomScheduled) {
      redirectSubroute = START_PROJECT_STEPS.booking.subroute
    }

    if (redirectSubroute)
      navigate?.(`/app/projects/${projectId}/buildzoom/${redirectSubroute}`, {
        replace: true,
        state: { isEffectRedirect: true },
      })
  }, [])

  const isFirstStep = currentStepIndex <= 0
  const isLastStep = currentStepIndex >= STEPS.length - 1

  const handleNext = async () => {
    await onNext?.()
    navigate?.(
      `/app/projects/${projectId}/buildzoom/${
        STEPS[currentStepIndex + 1].subroute
      }`,
    )
  }

  if (meetingBuildZoomScheduled)
    return (
      <>
        {!isMobile ? <HeaderBasic /> : null}
        <ThankYou
          imageUrl={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/buildzoom-thank-you.svg`}
          lateralText="Consultation Scheduled"
          mainButton={
            <Button
              fontAwesomeIcon="arrow-right-long"
              kind="solid"
              onClick={() => navigate?.(`/app/projects/${project.id}/home`)}
              size="fluid"
              text="Return to Dashboard"
            />
          }
          subtitle="We have received your information. Looking forward to meeting you."
        />
      </>
    )

  if (currentStepIndex === -1)
    return (
      <StartFlowView
        designerInformation={{
          picture: `${process.env.GATSBY_CDN_ROOT}/images/app/buildzoom-logo.svg`,
        }}
        description="To help connect you with the right contractor, BuildZoom needs some additional information about your project."
        handleStart={() =>
          navigate?.(
            `/app/projects/${project.id}/buildzoom/${STEPS[0].subroute}`,
          )
        }
        header={<HeaderBasic />}
        lateralText="Connect to Buildzoom"
        title={<h2>Connect to BuildZoom</h2>}
      />
    )

  return (
    <FlowLayout
      header={
        <HeaderBasic
          onBack={() => navigate?.(`/app/projects/${project.id}/home`)}
        />
      }
      main={
        <>
          <StepName className="subtitle gray">
            {STEPS[currentStepIndex].label}
          </StepName>
          <Router>
            {STEPS.map(({ View, subroute }) => (
              <View
                key={subroute}
                path={subroute}
                setOnNext={setOnNext}
                setNextDisabled={setNextDisabled}
                setLoading={setLoadingButton}
                {...routeProps}
              />
            ))}
          </Router>
        </>
      }
      actions={
        <NavFooter
          hideBack={isFirstStep}
          backDisabled={isFirstStep}
          nextDisabled={nextDisabled}
          loading={loadingButton}
          nextText="Continue"
          onBack={() =>
            navigate?.(
              `/app/projects/${projectId}/buildzoom/${
                STEPS[currentStepIndex - 1].subroute
              }`,
            )
          }
          onNext={isLastStep ? undefined : handleNext}
        />
      }
      stepperProps={{
        currentStep: currentStepIndex,
        steps: STEPS,
        breakLine: true,
      }}
    />
  )
}

const StepName = styled.p`
  text-align: center;
  text-transform: uppercase;
  display: none;

  @media ${Breakpoint.downFromTablet} {
    display: block;
  }
`
