import React from 'react'

import { RouteComponentProps } from '@reach/router'

import useUser from 'context/user/use'

import UserHome from './user'

const Index = (_: RouteComponentProps) => {
  const { user } = useUser()

  if (!user) return null
  return <UserHome />
}

export default Index
