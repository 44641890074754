import React from 'react'

import { notifySuccess } from 'context/notifications/trigger'
import useRoomMutate from 'context/room/use-mutate'
import { ModalApprovalBasic } from 'design-system/components/modal'
import { RoomGraphQL } from 'types/room'

const ApprovalModal = ({
  onClose,
  onFileApproved,
  open,
  room,
}: {
  onClose: () => void
  onFileApproved?: () => void
  open: boolean
  room: RoomGraphQL
}) => {
  const { updateRoomAndRefetch, loadingUpdate } = useRoomMutate()

  const handleApprove = async () => {
    await updateRoomAndRefetch({
      variables: {
        data: {
          data: {
            designBriefApprovedAt: new Date(),
          },
        },
        where: {
          id: room.id,
        },
      },
      onCompleted: async () => {
        notifySuccess('Design Brief approved')
        onFileApproved && onFileApproved()
        onClose()
        document.body.scrollTo({ top: 0 })
      },
    })
  }

  return (
    <ModalApprovalBasic
      cancelButton={{ text: 'Go back' }}
      onClose={onClose}
      open={open}
      saveButton={{
        onClick: handleApprove,
        text: 'Yes, I Approve',
        loading: loadingUpdate,
      }}
      title="Approve Design Brief"
      size="small"
    >
      <p>
        By approving your design brief, you’re letting your designer know that
        you have reviewed and are pleased with the design elements presented and
        are ready for us to begin designing your room.
        <br />
        <br />
        You will no longer be able to make changes before your design
        presentation.
      </p>
    </ModalApprovalBasic>
  )
}

export default ApprovalModal
