import React from 'react'

import { RouteComponentProps } from '@reach/router'

import RoomProjectFileUploads from 'components/app/project/room/project-file-uploads'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import AppLayout from 'design-system/containers/app-layout'
import { ProjectFileType } from 'types/project-file'

const Module = (
  _: RouteComponentProps<{
    location?: string
    project_id: string
    room_id: string
  }>,
) => {
  const { isTradeProject } = useProject()
  const { roomPhotos } = useRoom()

  return (
    <AppLayout>
      <RoomProjectFileUploads
        chooseProspectFiles={!isTradeProject}
        howToVideo="photos"
        projectFiles={roomPhotos}
        projectFileType={ProjectFileType.ROOM_PHOTO}
        subTitle={
          !isTradeProject
            ? 'Try adding at least 8 pictures. Is your space under-construction? Feel free to add any sketches or drawings.'
            : undefined
        }
        title={`Upload as many pictures ${
          !isTradeProject ? 'of your space' : ''
        } as you wish`}
      />
    </AppLayout>
  )
}

export default Module
