import React, { useEffect } from 'react'

import RoomProjectFileUploads from 'components/app/project/room/project-file-uploads'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import { ProjectFileType } from 'types/project-file'

import { StepViewProps } from '../types'

const FloorPlanView = ({
  chooseProspectFiles,
  setNextDisabled,
  setOnNext,
  setSkip,
}: StepViewProps) => {
  const { isTradeProject } = useProject()
  const { floorPlans } = useRoom()

  useEffect(() => {
    setOnNext(undefined)
  }, [])

  useEffect(() => {
    if (isTradeProject) {
      setNextDisabled(false)
      setSkip(!floorPlans.length)
    } else {
      setNextDisabled(!floorPlans.length)
      setSkip(false)
    }
  }, [floorPlans])

  return (
    <RoomProjectFileUploads
      chooseProspectFiles={chooseProspectFiles}
      howToVideo="floorPlan"
      key="floor-plans"
      path="floor-plans"
      projectFiles={floorPlans}
      projectFileType={ProjectFileType.FLOOR_PLAN}
      subTitle={
        isTradeProject
          ? 'Add any floor plans, architectural plans, etc you have'
          : 'Add any floor plans, sketches, architectural plans, etc you have'
      }
      title={
        isTradeProject
          ? 'Upload a floor plan of the space'
          : 'Upload a floor plan of your space'
      }
    />
  )
}

export default FloorPlanView
