import React from 'react'

import { Document, Page, Text, View } from '@react-pdf/renderer'

import { ReceiptStyles } from './styles'

const Receipt = ({
  children,
  title,
  rightColumn,
}: {
  children: React.ReactNode
  title: string
  rightColumn: React.ReactNode
}) => {
  return (
    <Document>
      <Page style={[ReceiptStyles.body]} size="A4" wrap>
        <View style={ReceiptStyles.row}>
          <Text style={ReceiptStyles.title}>FORM REMODEL INC.</Text>
        </View>
        <View style={ReceiptStyles.row}>
          <View style={ReceiptStyles.column}>
            <Text>5900 Balcones Drive STE 4000</Text>
            <Text>Austin TX 78731</Text>
            <Text>United States</Text>
            <Text>+1 (415) 429-3515</Text>
            <Text>help@formkitchens.com</Text>
          </View>
          <View style={ReceiptStyles.column}>{rightColumn}</View>
        </View>
        <View style={ReceiptStyles.divider} />
        <View style={ReceiptStyles.row}>
          <Text style={ReceiptStyles.title}>{title}</Text>
        </View>
        <View style={ReceiptStyles.divider} />
        <View style={ReceiptStyles.rowHeader}>
          <Text style={[ReceiptStyles.cell, ReceiptStyles.mainCell]}>
            Description
          </Text>
          <Text style={[ReceiptStyles.cell]}>Qty</Text>
          <Text style={[ReceiptStyles.cell]}>Unit Price</Text>
          <Text style={[ReceiptStyles.cell]}>Amount</Text>
        </View>
        {children}
        <View style={ReceiptStyles.footer}>
          <Text style={{ fontSize: 9 }}>
            Questions? Contact Form Remodel, Inc. at help@formkitchens.com or
            call at
          </Text>
          <Text style={{ fontSize: 9 }}>+1 (415) 429-3515</Text>
        </View>
      </Page>
    </Document>
  )
}

export default Receipt
