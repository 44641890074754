import React, { useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Grid, SearchProps } from 'semantic-ui-react'

import * as SelectionUtils from 'components/app/project/room/selection/utils'
import RoomElementOptionSelectionCard from 'components/shared/catalog/room-element-option/card'
import useAuth from 'context/auth/use'
import useRoom from 'context/room/use'
import useRoomDesignOptionsMutate from 'context/room/use-design-options-mutate'
import RoomElementOptionManyProvider from 'context/room-element-option/provider-many'
import useRoomElementOptionMany from 'context/room-element-option/use-many'
import { parseFloorMaterials } from 'context/selection-system/floor-materials'
import Button from 'design-system/components/button'
import { SelectionCardVariation } from 'design-system/components/selection-card'
import SelectionLayout from 'design-system/containers/selection-layout'
import { Breakpoint } from 'styles/app/system'
import { RoomElementOption } from 'types/room-element-option'

type SelectionFlooringProps = RouteComponentProps<{
  project_id: string
  room_id: string
}>

const SelectionFlooring = (props: SelectionFlooringProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const { isFORMStaff } = useAuth()

  const { floorMaterials: flooringSelected } = useRoom()
  const { onSaveRoomElementOptions } = useRoomDesignOptionsMutate()
  const { roomElementOptions } = useRoomElementOptionMany()

  const [search, setSearch] = useState<{
    results: RoomElementOption[] | null
    value: string
  }>({
    results: null,
    value: '',
  })

  const { floorsAll } = useMemo(
    () => parseFloorMaterials(roomElementOptions),
    [roomElementOptions],
  )

  const onSave = (item: Partial<RoomElementOption>) => {
    let selection
    if (flooringSelected?.find((s) => s.id === item.id))
      selection = flooringSelected.filter((s) => s.id !== item.id)
    else selection = [...(flooringSelected ?? []), item]

    onSaveRoomElementOptions(selection, flooringSelected ?? [])
  }

  const flooringearchChange = (
    _e: React.MouseEvent,
    { value }: SearchProps,
  ) => {
    setSearch({
      results: !value
        ? null
        : floorsAll.filter((f) =>
            [f.description, f.data?.displayName].some(
              (s) => s?.toLowerCase().includes(value.toLowerCase()),
            ),
          ),
      value: value as string,
    })
  }

  return (
    <SelectionLayout
      back={
        <Button
          color="gray"
          fontAwesomeIcon="arrow-left"
          iconAnimation="left"
          iconPosition="left"
          kind="text"
          onClick={() =>
            navigate(
              `/app/projects/${props.project_id}/rooms/${props.room_id}/design-call`,
            )
          }
          text={isMobile ? '' : 'Back'}
        />
      }
      searchProps={{
        onClose: () => {
          setSearch({
            results: null,
            value: '',
          })
        },
        onSearchChange: flooringearchChange,
        value: search.value,
      }}
      selection={
        flooringSelected?.length
          ? flooringSelected.map((f) => (
              <SelectionUtils.OptionSelected key={f.id}>
                <p className="no-margin small dark-gray">{f.description}</p>
                {isFORMStaff ? (
                  <span onClick={() => onSave(f)}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                  </span>
                ) : null}
              </SelectionUtils.OptionSelected>
            ))
          : null
      }
      title="Flooring"
    >
      <Grid doubling columns={5}>
        <Grid.Row stretched className="row-gap">
          {search.value && !search.results?.length ? (
            <Grid.Column>
              <p>No results</p>
            </Grid.Column>
          ) : (
            (search.results || floorsAll).map((f) => (
              <Grid.Column key={f.id}>
                <RoomElementOptionSelectionCard
                  roomElementOption={f}
                  selectionCardProps={{
                    ...(isFORMStaff
                      ? {
                          expandAndSelect: true,
                          onSelect: () => onSave(f),
                        }
                      : {
                          expandOnly: true,
                        }),
                    imageRatio: '1/1',
                    selected: flooringSelected?.some((s) => s.id === f.id),
                  }}
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))
          )}
        </Grid.Row>
      </Grid>
    </SelectionLayout>
  )
}

export default (
  props: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  return (
    <RoomElementOptionManyProvider
      defaultFilters={{
        feature: { identifier: 'floorMaterial' },
        sortBy: 'description',
        sortDirection: 'ascending',
        take: undefined,
      }}
    >
      <SelectionFlooring {...props} />
    </RoomElementOptionManyProvider>
  )
}
