import React from 'react'

import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import {
  parseLatestCountertopEstimate,
  parseLatestPlan,
  parseRenders,
} from 'context/design/utils'
import useOrder from 'context/order/use'
import useProject from 'context/project/use'
import { isImageFile } from 'context/project-file/utils'
import { parseFiles } from 'context/project-file/utils-many'
import {
  getRoomFormattedAdditional,
  getRoomFormattedType,
} from 'context/room/utils'
import DesignCard from 'design-system/components/cards/design-card'
import PrimaryCard from 'design-system/components/cards/primary-card'
import SecondaryCard from 'design-system/components/cards/secondary-card'
import TertiaryCard from 'design-system/components/cards/tertiary-card'
import TabsContainer from 'design-system/containers/tabs'
import { Breakpoint } from 'styles/app/system'
import { ProjectFile } from 'types/project-file'
import { getRoomImageByType } from 'views/utils'

const OrderDesigns = () => {
  const { project } = useProject()
  const { order, totalBeforeTaxes } = useOrder()
  const isSmallDesktop = useMedia(Breakpoint.fromTabletToSmallDesktop)

  const hasReceipt = order.placedAt && totalBeforeTaxes
  const applianceSpecs = order.designs?.reduce((acc, d) => {
    acc.push(
      ...(parseFiles(d.room?.files || [])?.applianceSpecs.map((f) => ({
        ...f,
        room: d.room,
      })) || []),
    )
    return acc
  }, [] as ProjectFile[])

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column>
          <h4 className="gray" style={{ paddingTop: '30px' }}>
            Approved designs included in order
          </h4>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="row-gap">
        <Grid.Column largeScreen={12} computer={11} tablet={16} mobile={16}>
          {!!order.designs?.length && (
            <TabsContainer<{ content: React.ReactNode }>
              tabs={order.designs.map((d) => {
                const { rendersReleased } = parseRenders(d)
                const { latestPlan } = parseLatestPlan(d)
                const { latestCountertopEstimate } =
                  parseLatestCountertopEstimate(d)
                const renderImage = rendersReleased.find((r) =>
                  isImageFile(r.key),
                )

                return {
                  key: d.id,
                  label: getRoomFormattedType(d.room),
                  content: (
                    <Grid stackable>
                      <Grid.Row stretched style={{ rowGap: '16px' }}>
                        <Grid.Column
                          widescreen={10}
                          largeScreen={10}
                          computer={16}
                          tablet={16}
                          mobile={16}
                        >
                          <DesignCard
                            kind="description"
                            description={getRoomFormattedAdditional(d.room)}
                            disabled={!latestPlan}
                            image={
                              renderImage
                                ? `${process.env.GATSBY_CDN_ROOT}/${renderImage.key}`
                                : getRoomImageByType(d.room.type)
                            }
                            title={`${getRoomFormattedType(d.room)}: ${d.name}`}
                            url={`/app/projects/${project.id}/rooms/${d.room.id}/designs/${d.id}/plan/${latestPlan?.id}`}
                          />
                        </Grid.Column>
                        <Grid.Column
                          widescreen={6}
                          largeScreen={6}
                          computer={16}
                          tablet={16}
                          mobile={16}
                        >
                          {isSmallDesktop || latestCountertopEstimate ? (
                            <CardsContainer>
                              <SecondaryCard
                                disabled={!rendersReleased?.length}
                                image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/visual-quote-b.svg`}
                                onClick={() =>
                                  navigate(
                                    `/app/projects/${project.id}/rooms/${d.room.id}/designs/${d.id}/quote`,
                                  )
                                }
                                title="Visual Quote"
                                basic
                              />
                              {latestCountertopEstimate && (
                                <SecondaryCard
                                  image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/countertop-estimate.svg`}
                                  onClick={() =>
                                    window.open(
                                      `${process.env.GATSBY_CDN_ROOT}/${latestCountertopEstimate?.key}`,
                                    )
                                  }
                                  title="Countertop Estimate"
                                  basic
                                />
                              )}
                            </CardsContainer>
                          ) : (
                            <PrimaryCard
                              disabled={!rendersReleased?.length}
                              image={`${process.env.GATSBY_CDN_ROOT}/images/app/illustrations/visual-quote.svg`}
                              onClick={() =>
                                navigate(
                                  `/app/projects/${project.id}/rooms/${d.room.id}/designs/${d.id}/quote`,
                                )
                              }
                              title="Visual Quote"
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  ),
                }
              })}
            >
              {(tab) => tab.content}
            </TabsContainer>
          )}
        </Grid.Column>
        {hasReceipt || applianceSpecs?.length ? (
          <Grid.Column
            largeScreen={4}
            computer={5}
            tablet={16}
            mobile={16}
            style={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '16px',
            }}
          >
            <>
              <p className="overline  no-margin">Resources</p>
              {hasReceipt ? (
                <TertiaryCard
                  icon="file-invoice-dollar"
                  title="Order Receipt"
                  onClick={() =>
                    navigate(
                      `/app/projects/${project.id}/orders/${order.id}/receipt`,
                    )
                  }
                />
              ) : null}
              {applianceSpecs?.map((f, idx) => (
                <TertiaryCard
                  icon="box"
                  title={`${getRoomFormattedType(f.room)} - Appliance Specs ${
                    idx > 0 ? idx + 1 : ''
                  }`}
                  onClick={() =>
                    window.open(
                      `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                        f.key,
                      )}`,
                      '_blank',
                    )
                  }
                  key={f.id}
                />
              ))}
            </>
          </Grid.Column>
        ) : null}
      </Grid.Row>
    </Grid>
  )
}
const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 16px;
`
export default OrderDesigns
