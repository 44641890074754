import React, { useState } from 'react'

import Helmet, { HelmetTags } from 'react-helmet'

interface ScriptLoaderProps {
  children: React.ReactNode
}
const scriptURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_API_KEY}&libraries=places&language=en-US`

const PlacesApiLoader = ({ children }: ScriptLoaderProps) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)

  const handleChangeClientState = (_: unknown, addedTags: HelmetTags): void => {
    if (addedTags?.scriptTags) {
      const foundScript = addedTags.scriptTags.find(
        ({ src }: { src: string }) => src === scriptURL,
      )
      if (foundScript) {
        foundScript.addEventListener('load', () => setScriptLoaded(true), {
          once: true,
        })
      }
    }
  }

  return (
    <>
      <Helmet onChangeClientState={handleChangeClientState}>
        <script async defer src={scriptURL} />
      </Helmet>
      {scriptLoaded && <> {children} </>}
    </>
  )
}

export default PlacesApiLoader
