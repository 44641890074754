import React, { useState } from 'react'

import { useMedia } from 'react-use'
import styled, { css } from 'styled-components'

import HeaderDashboard from 'components/app/project/header/header-dashboard'
import Button from 'design-system/components/button'
import { ModalBasic } from 'design-system/components/modal'
import Stepper, { StepperProps } from 'design-system/components/stepper'
import { Breakpoint, Colors } from 'styles/app/system'

import FormVideo from '../form-video'

export type StartFlowViewProps = {
  description: string
  designerInformation: {
    firstName?: string
    picture: string
    video?: string
  }
  handleStart?: () => void
  header?: React.ReactNode
  lateralText?: string
  steps?: StepperProps['steps']
  title: JSX.Element
}

const StartFlowView = ({
  description,
  designerInformation,
  handleStart,
  header,
  lateralText,
  steps,
  title,
}: StartFlowViewProps) => {
  const [openVideo, setOpenVideo] = useState(false)
  const isMobile = useMedia(Breakpoint.downFromSmallMobile)

  return (
    <BlackContainer>
      {header || <HeaderDashboard onlyMessages />}
      <div className="info">
        {lateralText && <LateralText text={lateralText} />}
        <div className="main">
          <img src={designerInformation.picture} />
          {title}
          {designerInformation.video && (
            <ModalBasic
              header={
                <HeaderContainer>
                  <Button
                    kind="ghost"
                    fontAwesomeIcon="times"
                    iconAnimation="scale"
                    onClick={() => setOpenVideo(false)}
                  />
                </HeaderContainer>
              }
              onClose={() => setOpenVideo(false)}
              onOpen={() => setOpenVideo(true)}
              open={openVideo}
              containerStyle={{
                width: isMobile ? '95%' : '80%',
                maxWidth: 700,
                height: 'unset',
              }}
              trigger={
                <Button
                  kind="text"
                  text="Learn more about me"
                  fontAwesomeIcon="video"
                  iconAnimation="scale"
                  iconPosition="left"
                  onClick={() => setOpenVideo(true)}
                  className="learn-more"
                />
              }
            >
              <div className="modal-body">
                <FormVideo src={designerInformation.video} />
              </div>
            </ModalBasic>
          )}
          <p>{description}</p>
          {steps && (
            <div className="stepper">
              <Stepper
                noShrink
                dark
                currentStep={0}
                fullBorder={false}
                steps={steps}
              />
            </div>
          )}
          <Button
            kind="solid"
            text="Get started"
            size={isMobile ? 'fluid' : 'large'}
            onClick={handleStart}
            className="get-started"
          />
        </div>
        {lateralText && <LateralText right text={lateralText} />}
      </div>
    </BlackContainer>
  )
}

const LateralText = ({ right, text }: { right?: boolean; text: string }) => (
  <VerticalAside right={right}>
    <p className="overline no-margin">{text}</p>
  </VerticalAside>
)

const BORDER = `1px solid ${Colors.gray700}`
const breakpointMedia = Breakpoint.downFromComputer

const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`

const VerticalAside = styled.aside<{ right?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  width: 72px;

  & > p {
    color: ${Colors.gray800};
    white-space: nowrap;
    transform: ${(props) => (props.right ? 'rotate(90deg)' : 'rotate(-90deg)')};
  }

  @media ${breakpointMedia} {
    width: 100%;
    padding: 16px 0;
    & > p {
      transform: none;
    }
  }

  //* Borders
  ${(props) => {
    if (props.right) {
      return css`
        border-left: ${BORDER};
        @media ${breakpointMedia} {
          border-top: ${BORDER};
        }
      `
    }
    return css`
      border-right: ${BORDER};
      @media ${breakpointMedia} {
        border-bottom: ${BORDER};
      }
    `
  }}
`

const BlackContainer = styled.div`
  & > div:first-child {
    position: relative !important;
  }

  background-color: ${Colors.gray300};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;

  div.info {
    display: flex;
    flex-grow: 1;

    @media ${breakpointMedia} {
      flex-direction: column;
    }
  }

  div.main {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 24px;
    width: calc(100% - 144px);

    & > img {
      border-radius: 50%;
      height: 100px;
      width: 100px;
      margin-bottom: 32px;
    }

    & > h2,
    p {
      color: ${Colors.gray900};
      text-align: center;
    }

    & > p {
      margin-top: 32px;
      margin-bottom: 32px;
      max-width: 640px;
    }

    button.learn-more {
      margin-top: 8px;
    }

    button.get-started {
      margin-top: 32px;
    }

    @media ${breakpointMedia} {
      width: auto;
      & > img {
        height: 80px;
        width: 80px;
      }
    }
    @media ${Breakpoint.downFromSmallMobile} {
      padding: 16px;
    }

    div.stepper {
      max-width: 100%;
      overflow: auto;
      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      & {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }
`

export default StartFlowView
