import React, { useState } from 'react'

import styled from 'styled-components'

import Button from 'design-system/components/button'
import { Modal } from 'design-system/components/modal'
import { Breakpoint } from 'styles/app/system'
import { SupplierOrder } from 'types/supplier-order'

const DetailsModal = ({
  supplierOrders,
}: {
  supplierOrders: SupplierOrder[]
}) => {
  const [showModal, toggleModal] = useState<boolean>(false)

  return (
    <Modal
      onClose={() => toggleModal(false)}
      onOpen={() => toggleModal(true)}
      open={showModal}
      title="Delivery Details"
      trigger={
        <Button kind="text" fontAwesomeIcon="plus" text="Show details" />
      }
      size="small"
    >
      <p className="light-gray subtitle">This order contains</p>
      <StyledUl>
        {supplierOrders.map((so) => (
          <li key={so.id}>
            <div>
              <p className="no-margin">{`[${so.nobiliaAckNumber}] ${so.nobiliaOrderRef}`}</p>
              {so.deliveryNote?.key ? (
                <Button
                  kind="text"
                  color="gray"
                  fontAwesomeIcon="download"
                  onClick={() =>
                    window.open(
                      `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                        so.deliveryNote?.key,
                      )}`,
                      '_blank',
                    )
                  }
                  text="Delivery Note"
                  iconAnimation="down"
                />
              ) : (
                <p className="pending light-gray no-margin">
                  <i>Pending</i>
                </p>
              )}
            </div>
          </li>
        ))}
      </StyledUl>
    </Modal>
  )
}

export default DetailsModal

const StyledUl = styled.ul`
  & > li {
    margin-bottom: 24px;

    & > div {
      display: grid;
      grid-template-columns: auto max-content;
      align-items: center;
      column-gap: 32px;
      row-gap: 16px;

      @media ${Breakpoint.downFromSmallMobile} {
        grid-template-columns: auto;
      }
    }
  }
`
