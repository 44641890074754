import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import NobiliaSampleClassSelectionCard from 'components/shared/catalog/nobilia-sample-class/card'
import useProject from 'context/project/use'
import { parseOrders } from 'context/project/utils'
import {
  Section,
  Spacer,
  UnderlineSubtitle,
} from 'design-system/components/guide/utils'
import { SelectionCardExpandSelectProps } from 'design-system/components/selection-card'

const Samples = () => {
  const { project } = useProject()
  const { ordersSamples } = parseOrders(project)

  if (!ordersSamples.length) return <></>

  return (
    <Section id="samples">
      <Grid centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">Your samples</h3>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        {ordersSamples?.map((o, idx) => (
          <Grid.Row centered key={o.id}>
            <Grid.Column width={16}>
              <UnderlineSubtitle>Order {idx + 1}</UnderlineSubtitle>
              <Divider hidden />
            </Grid.Column>
            {o.nobiliaSamples?.map((s) => {
              return (
                <Grid.Column key={s.id} computer={4} tablet={5} mobile={8}>
                  <NobiliaSampleClassSelectionCard
                    nobiliaSampleClass={s.productClass}
                    selectionCardProps={
                      {
                        imageRatio: '2/3',
                      } as Partial<SelectionCardExpandSelectProps>
                    }
                  />
                </Grid.Column>
              )
            })}
          </Grid.Row>
        ))}
        <Spacer height={300} />
      </Grid>
    </Section>
  )
}

export default Samples
