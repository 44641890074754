import React from 'react'

import { RouteComponentProps } from '@reach/router'

import DesignProvider from 'context/design/provider'

interface DesignRouteProps {
  project_id: string
  room_id: string
  design_id: string
}

interface DesignProps extends RouteComponentProps<DesignRouteProps> {
  children?: React.ReactNode
}

const Design = (props: DesignProps) => {
  return (
    <DesignProvider designId={props.design_id ?? ''}>
      {props.children}
    </DesignProvider>
  )
}

export default Design
