import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { NavigationStep } from '../types'
import BookingView from '../views/booking'
import FloorPlanView from '../views/floor-plan'

type PlanBookingStepsType = 'floorPlan' | 'booking'

export const START_PROJECT_STEPS: Record<PlanBookingStepsType, NavigationStep> =
  {
    floorPlan: {
      label: 'floor plan',
      completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
      subroute: 'floor-plan',
      View: FloorPlanView,
    },
    booking: {
      label: 'schedule',
      completedElement: <FontAwesomeIcon icon={['fal', 'check']} />,
      subroute: 'booking',
      View: BookingView,
    },
  }

export const START_PROJECT_STEPS_ARRAY = [
  START_PROJECT_STEPS.floorPlan,
  START_PROJECT_STEPS.booking,
]
