import { NavigateFn } from '@reach/router'
import { navigate } from 'gatsby'

export const navigateWithPreviousLocation: NavigateFn = (to, options = {}) =>
  navigate(to as string, {
    ...options,
    state: {
      ...(options?.state ?? {}),
      previousLocation: window.location.pathname,
    },
  })
