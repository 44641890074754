import { Table } from 'semantic-ui-react'
import styled from 'styled-components'

import { Breakpoint, Colors } from 'styles/app/system'

export const StyledTable = styled(Table)`
  &&& {
    background-color: transparent;
    border: none;

    @media ${Breakpoint.onlyMobile} {
      & > thead {
        display: none !important;
      }
    }

    & > thead {
      background-color: transparent;
    }

    & > tbody {
      background-color: ${Colors.white};
    }
  }
`

export const TableHeaderCell = styled(Table.HeaderCell)`
  &&& {
    background-color: transparent;
    color: ${Colors.gray500};
    font-family: 'GT America';
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 2px;
    text-transform: uppercase;
    white-space: nowrap;
  }
`

export const TableBodyRow = styled(Table.Row)`
  &&&& {
    & > td:last-child {
      & > button:first-child {
        margin-right: 8px;
      }
    }

    @media ${Breakpoint.onlyMobile} {
      align-items: center;
      display: grid !important;
      grid-template-columns: max-content auto max-content;
      grid-template-rows: auto auto auto auto;
      padding: 16px 0;
      row-gap: 6px;

      & > td:nth-child(2),
      & > td:nth-child(3),
      & > td:nth-child(4),
      & > td:nth-child(5),
      & > td:nth-child(6) {
        grid-column: 2;

        :empty {
          display: none !important;
        }
      }

      & > td:last-child:not(.design-cell) {
        background: ${Colors.gray100};
        display: flex !important;
        grid-column: 1 / span 2;
        justify-content: end;
        margin: 12px 0 -16px;
      }
    }
  }
`
