import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

import useProject from 'context/project/use'
import useRoom from 'context/room/use'

const RoomRedirect = (_: RouteComponentProps) => {
  const { paymentProjectDepositPaid, project, roomPrimary } = useProject()
  const { room } = useRoom()

  useEffect(() => {
    const isPrimary = room?.id === roomPrimary?.id

    // Extra Room
    if (!isPrimary) {
      navigate(`/app/projects/${project.id}/rooms/${room?.id}/extra-room`, {
        replace: true,
      })
      return
    }

    // Primary Room
    if (paymentProjectDepositPaid) {
      navigate(`/app/projects/${project.id}/rooms/${room?.id}/home`, {
        replace: true,
      })
      return
    }

    // Start Project Flow
    navigate(`/app/projects/${project.id}/rooms/${room?.id}/start-project`, {
      replace: true,
    })
    return
  }, [])

  return <></>
}

export default RoomRedirect
