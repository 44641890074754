import React, { useEffect, useMemo, useState } from 'react'

import { Router } from '@reach/router'

import { NavFooter } from 'components/app/nav-footer'
import HeaderDashboard from 'components/app/project/header/header-dashboard'
import StartFlowView from 'components/app/project/start-flow-view'
import Loader from 'components/shared/loader'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomMutate from 'context/room/use-mutate'
import FlowLayout, { StepName } from 'design-system/containers/flow-layout'
import { parseStateWithPrevious } from 'utils/navigation'

import {
  START_PROJECT_STEPS,
  START_PROJECT_STEPS_ARRAY as STEPS,
} from './steps'
import { RoomFlowRouteProps } from '../types'

export const StartProjectFlowIndex = ({
  path: _,
  ...routeProps
}: RoomFlowRouteProps) => {
  const {
    '*': subroute,
    project_id: projectId,
    room_id: roomId,
    navigate,
    location,
  } = routeProps
  const { primaryDesigner } = useProject()
  const { room } = useRoom()
  const { updateRoomAndRefetch } = useRoomMutate()
  const [nextDisabled, setNextDisabled] = useState(true)
  const [onNext, setOnNext] = useState<() => Promise<void>>()
  const [loadingButton, setLoadingButton] = useState(false)
  const [loading, setLoading] = useState(true)

  const state = parseStateWithPrevious(location?.state, {
    isEffectRedirect: false,
  })
  const { isEffectRedirect } = state

  const currentStepIndex = useMemo(
    () => STEPS.findIndex((step) => step.subroute === subroute),
    [subroute],
  )

  useEffect(() => {
    isEffectRedirect && setLoading(false)
  }, [isEffectRedirect])

  useEffect(() => {
    let redirectSubroute = ''
    if (room?.data.floorPlansCompletedAt) {
      redirectSubroute = START_PROJECT_STEPS.booking.subroute
    }

    navigate?.(
      `/app/projects/${projectId}/rooms/${roomId}/start-project/${redirectSubroute}`,
      {
        state: { ...state, isEffectRedirect: true },
      },
    )
  }, [])

  const setStepCompleted = async () => {
    if (
      subroute === START_PROJECT_STEPS.floorPlan.subroute &&
      !room?.data.floorPlansCompletedAt
    ) {
      await updateRoomAndRefetch({
        variables: {
          data: { data: { ...room?.data, floorPlansCompletedAt: new Date() } },
          where: { id: roomId ?? '' },
        },
      })
    }
  }

  const isFirstStep = currentStepIndex <= 0
  const isLastStep = currentStepIndex >= STEPS.length - 1

  const handleNext = async () => {
    await onNext?.()
    await setStepCompleted()
    if (!isLastStep) {
      navigate?.(
        `/app/projects/${projectId}/rooms/${roomId}/start-project/${
          STEPS[currentStepIndex + 1].subroute
        }`,
      )
    } else {
      navigate?.(`/app/projects/${projectId}/rooms/${roomId}/home`)
    }
  }

  if (loading) return <Loader />

  if (currentStepIndex === -1) {
    return (
      <StartFlowView
        description="Upload your floor plan and book a initial call to get started."
        designerInformation={{
          firstName: primaryDesigner?.firstName,
          picture: primaryDesigner?.picture ?? '',
        }}
        handleStart={() =>
          navigate?.(
            `/app/projects/${projectId}/rooms/${roomId}/start-project/${STEPS[0].subroute}`,
          )
        }
        lateralText="Welcome to FORM"
        steps={STEPS}
        title={<h2>Welcome to FORM</h2>}
      />
    )
  }

  return (
    <FlowLayout
      header={<HeaderDashboard onlyMessages />}
      main={
        <>
          {<StepName>{STEPS[currentStepIndex].label}</StepName>}
          <Router>
            {STEPS.map(({ View, subroute }) => (
              <View
                key={subroute}
                path={subroute}
                setOnNext={setOnNext}
                setNextDisabled={setNextDisabled}
                setLoading={setLoadingButton}
                setSkip={() => {}}
                chooseProspectFiles={false}
                isExtraRoom
                {...routeProps}
              />
            ))}
          </Router>
        </>
      }
      actions={
        <NavFooter
          hideBack={isFirstStep}
          backDisabled={isFirstStep}
          nextDisabled={nextDisabled}
          loading={loadingButton}
          onBack={() =>
            navigate?.(
              `/app/projects/${projectId}/rooms/${roomId}/start-project/${
                STEPS[currentStepIndex - 1].subroute
              }`,
            )
          }
          onNext={handleNext}
        />
      }
      stepperProps={{
        currentStep: currentStepIndex,
        steps: STEPS,
      }}
    />
  )
}
