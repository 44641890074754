import React, { useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Grid, SearchProps } from 'semantic-ui-react'

import * as SelectionUtils from 'components/app/project/room/selection/utils'
import GenericOptionSelectionCard from 'components/shared/catalog/generic-option/card'
import useAuth from 'context/auth/use'
import GenericOptionManyProvider from 'context/generic-option/provider-many'
import useGenericOptionMany from 'context/generic-option/use-many'
import useRoom from 'context/room/use'
import useRoomDesignOptionsMutate from 'context/room/use-design-options-mutate'
import { parseBacksplash } from 'context/selection-system/backsplash'
import Button from 'design-system/components/button'
import { SelectionCardVariation } from 'design-system/components/selection-card'
import SelectionLayout from 'design-system/containers/selection-layout'
import { Breakpoint } from 'styles/app/system'
import { GenericOption } from 'types/generic-option'

type SelectionBacksplashProps = RouteComponentProps<{
  project_id: string
  room_id: string
}>

const SelectionBacksplash = (props: SelectionBacksplashProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const { isFORMStaff } = useAuth()

  const { backsplashMaterials: backsplashsSelected } = useRoom()
  const { onSaveGenericOptions } = useRoomDesignOptionsMutate()
  const { genericOptions } = useGenericOptionMany()

  const [search, setSearch] = useState<{
    results: GenericOption[] | null
    value: string
  }>({
    results: null,
    value: '',
  })

  const { backsplashOnDesignCall: allBacksplash } = useMemo(
    () => parseBacksplash(genericOptions),
    [genericOptions],
  )

  const onSave = (item: Partial<GenericOption>) => {
    let selection
    if (backsplashsSelected?.find((s) => s.id === item.id))
      selection = backsplashsSelected.filter((s) => s.id !== item.id)
    else
      selection = [...(backsplashsSelected as Partial<GenericOption>[]), item]

    onSaveGenericOptions(selection, backsplashsSelected ?? [])
  }

  const backsplashSearchChange = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    { value }: SearchProps,
  ) => {
    setSearch({
      results: !value
        ? null
        : (allBacksplash.filter((f) =>
            [f.description, f.data?.displayName].some(
              (s) => s?.toLowerCase().includes(value.toLowerCase()),
            ),
          ) as GenericOption[]),
      value: value as string,
    })
  }

  return (
    <SelectionLayout
      back={
        <Button
          color="gray"
          fontAwesomeIcon="arrow-left"
          iconAnimation="left"
          iconPosition="left"
          kind="text"
          onClick={() =>
            navigate(
              `/app/projects/${props.project_id}/rooms/${props.room_id}/design-call`,
            )
          }
          text={isMobile ? '' : 'Back'}
        />
      }
      searchProps={{
        onClose: () => {
          setSearch({
            results: null,
            value: '',
          })
        },
        onSearchChange: backsplashSearchChange,
        value: search.value,
      }}
      selection={
        backsplashsSelected?.length
          ? backsplashsSelected.map((f) => (
              <SelectionUtils.OptionSelected key={f.id}>
                <p className="no-margin small dark-gray">{f.description}</p>
                {isFORMStaff ? (
                  <span onClick={() => onSave(f)}>
                    <FontAwesomeIcon icon={['fal', 'times']} />
                  </span>
                ) : null}
              </SelectionUtils.OptionSelected>
            ))
          : null
      }
      title="Backsplash"
    >
      <Grid doubling columns={5}>
        <Grid.Row stretched className="row-gap">
          {search.value && !search.results?.length ? (
            <Grid.Column>
              <p>No results</p>
            </Grid.Column>
          ) : (
            (search.results || allBacksplash).map((f) => (
              <Grid.Column key={f.id}>
                <GenericOptionSelectionCard
                  genericOption={f}
                  selectionCardProps={{
                    ...(isFORMStaff
                      ? {
                          expandAndSelect: true,
                          onSelect: () => onSave(f),
                        }
                      : {
                          expandOnly: true,
                        }),
                    imageRatio: '1/1',
                    selected: backsplashsSelected?.some((s) => s.id === f.id),
                  }}
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))
          )}
        </Grid.Row>
      </Grid>
    </SelectionLayout>
  )
}

export default (
  props: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  return (
    <GenericOptionManyProvider
      catalog={process.env.GATSBY_DEFAULT_CATALOG}
      defaultFilters={{
        feature: {
          identifier: 'backsplashMaterial',
        },
        sortBy: 'description',
        sortDirection: 'ascending',
        take: undefined,
      }}
    >
      <SelectionBacksplash {...props} />
    </GenericOptionManyProvider>
  )
}
