import React, { createContext } from 'react'

import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'

import Loader from 'components/shared/loader'
import {
  FIND_UNIQUE_DISCONNECTED_OPTION,
  FindUniqueDisconnectedOptionPayload,
  FindUniqueDisconnectedOptionVariables,
} from 'queries/disconnected-option'
import { DisconnectedOption } from 'types/disconnected-option'

interface IDisconnectedOptionContext {
  catalog?: string
  disconnectedOption: DisconnectedOption
  refetch: () => Promise<unknown>
}

interface DisconnectedOptionProps
  extends RouteComponentProps<{
    catalog: string
    disconnected_option_id: string
  }> {
  children: React.ReactNode
}

export const DisconnectedOptionContext =
  createContext<IDisconnectedOptionContext>({} as IDisconnectedOptionContext)

const DisconnectedOptionProvider = (props: DisconnectedOptionProps) => {
  const { data, loading, refetch } = useQuery<
    FindUniqueDisconnectedOptionPayload,
    FindUniqueDisconnectedOptionVariables
  >(FIND_UNIQUE_DISCONNECTED_OPTION, {
    variables: {
      where: { id: props.disconnected_option_id },
    },
  })

  if (loading || !data) return <Loader />

  return (
    <DisconnectedOptionContext.Provider
      value={{
        catalog: props.catalog,
        disconnectedOption: data.disconnectedOption,
        refetch,
      }}
    >
      {props.children}
    </DisconnectedOptionContext.Provider>
  )
}

export default DisconnectedOptionProvider
