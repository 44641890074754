import React, { useState } from 'react'

import { Grid, Image, List } from 'semantic-ui-react'

import SamplesOrderStatus from 'components/app/project/order-samples/status'
import ConfirmationModal from 'components/shared/confirmation-modal'
import { getAddressWithName } from 'context/address/utils'
import { notifySuccess } from 'context/notifications/trigger'
import * as checkoutUtils from 'context/order/utils-checkout'
import useProjectOrderSamplesManyMutate from 'context/project/order-samples/use-many-mutate'
import useProject from 'context/project/use'
import useProjectMutate from 'context/project/use-mutate'
import Button from 'design-system/components/button'
import { FormList } from 'styles/app/components/list'
import { FormDivider } from 'styles/app/system'
import { Order } from 'types/order'
import { Address } from 'types/utils'

import AddressSelect from './address-select'
import OrderedBy from './ordered-by'

interface OrderSummaryProps {
  onEdit?: () => void
  order: Order
  title?: string
}

const OrderSummary = ({
  onEdit,
  order,
  title = 'Order',
}: OrderSummaryProps) => {
  const { project } = useProject()
  const { updateProjectAndRefetch } = useProjectMutate()
  const { deleteOrderAndRefetch, loadingDelete, updateOrderAndRefetch } =
    useProjectOrderSamplesManyMutate()

  const [deleteOrderModal, toggleDeleteOrderModal] = useState<boolean>(false)

  const onChangeAddress = async (address: Address) => {
    await updateOrderAndRefetch({
      variables: {
        data: {
          shippingAddress: address,
        },
        where: {
          id: order.id ?? '',
        },
      },
    })
    if (address) {
      await updateProjectAndRefetch({
        variables: {
          data: {
            shippingAddress: address,
          },
          where: {
            id: project.id,
          },
        },
      })
    }
    notifySuccess('Address Selected')
  }

  const handleDeleteOrder = async () => {
    await deleteOrderAndRefetch({
      variables: {
        where: {
          id: order.id ?? '',
        },
      },
    })
    toggleDeleteOrderModal(false)
  }

  return (
    <Grid.Column largeScreen={7} computer={8} tablet={16} mobile={16}>
      <FormList>
        <List.Item>
          {!order.placedAt && (
            <List.Content floated="right">
              <Button
                kind="ghost"
                color="gray"
                fontAwesomeIcon="pencil"
                onClick={onEdit}
                text="Edit"
              />
              <Button
                kind="ghost"
                fontAwesomeIcon="trash-can"
                onClick={() => toggleDeleteOrderModal(true)}
              />
            </List.Content>
          )}
          <List.Content>
            <p className="caption">
              {order.placedAt ? 'Status: ' : ''}
              {title}
            </p>
          </List.Content>
        </List.Item>
        {!!order.placedAt && (
          <>
            <FormDivider style={{ margin: '10px 0' }} />
            <SamplesOrderStatus order={order} />
          </>
        )}
        <FormDivider style={{ margin: '10px 0' }} />
        {order.nobiliaSamples?.map((s) => {
          const sample = checkoutUtils.nobiliaSampleToPaymentItem(s)
          return (
            <List.Item key={s.id}>
              <List.Content
                floated="right"
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  height: '43px',
                }}
              >
                <p>x{sample.quantity}</p>
              </List.Content>
              <List.Icon>
                <Image
                  src={sample.imageUrl}
                  style={{ margin: '0', maxWidth: '30px' }}
                />
              </List.Icon>
              <List.Content verticalAlign="middle">
                <p>{sample.name}</p>
              </List.Content>
            </List.Item>
          )
        })}
        <FormDivider style={{ margin: '10px 0' }} />
        {!order.placedAt && (
          <>
            <OrderedBy />
            <FormDivider style={{ margin: '10px 0' }} />
          </>
        )}
        <List.Item>
          <List.Content>
            <p className="caption">
              {order.placedAt ? 'Shipping Address' : 'Select Shipping Address'}
            </p>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content>
            {order.placedAt ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: getAddressWithName(order.shippingAddress, true),
                }}
              />
            ) : (
              <AddressSelect
                enableEdit
                initialAddress={
                  order.shippingAddress ||
                  project.shippingAddress ||
                  project.projectAddress
                }
                onChangeAddress={onChangeAddress}
                orderAddress={order.shippingAddress}
                whiteInputs={false}
              />
            )}
          </List.Content>
        </List.Item>
      </FormList>
      <ConfirmationModal
        cancelText="No, don't delete"
        confirmText="Yes, delete order"
        firstTitle={`Are you sure you want to delete this ${title}?`}
        loading={loadingDelete}
        onCancel={() => toggleDeleteOrderModal(false)}
        onConfirm={async () => await handleDeleteOrder()}
        open={deleteOrderModal}
      />
    </Grid.Column>
  )
}

export default OrderSummary
