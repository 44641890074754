import React from 'react'

import { useMedia } from 'react-use'
import { Divider, Grid } from 'semantic-ui-react'

import { parseFilesByType } from 'context/project-file/utils'
import useRoom from 'context/room/use'
import FilePlaceholderCard from 'design-system/components/cards/file-placeholder-card'
import Carousel from 'design-system/components/carousel'
import ImageCarousel from 'design-system/components/carousel/image-carousel'
import {
  Section,
  Spacer,
  UnderlineSubtitle,
} from 'design-system/components/guide/utils'
import { Breakpoint } from 'styles/app/system'
import { ProjectFile } from 'types/project-file'

const ProjectSpace = () => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const { roomPhotos, floorPlans } = useRoom()

  const { imageFiles: planImages, otherFiles: planFiles } =
    parseFilesByType(floorPlans)
  const { imageFiles: photoImages, otherFiles: photoFiles } =
    parseFilesByType(roomPhotos)

  const getFileSlides = (files: ProjectFile[]) =>
    files.map((f) => ({
      id: f.id,
      item: (
        <div style={{ paddingTop: 40, height: '100%', paddingBottom: 20 }}>
          <FilePlaceholderCard file={f} />
        </div>
      ),
    }))

  return (
    <>
      {floorPlans.length ? (
        <Section id="floor-plan">
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <h3 className="gray">Your floor plans</h3>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            {planImages.length ? (
              <Grid.Row>
                <Grid.Column>
                  <ImageCarousel
                    images={planImages.map(
                      (file) =>
                        `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                          file.metadata?.keyCompressed || file.key,
                        )}`,
                    )}
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}
            {planFiles.length ? (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <UnderlineSubtitle>Files</UnderlineSubtitle>
                  </Grid.Column>
                </Grid.Row>
                {isMobile ? (
                  <Carousel
                    slides={getFileSlides(planFiles)}
                    hideNavButtons
                    slideSize={80}
                  />
                ) : (
                  <Grid.Row className="row-gap">
                    {planFiles.map((f) => (
                      <Grid.Column
                        key={f.id}
                        computer={4}
                        tablet={8}
                        mobile={13}
                      >
                        <FilePlaceholderCard file={f} />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                )}
              </>
            ) : null}
            {!isMobile && <Spacer height={300} />}
          </Grid>
        </Section>
      ) : null}
      {roomPhotos.length ? (
        <Section id="photos">
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <h3 className="gray">Your room photos</h3>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            {photoImages.length ? (
              <Grid.Row>
                <Grid.Column>
                  <ImageCarousel
                    images={photoImages.map(
                      (file) =>
                        `${process.env.GATSBY_CDN_ROOT}/${encodeURIComponent(
                          file.metadata?.keyCompressed || file.key,
                        )}`,
                    )}
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}
            {photoFiles.length ? (
              <>
                <Grid.Row>
                  <Grid.Column>
                    <UnderlineSubtitle>Files</UnderlineSubtitle>
                  </Grid.Column>
                </Grid.Row>
                {isMobile ? (
                  <Carousel
                    slides={getFileSlides(photoFiles)}
                    hideNavButtons
                    slideSize={80}
                  />
                ) : (
                  <Grid.Row className="row-gap">
                    {photoFiles.map((f) => (
                      <Grid.Column
                        key={f.id}
                        computer={4}
                        tablet={8}
                        mobile={13}
                      >
                        <FilePlaceholderCard file={f} />
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                )}
              </>
            ) : null}
            {!isMobile && <Spacer height={300} />}
          </Grid>
        </Section>
      ) : null}
    </>
  )
}

export default ProjectSpace
