import React, { useState } from 'react'

import { Form, Grid, Image, Input } from 'semantic-ui-react'

import useAuth from 'context/auth/use'
import useRoom from 'context/room/use'
import useRoomGuideOptions from 'context/room/use-guide-options'
import useRoomMutate from 'context/room/use-mutate'
import Button from 'design-system/components/button'
import { Section } from 'design-system/components/guide/utils'
import { StyledForm } from 'styles/app/components/form'
import { GuideOptionType } from 'types/guide-option'

const Budget = () => {
  const { isFORMStaff } = useAuth()
  const { room } = useRoom()
  const { loadingUpdate, updateRoomAndRefetch } = useRoomMutate()
  const { tradeBudget } = useRoomGuideOptions()

  const [budget, setBudget] = useState<string>(
    tradeBudget?.data?.budget as string,
  )
  const unsavedChanges = tradeBudget?.data?.budget !== budget

  const onSave = async () => {
    await updateRoomAndRefetch({
      variables: {
        data: {
          guideOptions: {
            create: [
              {
                guide: GuideOptionType.PROJECT_CONSULTATION,
                identifier: 'budget',
                data: {
                  budget: budget,
                },
              },
            ],
            delete: tradeBudget
              ? [
                  {
                    id: tradeBudget.id ?? '',
                  },
                ]
              : [],
          },
        },
        where: {
          id: room?.id ?? '',
        },
      },
    })
  }

  return (
    <Section id="budget">
      <Grid>
        <Grid.Row verticalAlign="middle">
          <Grid.Column computer={8} tablet={8} mobile={16}>
            <h3 className="gray">
              What budget would you like us to design with?
            </h3>
            <p className="gray">
              We'll price out your project according to your plans and
              specifications so that we can align on next steps. <br /> We are
              always transparent with our pricing. We provide itemized
              quotations throughout our development process.
            </p>
            <StyledForm>
              <Form.Group unstackable style={{ flexDirection: 'row' }}>
                <Form.Field width={10}>
                  <Input
                    disabled={!isFORMStaff}
                    placeholder="e.g. $30,000"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setBudget(e.target.value)
                    }}
                    value={budget}
                  />
                </Form.Field>
                {isFORMStaff ? (
                  <Button
                    disabled={!unsavedChanges}
                    kind="solid"
                    loading={loadingUpdate}
                    onClick={onSave}
                    text="Save"
                  />
                ) : null}
              </Form.Group>
            </StyledForm>
          </Grid.Column>
          <Grid.Column computer={7} tablet={8} mobile={16} floated="right">
            <Image
              centered
              src={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-call/budget.svg`}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default Budget
