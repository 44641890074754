import React from 'react'

import styled from 'styled-components'

import Button from 'design-system/components/button'
import { Colors } from 'styles/app/system'

const DashedCard = ({
  image,
  onClick,
  title,
  whiteCard,
}: {
  image: React.ReactNode
  onClick: () => void
  title: string
  whiteCard?: boolean
}) => {
  return (
    <StyledCard styled={{ white: whiteCard }}>
      {image}
      <div className="content">
        <p className="no-margin">{title}</p>
        <Button
          fontAwesomeIcon="plus"
          iconPosition="left"
          kind="ghost"
          onClick={onClick}
          text="Add"
        />
      </div>
    </StyledCard>
  )
}

export default DashedCard

const StyledCard = styled.div<{
  styled?: {
    white?: boolean
  }
}>`
  align-items: center;
  background: ${({ styled }) => (styled?.white ? 'white' : 'none')};
  border: 1px dashed ${Colors.gray300};
  border-radius: 4px;
  display: flex;
  gap: 10px;
  height: 90px;
  max-height: 90px;
  padding: 20px 16px;

  div.icon {
    align-items: center;
    background: ${Colors.gray100};
    border-radius: 3px;
    display: flex;
    height: 50px;
    justify-content: center;
    min-width: 50px;
    width: 50px;

    svg {
      color: ${Colors.gray500};
    }
  }

  img {
    height: 80px;
    width: 80px;
    min-height: 80px;
    min-width: 80px;
  }

  div.content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
  }
`
