import React from 'react'

import { RouteComponentProps } from '@reach/router'
import moment from 'moment'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import StepTitle from 'components/app/step-title'
import useProject from 'context/project/use'
import useGuideUrls from 'context/project/use-guide-urls'
import PrimaryCard from 'design-system/components/cards/primary-card'
import AppLayout from 'design-system/containers/app-layout'
import { Breakpoint } from 'styles/app/system'

const Module = (_: RouteComponentProps) => {
  const {
    meetingCheckInOccurred,
    meetingDesignOccurred,
    meetingHandoverOccurred,
    meetingPresentationOccurred,
    meetingProjectConsultationOccurred,
    meetingProjectProposalOccurred,
    ordersCabinetryPublished,
    paymentProjectDepositPaid,
  } = useProject()

  const meetingCheckinAfterProjectDepositOcurred =
    meetingCheckInOccurred &&
    paymentProjectDepositPaid &&
    moment(meetingCheckInOccurred.startTime).isAfter(
      moment(paymentProjectDepositPaid.createdAt),
    )

  const guideUrls = useGuideUrls()

  return (
    <AppLayout>
      <Grid centered stackable>
        <StepTitle title="Call Recaps" />
        <GridRow stretched>
          {meetingDesignOccurred ? (
            <Grid.Column computer={4} tablet={5} mobile={16}>
              <PrimaryCard
                image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-call/kickoff-guide.webp`}
                onClick={() => window.open(guideUrls.DesignCall)}
                title="Initial call"
              />
            </Grid.Column>
          ) : null}
          {meetingPresentationOccurred ? (
            <Grid.Column computer={4} tablet={5} mobile={16}>
              <PrimaryCard
                disabled={!guideUrls.DesignPresentation}
                image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-presentation/design-presentation.webp`}
                onClick={() => window.open(guideUrls.DesignPresentation)}
                title="Design presentation"
              />
            </Grid.Column>
          ) : null}
          {meetingCheckinAfterProjectDepositOcurred ? (
            <>
              <Grid.Column computer={4} tablet={5} mobile={16}>
                <PrimaryCard
                  image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-review/design-review.webp`}
                  onClick={() => window.open(guideUrls.DesignReview)}
                  title="Design review"
                />
              </Grid.Column>
              <Grid.Column computer={4} tablet={5} mobile={16}>
                <PrimaryCard
                  image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/extra-room/extra-room.webp`} //TODO: Get the correct image when it exists
                  onClick={() => window.open(guideUrls.ExtraRoom)}
                  title="Extra room"
                />
              </Grid.Column>
            </>
          ) : null}
          {meetingHandoverOccurred &&
            ordersCabinetryPublished.map((o) => (
              <Grid.Column computer={4} tablet={5} mobile={16}>
                <PrimaryCard
                  image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/handover/handover.webp`} //TODO: Get the correct image when it exists
                  onClick={() =>
                    window.open(guideUrls.getHandover(o?.id ?? ''))
                  }
                  title="Handover call"
                />
              </Grid.Column>
            ))}
          {meetingProjectConsultationOccurred ? (
            <Grid.Column computer={4} tablet={5} mobile={16}>
              <PrimaryCard
                disabled={!guideUrls.ProjectConsultation}
                image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/project-consultation/trade-project-consultation.webp`}
                onClick={() => window.open(guideUrls.ProjectConsultation)}
                title="Project consultation"
              />
            </Grid.Column>
          ) : null}
          {meetingProjectProposalOccurred ? (
            <Grid.Column computer={4} tablet={5} mobile={16}>
              <PrimaryCard
                disabled={!guideUrls.ProjectProposal}
                image={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/project-proposal/trade-project-proposal.webp`}
                onClick={() => window.open(guideUrls.ProjectProposal)}
                title="Project proposal"
              />
            </Grid.Column>
          ) : null}
        </GridRow>
      </Grid>
    </AppLayout>
  )
}

export default Module

const GridRow = styled(Grid.Row)`
  @media ${Breakpoint.upFromMobile} {
    row-gap: 20px;
  }
`
