import React, { useState } from 'react'

import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import AppliancesConfirmationView from 'components/app/project/room/appliances/confirm'
import AppliancesEditView from 'components/app/project/room/appliances/edit'
import StepTitle from 'components/app/step-title'
import ConfirmationModal from 'components/shared/confirmation-modal'
import HelpMessage from 'components/shared/help-message'
import GenericProductClassManyProvider from 'context/generic-product-class/provider-many'
import useRoom from 'context/room/use'
import useRoomMutate from 'context/room/use-mutate'
import Button from 'design-system/components/button'
import SaveToast from 'design-system/components/save-toast'
import AppLayout from 'design-system/containers/app-layout'
import { StatusMode } from 'types/utils'

const Module = (
  _: RouteComponentProps<{
    project_id: string
    room_id: string
    location?: { search: string }
  }>,
) => {
  const { room, appliancesMode, appliancesDirty, appliancesAreConfirmed } =
    useRoom()
  const { updateRoomAndRefetch, loadingUpdate } = useRoomMutate()

  const [showConfirmModal, toggleConfirmModal] = useState<boolean>(false)
  const [showChangesModal, toggleChangesModal] = useState<boolean>(false)

  return (
    <GenericProductClassManyProvider
      defaultFilters={{
        identifierNotIn: ['backsplash', 'countertop', 'plinth'],
        sortBy: 'identifier',
        sortDirection: 'ascending',
        take: undefined,
      }}
    >
      <AppLayout>
        <Grid stackable>
          {[StatusMode.Confirmation, StatusMode.Confirmed].includes(
            appliancesMode as StatusMode,
          ) ? (
            <AppliancesConfirmationView
              enableConfirm={appliancesMode === StatusMode.Confirmation}
            />
          ) : (
            <>
              <StepTitle
                title="Appliances & Fixtures"
                subTitle={(() => {
                  if (appliancesMode === StatusMode.OpenForEdit)
                    return 'Update, add or delete the appliances below. Your designer will use this set for your design.'
                  if (appliancesMode === StatusMode.OpenForRevision)
                    return 'Update, add or request deletion of any of the appliances below, your designer will update them for your next design revision'
                  return ''
                })()}
              />
              <Grid.Row centered style={{ paddingTop: 0 }}>
                <Grid.Column computer={10} tablet={14} mobile={16}>
                  {appliancesMode === StatusMode.Locked ? (
                    <HelpMessage
                      message="Your designer is creating your Initial Design with these appliances. Don’t worry, you’ll be able to update them in the next stage of your project."
                      title="We are working on your design with this appliance set"
                    />
                  ) : null}
                  {appliancesMode === StatusMode.Submitted ? (
                    <HelpMessage
                      message="Looks like you've made changes. Your designer will evaluate and update them for your next design review."
                      title="Appliances updates Submitted"
                    />
                  ) : null}
                </Grid.Column>
              </Grid.Row>
              <AppliancesEditView
                deletionEnabled={appliancesMode === StatusMode.OpenForEdit}
                editEnabled={[
                  StatusMode.OpenForEdit,
                  StatusMode.OpenForRevision,
                ].includes(appliancesMode as StatusMode)}
                showLabels={appliancesMode === StatusMode.OpenForRevision}
              />
            </>
          )}
        </Grid>
        {appliancesMode === StatusMode.OpenForRevision && (
          <SaveToast
            mainButtonText="Yes, Submit"
            message="Are these your final appliances?"
            onClick={async () => {
              await updateRoomAndRefetch({
                variables: {
                  data: {
                    data: {
                      applianceChangesSubmittedAt: new Date(),
                    },
                  },
                  where: {
                    id: room?.id ?? '',
                  },
                },
              })
            }}
            show={!!appliancesDirty.length}
          />
        )}
        {appliancesMode === StatusMode.Confirmation && (
          <SaveToast
            disabled={!appliancesAreConfirmed}
            mainButtonText="Confirm All"
            onClick={() => toggleConfirmModal(true)}
            show={true}
            extraOptions={
              <>
                <p>or</p>
                <Button
                  color="dark"
                  kind="outlined"
                  onClick={() => toggleChangesModal(true)}
                  text="Make Changes"
                />
              </>
            }
            message="Are these your final appliances?"
          />
        )}
        <ConfirmationModal
          confirmText="Yes, Confirm"
          firstTitle="Are you ready to confirm your final list of  appliances?"
          loading={loadingUpdate}
          onCancel={() => toggleConfirmModal(false)}
          onConfirm={async () => {
            await updateRoomAndRefetch({
              variables: {
                data: {
                  data: {
                    appliancesConfirmedAt: new Date(),
                  },
                },
                where: {
                  id: room?.id ?? '',
                },
              },
            })
            toggleConfirmModal(false)
          }}
          open={showConfirmModal}
        />
        <ConfirmationModal
          confirmText="Yes, Make Changes"
          description="Changes can require additional design work and affect your project timeline. Finalizing these details as soon as possible help avoid delays."
          firstTitle="Are you sure you want to make changes?"
          loading={loadingUpdate}
          onCancel={() => toggleChangesModal(false)}
          onConfirm={async () => {
            await updateRoomAndRefetch({
              variables: {
                data: {
                  data: {
                    applianceChangesSubmittedAt: null,
                  },
                },
                where: {
                  id: room?.id ?? '',
                },
              },
            })
            toggleChangesModal(false)
          }}
          open={showChangesModal}
        />
      </AppLayout>
    </GenericProductClassManyProvider>
  )
}

export default Module
