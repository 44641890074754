import React, { useMemo, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'
import { isEqual } from 'lodash'
import { useMedia } from 'react-use'
import { Divider, Grid } from 'semantic-ui-react'

import * as SelectionUtils from 'components/app/project/room/selection/utils'
import { getChipColor } from 'components/shared/chip'
import ConfirmationModal from 'components/shared/confirmation-modal'
import NotificationBar from 'components/shared/notification-bar'
import AppliancePackManyProvider from 'context/appliance-pack/provider-many'
import useAppliancePackMany from 'context/appliance-pack/use-many'
import { getAppliancePackPriceFormatted } from 'context/appliance-pack/utils'
import { AppliancePackCategory } from 'context/appliance-pack/utils-many'
import { formatDollars } from 'context/dollar-utils'
import useGenericProductMutateNoContext from 'context/generic-product/use-mutate-no-context'
import { getReferenceImage } from 'context/media/utils-many'
import useRoom from 'context/room/use'
import useRoomGuideOptions from 'context/room/use-guide-options'
import useRoomMutateNoContext from 'context/room/use-mutate-no-context'
import Button from 'design-system/components/button'
import { SelectionCardExpandSelect } from 'design-system/components/selection-card'
import SelectionLayout from 'design-system/containers/selection-layout'
import { Breakpoint, Colors } from 'styles/app/system'
import { AppliancePack } from 'types/appliance-pack'
import { GuideOptionType } from 'types/guide-option'
import { resourcesUrls } from 'views/utils'

type SelectionApplianceProps = RouteComponentProps<{
  project_id: string
  room_id: string
}>

const SelectionAppliance = (props: SelectionApplianceProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const { room, refetch } = useRoom()
  const { appliancePacks } = useRoomGuideOptions()
  const { updateRoom } = useRoomMutateNoContext()
  const { createGenericProduct } = useGenericProductMutateNoContext()
  const { appliancePacks: appliancePacksAll, AppliancePacksByCategory } =
    useAppliancePackMany()

  const selectedPacks = useMemo(
    () =>
      appliancePacksAll.filter((p) =>
        appliancePacks
          .map((p) => p.identifier.replace('appliance', ''))
          .includes(p.identifier),
      ),
    [appliancePacks, appliancePacksAll],
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [showModal, toggleModal] = useState<boolean>(false)
  const [category, setCategory] = useState(AppliancePackCategory.Essentials)
  const [selection, setSelection] =
    useState<Partial<AppliancePack>[]>(selectedPacks)

  const unsavedChanges = useMemo(
    () =>
      !isEqual(
        selectedPacks.map((f) => f.id).sort(),
        selection.map((f) => f.id).sort(),
      ),
    [selectedPacks, selection],
  )

  const onDelete = (packId: string) => {
    setSelection(selection.filter((s) => s.id !== packId))
  }

  const onSelect = (item: AppliancePack) => {
    const beverage = ['wineCoolers', 'beverageCenters', 'coffeeMakers']
    const isBeverage = selection.filter(
      (s) => s.identifier && beverage.includes(s.identifier),
    )
    if (!beverage.includes(item.identifier)) setSelection([item, ...isBeverage])
    else setSelection([...(selection || []), item])
  }

  const onSave = async () => {
    setLoading(true)
    await Promise.all(
      selection.map(async (s) => {
        await Promise.all(
          s.appliances?.map(async (a) => {
            if (
              !room?.genericProducts.some(
                (gp) => gp.data?.appliancePackCopyId === a.id,
              )
            )
              await createGenericProduct({
                variables: {
                  data: {
                    data: {
                      appliancePackCopyId: a.id,
                      ...a.data,
                    },
                    productClass: {
                      connect: {
                        id: a.productClass.id,
                      },
                    },
                    quantity: 1,
                    room: {
                      connect: {
                        id: room?.id ?? '',
                      },
                    },
                  },
                },
              })
          }) ?? [],
        )
      }),
    )
    await updateRoom({
      variables: {
        data: {
          guideOptions: {
            create: selection.map((s) => ({
              guide: GuideOptionType.DESIGN_CALL,
              identifier: 'appliance' + s.identifier,
              data: s.data,
            })),
            delete: appliancePacks.map((koo) => ({
              id: koo.id as string,
            })),
          },
        },
        where: {
          id: room?.id ?? '',
        },
      },
    })
    refetch()
    setLoading(false)
  }

  return (
    <SelectionLayout
      back={
        unsavedChanges ? (
          <Button kind="solid" onClick={() => toggleModal(true)} text="Save" />
        ) : (
          <Button
            color="gray"
            fontAwesomeIcon="arrow-left"
            iconAnimation="left"
            iconPosition="left"
            kind="text"
            onClick={() =>
              navigate(
                `/app/projects/${props.project_id}/rooms/${props.room_id}/design-call`,
              )
            }
            text={isMobile ? '' : 'Back'}
          />
        )
      }
      categories={Object.values(AppliancePackCategory).map((c) => (
        <SelectionUtils.Category
          active={category === c}
          key={c}
          onClick={() => setCategory(c)}
          color={getChipColor(null, 1).tagColor}
          textColor={getChipColor(null).textColor}
        >
          <p>{AppliancePacksByCategory[c].name}</p>
        </SelectionUtils.Category>
      ))}
      selection={
        selection.length
          ? selection.map((p) => (
              <SelectionUtils.OptionSelected key={p.id}>
                <p className="no-margin small dark-gray">
                  {p?.data?.name} {getAppliancePackPriceFormatted(p)} *
                </p>
                <span onClick={() => onDelete(p.id ?? '')}>
                  <FontAwesomeIcon icon={['fal', 'times']} />
                </span>
              </SelectionUtils.OptionSelected>
            ))
          : null
      }
      title="Appliances"
    >
      <Grid stackable>
        {selectedPacks.length ? (
          <Grid.Row>
            <Grid.Column>
              <NotificationBar text="Looks like you already have an appliance pack linked to your project. If you choose another one, it’s appliances will be added to your card too. You can edit or delete them on the appliance card on the project dashboard.  " />
            </Grid.Column>
          </Grid.Row>
        ) : null}
        {AppliancePacksByCategory[category].appliances.map((pack, id) => {
          const selected = selection.find((s) => s.id === pack.id)
          return (
            <>
              <Grid.Row>
                <Grid.Column computer={5} tablet={5} mobile={16}>
                  <div>
                    <h4>{pack.data.nameDisplay}</h4>
                    <p className="caption">
                      Starting at: {getAppliancePackPriceFormatted(pack)} *
                    </p>
                    <p className="gray">{pack.data?.description}</p>
                    {selected ? (
                      <Button
                        color="green"
                        fontAwesomeIcon="check"
                        kind="solid"
                        size="medium"
                        text="Selected"
                      />
                    ) : (
                      <Button
                        fontAwesomeIcon="plus"
                        kind="outlined"
                        onClick={() => onSelect(pack)}
                        size="medium"
                        text="Select"
                      />
                    )}
                  </div>
                </Grid.Column>
                <Grid.Column computer={11} tablet={11} mobile={16}>
                  <Grid>
                    <Grid.Row stretched style={{ rowGap: '26px' }}>
                      {pack.appliances.map((a, key) => (
                        <Grid.Column
                          key={key}
                          computer={3}
                          tablet={5}
                          mobile={8}
                        >
                          <div
                            style={{
                              border: `1px solid ${Colors.gray200}`,
                              borderRadius: '4px',
                            }}
                          >
                            <SelectionCardExpandSelect
                              description={formatDollars(a.data?.price)}
                              imageUrl={getReferenceImage(a.productClass.media)}
                              title={`${a.productClass.metadata?.name || ''} ${a
                                .data?.size}`}
                            />
                          </div>
                        </Grid.Column>
                      ))}
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
              {id <
                AppliancePacksByCategory[category].appliances.length - 1 && (
                <div
                  style={{
                    borderBottom: '1px dashed #E7E5E4',
                    width: '100%',
                    margin: '30px 0',
                  }}
                />
              )}
            </>
          )
        })}
        <Divider hidden />
        <Grid.Row>
          <Grid.Column textAlign="center">
            <p className="small gray">
              *Estimated prices were captured on July 20, 2022, before tax.
              <br /> Check with the vendor for the most accurate pricing and
              availability.
            </p>
            <Button
              fontAwesomeIcon="arrow-up-right-from-square"
              kind="text"
              onClick={() => window.open(resourcesUrls.applianceGuide)}
              text="Open Appliance Guide"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <ConfirmationModal
        cancelText="Continue Browsing"
        confirmText="Yes, add Appliances"
        firstTitle="These appliances will be added to your appliance card."
        description="It’s advised to choose only one appliance package to work
        with for now. Edits can be made in the appliance card on
        your dashboard if needed."
        loading={loading}
        onCancel={() => {
          toggleModal(false)
        }}
        onConfirm={async () => {
          await onSave()
          toggleModal(false)
          navigate(
            `/app/projects/${props.project_id}/rooms/${props.room_id}/design-call`,
          )
        }}
        open={showModal}
      />
    </SelectionLayout>
  )
}

export default (
  props: RouteComponentProps<{ project_id: string; room_id: string }>,
) => {
  return (
    <AppliancePackManyProvider
      catalog={process.env.GATSBY_DEFAULT_CATALOG}
      defaultFilters={{
        sortBy: 'identifier',
        sortDirection: 'ascending',
        take: undefined,
      }}
    >
      <SelectionAppliance {...props} />
    </AppliancePackManyProvider>
  )
}
