import React from 'react'

import { IconName } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMedia } from 'react-use'
import { Grid, Image } from 'semantic-ui-react'
import styled from 'styled-components'

import { Section, Spacer } from 'design-system/components/guide/utils'
import { Breakpoint, Colors } from 'styles/app/system'

const items = [
  {
    icon: 'hexagon',
    title: 'Step 1',
    step: 'Choose front style',
  },
  {
    icon: 'cabinet-filing',
    title: 'Step 2',
    step: 'Choose handles style',
  },
  {
    icon: 'refrigerator',
    title: 'Step 3',
    step: 'Check on appliances',
  },
  {
    icon: 'house-chimney-window',
    title: 'Step 4',
    step: 'Choose other elements',
  },
]

const StartProject = () => {
  const isMobile = useMedia(Breakpoint.onlyMobile)

  return (
    <Section>
      <Grid stackable>
        <Grid.Row columns="equal" verticalAlign="middle">
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <h2 className="gray">Ready to start your project?</h2>
            <Spacer height={40} />
            {items.map((item, key) => (
              <StepItem key={key} lastItem={key === items.length - 1}>
                <div className="icon-container">
                  <FontAwesomeIcon icon={['fal', item.icon as IconName]} />
                </div>
                <div>
                  <p className="subtitle gray">{item.title}</p>
                  <p>{item.step}</p>
                </div>
              </StepItem>
            ))}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Image
              src={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/design-call/design-call-section.webp`}
            />
          </Grid.Column>
        </Grid.Row>
        {!isMobile && <Spacer height={200} />}
      </Grid>
    </Section>
  )
}

export default StartProject

const StepItem = styled.div<{ lastItem?: boolean }>`
  align-items: baseline;
  display: flex;
  gap: 12px;
  min-height: 90px;

  p.subtitle {
    margin-bottom: 5px;
  }

  svg {
    color: ${Colors.orange500};
    font-size: 16px;
  }

  .icon-container {
    position: relative;
    display: inline-block;
  }

  .icon-container::after {
    display: ${(props) => (props.lastItem ? 'none' : 'block')};
    border-left: 1px dashed ${Colors.gray300};
    content: '';
    height: 55px;
    left: 50%;
    position: absolute;
    top: calc(100% + 6px);
    transform: translateX(-50%);
    width: 2px;
  }
`
