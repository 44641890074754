import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'

import UserProvider from 'context/user/provider'

const UserIndex = (
  props: RouteComponentProps & { children: React.ReactNode },
) => {
  useEffect(() => {
    const htmlElement = document.getElementsByTagName('html')[0]
    const bodyElement = document.getElementsByTagName('body')[0]

    const handleResize = () => {
      if (window.innerWidth < 768) {
        bodyElement.style.setProperty(
          'height',
          `${window.innerHeight}px`,
          'important',
        )
        htmlElement.style.setProperty(
          'height',
          `${window.innerHeight}px`,
          'important',
        )
      } else {
        htmlElement.classList.remove('no-overflow')
        htmlElement.style.height = '100%'
        bodyElement.style.height = '100%'
      }
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return <UserProvider>{props.children}</UserProvider>
}

export default UserIndex
