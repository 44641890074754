import React from 'react'

import { RouteComponentProps } from '@reach/router'

import RoomProjectFileUploads from 'components/app/project/room/project-file-uploads'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import AppLayout from 'design-system/containers/app-layout'
import { ProjectFileType } from 'types/project-file'

const Module = (
  _: RouteComponentProps<{
    location?: string
    project_id: string
    room_id: string
  }>,
) => {
  const { isTradeProject } = useProject()
  const { floorPlans } = useRoom()

  return (
    <AppLayout>
      <RoomProjectFileUploads
        chooseProspectFiles={!isTradeProject}
        howToVideo="floorPlan"
        projectFiles={floorPlans}
        projectFileType={ProjectFileType.FLOOR_PLAN}
        subTitle={
          isTradeProject
            ? 'Please upload floor plan(s) with essential measurements. We recommend plans provided by an architect or contractor.'
            : 'It can be a floor plan or a hand-drawn sketch, just remember to add measurements. Approximates are fine for this stage.'
        }
        title={
          isTradeProject ? 'Upload plans' : 'Upload a floor plan of your space'
        }
      />
    </AppLayout>
  )
}

export default Module
