import React, { useState } from 'react'

import { Divider, Form, Grid, Input } from 'semantic-ui-react'
import styled from 'styled-components'

import HelpMessage from 'components/shared/help-message'
import useAuth from 'context/auth/use'
import useProject from 'context/project/use'
import useRoom from 'context/room/use'
import useRoomGuideOptions from 'context/room/use-guide-options'
import useRoomMutate from 'context/room/use-mutate'
import Button from 'design-system/components/button/button'
import TimelineCard from 'design-system/components/guide/timeline-card'
import { Section } from 'design-system/components/guide/utils'
import { StyledForm } from 'styles/app/components/form'
import { Breakpoint } from 'styles/app/system'
import { GuideOptionType } from 'types/guide-option'
import { navigateWithPreviousLocation } from 'utils/navigation'

const Timeline = () => {
  const { isFORMStaff } = useAuth()
  const { room } = useRoom()
  const { primaryProspect, project, projectAddress } = useProject()
  const { projectTimeline } = useRoomGuideOptions()
  const { loadingUpdate, updateRoomAndRefetch } = useRoomMutate()
  const [timeline, setTimeline] = useState<string>(
    (projectTimeline?.data?.timeline as string) ?? '',
  )
  const unsavedChanges = projectTimeline?.data?.timeline !== timeline

  const onSave = async () => {
    await updateRoomAndRefetch({
      variables: {
        data: {
          guideOptions: {
            create: [
              {
                guide: GuideOptionType.DESIGN_CALL,
                identifier: 'timeline',
                data: {
                  timeline: timeline,
                },
              },
            ],
            delete: projectTimeline
              ? [
                  {
                    id: projectTimeline.id ?? '',
                  },
                ]
              : [],
          },
        },
        where: {
          id: room?.id ?? '',
        },
      },
    })
  }

  return (
    <Section id="timeline">
      <Grid centered>
        <Grid.Row>
          <Grid.Column largeScreen={8} computer={7} tablet={16} mobile={16}>
            <p className="subtitle light-gray">Timeline</p>
            <h3 className="gray no-margin">
              Do you have a timeline you are working towards?
            </h3>
            <Divider hidden />
            <StyledForm>
              <Form.Group unstackable style={{ flexDirection: 'row' }}>
                <Form.Field width={11}>
                  <Input
                    disabled={!isFORMStaff}
                    placeholder="e.g. 10-14 weeks"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setTimeline(e.target.value)
                    }}
                    value={timeline}
                  />
                </Form.Field>
                {isFORMStaff ? (
                  <Button
                    disabled={!unsavedChanges}
                    kind="solid"
                    loading={loadingUpdate}
                    onClick={onSave}
                    text="Save"
                  />
                ) : null}
              </Form.Group>
            </StyledForm>
            {!project.projectAddress && (
              <HelpMessageDiv>
                <HelpMessage
                  content={
                    <HelpMessageContent>
                      <p className="gray">
                        Please add your project address to calculate an
                        estimated delivery window specific to your project.
                      </p>
                      <Button
                        kind="solid"
                        text="Add"
                        onClick={() =>
                          navigateWithPreviousLocation(
                            `/app/projects/${project.id}/project-address`,
                          )
                        }
                        style={{ minWidth: 'unset', width: 'fit-content' }}
                      />
                    </HelpMessageContent>
                  }
                />
              </HelpMessageDiv>
            )}
          </Grid.Column>

          <Grid.Column largeScreen={8} computer={9} tablet={16} mobile={16}>
            <TimelineCard
              postalCode={
                projectAddress?.postalCode || primaryProspect?.zipCode
              }
              url={`/app/projects/${project.id}/timeline`}
              useWeeks
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default Timeline

const HelpMessageContent = styled.div`
  align-items: center;
  column-gap: 16px;
  display: flex;
  row-gap: 8px;

  @media ${Breakpoint.onlyMobile} {
    flex-direction: column;
    justify-content: center;

    & > button {
      width: 100%;
    }
  }
`

const HelpMessageDiv = styled.div`
  margin-bottom: 32px;
  margin-top: 32px;
`
