import React from 'react'

import { Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import NextStepsCarousel from 'design-system/components/guide/next-steps-carousel'
import { Section } from 'design-system/components/guide/utils'

const nextSteps = [
  {
    leadText:
      'To move forward into design development, the next step is to pay a <span class="bold">5% Trade Deposit</span>.',
    description:
      'This will be based off of the proposal pricing and is redeemable towards the project total.',
    image: `images/interactive-guides/project-proposal/next-step-project-proposal-01.svg`,
  },
  {
    leadText:
      'You and your designer will review any additional feedback before jumping into <span class="bold">the next revision</span>.',
    image: `images/interactive-guides/project-proposal/next-step-project-proposal-02.svg`,
  },
  {
    leadText:
      'You can <span class="bold">add or subtract scope</span> as needed. An <span class="bold">additional 5% deposit</span>  will be required for spaces that require <span class="bold">more design work</span>, which is fully redeemable towards that room.',
    image: `images/interactive-guides/project-proposal/next-step-project-proposal-03.svg`,
  },
]

const NextSteps = () => {
  const { isTradeProject } = useProject()

  return (
    <Section id="next-steps">
      <Grid centered>
        <NextStepsCarousel
          isTradeProject={isTradeProject}
          slideData={nextSteps}
        />
      </Grid>
    </Section>
  )
}

export default NextSteps
