import React from 'react'

import PreProjectDepositProvider from 'context/project/pre-project-deposit/provider'

import PreProjectDepositDashboard from './dashboard'

const PreProjectDepositIndex = () => {
  return (
    <PreProjectDepositProvider>
      <PreProjectDepositDashboard />
    </PreProjectDepositProvider>
  )
}

export default PreProjectDepositIndex
