import React, { useState } from 'react'

import { navigate } from 'gatsby'
import { useMedia } from 'react-use'
import { Divider, Grid } from 'semantic-ui-react'

import GenericOptionSelectionCard from 'components/shared/catalog/generic-option/card'
import RoomElementOptionSelectionCard from 'components/shared/catalog/room-element-option/card'
import useAuth from 'context/auth/use'
import useRoom from 'context/room/use'
import Button from 'design-system/components/button'
import Carousel from 'design-system/components/carousel'
import ExpandImage from 'design-system/components/guide/expand-image'
import SelectionCover from 'design-system/components/guide/selection-cover'
import { Section, Spacer } from 'design-system/components/guide/utils'
import { SelectionCardVariation } from 'design-system/components/selection-card'
import { Breakpoint } from 'styles/app/system'

const CDN = process.env.GATSBY_CDN_ROOT
const backsplashesConfig = [
  {
    src: `${CDN}/images/interactive-guides/design-call/backsplash-short.webp`,
    title: 'Short Backsplash',
  },
  {
    src: `${CDN}/images/interactive-guides/design-call/backsplash-mixed.webp`,
    title: 'Mixed Backsplash',
  },
  {
    src: `${CDN}/images/interactive-guides/design-call/backsplash-full.webp`,
    title: 'Full Backsplash',
  },
]

interface ElementsProps {
  header?: JSX.Element
  projectId: string
  roomId: string
}

const Elements = (props: ElementsProps) => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const [elementStyleIdx, setelementStyleIdx] = useState<number | null>(null)
  const { isFORMStaff } = useAuth()
  const { backsplashMaterials, countertopMaterials, floorMaterials } = useRoom()

  return (
    <>
      <Section id="elements">
        <Grid centered>
          {props.header}
          <Grid.Row>
            <Grid.Column textAlign="center">
              <h3 className="gray">Other finishes</h3>
              <p>
                These selections are for rendering purposes only. Currently we
                aren’t able to supply flooring, however countertops and
                backsplashes may be available in select areas.
              </p>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          {isMobile ? (
            <Grid.Row>
              <Grid.Column>
                <Carousel
                  slides={backsplashesConfig.map((v, idx) => ({
                    id: idx,
                    item: (
                      <ExpandImage
                        activeIndex={elementStyleIdx}
                        idx={idx}
                        images={backsplashesConfig.map((v) => v.src)}
                        onClose={() => setelementStyleIdx(null)}
                        src={v.src}
                        title={v.title}
                      />
                    ),
                  }))}
                  hideNavButtons
                  slideSize={80}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row stretched columns={3} className="row-gap">
              {backsplashesConfig.map((v, idx) => (
                <Grid.Column key={v.title}>
                  <ExpandImage
                    activeIndex={elementStyleIdx}
                    idx={idx}
                    images={backsplashesConfig.map((v) => v.src)}
                    onClose={() => setelementStyleIdx(null)}
                    src={v.src}
                    title={v.title}
                  />
                </Grid.Column>
              ))}
            </Grid.Row>
          )}
          <Spacer />
          <Grid.Row centered className="row-gap">
            <Grid.Column computer={5} tablet={16} mobile={16}>
              {!backsplashMaterials?.length ? (
                <SelectionCover
                  buttonEnabled
                  buttonText={isFORMStaff ? 'Select' : 'See options'}
                  image="design-call/kickoff-backsplash.png"
                  onClick={() =>
                    navigate(
                      `/app/projects/${props.projectId}/rooms/${props.roomId}/selection/backsplash-selection`,
                    )
                  }
                  small
                  title="Backsplash"
                />
              ) : (
                <Grid>
                  <Grid.Row centered className="row-gap">
                    <Grid.Column computer={13} tablet={8} mobile={16}>
                      <div
                        className="flex-center"
                        style={{ justifyContent: 'space-between' }}
                      >
                        <p className="caption no-margin">Backsplash</p>
                        <Button
                          fontAwesomeIcon="arrow-right-long"
                          kind="ghost"
                          onClick={() =>
                            navigate(
                              `/app/projects/${props.projectId}/rooms/${props.roomId}/selection/backsplash-selection`,
                            )
                          }
                          text={isFORMStaff ? 'Change' : 'See options'}
                        />
                      </div>
                    </Grid.Column>
                    {backsplashMaterials.map((f) => (
                      <Grid.Column
                        key={f.id}
                        computer={13}
                        tablet={8}
                        mobile={16}
                      >
                        <div
                          style={{
                            border: '1px solid #E7E5E4',
                            borderRadius: '4px',
                          }}
                        >
                          <GenericOptionSelectionCard
                            genericOption={f}
                            selectionCardProps={{
                              expandOnly: true,
                              imageRatio: '1/1',
                            }}
                            selectionCardVariation={
                              SelectionCardVariation.EXPAND_SELECT
                            }
                          />
                        </div>
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                  <Divider className="only-mobile" hidden />
                </Grid>
              )}
            </Grid.Column>
            <Grid.Column computer={5} tablet={16} mobile={16}>
              {!countertopMaterials?.length ? (
                <SelectionCover
                  buttonEnabled
                  buttonText={isFORMStaff ? 'Select' : 'See options'}
                  image="design-call/kickoff-countertop.png"
                  onClick={() =>
                    navigate(
                      `/app/projects/${props.projectId}/rooms/${props.roomId}/selection/countertop-selection`,
                    )
                  }
                  small
                  title="Countertop"
                />
              ) : (
                <Grid>
                  <Grid.Row centered className="row-gap">
                    <Grid.Column computer={13} tablet={8} mobile={16}>
                      <div
                        className="flex-center"
                        style={{ justifyContent: 'space-between' }}
                      >
                        <p className="caption no-margin">Countertop</p>
                        <Button
                          fontAwesomeIcon="arrow-right-long"
                          kind="ghost"
                          onClick={() =>
                            navigate(
                              `/app/projects/${props.projectId}/rooms/${props.roomId}/selection/countertop-selection`,
                            )
                          }
                          text={isFORMStaff ? 'Change' : 'See options'}
                        />
                      </div>
                    </Grid.Column>
                    {countertopMaterials.map((f) => (
                      <Grid.Column
                        key={f.id}
                        computer={13}
                        tablet={8}
                        mobile={16}
                      >
                        <div
                          style={{
                            border: '1px solid #E7E5E4',
                            borderRadius: '4px',
                          }}
                        >
                          <GenericOptionSelectionCard
                            genericOption={f}
                            selectionCardProps={{
                              expandOnly: true,
                              imageRatio: '1/1',
                            }}
                            selectionCardVariation={
                              SelectionCardVariation.EXPAND_SELECT
                            }
                          />
                        </div>
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                  <Divider className="only-mobile" hidden />
                </Grid>
              )}
            </Grid.Column>
            <Grid.Column computer={5} tablet={16} mobile={16}>
              {!floorMaterials?.length ? (
                <SelectionCover
                  buttonEnabled
                  buttonText={isFORMStaff ? 'Select' : 'See options'}
                  image="design-call/kickoff-flooring.png"
                  onClick={() =>
                    navigate(
                      `/app/projects/${props.projectId}/rooms/${props.roomId}/selection/flooring-selection`,
                    )
                  }
                  small
                  title="Flooring"
                />
              ) : (
                <Grid>
                  <Grid.Row centered className="row-gap">
                    <Grid.Column computer={13} tablet={8} mobile={16}>
                      <div
                        className="flex-center"
                        style={{ justifyContent: 'space-between' }}
                      >
                        <p className="caption no-margin">Flooring</p>
                        <Button
                          fontAwesomeIcon="arrow-right-long"
                          kind="ghost"
                          onClick={() =>
                            navigate(
                              `/app/projects/${props.projectId}/rooms/${props.roomId}/selection/flooring-selection`,
                            )
                          }
                          text={isFORMStaff ? 'Change' : 'See options'}
                        />
                      </div>
                    </Grid.Column>
                    {floorMaterials.map((f) => (
                      <Grid.Column
                        key={f.id}
                        computer={13}
                        tablet={8}
                        mobile={16}
                      >
                        <div
                          style={{
                            border: '1px solid #E7E5E4',
                            borderRadius: '4px',
                          }}
                        >
                          <RoomElementOptionSelectionCard
                            roomElementOption={f}
                            selectionCardProps={{
                              expandOnly: true,
                              imageRatio: '1/1',
                            }}
                            selectionCardVariation={
                              SelectionCardVariation.EXPAND_SELECT
                            }
                          />
                        </div>
                      </Grid.Column>
                    ))}
                  </Grid.Row>
                  <Divider className="only-mobile" hidden />
                </Grid>
              )}
            </Grid.Column>
          </Grid.Row>
          {!isMobile && <Spacer height={150} />}
        </Grid>
      </Section>
    </>
  )
}

export default Elements
