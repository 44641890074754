import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import useProject from 'context/project/use'
import HexImage from 'design-system/components/guide/hex-image'
import { Section } from 'design-system/components/guide/utils'

const ThanYou = () => {
  const { accountExecutive, primaryDesigner, techDesigner } = useProject()
  return (
    <Section id="thank-you">
      <Grid relaxed="very" centered>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <p className="subtitle">
              From everyone who worked on your project so far
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          {accountExecutive && (
            <Grid.Column largeScreen={3} computer={4} tablet={5} mobile={8}>
              <HexImage gray rotated src={accountExecutive.picture} />
            </Grid.Column>
          )}
          {primaryDesigner && (
            <Grid.Column largeScreen={3} computer={4} tablet={5} mobile={8}>
              <HexImage gray rotated src={primaryDesigner.picture} />
            </Grid.Column>
          )}
          {techDesigner && (
            <Grid.Column largeScreen={3} computer={4} tablet={5} mobile={8}>
              <HexImage gray rotated src={techDesigner.picture} />
            </Grid.Column>
          )}
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h1 style={{ fontStyle: 'italic' }}>It’s been a pleasure!</h1>
            <h3>We appreciate you for choosing FORM.</h3>
            <p>
              Our whole team is always looking for ways to improve and value
              feedback, so we’d love to hear from you. When your project is
              complete, we hope you’ll share the results - it’s always exciting
              for us to see photos :)
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Section>
  )
}

export default ThanYou
