import React from 'react'

import { useMatch } from '@reach/router'
import { navigate } from 'gatsby'

import HeaderBasic from 'components/app/project/header/header-basic'
import { useProjectMessageMany } from 'context/project/message/provider-many'

const Header = () => {
  const match = useMatch('/app/projects/:project_id/*')
  const { refetch: refetchMessages } = useProjectMessageMany()
  const isInternalReferrer =
    document.referrer.startsWith(window.location.origin) ||
    window.history.state?.key

  const handleOnBack = () => {
    if ((history.length > 1 && isInternalReferrer) || !match?.project_id) {
      navigate(-1)
    } else {
      navigate(`/app/projects/${match.project_id}`)
    }
    refetchMessages()
  }

  return <HeaderBasic title="My Messages" onBack={handleOnBack} />
}

export default Header
