import React, { useState } from 'react'

import { useMedia } from 'react-use'
import { Divider, Grid, Image } from 'semantic-ui-react'

import ImageCarousel from 'design-system/components/carousel/image-carousel'
import ExpandImage from 'design-system/components/guide/expand-image'
import { Section, Spacer } from 'design-system/components/guide/utils'
import { Breakpoint } from 'styles/app/system'

const finalKitchens = [
  `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/introducing-form-01.webp`,
  `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/introducing-form-02.webp`,
  `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/introducing-form-03.webp`,
  `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/introducing-form-04.webp`,
  `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/introducing-form-05.webp`,
  `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/introducing-form-06.webp`,
  `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/introducing-form-07.webp`,
  `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/introducing-form-08.webp`,
  `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/introducing-form-09.webp`,
  `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/introducing-form-10.webp`,
]

const visuals = [
  {
    src: `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/photo-of-space.webp`,
    title: 'Photo of the space',
  },
  {
    src: `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/other-studio-visual.webp`,
    title: 'Typical visuals provided by other studios',
  },
  {
    src: `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/form-visual.webp`,
    title: 'FORM photorealistic visuals',
  },
  {
    src: `${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/form-kitchen.webp`,
    title: 'Photo of final kitchen',
  },
]

const WhatWeOffer = () => {
  const isMobile = useMedia(Breakpoint.onlyMobile)
  const isDownFromComputer = useMedia(Breakpoint.downFromComputer)
  const [visualsIndex, setVisualsIndex] = useState<number | null>(null)

  return (
    <Section id="about-form">
      <Grid padded stackable>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h3 className="gray">
              We supply made-to-order German cabinetry, beautifully designed to
              help you get the most out of your space.
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={16}>
            <ImageCarousel images={finalKitchens} />
          </Grid.Column>
        </Grid.Row>
        <Spacer height={isMobile ? 100 : 300} />
        <Grid.Row>
          <Grid.Column tablet={16} computer={8} verticalAlign="middle">
            <h3>
              Keep track of your project through a personalized online dashboard
            </h3>
            <Spacer height={40} />
            <p className="gray">
              Your project details, resources and communications with our teams
              live in on one platform for easy access.
            </p>
          </Grid.Column>
          <Grid.Column tablet={16} computer={8}>
            <Image
              src={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/free-consultation-work-with-designer.svg`}
            />
          </Grid.Column>
        </Grid.Row>
        <Spacer height={isMobile ? 100 : 300} />
        <Grid.Row>
          <Grid.Column>
            <Section>
              <Grid textAlign="center" padded>
                <Grid.Row>
                  <Grid.Column verticalAlign="middle">
                    <h3
                      className="no-margin gray"
                      style={{ textAlign: 'left' }}
                    >
                      We then bring your design to life with our photorealistic
                      visuals.
                    </h3>
                  </Grid.Column>
                </Grid.Row>
                <Divider hidden />
                <Grid.Row className="row-gap" stretched>
                  {isDownFromComputer ? (
                    <ImageCarousel images={visuals.map((v) => v.src)} />
                  ) : (
                    visuals.map((v, idx) => (
                      <Grid.Column key={v.title} width={8}>
                        <ExpandImage
                          activeIndex={visualsIndex}
                          idx={idx}
                          images={visuals.map((v) => v.src)}
                          onClose={() => setVisualsIndex(null)}
                          src={v.src}
                          title={v.title}
                        />
                      </Grid.Column>
                    ))
                  )}
                </Grid.Row>
              </Grid>
            </Section>
          </Grid.Column>
        </Grid.Row>
        <Spacer height={isMobile ? 100 : 300} />
        <Grid.Row>
          <Grid.Column tablet={16} computer={8} verticalAlign="middle">
            <h3 className="no-margin gray">
              Our online model allows us to offer prices up to 40% less than
              other showrooms.
            </h3>
          </Grid.Column>
          <Grid.Column tablet={16} computer={8}>
            <Image
              src={`${process.env.GATSBY_CDN_ROOT}/images/interactive-guides/free-consultation/price-comparison.svg`}
            />
          </Grid.Column>
        </Grid.Row>
        <Spacer height={isMobile ? 100 : 300} />
      </Grid>
    </Section>
  )
}

export default WhatWeOffer
