import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RouteComponentProps } from '@reach/router'
import { Grid } from 'semantic-ui-react'

import StepTitle from 'components/app/step-title'
import Button from 'design-system/components/button'
import AppLayout from 'design-system/containers/app-layout'
import HexBulletRow from 'design-system/containers/hex-bullet-row/hex-bullet-row'
import { Colors } from 'styles/app/system'
import { resourcesUrls } from 'views/utils'

export const InstallationResources = (_: RouteComponentProps) => {
  return (
    <AppLayout>
      <Grid stackable>
        <Grid.Row centered>
          <Grid.Column computer={12} tablet={12} mobile={16} textAlign="center">
            <StepTitle
              title="Prepare for Installation"
              subTitle="Please refer to these items prior to beginning construction or installation on your project. Be sure to review all of the relevant information to ensure a successful installation."
            />
          </Grid.Column>
        </Grid.Row>
        <HexBulletRow
          color={Colors.green600}
          description="Blocking is required when installing FORM cabinetry in order to provide a  safe and secure anchor for the cabinets. It is especially critical for wall cabinets which install on individual mounting rails. Floating shelves also require blocking as they are not easily installed on stud locations.
          <br /><br />
          If you are unsure where to install the blocking on your project, please contact our Customer Success team.
          <br /><br />
          If you purchased lighting from FORM, please be aware it may be necessary to add / move outlets or switches depending on the configuration of your project. Please review our FAQs for more information or contact our team with questions."
          hexText="1"
          image="images/app/prepare-installation-01.webp"
          title="Prepare the space for installation"
        />
        <HexBulletRow
          color={Colors.orange500}
          description="We recommend scheduling a call with our Customer Success team to review the specific details of your project. We ask that your installer or project manager join as well to make sure that everything is installed correctly and as designed. It is best to time the call within 2-3 weeks of installation.
          <br /><br />
          Our team will reach out to schedule this call once your project has arrived in the US."
          hexText="2"
          image="images/app/prepare-installation-02.webp"
          title="Schedule a pre-Installation call with our team"
        />
        <HexBulletRow
          color={Colors.red500}
          description="Please review this document(s) and follow it very closely during the installation process. This document contains: intended location for each cabinet, detailed measurements of the cabinetry locations, trim or panels. It also contains countertop / backsplash dimensions."
          hexText="3"
          image="images/app/prepare-installation-03.webp"
          title="Installation Pack"
        />
        <HexBulletRow
          button={
            <Button
              color="blue"
              fontAwesomeIcon="arrow-down-to-line"
              kind="solid"
              onClick={() =>
                window.open(
                  `${process.env.GATSBY_CDN_ROOT}/files/FORM-GENERAL-INSTALLATION-MANUAL.pdf`,
                )
              }
              text="Download"
            />
          }
          color={Colors.gray500}
          description="Complete guide to installing FORM cabinetry."
          hexText="4"
          image="images/app/prepare-installation-04.webp"
          title="Installation Manual"
        />
        <HexBulletRow
          button={
            <Button
              color="blue"
              icon={
                <FontAwesomeIcon icon={['fal', 'arrow-up-right-from-square']} />
              }
              kind="solid"
              onClick={() =>
                window.open(
                  'https://formkitchens.notion.site/General-Installation-Manual-86ae3c71b079479293df8a388e98ae46',
                )
              }
              text="Go to videos"
            />
          }
          color={Colors.blue500}
          description="Step by step videos detailing the installation of FORM cabinetry and accessories."
          hexText="5"
          image="images/app/prepare-installation-05.webp"
          title="Installation Videos"
        />
        <HexBulletRow
          color={Colors.green500}
          description="This is a compilation of the appliance specifications that were
          taken into consideration for your design. Please continue to refer
          to the manufacturer for the most definitive installation information
          and guidance available."
          hexText="6"
          image="images/app/prepare-installation-06.webp"
          title="Appliance Specifications"
        />
        <HexBulletRow
          button={
            <Button
              color="blue"
              fontAwesomeIcon="arrow-down-to-line"
              kind="solid"
              onClick={() => window.open(resourcesUrls.careAndMaintenance)}
              text="Download"
            />
          }
          color={Colors.blue600}
          description="Care and maintenance instructions for FORM cabinetry."
          hexText="7"
          image="images/app/prepare-installation-07.webp"
          title="Care and Maintenance"
        />
      </Grid>
    </AppLayout>
  )
}

export default InstallationResources
