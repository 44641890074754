import React from 'react'

import { Container, Grid } from 'semantic-ui-react'
import styled from 'styled-components'

import CatalogFeatureSelectionCard from 'components/shared/catalog/catalog-feature/card'
import useRoom from 'context/room/use'
import {
  SelectionCardVariation,
  SelectionCardExpandSelectProps,
} from 'design-system/components/selection-card'
import { Colors } from 'styles/app/system'

import SectionTitle from './section-title'

const Functionality = () => {
  const { catalogFeatures } = useRoom()
  return catalogFeatures?.length ? (
    <MainContainer>
      <Container>
        <Grid>
          <SectionTitle
            subtitle="These are the special features that we selected for your kitchen. The more we include the more expensive the kitchen is likely to be. We can always simplify and reduce the cost."
            title="Functionality"
          />
          <Grid.Row style={{ rowGap: '30px' }}>
            {catalogFeatures.map((f) => (
              <Grid.Column computer={4} tablet={5} mobile={8}>
                <CatalogFeatureSelectionCard
                  catalogFeature={f}
                  selectionCardProps={
                    {
                      imageRatio: '1/1',
                      expandOnly: true,
                    } as Partial<SelectionCardExpandSelectProps>
                  }
                  selectionCardVariation={SelectionCardVariation.EXPAND_SELECT}
                />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </Container>
    </MainContainer>
  ) : null
}

export default Functionality

const MainContainer = styled.section`
  background: ${Colors.gray50};
  padding: 70px 0 130px;
`
