import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from 'semantic-ui-react'

import ConfirmationModal from 'components/shared/confirmation-modal'
import HelpMessage from 'components/shared/help-message'
import {
  CloseIconMobile,
  InitialContainer,
} from 'components/shared/project/project-file/utils'
import useDesign from 'context/design/use'
import useProject from 'context/project/use'
import useProjectFile from 'context/project-file/use'
import useProjectFileMutate from 'context/project-file/use-mutate'
import useRoom from 'context/room/use'
import Button from 'design-system/components/button'

import ApprovalModal from './modal-approval'

const ApprovalSidebar = ({ sidebarToggle }: { sidebarToggle: () => void }) => {
  const { isTradeProject } = useProject()
  const { projectFile } = useProjectFile()
  const { appliancesIncluded, isPrimaryRoom, initialDesign, room } = useRoom()
  const { design, isAddOnDesign } = useDesign()
  const { loadingUpdate, updateProjectFileAndRefetch } = useProjectFileMutate()

  const [showApprovalModal, toggleApprovalModal] = useState<boolean>(false)
  const [showApprovalAddOnModal, toggleApprovalAddOnModal] =
    useState<boolean>(false)
  const [showChangesModal, toggleChangesModal] = useState<boolean>(false)

  const isInitialDesign = initialDesign.id === design?.id

  return (
    <InitialContainer>
      <CloseIconMobile onClick={sidebarToggle}>
        <FontAwesomeIcon icon={['fal', 'times']} />
      </CloseIconMobile>
      {!isTradeProject && isPrimaryRoom && isInitialDesign ? (
        <>
          <h4>Review your Design Plan</h4>
          <p>Please carefully review this document.</p>
          <Button
            kind="solid"
            onClick={async () => {
              await updateProjectFileAndRefetch({
                variables: {
                  data: {
                    metadata: {
                      ...projectFile.metadata,
                      changesRequestedAt: new Date(),
                    },
                  },
                  where: {
                    id: projectFile.id,
                  },
                },
              })
            }}
            size="fluid"
            style={{ marginTop: '15px' }}
            text="Start Revision"
          />
        </>
      ) : (
        <>
          <h4>Review your Design Plan</h4>
          <p>
            Please carefully review this document.
            <br />
            Click on <b>"Approve Design Plan"</b> when you are ready to move
            forward with your order.
          </p>
          {(appliancesIncluded && !room?.data.appliancesConfirmedAt) ||
          !room?.data?.measurementsConfirmedAt ? (
            <HelpMessage
              icon="triangle-exclamation"
              message={`Please confirm all ${
                appliancesIncluded ? '<b>appliances</b> and ' : ''
              }<b>measurements</b> first before approving your design.`}
              title="Important"
            />
          ) : null}
          <Divider hidden />
          {isAddOnDesign ? (
            <Button
              kind="solid"
              size="fluid"
              text="Approve Design Plan"
              onClick={() => toggleApprovalAddOnModal(true)}
            />
          ) : (
            <ApprovalModal
              showApprovalModal={showApprovalModal}
              toggleApprovalModal={toggleApprovalModal}
              trigger={
                <Button
                  disabled={
                    (appliancesIncluded &&
                      !room?.data?.appliancesConfirmedAt) ||
                    !room?.data?.measurementsConfirmedAt
                  }
                  kind="solid"
                  size="fluid"
                  text="Approve Design Plan"
                />
              }
            />
          )}
          <Button
            color="blue"
            kind="outlined"
            onClick={() => toggleChangesModal(true)}
            size="fluid"
            style={{ marginTop: '15px' }}
            text="Make More Changes"
          />
        </>
      )}
      <ConfirmationModal
        confirmText="Yes, Make Changes"
        description="Changes might affect your project timeline."
        firstTitle="Are you sure you want to make changes?"
        loading={loadingUpdate}
        onCancel={() => toggleChangesModal(false)}
        onConfirm={async () => {
          await updateProjectFileAndRefetch({
            variables: {
              data: {
                metadata: {
                  ...projectFile.metadata,
                  changesRequestedAt: new Date(),
                },
              },
              where: {
                id: projectFile.id,
              },
            },
          })
          toggleChangesModal(false)
        }}
        open={showChangesModal}
      />
      <ConfirmationModal
        confirmText="Approve Design"
        description="By approving, you acknowledge that you have reviewed this design in full and would like to send it into production."
        firstTitle="Ready to approve your design?"
        loading={loadingUpdate}
        onCancel={() => toggleApprovalAddOnModal(false)}
        onConfirm={async () => {
          await updateProjectFileAndRefetch({
            variables: {
              data: {
                approvedAt: new Date(),
              },
              where: {
                id: projectFile.id,
              },
            },
          })
          toggleApprovalAddOnModal(false)
        }}
        open={showApprovalAddOnModal}
      />
    </InitialContainer>
  )
}

export default ApprovalSidebar
